import React from 'react';
import { Route, Switch, Redirect } from "react-router-dom";
import Axios from "axios";
import { _envieronment } from './components/FetchCmp/fetchCmp'
// import logo from './assets/img/corttLogoO.png';
import logoSymbol from './assets/img/corttC.png';
import './App.css';
import './assets/scss/Cortteza.scss';
import AdminNavbar from "./components/Navbars/AdminNavbar.jsx";
import Sidebar from "./components/Sidebar/Sidebar.jsx";
import BucketContext from './components/FetchCmp/BucketContext';
import FetchCmp from './components/FetchCmp/fetchCmp';
import Cache from "./cache";
import Login from './pages/Login/Login.jsx';
import UserLogin from './pages/Login/UserLogin.jsx';
import BucketSelector from "./pages/Buckets/BucketSelector.jsx";
import Dashboard from "./pages/Dashboard/Dashboard.jsx";
import Segments from "./pages/Segments/Segments.jsx";
import MySegments from "./pages/SegmentCreator/MySegments.jsx";
import MyExports from "./pages/Exports/MyExports.jsx";
import Campaigns from "./pages/Campaigns/Campaigns.jsx";
import Admin from "./pages/Admin/Admin.jsx";
import CRM from './pages/Crm/Crm';
//Plugins






class App extends React.Component {
  constructor(props) {
    super(props)
    this.mainPanelRef = React.createRef();
    this.handleLangChange = this.handleLangChange.bind(this);
    this.changeBucket = this.changeBucket.bind(this);
    this.changeBucketGeneralConfigurations = this.changeBucketGeneralConfigurations.bind(this);
    this.changeBucketCampaignsConfiguration = this.changeBucketCampaignsConfiguration.bind(this);
    this.toggleCampaignRute = this.toggleCampaignRute.bind(this);
    this.changeAppState = this.changeAppState.bind(this);
    this.login = this.login.bind(this);
    this.logout = this.logout.bind(this);
    var Rtoken = sessionStorage.getItem('token');
    var _t = null;
    var _a = false;
    if (Rtoken != null) {
      _t = Rtoken;
      _a = true
    }
    this.state = {
      auth: _a,
      appState: 'bucketSelector',
      bucket: {},
      token: _t,
      isAdmin: { status: false, adminData: null },
      lang: 'es',
      backgroundColor: 'default',
      sidebarOpened: document.documentElement.className.indexOf("nav-open") !== -1,
      userLogin: false,
    };
    this.cache = new Cache();
  }

  async componentDidMount() {
    var token = null;
    var route = this.props.location.pathname.split("/");

    if (route[1] == "token" && route[2] == "access") {
      token = this.props.location.search;
      token = token.substring(1);
      // token = token[1];
    }

    if (route[1] == 'userlogin') {
      this.setState({ userLogin: true })
    }
    else {
      this.setState({ userLogin: false });
    }

    if (token !== null) {
      console.log('Token:', token);
      var url = _envieronment.get('tfa') + "token/validate";
      let res = await Axios.post(url, { tokenstring: token, code: "" });
      // console.log(res)
      // console.log('TokenResponse:',res.data.data.token);
      this.storeInCache("token", res.data.data.token)
      this.setState(() => ({ auth: true, token: res.data.data.token }))
      // window.location.pathname = "";
    }
  }

  // 1. LENGUAGE AND THEMING FUNCTIONS  ------------------------------------------------------------------------------------

  handleLangChange() {
    this.setState(state => {
      var lang = this.state.lang == "es" ? "en" : "es";
      return ({ lang: lang })
    })
  }

  getBrandText = path => {
    var title = path.substring(1, path.length);
    if (path === "/") return "Cortteza";

    return title;
  };

  toggleSidebar = () => {
    document.documentElement.classList.toggle("nav-open");
    this.setState({ sidebarOpened: !this.state.sidebarOpened });
  };

  // 2. BROWSER CACHE FUNCTIONS -------------------------------------------------------------------------------------

  storeInCache(key, value) {
    sessionStorage.setItem(key, value);
  }

  removeFromCache(key) {
    sessionStorage.removeItem(key);
  }

  getFromCache(key) {
    sessionStorage.getItem(key);
  }

  // 3. ACCESS FUNCTIONS ----------------------------------------------------------------------------------------------

  login(token) {
    this.storeInCache("token", token)
    this.setState(() => ({ auth: true, token: token }))
  }

  logout() {
    this.removeFromCache('token');
    this.removeFromCache('appState')
    this.setState(() => ({ auth: false, token: null }))
  }

  // 4. Bucket Utility Functions --------------------------------------------------------------------------------------

  changeBucketGeneralConfigurations(newConfiguration) {
    this.setState(s => {
      s.bucket['name'] = newConfiguration['name'];
      s.bucket['currencySymbol'] = newConfiguration['currencySymbol'];
      s.bucket['companyLogoCDNUrl'] = newConfiguration['companyLogoCDNUrl'];
      s.bucket['region'] = newConfiguration['region'];
      s.bucket['genderSeparation'] = newConfiguration['genderSeparation'];
      s.bucket['additionalConfig'] = newConfiguration['additionalConfig'];
      return s;
    })
  }

  changeBucketCampaignsConfiguration(newConfiguration) {
    this.setState(s => {
      s.bucket['campaignsConfiguration'] = newConfiguration;
      return s;
    })
  }

  toggleCampaignRute(bool) {
    if (bool === undefined) { return }
    if (bool) {
      this.setState(s => {

        var exists = false;
        s.bucket['menuOptions'].forEach(r => { if (r.path === "campaigns") { exists = true } })
        if (exists == false) {
          s.bucket['menuOptions'].push({
            "path": "campaigns",
            "title": "menuCampaigns",
            "icon": "ti-email"
          })
        }

        return { bucket: s.bucket };
      })
    }
    else {
      this.setState(s => {
        var newMenu = s.bucket['menuOptions'].filter(obj => { if (obj.path === 'campaigns') { return false } else { return true } });
        s.bucket['menuOptions'] = newMenu;
        return { bucket: s.bucket };
      })
    }
  }


  // 4. NAVIGATION FUNCTIONS -------------------------------------------------------------------------------------------

  changeBucket(bucket, isAdmin) {
    this.setState(() => ({ bucket: bucket, backgroundColor: "default.css", isAdmin: isAdmin }));
    this.changeAppState('hasBucket');
  }

  changeAppState(state) {
    this.storeInCache('appState', state)
    if (state == 'bucketSelector') {
      this.setState(() => ({ appState: state, backgroundColor: 'default' }))
    }
    else {
      this.setState(() => ({ appState: state }))
    }

  }

  createRoutesForSidebar = (routes) => {
    return routes.map((route, key) => {
      switch (route.path) {
        case 'dashboard':
          return (
            {
              path: route.path,
              name: route.title,
              icon: route.icon,
            }
          );
          break;

        case 'segments':
          return (
            {
              path: route.path,
              name: route.title,
              icon: route.icon,
            }
          );
          break;

        case 'filters':
          return (
            {
              path: 'mysegments',
              name: route.title,
              icon: route.icon,
            }
          );
          break;

        case 'myexports':
          return (
            {
              path: 'myexports',
              name: route.title,
              icon: route.icon,
            }
          );
          break;

        case 'campaigns':
          return (
            {
              path: 'campaigns',
              name: route.title,
              icon: route.icon,
            }
          );
          break;

        case 'crm':
          return (
            {
              path: 'crm',
              name: route.title,
              icon: route.icon,
            }
          );
          break;

        default:
          //uncoment to show the rest of the routes
          // return (
          //   {
          //     path: route.path,
          //     name: route.title,
          //     icon: route.icon,
          //   }
          // );
          return null
          break;
      }
    });
  }

  createRoutesForRouter = (routes) => {
    var r = routes.map((prop, key) => {
      var component = null;
      switch (prop.path) {
        case 'dashboard':
          component = Dashboard;
          break;

        case 'segments':
          component = Segments;
          break;

        case 'filters':
          component = MySegments;
          break;

        case 'myexports':
          component = MyExports;
          break;

        case 'campaigns':
          component = Campaigns;
          break;

        case "crm": component = CRM; break;
        default:
          component = Dashboard;
          break;
      }
      return (
        <Route
          path={prop.path == 'filters' ? "/mysegments" : "/" + prop.path}
          component={component}
          key={key}
        />
      );

    });

    if (this.state.isAdmin.status) {
      r.push(
        <Route
          path={'/admin'}
          component={Admin}
          key={routes.length + 1}
        />
      )
    }

    return r;
  };

  // 5. PRE-RENDER METHODS TO DETERMINE WHAT TO PAINT IN SCREEN ---------------------------------------------------------
  getSidebar(state) {
    switch (state) {
      case 'hasBucket':
        return <Sidebar {...this.props}
          routes={this.createRoutesForSidebar(this.state.bucket.menuOptions)}
          bgColor={this.state.bucket.theme}
          logo={this.state.bucket.companyLogoCDNUrl}
          toggleSidebar={this.toggleSidebar}
          lang={this.state.lang}
          handleLangChange={this.handleLangChange}
          isAdmin={this.state.isAdmin.status}
        />

      default: return <Sidebar {...this.props}
        routes={[]}
        bgColor={this.state.backgroundColor}
        logo={logoSymbol}
        toggleSidebar={this.toggleSidebar}
        lang={this.state.lang}
        handleLangChange={this.handleLangChange}
      />

    }
  }

  getMainPanel(state) {
    switch (state) {
      case 'hasBucket':
        return <Switch>{this.createRoutesForRouter(this.state.bucket.menuOptions)} <Redirect to="/dashboard" /></Switch>;
        //return <div>To do...</div>
        break;
      case 'bucketSelector':
        return <FetchCmp render={props =>
          <BucketSelector
            askFor={props.askFor}
            handleBucketChange={this.changeBucket}
            logout={props.logout}
            lang={this.state.lang}
            token={this.state.token}
            validateAdminToken={props.validateAdminToken}
          />}
        />;
        break;

      default: return <div>Default</div>

    }
  }

  // 6. RENDER -----------------------------------------------------------------------------------------------------------
  render() {

    return (
      <>
        {this.state.auth ? //Verifica que el ususario este logeado, para renderizar el login o el dashboard
          <div className="wrapper">
            {this.getSidebar(this.state.appState)}


            <div className="main-panel" ref={this.mainPanelRef}>
              <AdminNavbar {...this.props}
                brandText={this.getBrandText(this.props.location.pathname)}
                sidebarOpened={this.state.sidebarOpened}
                handleBucketButton={this.changeAppState}
                bucketName={this.state.bucket.name}
                bucketId={this.state.bucket.bucketId}
                toggleSidebar={this.toggleSidebar}
              />


              <BucketContext.Provider value={{
                bucket: this.state.bucket,
                lang: this.state.lang,
                token: this.state.token,
                adminData: this.state.isAdmin.adminData,
                logout: this.logout,
                cache: this.cache,
                scrollToTop: this.scrollToTop,
                changeBucketCampConf: this.changeBucketCampaignsConfiguration,
                changeBucketGeneralConfigurations: this.changeBucketGeneralConfigurations,
                toggleCampaignRute: this.toggleCampaignRute
              }}
              >
                {this.getMainPanel(this.state.appState)}
              </BucketContext.Provider>


            </div>
          </div>
          :
          this.state.userLogin ?
            <UserLogin login={this.login} />
            :
            <Login login={this.login} />
        }
      </>
    );
  }
}



export default App;
