import React, { useState, useEffect, useRef } from "react";
import ReactTooltip from "react-tooltip";
import EmailEditor from 'react-email-editor';
import {
    Card, CardBody,
    Container, Row, Col,
    Button,
    Input,
    Modal, ModalBody, ModalHeader
} from "reactstrap";
import { CSSTransition } from "react-transition-group"
import DragnDropIcon from '../../../../assets/icons/createCampaign/dragndrop.png';
import CuponIcon from '../../../../assets/icons/createCampaign/cupon.png';
import UploadIcon from '../../../../assets/icons/createCampaign/upload.png';
import logoCortteza from '../../../../assets/img/corttC.png';
import { CampaignEmailCreatorLang } from '../../../../lang';
import Breadcrumbs from "../SubComponents/Breadcrumbs";
import Cuponera from "../SubComponents/Cuponera";
import SegmentSelectionForm from './SegmentSelectionForm';
import FinalPreviewForm from './FinalPreviewForm';
import sample from "../../../../assets/emailDesigns/sample.json";
import blank from "../../../../assets/emailDesigns/blank.json";
// import cuponCodeRow from "../../../../assets/emailDesigns/sample.json";
import { registerTool, unlay } from "../../../../assets/emailDesigns/custom";
import testjson from './test.json';
//MAIN COMPONENT

var EmailCampaignForm = props => {

    // 1. Set initial properties ---------------
    const [step, setInternalStep] = useState(1); // 1. Basic Campaign Info | 2. Email construction or cupon selection | 3. Desing or Cuponera or Upload | 4. Segment Selection
    const [emailType, setEmailType] = useState(''); // html | cupon | image
    const [editorLoaded, setEditorLoaded] = useState(false);
    const [editorNextOption, setEditorNextOption] = useState({ label: "Guardar y salir", function: props.setStep })
    const [isDropDownOpen, setIsDropDownOpen] = useState(false);
    const [selectionCount, setSelectionCount] = useState(0);
    const [cuponCount, setCuponCount] = useState(0);
    const [finalSelectionLoaded, setFinalSelectionLoaded] = useState(false);
    const [modalScreen, setModalScreen] = useState('');
    const [image, setImage] = useState({
        file: null,
        filePreview: null
    })
    const [testMail, setTestMail] = useState({
        testEmail: null,
        testEmailSending: false,
        testEmailSent: false,
        testEmailError: false
    })
    const [testModal, setTestModal] = useState(false);
    const [Campaign, setCampaign] = useState({
        name: '',
        subject: '',
        segments: [],
        cupon: null,
        redirectUrl: '',
        html: null,
    })

    //Editor Related States ---------------------------------------------------------------------------------------------------------------------------------------------------
    const [cuponList, setCuponList] = useState(null);
    const [cuponCodes, setCuponCodes] = useState(null);
    const [cuponAdded, setCouponAdded] = useState(false);
    // const [multipleCoupons, setMultipleCoupons] = useState({status: false, count: 1});
    var emailEditorRef = useRef(null);
    const [emailDesign, setEmailDesing] = useState(null);
    const [scriptLoaded, setscriptLoaded] = useState(false);
    var labels = CampaignEmailCreatorLang(props.lang);
    // var buttons = [
    //     {text: labels.lblCreateCampaign, function: summitCampaign}
    // ]


    //-----------------------------------------------------------------------------------------------------------------------------------------------------
    // 2. Utility Functions -----------------------------------------------------------------------------------------------------------------------------------------------------
    //-----------------------------------------------------------------------------------------------------------------------------------------------------

    useEffect(() => {
        ReactTooltip.rebuild();
        var getCuponList = async () => {
            let cacheId = "GetCuponList" + Math.round(Math.random() * 1000);
            try {
                let cuponList = await props.askFor("campaigns", "getCuponList", [], false, {}, cacheId);
                cuponList.filter(c => {
                    let notExpired = new Date(c.ExpirationDate).getTime() > new Date().getTime();
                    let isByRegister = c.CouponType === "R";
                    if (notExpired && isByRegister && c.CouponStatus) {
                        return true;
                    }
                    else {
                        return false;
                    }
                })
                var cupones = [{ label: "Default", value: "" }];
                cuponList.forEach(c => {
                    let cup = { label: c.Name, value: c["ImageList"][0]["ImageUrl"] };
                    // let cup = { label: c.Name, value: c.ID };
                    cupones.push(cup);
                })

                // console.log(cuponList)
                setCuponCodes(cupones);
                setCuponList(cuponList)
            }
            catch (err) {
                console.log(err)
            }
        }

        var loadScripts = () => {
            const unlayer = document.createElement("script");
            // unlayer.id="unlayer";
            unlayer.src = "https://editor.unlayer.com/embed.js";
            unlayer.async = true;
            // <script src="https://editor.unlayer.com/embed.js"></script>
            const script = document.createElement("script");
            // script.id="init";
            // let x = registerTool()
            script.text = "unlayer.init({id: 'email-editor',projectId: 5447, appearance: {theme: 'dark'}, features: {undoRedo: false}}); unlayer.addEventListener('design:updated', function(data) {var type = data.type;var item = data.item;var changes = data.changes;console.log('design:updated', type, item, changes);})";
            script.async = true;
            document.body.appendChild(unlayer);
            document.body.appendChild(script);
            setscriptLoaded(false);
        }

        var setEventListeners = async () => {
        }

        if (cuponList == null) {
            getCuponList();
        }

        if (step == 3 && scriptLoaded == false) {
            // loadScripts()
            // setscriptLoaded(true)
            // setEventListeners()
        }

        return () => {
        }
    })

    var createFormTextInput = (label, explan, controller, required, value, placeholder) => {
        if (required == undefined) { required = false }
        if (value == undefined || value == null) { value = "" }
        if (placeholder == undefined || placeholder == null) { placeholder = "" }
        return (
            <div className="formGroup">
                <label> {label} <span className="requiredLabel" data-for="emailCampaignCreatorFormT" data-tip="Campo requerido" data-place="right">{required ? "?" : ""}</span> </label>
                <Input type="text" placeholder={placeholder} onChange={e => { controller(e.target.value) }} value={value}></Input>
                <p>{explan}</p>
            </div>
        )
    }

    var createSelectCard = (title, icon, text, emailType) => {
        if (title == undefined) { title = "" }
        if (icon == undefined) { icon = "" }
        if (text == undefined) { text = "" }
        return (
            <Col className="emailTypeSelectCard">
                <Card onClick={() => { setEmailType(emailType); setInternalStep(3) }}>
                    <CardBody className="container">
                        <h3 className="icon">{title}</h3>
                        {icon}
                        <p>{text}</p>
                    </CardBody>
                </Card>
            </Col>
        )
    }

    var toggleDropDown = () => {
        setIsDropDownOpen(!isDropDownOpen);
    }

    var editorOnLoad = () => {
        //It checks to see if the reference for the editor was created, if its not,
        // then sets an interval of 1s and does the onLoad
        //1. Directly executes onLoad
        if (emailEditorRef.current !== undefined && emailEditorRef.current !== null) {
            emailEditorRef.current.editor.addEventListener('design:updated', function (data) {
                var type = data.type;
                var item = data.item;
                var changes = data.changes;
                // console.log('Data:', data);
                // console.log('Type:', type);
                // console.log('Item:', item);

                // EVENT DECLARATION ---------------------------------------------------------------------------
                if (type == 'content:added') {
                    if (item.type = "custom" && item.slug == "my_coupon") {
                        emailEditorRef.current.editor.exportHtml(async data => {
                            const { design, html } = data;
                            var newD = searchForPropertyInDesing(design, 'coupon_image', 'https://www.butlers.com.au/components/com_easyblog/themes/wireframe/images/placeholder-facebook.png');
                            // newD = searchForPropertyInDesing(newD, 'coupon_image', 'https://www.butlers.com.au/components/com_easyblog/themes/wireframe/images/placeholder-facebook.png');
                            // newD = searchForPropertyInDesing(newD, 'coupon_name', 'Mi Cupón');
                            // newD = searchForPropertyInDesing(newD, 'coupon_description', 'Esta es la descripción de mi cupón');
                            emailEditorRef.current.editor.loadDesign(newD);
                            // console.log('Desing: ', design);
                        })
                    }
                }

                if (type = 'content:modified') {
                    if (changes != undefined) {
                        // console.log(changes)
                        if (changes.name == "coupon") {
                            var cupon = null;
                            cuponList.forEach(c => {
                                if (c["ImageList"][0]["ImageUrl"] == changes.value) {
                                    setCampaignCupon(c)
                                    cupon = c;
                                }
                            });
                            if (cupon != null) {
                                emailEditorRef.current.editor.exportHtml(async data => {
                                    const { design, html } = data;
                                    var newD = searchForPropertyInDesing(design, 'coupon', cupon["ImageList"][0]["ImageUrl"]);
                                    newD = searchForPropertyInDesing(newD, 'coupon_ID', cupon.ID);
                                    newD = searchForPropertyInDesing(newD, 'coupon_terms', cupon.TermsAndConditions);
                                    newD = searchForPropertyInDesing(newD, 'coupon_image', cupon["ImageList"][0]["ImageUrl"]);
                                    newD = searchForPropertyInDesing(newD, 'coupon_name', cupon.Name);
                                    newD = searchForPropertyInDesing(newD, 'coupon_description', cupon.Description);
                                    emailEditorRef.current.editor.loadDesign(newD);
                                    // console.log('Desing: ', design);
                                })
                            }
                        }
                    }
                }

                if (type = "content:removed") {
                    setCouponAdded(false);
                }
            })
            emailEditorRef.current.editor.registerCallback('image', uploadImgFile);
            if (emailDesign != null) { emailEditorRef.current.editor.loadDesign(emailDesign) }
        }
        //2. Sets Interval an then executes onLoad
        else {
            setInterval(() => {
                emailEditorRef.current.editor.addEventListener('design:updated', function (data) {
                    var type = data.type;
                    var item = data.item;
                    var changes = data.changes;
                    // console.log('Data:', data);
                    // console.log('Type:', type);
                    // console.log('Item:', item);

                    // EVENT DECLARATION ---------------------------------------------------------------------------
                    if (type == 'content:added') {
                        if (item.type = "custom" && item.slug == "my_coupon") {
                            emailEditorRef.current.editor.exportHtml(async data => {
                                const { design, html } = data;
                                var newD = searchForPropertyInDesing(design, 'coupon_image', 'https://www.butlers.com.au/components/com_easyblog/themes/wireframe/images/placeholder-facebook.png');
                                // newD = searchForPropertyInDesing(newD, 'coupon_image', 'https://www.butlers.com.au/components/com_easyblog/themes/wireframe/images/placeholder-facebook.png');
                                // newD = searchForPropertyInDesing(newD, 'coupon_name', 'Mi Cupón');
                                // newD = searchForPropertyInDesing(newD, 'coupon_description', 'Esta es la descripción de mi cupón');
                                emailEditorRef.current.editor.loadDesign(newD);
                                // console.log('Desing: ', design);
                            })
                        }
                    }

                    if (type = 'content:modified') {
                        if (changes != undefined) {
                            // console.log(changes)
                            if (changes.name == "coupon") {
                                var cupon = null;
                                cuponList.forEach(c => {
                                    if (c["ImageList"][0]["ImageUrl"] == changes.value) {
                                        setCampaignCupon(c)
                                        cupon = c;
                                    }
                                });
                                if (cupon != null) {
                                    emailEditorRef.current.editor.exportHtml(async data => {
                                        const { design, html } = data;
                                        var newD = searchForPropertyInDesing(design, 'coupon', cupon["ImageList"][0]["ImageUrl"]);
                                        newD = searchForPropertyInDesing(newD, 'coupon_ID', cupon.ID);
                                        newD = searchForPropertyInDesing(newD, 'coupon_terms', cupon.TermsAndConditions);
                                        newD = searchForPropertyInDesing(newD, 'coupon_image', cupon["ImageList"][0]["ImageUrl"]);
                                        newD = searchForPropertyInDesing(newD, 'coupon_name', cupon.Name);
                                        newD = searchForPropertyInDesing(newD, 'coupon_description', cupon.Description);
                                        emailEditorRef.current.editor.loadDesign(newD);
                                        // console.log('Desing: ', design);
                                    })
                                }
                            }
                        }
                    }

                    if (type = "content:removed") {
                        setCouponAdded(false);
                    }
                })
                emailEditorRef.current.editor.registerCallback('image', uploadImgFile);
                if (emailDesign != null) { emailEditorRef.current.editor.loadDesign(emailDesign) }
            }, 2000)
        }

        setEditorLoaded(true);
    }

    var setStepCheckingCondition = (nstep) => {
        var bool = false;
        switch (step) {
            case 1: if (Campaign.name !== "" && Campaign.subject !== "" && nstep > step) { bool = true; setInternalStep(nstep) }; break;
            case 2:
                if (nstep < step) {
                    setInternalStep(nstep);
                }
                ; break;
            case 3:
                if (nstep < step) {
                    setInternalStep(nstep)
                } else {
                    if (Campaign.html != null) { bool = true; setInternalStep(nstep); };
                }
                break;
            case 4:
                if (nstep < step) {
                    setInternalStep(nstep);
                    setFinalSelectionLoaded(false);
                } else {
                    if (Campaign.segments.length > 0) { bool = true; setInternalStep(nstep); };
                }
                break;
            case 5:
                if (nstep < step) {
                    setInternalStep(nstep);
                    setFinalSelectionLoaded(false)
                } else {
                    if (Campaign.name !== "" && Campaign.subject !== "") { bool = true; };
                }
                break;
            default: ; break;
        }

        return bool;
    }

    var breadcrumbs = [
        { text: labels.lblStepConf, function: setStepCheckingCondition, functionValue: 1 },
        { text: labels.lblStepContentType, function: setStepCheckingCondition, functionValue: 2 },
        { text: labels.lblStepDesing, function: setStepCheckingCondition, functionValue: 3 },
        { text: labels.lblStepSegments, function: setStepCheckingCondition, functionValue: 4 },
        { text: labels.lblStepConfirmation, function: setStepCheckingCondition, functionValue: 5 },
    ];

    var resetEmailDesing = () => {
        setEmailDesing(blank);
        emailEditorRef.current.editor.loadDesign(blank);
    }

    var exportHtml = () => {
        emailEditorRef.current.editor.exportHtml(data => {
            const { design, html } = data;
            props.setCampaignHtml(data.html);
            props.setStep(3)
        })

    }

    var searchForPropertyInDesing = (desing, property, value) => {
        var body = desing.body;
        var rows = body.rows;
        rows.forEach(r => {
            var columns = r.columns;
            columns.forEach(c => {
                var contents = c.contents;
                contents.forEach(ct => {
                    if (ct.type == 'custom' && ct.slug == 'my_coupon') {
                        ct.values[property] = value;
                    }
                })
            })
        })

        return desing;
    }

    var deleteExtraCouponInDesing = (desing) => {
        var body = desing.body;
        var rows = body.rows;
        var propertyFound = false;
        var duplicatedFound = false;
        var duplicateIndexArray = [];
        rows.forEach(r => {
            var columns = r.columns;
            columns.forEach(c => {
                var contents = c.contents;
                contents.forEach((ct, i) => {
                    if (ct.type == 'custom' && ct.slug == 'my_coupon') {
                        if (propertyFound == false) {
                            propertyFound = true;
                        }
                        else {
                            duplicateIndexArray.push(i);
                        }
                    }
                })
                duplicateIndexArray.forEach(index=>{
                    contents.splice(index,1)
                })
                
            })
        })

        return desing;
    }

    //------------------------------------------------------------------------------------------------------------------------------------------------
    // 3. Test Email Functions -----------------------------------------------------------------------------------------------------------------------
    //------------------------------------------------------------------------------------------------------------------------------------------------

    var setEmailforTest = (email) => {
        setTestMail(t => {
            t.testEmail = email;
            return ({ ...t });
        })
    }

    var setTestEmailError = (bool) => {
        if (bool == undefined) { bool = false }
        setTestMail(t => {
            t.testEmailError = bool;
            return ({ ...t });
        })
    }

    var toggleTestEmailSent = (bool) => {
        if (bool == undefined) { bool = false }
        setTestMail(t => {
            t.testEmail = "";
            t.testEmailSent = bool;
            t.testEmailSending = false;
            return ({ ...t });
        })
    }

    // var toggleTestModal = () => { setTestModal(!testModal); setEmailforTest("") }
    var toggleTestModal = (screen) => { if (screen === undefined) { screen = '' }; setModalScreen(screen); setEmailforTest(""); setTestModal(!testModal); }

    //-----------------------------------------------------------------------------------------------------------------------------------------------------
    // 4. Email Campaign Object Functions ------------------------------------------------------------------------------------------------------------------------------------------------------------------
    //------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------

    var setCampaignName = (name) => {
        setCampaign(c => {
            c.name = name;
            return ({ ...c });
        })
    }

    var setCampaignSubject = (sub) => {
        setCampaign(c => {
            c.subject = sub;
            return ({ ...c });
        })
    }

    var setCampaignSegments = (seg, count) => {
        setCampaign(c => {
            c.segments = seg;
            return ({ ...c });
        })
        setSelectionCount(count)
        setStepCheckingCondition(5);
    }

    var setCampaignRedirectUrl = red => {
        setCampaign(c => {
            c.redirectUrl = red;
            return ({ ...c });
        })
    }

    var setCampaignImg = (file, filePreview) => {
        let f = { file: file, filePreview: filePreview }
        setImage({ ...f });
    }

    var setCampaignHtml = () => {
        emailEditorRef.current.editor.exportHtml(data => {
            const { design, html } = data;
            var newD = searchForPropertyInDesing(design, 'coupon_link_to_action', props.cuponeraLinkToAction); //"https://cuponeraclientapidev.loymark.com/ssr/loymark/{{contact.LAST_COUPON}}"
            emailEditorRef.current.editor.loadDesign(newD);
            // 1.3 Export the HTML content
            emailEditorRef.current.editor.exportHtml(data => {
                const { design, html } = data;
                var newD = deleteExtraCouponInDesing(design);
                emailEditorRef.current.editor.loadDesign(newD);
                emailEditorRef.current.editor.exportHtml(data => {
                    const { design, html } = data;
                    setEmailDesing(design);
                    setCampaign(c => {
                        c.html = html;
                        return ({ ...c });
                    });
                    setStepCheckingCondition(4);
                })
                // var newD = searchForPropertyInDesing(design, 'coupon_image', 'https://www.butlers.com.au/components/com_easyblog/themes/wireframe/images/placeholder-facebook.png');

            })
        })

    }

    var setCampaignCupon = cupon => {
        var cup = null;
        var cc = 0;
        if (cupon != undefined) {
            if (cupon["ImageList"].length > 0) {
                cup = {
                    "ID": cupon['ID'],
                    "ImageUrl": cupon["ImageList"][0]["ImageUrl"],
                };
            }
            else {
                cup = {
                    "ID": cupon['ID'],
                    "ImageUrl": "https://storage.googleapis.com/corttezamailinglists/logoCortteza.png",
                };
            }

            cc = cupon["QuantityAvailable"] - cupon["QuantityClaimed"];
        }

        setCuponCount(cc);
        setCampaign(c => {
            c.cupon = cup;
            return ({ ...c })
        })

    }

    var resetCampaignSegments = () => {
        setCampaign(c => {
            c.segments = [];
            return ({ ...c })
        })
    }

    var changeEmailType = (type) => {
        switch (type) {
            case "html":
                setEmailType(type);
                setCampaign(c => { c.cupon = null; return ({ ...c }) });
                setImage({ file: null, filePreview: null });
                // setStepCheckingCondition(3);
                break;
            case "cupon":
                setEmailType(type);
                setCampaign(c => { c.html = null; return ({ ...c }) });
                setImage({ file: null, filePreview: null });
                // setStepCheckingCondition(3)
                break;
            case "image":
                setEmailType(type);
                setCampaign(c => { c.html = null; return ({ ...c }) });
                setCampaign(c => { c.cupon = null; return ({ ...c }) });
                // setStepCheckingCondition(3)
                break;
        }
    }

    //-----------------------------------------------------------------------------------------------------------------------------------------------------
    //5. Async Functions - API CALLS ------------------------------------------------------------------------------------------------------------------------------------------------
    //------------------------------------------------------------------------------------------------------------------------------------------------
    var summitCampaign = async () => {
        if (props.campaignConfig.is_demo) {
            props.setLoadingStep(true);
            console.log("Is not demo")
            setInterval(() => {
                props.changeScreen({ screen: 'list', creationCancel: false });
            }, 2000);
        }
        else {
            if (Campaign.name != "" && Campaign.subject != "" && Campaign.segments.length > 0 && (image.file != null || Campaign.cupon != null || Campaign.html != null)) {
                props.setLoadingStep(true);
                var formData = new FormData();
                formData.append("campaign", JSON.stringify(Campaign));
                formData.append('imgFile', image.file);
                var res = await props.formDataPetition('campaigns', "createCampaign", formData);
                //var res = null;
                if (res == null) {
                    alert("Error while creating the campaign")
                }
                props.changeScreen({ screen: 'list', creationCancel: false });
            }
        }

    }

    var sendEmailTest = async () => {
        if (Campaign.name != "" && Campaign.subject != "") {
            // 0. Set loading State -----------------------------------------------------------------------------
            setTestMail(tm => { tm.testEmailSending = true; return ({ ...tm }); })
            // 1. Check if the test includes a coupon -----------------------------------------------------------------------------
            if (Campaign.cupon != null) {
                // 1.1 Claim the Coupon -----------------------------------------------------------------------------
                let cacheId = 'ClaimCupon' + Math.round(Math.random() * 1000);
                let code = await props.askFor('campaigns', 'claimCupon', [], false, { cuponId: Campaign.cupon.ID }, cacheId)
                // let code = "ASDFASDS"; //Example Code (Dev property)
                if (code != null) {
                    emailEditorRef.current.editor.exportHtml(async data => {
                        const { design, html } = data;
                        // console.log('Desing: ', design);
                        // 1.2 Replace the link of the image with the direction of the Coupon Site -----------------------------------------------------------------------------
                        var newD = searchForPropertyInDesing(design, 'coupon_link_to_action', "https://cuponeraclientapidev.loymark.com/ssr/loymark/" + code);
                        emailEditorRef.current.editor.loadDesign(newD);
                        // 1.3 Export the HTML content
                        emailEditorRef.current.editor.exportHtml(async data => {
                            const { design, html } = data;
                            // console.log('Desing After: ', design);
                            // console.log('HTML: ', html);
                            var formData = new FormData();
                            var email = {
                                destination: testMail.testEmail,
                                subject: Campaign.subject,
                                content: "",
                                cupon: Campaign.cupon,
                                redirectUrl: '',
                                html: html,
                            }
                            formData.append("email", JSON.stringify(email));
                            formData.append('imgFile', image.file);
                            // var cacheId = "SendTestEmail-" + this.props.bucket.bucketId + "-" + this.state.campaign.name
                            var res = await props.formDataPetition('campaigns', "sendTestEmail", formData);
                            // console.log(res)
                            if (res != null) {
                                setTestMail(tm => {
                                    tm.testEmailSent = true;
                                    tm.testEmailSending = false;
                                    tm.testEmailError = false;
                                    return ({ ...tm });
                                })
                            }
                            else {
                                setTestMail(tm => {
                                    tm.testEmailSent = true;
                                    tm.testEmailSending = false;
                                    tm.testEmailError = true;
                                    return ({ ...tm });
                                })
                            }
                        })
                    })
                }
                else {
                    setTestMail(tm => {
                        tm.testEmailSent = true;
                        tm.testEmailSending = false;
                        tm.testEmailError = true;
                        return ({ ...tm });
                    })
                }
            }
            else {
                emailEditorRef.current.editor.exportHtml(async data => {
                    const { design, html } = data;
                    // console.log('Desing: ', design);
                    // console.log('HTML: ', html);
                    var formData = new FormData();
                    var email = {
                        destination: testMail.testEmail,
                        subject: Campaign.subject,
                        content: "",
                        cupon: Campaign.cupon,
                        redirectUrl: '',
                        html: html,
                    }
                    formData.append("email", JSON.stringify(email));
                    formData.append('imgFile', image.file);
                    var res = await props.formDataPetition('campaigns', "sendTestEmail", formData);
                    if (res != null) {
                        setTestMail(tm => {
                            tm.testEmailSent = true;
                            tm.testEmailSending = false;
                            tm.testEmailError = false;
                            return ({ ...tm });
                        })
                    }
                    else {
                        setTestMail(tm => {
                            tm.testEmailSent = true;
                            tm.testEmailSending = false;
                            tm.testEmailError = true;
                            return ({ ...tm });
                        })
                    }
                })
            }
        }
        else {
            setTestMail(tm => {
                tm.testEmailSent = true;
                tm.testEmailSending = false;
                tm.testEmailError = true;
                return ({ ...tm });
            })
        }
    }

    var uploadImgFile = async (file, done) => {
        // Handle file upload here
        done({ progress: 50 })
        var data = new FormData()
        data.append('imgFileName', "Campaign-" + Campaign.name);
        data.append('imgFile', file.attachments[0]);
        var res = await props.formDataPetition('campaigns', "uploadImage", data);
        if (res != null) {
            done({ progress: 100, url: res })
        }
    };

    //-----------------------------------------------------------------------------------------------------------------------------------------------------
    // 6. Pre-render functions -------------------------------------------------------------------------------------------------------------------------------------------------------------------
    //------------------------------------------------------------------------------------------------------------------------------------------------
    var getScreenStep = () => {
        var screen = <></>;
        switch (step) {
            case 1: //Configuration
                screen =
                    <Col lg="8" md="10" sm="12">
                        {createFormTextInput(labels.lblCampaignName, labels.lblCampaignNameExplan, setCampaignName, true, Campaign.name, "Promoción de Pilsen")}
                        {createFormTextInput(labels.lblCampaignSubject, labels.lblCampaignSubjectExplan, setCampaignSubject, true, Campaign.subject, "Disfrutá de este fin de semana con...")}

                        <div className="buttonsRow">
                            {Campaign.name != "" && Campaign.subject != "" ?
                                cuponList == null ?
                                    <CSSTransition classNames="animated" timeout={1000} in={true} appear={true}>
                                        {props.loadingSpinner}
                                        <p className="text-center">Cargando datos de cupones</p>
                                    </CSSTransition>
                                    :
                                    <CSSTransition classNames="animated" timeout={1000} in={true} appear={true}>
                                        <Button onClick={() => { changeEmailType("html"); setInternalStep(3); /* setStepCheckingCondition(2) */ }}>Siguiente</Button>
                                    </CSSTransition>
                                :
                                ""
                            }
                        </div>
                    </Col>
                    ; break;
            case 2: //Type selection
                screen =
                    <Col lg="8" md="10" sm="12">
                        <Row>
                            <Col lg="6" md="6" sm="6" className="emailTypeSelectCard">
                                <Card onClick={() => { changeEmailType("html"); setInternalStep(3); setscriptLoaded(true) }}>
                                    <CardBody>
                                        <h5 className="icon">Editar Email</h5>
                                        <img src={DragnDropIcon} alt="" className="img-responsive" />
                                        <p>{labels.lblDragDropExplan}</p>
                                    </CardBody>
                                </Card>
                            </Col>
                            {props.cuponeraEnabled ?
                                <Col lg="6" md="6" sm="6" className="emailTypeSelectCard">
                                    <Card onClick={() => { changeEmailType("cupon"); setInternalStep(3) }}>
                                        <CardBody>
                                            <h5 className="icon">Seleccionar Cupón</h5>
                                            <img src={CuponIcon} alt="" className="img-responsive" />
                                            <p>{labels.lblCuponExplan}</p>
                                        </CardBody>
                                    </Card>
                                </Col>
                                : ""
                            }
                            {/* <Col lg="4" md="4" sm="4" className="emailTypeSelectCard">
                                <Card onClick={() => { changeEmailType("image"); setInternalStep(3) }}>
                                    <CardBody>
                                        <h5 className="icon">Imagen</h5>
                                        <img src={UploadIcon} className="img-responsive" />
                                        <p>Sube una imagen para utilizar dentro de la plantilla</p>
                                    </CardBody>
                                </Card>
                            </Col> */}
                        </Row>
                    </Col>
                    ; break;
            case 3: //Content creation
                // switch (emailType) {
                // case "html":
                screen =
                    <CSSTransition in={editorLoaded} appear={true} classNames="fadeIn" timeout={1000}>
                        <div className="emailEditor">
                            {editorLoaded == true ?
                                <div className="buttonContainer">
                                    <img src={logoCortteza} alt="" className="logo" />
                                    <h5>{Campaign.name}</h5>
                                    <ReactTooltip id="eCreator" />
                                    <Button onClick={() => { toggleTestModal('sendTest') }} data-tip={labels.lblSentTestEmail} data-for="eCreator" data-place="bottom"><span className="ti-email" /></Button>
                                    <Button onClick={resetEmailDesing} data-tip={labels.lblBlankDesing} data-for="eCreator" data-place="bottom"><span className="ti-trash" /></Button>
                                    <Button onClick={() => { if (Campaign.html != null) { setStepCheckingCondition(4) } else { setStepCheckingCondition(1) } }} data-tip="Salir sin guardar" data-for="eCreator" data-place="bottom"><span className="ti-back-left" /></Button>
                                    <Button className="mainButton" onClick={() => { setCampaignHtml() }}>{labels.lblSaveAndContinue}</Button>
                                </div>
                                : ""}

                            {/* <div style={{ minHeight: "100%" }}>
                                    <div style={{ height: "100vh" }} id="email-editor"></div>
                                </div> */}

                            {cuponList != null && cuponCodes != null ?
                                // console.log("Should Load")
                                <EmailEditor
                                    projectId={5447}
                                    tools={{
                                        'custom#my_coupon': {
                                            // enabled: props.cuponeraEnabled,
                                            properties: {
                                                coupon: {
                                                    editor: {
                                                        data: {
                                                            options: cuponCodes
                                                        },

                                                    }
                                                }
                                            }
                                        }
                                    }}
                                    ref={emailEditorRef}
                                    onLoad={editorOnLoad}
                                    minHeight="93%"
                                    options={{
                                        locale: "es-ES",
                                        appearance: {
                                            theme: 'dark',
                                            panels: {
                                                tools: {
                                                    dock: 'left'
                                                }
                                            }
                                        }
                                        // customJS: registerTool()
                                    }}
                                />
                                : ""}

                        </div>
                    </CSSTransition>
                    // ; break;
                    //     case "cupon":
                    //         if (Campaign.cupon == null) {
                    //             screen =
                    //                 <div className="cuponSelector">
                    //                     <Row>
                    //                         <Col lg="8" md="10" sm="12">
                    //                             <Card>
                    //                                 <CardBody>
                    //                                     <Cuponera
                    //                                         askFor={props.askFor}
                    //                                         loadingSpinner={props.loadingSpinner}
                    //                                         setCampaignCupon={setCampaignCupon}
                    //                                         selectedCupon={Campaign.cupon}
                    //                                         type="email"
                    //                                     />
                    //                                 </CardBody>
                    //                             </Card>
                    //                         </Col>
                    //                     </Row>

                    //                     <Row>
                    //                         <div className="buttonContainer">
                    //                             {Campaign.cupon != null ?
                    //                                 <>
                    //                                     <CSSTransition classNames="animated" timeout={1000} in={true} appear={true}>
                    //                                         <Button onClick={toggleTestModal}><span className="ti-email" /> Enviar correo de prueba</Button>
                    //                                     </CSSTransition>

                    //                                     <CSSTransition classNames="animated" timeout={1000} in={true} appear={true}>
                    //                                         <Button onClick={() => { /* setInternalStep(4) */ }}>Abrir Editor de correo <span className="ti-arrow-right" /></Button>
                    //                                     </CSSTransition>
                    //                                 </>
                    //                                 :
                    //                                 ""}
                    //                         </div>
                    //                     </Row>
                    //                 </div>;
                    //         }
                    //         else {
                    //             screen =
                    //                 <CSSTransition in={editorLoaded} appear={true} classNames="fadeIn" timeout={1000}>
                    //                     <div className="emailEditor">
                    //                         {editorLoaded == true ?
                    //                             <div className="buttonContainer">
                    //                                 <img src={logoCortteza} alt="" className="logo" />
                    //                                 <h5>{Campaign.name}</h5>
                    //                                 <ReactTooltip id="ecCreator" />

                    //                                 <Button onClick={() => { toggleTestModal('sendTest') }} data-tip={labels.lblSentTestEmail} data-for="ecCreator" data-place="bottom"><span className="ti-email" /></Button>
                    //                                 <Button onClick={() => { setCampaignCupon() }} data-tip={"Cambiar Cupón"} data-for="ecCreator" data-place="bottom"><span className="ti-ticket" /></Button>
                    //                                 <Button onClick={() => { if (Campaign.html != null) { setStepCheckingCondition(4) } else { setStepCheckingCondition(2) } }} data-tip="Salir sin guardar" data-for="ecCreator" data-place="bottom"><span className="ti-back-left" /></Button>

                    //                                 <Button className="mainButton" onClick={() => { setCampaignHtml() }}>{labels.lblSaveAndContinue}</Button>
                    //                                 {/* <ButtonDropdown className="mainButton" isOpen={isDropDownOpen} toggle={toggleDropDown}>
                    //                                     <Button onClick={() => { setCampaignHtml() }}>{editorNextOption.label}</Button>
                    //                                     <DropdownToggle caret />
                    //                                     <DropdownMenu>
                    //                                         <DropdownItem onClick={() => { setCampaignHtml() }}>{labels.lblSaveAndContinue}</DropdownItem>
                    //                                         <DropdownItem onClick={() => { setCampaignCupon() }}>Cambiar Cupón</DropdownItem>
                    //                                         <DropdownItem onClick={() => { if (Campaign.html != null) { setInternalStep(4) } else { setInternalStep(2) } }}>Salir sin guardar</DropdownItem>
                    //                                     </DropdownMenu>
                    //                                 </ButtonDropdown> */}
                    //                             </div>
                    //                             : ""}

                    //                         <EmailEditor
                    //                             ref={emailEditorRef}
                    //                             onLoad={editorOnLoad}
                    //                             minHeight="93%"
                    //                         />
                    //                     </div>
                    //                 </CSSTransition>
                    //         }

                    //         break;
                    //     case "image": screen =
                    //         <Col lg="8" md="10" sm="12">
                    //             <div className="imageUploader">
                    //                 <div className="formGroup">
                    //                     <Upload labels={labels} setCampaignImg={setCampaignImg} image={image.filePreview} />
                    //                 </div>

                    //                 <div className="buttonContainer">
                    //                     {image.file != null && image.filePreview != null ?
                    //                         <>
                    //                             <CSSTransition classNames="animated" timeout={1000} in={true} appear={true}>
                    //                                 <Button onClick={() => { toggleTestModal('sendTest') }}><span className="ti-email" /> {labels.lblSentTestEmail}</Button>
                    //                             </CSSTransition>

                    //                             <CSSTransition classNames="animated" timeout={1000} in={true} appear={true}>
                    //                                 <Button onClick={() => { setInternalStep(4) }}>{labels.lblNext} <span className="ti-arrow-right" /></Button>
                    //                             </CSSTransition>
                    //                         </>
                    //                         :
                    //                         ""}
                    //                 </div>
                    //             </div>
                    //         </Col>
                    //         ; break;
                    //     default: ; break;
                    // }
                    ; break;
            case 4: // SegmentSelection
                screen =
                    <Col lg="12" md="12" sm="12">
                        <Card>
                            <CardBody>
                                <SegmentSelectionForm
                                    askFor={props.askFor}
                                    exportFunction={setCampaignSegments}
                                    loadingSpinner={props.loadingSpinner}
                                    selectionCount={selectionCount}
                                    lang={props.lang}
                                    currentEmailCuota={props.currentEmailCuota}
                                    limitEmailCuota={props.campaignConfig.month_limit}
                                />
                            </CardBody>
                        </Card>
                    </Col>
                    ; break
            case 5: // Campaign Confirmation
                screen =
                    <Col lg="12" md="12" sm="12">
                        <FinalPreviewForm
                            askFor={props.askFor}
                            lang={props.lang}
                            type="email"
                            emailType={emailType}
                            loadingSpinner={props.loadingSpinner}
                            selectionCount={selectionCount}
                            segments={Campaign.segments}
                            campaignName={Campaign.name}
                            campaignSubject={Campaign.subject}
                            campaignImg={image.filePreview}
                            campaignCupon={Campaign.cupon}
                            campaignHtml={Campaign.html}
                            cuponCount={cuponCount}
                            maxEmailLimit={props.campaignConfig.month_limit}
                            isLoaded={setFinalSelectionLoaded}
                        />

                        {finalSelectionLoaded ?
                            <Row className="buttonsRow">
                                <Button onClick={() => { toggleTestModal('confirmation') }}>{labels.lblCreateCampaign}</Button>
                            </Row>
                            :
                            ""
                        }

                    </Col>
                    ;
                ; break
        }

        return screen;
    }

    var getModalContent = () => {
        var screen = <></>;

        switch (modalScreen) {
            case 'confirmation':
                screen = <div className="confirmation">
                    <h6>La campaña se creará a continuación</h6>
                    <div className="buttonsRow">
                        <Button onClick={() => { summitCampaign() }}>{labels.lblAccept}</Button>
                        <Button onClick={toggleTestModal}>{labels.lblCancel}</Button>
                    </div>
                </div>
                    ; break;
            case "sendTest":
                screen = <div className="sendTestEmail">
                    <div className="inputGroup">
                        {testMail.testEmailSent ?
                            testMail.testEmailError ?
                                <>
                                    <span>Error al enviar el correo</span>
                                    <button onClick={() => { toggleTestEmailSent(false); }}>Enviar otro coreo</button>
                                </>
                                :
                                <>
                                    <span>Enviado a: {testMail.testEmail}</span>
                                    <button onClick={() => { toggleTestEmailSent(false); }}>Enviar otro correo</button>
                                </>
                            :
                            testMail.testEmailSending ?
                                <>
                                    {props.loadingSpinner}
                                </>
                                :
                                <>
                                    <Input type="email" placeholder="email@mail.com" onChange={e => { setEmailforTest(e.target.value) }}></Input>
                                    <button onClick={() => { sendEmailTest(); }}>Enviar Email de prueba</button>
                                </>
                        }
                    </div>
                </div>
                break;
            default: ; break;
        }

        return screen;
    }

    //Render
    return (
        <Container fluid className="emailCampaignCreatorForm">
            <Row className="stepRow">
                <Breadcrumbs breadcrumps={breadcrumbs} active={step} />
            </Row>
            <Row>
                <ReactTooltip id="emailCampaignCreatorFormT" />

                {getScreenStep()}

                <Modal isOpen={testModal} toggle={toggleTestModal} centered={true} className="emailModal">
                    <ModalHeader toggle={toggleTestModal}><label> {modalScreen == "sendTest" ? labels.lblTestEmail : ""}  </label></ModalHeader>
                    <ModalBody>
                        {getModalContent()}
                    </ModalBody>
                </Modal>
            </Row>
        </Container>
    )

}

//SUBCOMPONENT

class Upload extends React.Component {
    constructor(props) {
        super(props)
        // if(props.file != undefined)
        this.state = {
            file: props.image != null ? props.image : null,
        }
        this.handleChange = this.handleChange.bind(this)
    }

    handleChange(event) {
        if (event.target.files[0] == null || event.target.files[0] == undefined) {
            var filePreview = null;
            var file = null;
        }
        else {
            var filePreview = URL.createObjectURL(event.target.files[0]);
            var file = event.target.files[0];
        }

        this.setState({ file: filePreview }, () => { this.props.setCampaignImg(file, filePreview) })
    }

    render() {
        return (
            <div className="upload">
                <input type="file" name="imgFile" id="imgFile" onChange={this.handleChange} />
                <label htmlFor="imgFile">
                    {this.state.file == null ?
                        <>
                            <span className="ti-upload" />
                            <h5>{this.props.labels.lblCampaigImg}</h5>
                            <p>{this.props.labels.lblCampaigImgExplan}</p>
                        </>

                        :
                        <img src={this.state.file} />
                    }
                </label>

            </div>
        );
    }
}


export default EmailCampaignForm;