import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Nav, NavItem } from 'reactstrap';
import classNames from "classnames";


var Submenu = props => {

    //1. Var initialization
    const permissions = props.permissions;


    // 2. Permissions Functions
    var searchInPerm = (search) => {
        var exists = false;
        permissions.forEach(p => {
            if (p.id === 'owner') {
                exists = true;
            }
            else {
                if (p.id === search) {
                    p.buckets.forEach(b => {
                        if (b === props.bucketId) {
                            exists = true;
                        }
                    })

                }
            }

        });
        return exists
    }

    //1.1 Permission Detection
    const owner_permission = searchInPerm("owner");
    const bucketAdmin_permission = searchInPerm('bucket_admin');
    const campaignAdmin_permission = searchInPerm('campaign_admin');

    // 3.Jerarquía de permisos

    // 1. owner
    // 2. bucket_admin
    // 3. campaign_admin

    var getMenu = () => {
        var menu = <Nav vertical>
            {owner_permission || bucketAdmin_permission ? <NavItem className={classNames({ active: props.active === "general" })} onClick={() => { props.changeScreen('general') }}>General</NavItem> : ""}
            {owner_permission ||campaignAdmin_permission ? <NavItem className={classNames({ active: props.active === "campaigns" })} onClick={() => { props.changeScreen('campaigns') }}>Campañas</NavItem> : ""}
            {owner_permission ? <NavItem className={classNames({ active: props.active === "permissions" })} onClick={() => { props.changeScreen('permissions') }}>Permisos</NavItem> : ""}
            {/* {owner_permission || bucketAdmin_permission ? <NavItem className={classNames({ active: props.active === "labels" })} onClick={() => { props.changeScreen('labels') }}>Etiquetas</NavItem> : ""} */}
            {/* {owner_permission || bucketAdmin_permission ? <NavItem className={classNames({active:props.active === "theme"})} onClick={()=>{props.changeScreen('theme')}}>Tema</NavItem> : ""} */}
        </Nav>
            ;


        return menu;
    }

    return (
        <div className="adminSubmenu">
            {getMenu()}
        </div>
    )
}

Submenu.propTypes = {
    permissions: PropTypes.array,
    active: PropTypes.oneOf(['general', 'campaigns', "permissions", "labels", "theme"]),
    changeScreen: PropTypes.func,
}

export { Submenu };