import React from "react";
import ReactTooltip from "react-tooltip";
import classnames from 'classnames';
import ClientDetail from '../Client/ClientDetail'
import { MyExportsLang, MyExportsClientListLang } from '../../lang';
import NumberFormat from "react-number-format";
import {
    Container, Row, Col,
    Card, CardBody,
    Button,
    Table,
    Input,
    Dropdown,
    UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem,
    Modal, ModalBody, ModalHeader
}
    from 'reactstrap'
import FetchCmp from "../../components/FetchCmp/fetchCmp";


class ExportsList extends React.Component {
    constructor(props) {
        super(props)
        this._isMounted = false;
        this._downloadUrl = props.getDownloadUrl('export', 'prod');
        //State
        this.state = {
            petitionBody: {
                page: 0,
                parameters: { textsearch: "" },
                sort: "createdate",
                sortdirection: -1,
            },
            dataList: null,
            edit: false,
            exportToEdit: "",
            exportEdited: {
                exportid: "",
                parameters: { description: "", name: "" }
            },
            dropdownViewModeDisplay: false,
            viewMode: 'table',
            openModal: false,
            deleteId: "",
            deleteLoading: false,
        }
        //Function Binding
        this.openEdit = this.openEdit.bind(this);
        this.handleExportName = this.handleExportName.bind(this);
        this.handleExportDescription = this.handleExportDescription.bind(this);
        this.saveEdit = this.saveEdit.bind(this);
        this.cancelEdit = this.cancelEdit.bind(this);
        this.searchExport = this.searchExport.bind(this);
    }

    async componentDidMount() {
        this._isMounted = true;
        try {
            if (this._isMounted) {
                let cacheId = "GetExportsList" + Math.round(Math.random() * 1000);
                let data = await this.props.askFor('crm', 'crm_bucket/clients/export/list', [], false, this.state.petitionBody, cacheId);
                if (data !== null) {
                    let dataList = data.data;
                    this.setState({ dataList: dataList })
                }
            }
        }
        catch (err) {
            console.log(err)
        }
    }

    async componentDidUpdate(prevProps, prevState) {
        ReactTooltip.rebuild()
        if (prevState.edit !== this.state.edit && this.state.edit === false) {
            try {
                if (this._isMounted) {
                    let cacheId = "GetExportsList" + Math.round(Math.random() * 1000);
                    let data = await this.props.askFor('crm', 'crm_bucket/clients/export/list', [], false, this.state.petitionBody, cacheId);
                    if (data != null) {
                        let dataList = data.data;
                        this.setState({ dataList: dataList })
                    }
                }
            }
            catch (err) {
                console.log(err)
            }
        }

        if (prevState.data !== this.state.data && this.state.data == null) {
            let cacheId = "GetExportsList" + Math.round(Math.random() * 1000);
            let data = await this.props.askFor('crm', 'crm_bucket/clients/export/list', [], false, this.state.petitionBody, cacheId);
            if (data !== null) {
                let dataList = data.data;
                this.setState({ dataList: dataList })
            }
        }
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    //1. EXPORT EDITING FUNCTIONS -------------------------- -------------------------- -------------------------- --------------------------
    openEdit(ex) {
        if (this._isMounted) {
            this.setState(state => {
                let res = state.exportEdited;
                res.exportid = ex.id;
                res.parameters.name = ex.name;
                res.parameters.description = ex.description;
                return ({ edit: true, exportToEdit: ex.id, exportEdited: res })
            });
        }
    }

    cancelEdit() {
        if (this._isMounted) {
            this.setState({ edit: false })
        }
    }

    handleExportName(name) {
        if (this._isMounted) {
            this.setState(state => {
                let res = state.exportEdited;
                res.parameters.name = name;
                return ({ exportEdited: res })
            })
        }
    }

    handleExportDescription(description) {
        if (this._isMounted) {
            this.setState(state => {
                let res = state.exportEdited;
                res.parameters.description = description;
                return ({ exportEdited: res })
            })
        }
    }

    async saveEdit() {
        try {
            if (this._isMounted) {
                let cacheId = 'EditExport' + Math.round(Math.random() * 1000);
                let data = this.props.askFor('crm', 'crm_bucket/updateexportid', [], false, this.state.exportEdited, cacheId)
                if (data != null) {
                    this.setState({ edit: false });
                };
            }
        }
        catch (err) {
            console.log(err);
        }

    }

    async deleteExport() {
        try {
            if (this._isMounted) {
                this.setState({ deleteLoading: true })
                let cacheId = 'DeleteExport' + Math.round(Math.random() * 1000);
                let data = await this.props.askFor('crm', 'crm_bucket/deleteexportid', [], false, { exportid: this.state.deleteId }, cacheId)
                if (data != null) {
                    console.log(data);
                    this.setState({ deleteId: '', deleteLoading: false, data: null, openModal: false })
                };
            }
        }
        catch (err) {
            console.log(err);
        }
    }

    //2. SORTING FUNCTION -------------------------- -------------------------- -------------------------- --------------------------
    searchExport(exportName) {
        this.setState(state => {
            state.dataList.forEach((ex, index) => {
                if (ex.name.toUpperCase().indexOf(exportName.toUpperCase()) !== -1) {
                    state.dataList.splice(index, 1);
                    state.dataList.unshift(ex);
                }
            });

            return ({ dataList: state.dataList })
        })
    }

    //3. VIEW MODe FUNCTIONS --------------------------------------------------------------------------------------------------------
    getLabelValue() {
        switch (this.state.viewMode) {
            case 'list': return 'Vista de lista'; break;
            case 'table': return 'Vista de tabla'; break;
            default: ; break;
        }
    }

    getView() {
        var screen = <></>;

        switch (this.state.viewMode) {
            case "list":
                screen = <ul className="list-unstyled">
                    {this.state.dataList.map((ex, key) => {
                        return (
                            <li key={key}>
                                <Card>
                                    <CardBody>
                                        <Row>
                                            <Col lg="2">{/* Number */}
                                                <h1 className="text-center">{key + 1}</h1>
                                            </Col>
                                            <Col lg="10">{/* Content */}
                                                <Row>
                                                    <Col lg="10" sm="10" xs="10">{/* Name and Description */}
                                                        {this.state.edit && this.state.exportToEdit === ex.id ?
                                                            <>
                                                                <Input type="text" defaultValue={ex.name} onChange={(e) => this.handleExportName(e.target.value)} />
                                                                <Input type="text" defaultValue={ex.description} onChange={e => this.handleExportDescription(e.target.value)} />
                                                            </>

                                                            :
                                                            <>
                                                                <h3>{ex.name}</h3>
                                                                <h4>{ex.description}</h4>
                                                            </>
                                                        }
                                                    </Col>
                                                    <Col lg="2" sm="2" xs="2">{/* Check Sing */}
                                                        <i className={classnames({ "ti-check": ex.status === "R", "ti-time": (ex.status === "P" || ex.status === "W"), "ti-alert": ex.status === "E", iconBig: true })} />
                                                    </Col>
                                                </Row>
                                                <hr />
                                                <Row>
                                                    <Col lg="4">{/* Date and Client-Count */}
                                                        <p className="d-inline"><i className="fa fa-calendar" /> {new Date(ex.createdate).toLocaleDateString()}</p>
                                                        <p className="d-inline"><i className="ti-user" /> {ex.recordcount}</p>
                                                    </Col>
                                                    <Col lg="8">{/* Buttons */}
                                                        {this.state.edit && this.state.exportToEdit === ex.id ?
                                                            <>
                                                                <button data-tip={this.props.labels.lblButtonSave} data-for="buttonExportsTooltip" className="d-inline btns" onClick={() => { this.saveEdit() }}><i className="fa fa-save" /></button>
                                                                <button data-tip={this.props.labels.lblButtonCancel} data-for="buttonExportsTooltip" className="d-inline btns" onClick={() => { this.cancelEdit() }}></button>
                                                            </>
                                                            :
                                                            <>
                                                                <button data-tip={this.props.labels.lblButtonEdit} data-for="buttonExportsTooltip" className="d-inline btns" onClick={() => { this.openEdit(ex) }}><i className="fa fa-pencil-alt" /></button>
                                                                <button data-tip={this.props.labels.lblButtonDownload} data-for="buttonExportsTooltip" className="d-inline btns"> <a target="_blank" href={this._downloadUrl + ex.exportfile}><i className="ti-download" /></a></button>
                                                                <button data-tip={this.props.labels.lblButtonViewClients} data-for="buttonExportsTooltip" className="d-inline btns" onClick={() => { this.props.selectExport(ex.id); this.props.navigate('exportClientList') }}><i className="ti-eye" /></button>
                                                                <button data-tip={'Eliminar Archivo'} data-for="buttonExportsTooltip" className="d-inline btns" onClick={() => { this.setState({ openModal: true, deleteId: ex.id }) }}><i className="ti-trash" /></button>
                                                            </>
                                                        }
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                    </CardBody>
                                </Card>
                            </li>
                        )
                    })}
                </ul>
                    ; break;
            case "table":
                screen = <>
                    <ReactTooltip id="segmentTableTooltip" place="bottom" textColor="#1c79cd" backgroundColor="#c7e1f9" />
                    <Table responsive striped bordered className="exportsTable">
                        <thead>
                            <tr>
                                <th></th>
                                <th>Nombre</th>
                                <th>Conteo de clientes</th>
                                <th>Fecha de creación</th>
                                <th>Acciones</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                this.state.dataList.map((ex, key) => {
                                    return (<tr key={key} >
                                        <td>{key + 1}</td>
                                        <td>{ex.name}</td>
                                        <td>{ex.recordcount}</td>
                                        <td>{new Date(ex.createdate).toLocaleDateString()}</td>
                                        <td>
                                            {this.state.edit && this.state.exportToEdit === ex.id ?
                                                <>
                                                    <button data-tip={this.props.labels.lblButtonSave} data-for="buttonExportsTooltip" className="d-inline btns" onClick={() => { this.saveEdit() }}><i className="fa fa-save" /></button>
                                                    <button data-tip={this.props.labels.lblButtonCancel} data-for="buttonExportsTooltip" className="d-inline btns" onClick={() => { this.cancelEdit() }}></button>
                                                </>
                                                :
                                                <>
                                                    <button data-tip={this.props.labels.lblButtonEdit} data-for="buttonExportsTooltip" className="d-inline btns" onClick={() => { this.openEdit(ex) }}><i className="fa fa-pencil-alt" /></button>
                                                    <button data-tip={this.props.labels.lblButtonDownload} data-for="buttonExportsTooltip" className="d-inline btns"> <a target="_blank" href={this._downloadUrl + ex.exportfile}><i className="ti-download" /></a></button>
                                                    <button data-tip={this.props.labels.lblButtonViewClients} data-for="buttonExportsTooltip" className="d-inline btns" onClick={() => { this.props.selectExport(ex.id); this.props.navigate('exportClientList') }}><i className="ti-eye" /></button>
                                                    <button data-tip={'Eliminar Archivo'} data-for="buttonExportsTooltip" className="d-inline btns" onClick={() => { this.setState({ openModal: true, deleteId: ex.id }) }}><i className="ti-trash" /></button>
                                                </>
                                            }
                                        </td>
                                    </tr>)
                                })
                            }
                        </tbody>
                    </Table>
                </>
                    ; break;
            default: ; break;
        }

        return screen
    }

    changeViewMode(val) {
        this.setState({ viewMode: val });
    }

    //4. RENDER -------------------------- -------------------------- -------------------------- --------------------------
    render() {
        return (<div className="content">
            <Container fluid className="exportsList">
                <Row className="dropdownCol">
                    <h1>Mis Exports</h1>
                    <div>
                        <Dropdown isOpen={this.state.dropdownViewModeDisplay} toggle={() => { this.setState(s => ({ dropdownViewModeDisplay: !s.dropdownViewModeDisplay })) }}>
                            <DropdownToggle caret>
                                {this.getLabelValue()}
                            </DropdownToggle>
                            <DropdownMenu>
                                <DropdownItem onClick={() => { this.changeViewMode('list') }}>Ver en lista</DropdownItem>
                                <DropdownItem onClick={() => { this.changeViewMode('table') }}>Ver en tabla</DropdownItem>
                            </DropdownMenu>
                        </Dropdown>
                    </div>

                </Row>
                <Row>{/* Search Bar */}
                    <Input type="text" placeholder={this.props.labels.lblPlaceHolder} onChange={e => { this.searchExport(e.target.value) }} />
                </Row>
                <Row>{/* Export List */}
                    <Col>
                        <ReactTooltip id="buttonExportsTooltip" place="top" backgroundColor="#4a92d4" textColor="#fff" />
                        {this.state.dataList != null ?
                            this.state.dataList.length == 0 ?
                                <h4 className="text-center" style={{ color: 'gray' }}>No hay exports creados</h4>
                                :
                                this.getView()
                            :
                            <div className="tex-center">
                                {this.props.loadingSpinner}
                                <h3 style={{ width: "100%", textAlign: "center" }}>{this.props.labels.lblLoading}</h3>
                            </div>
                        }
                    </Col>
                </Row>
            </Container>

            <Modal isOpen={this.state.openModal} toggle={() => { this.setState(s => ({ openModal: !s.openModal })) }} centered={true} className="">
                <ModalHeader toggle={() => { this.setState(s => ({ openModal: !s.openModal })) }}><label> Eliminar Export  </label></ModalHeader>
                <ModalBody>
                    {this.state.deleteLoading ?
                        <div className="tex-center">
                            {this.props.loadingSpinner}
                        </div>
                        :
                        <div className="deleteExportModal">
                            <p>Seguro que desea eliminar el export</p>
                            <Button onClick={() => { this.deleteExport() }}>Aceptar</Button>
                            <Button onClick={() => { this.setState({ openModal: false }) }}>Cancelar</Button>
                        </div>
                    }

                </ModalBody>
            </Modal>
        </div>)
    }
}

class ClientList extends React.Component {
    constructor(props) {
        super(props)
        this._isMounted = false;
        this._pageSize = 10;
        this.labels = MyExportsClientListLang(props.lang);
        this.state = {
            petitionBody: {
                exportid: props.exportId,
                page: 0,
                sort: 'NAME',
                sortdirection: -1,
                textsearch: '',
            },
            sorter: this.labels.lblSorterName,
            order: 'Desc.',
            page: 0,
            dataList: null,
            totalCount: 0,
        }
        //Function Binding
        this.handleOrder = this.handleOrder.bind(this);
        this.handleSorter = this.handleSorter.bind(this);
        this.firstPage = this.firstPage.bind(this);
        this.nextPage = this.nextPage.bind(this);
        this.prevPage = this.prevPage.bind(this);
        this.lastPage = this.lastPage.bind(this);
    }

    //0. REACT LIFE-CYCLE ---------------------------------------------------------------------------------
    async componentDidMount() {
        this._isMounted = true;
        try {
            if (this._isMounted) {
                let cacheId = 'GetClientList' + this.props.exportId + this.state.petitionBody.textsearch + this.state.petitionBody.sort + this.state.petitionBody.sortdirection + this.state.petitionBody.page;
                let data = await this.props.askFor('crm', 'crm_bucket/searchclientswithexportid', [], false, this.state.petitionBody, cacheId);
                console.log('Data:', data)
                if (data !== null) {
                    this.setState({ dataList: data.clients, totalCount: data.count })
                }
            }
        }
        catch (err) {
            console.log(err)
        }
    }

    async componentDidUpdate(prevProps, prevState) {
        if (prevProps.lang !== this.props.lang) {
            this.labels = MyExportsClientListLang(this.props.lang);
            if (this._isMounted) {
                this.forceUpdate();
            }
        }

        if ((prevState.page !== this.state.page) || (prevState.sorter !== this.state.sorter) || (prevState.order !== this.state.order)) {
            try {
                if (this._isMounted) {
                    let cacheId = 'GetClientList' + this.props.exportId + this.state.petitionBody.textsearch + this.state.petitionBody.sort + this.state.petitionBody.sortdirection + this.state.petitionBody.page;
                    let data = await this.props.askFor('crm', 'crm_bucket/searchclientswithexportid', [], false, this.state.petitionBody, cacheId);
                    if (data !== null) {
                        this.setState({ dataList: data.clients, totalCount: data.count })
                    }
                }
            }
            catch (err) {
                console.log(err)
            }
        }
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    //1. DATA HANDLERS ---------------------------------------------------------------------------------
    handleSorter(label, field) {
        if (this._isMounted) {
            this.setState(state => {
                let res = state.petitionBody;
                res.sort = field;
                return ({ sorter: label, petitionBody: res })
            })
        }
    }

    handleOrder(order) {
        if (this._isMounted) {
            this.setState(state => {
                let res = state.petitionBody;
                let ord = null;
                switch (order) {
                    case "Asc.": ord = 1; break;
                    case "Desc.": ord = -1; break;
                    default: ; break;
                }
                res.sortdirection = ord;
                return ({ order: order, petitionBody: res })
            })
        }
    }

    //2. HANDLE PAGE FUNCTION ---------------------------------------------------------------------------------------------------------------------

    nextPage() {
        let clientsCount = this.state.totalCount;
        let pageCount = Math.ceil(clientsCount / this._pageSize);
        if (this.state.petitionBody.page + 1 < pageCount) {
            this.setState(state => {
                let pet = state.petitionBody;
                pet.page += 1;
                return ({ petitionBody: pet, page: pet.page })
            })
        }
        else {
            //reach last page
        }
    }

    prevPage() {
        if (this.state.petitionBody.page === 0) { return /*Reach firts page */ }
        this.setState(state => {
            let pet = state.petitionBody;
            pet.page -= 1;
            return ({ petitionBody: pet, page: pet.page })
        })
    }

    firstPage() {
        let currentPage = this.state.petitionBody.page;
        if (currentPage > 0) {
            this.setState(state => {
                let pet = state.petitionBody;
                pet.page = 0;
                return ({ petitionBody: pet, page: pet.page })
            })
        }
    }

    lastPage() {
        let currentPage = this.state.petitionBody.page;
        let clientsCount = this.state.totalCount;
        let pageCount = Math.ceil(clientsCount / this._pageSize);
        if (currentPage < pageCount) {
            this.setState(state => {
                let pet = state.petitionBody;
                pet.page = pageCount - 1;
                return ({ petitionBody: pet, page: pet.page })
            })
        }
    }

    //3. RENDER --------------------------------------------------------------------------------
    render() {
        return (<div className="content">
            <Container fluid className="exportsClientList">
                <Card>{/* DROPDOWN AND GETBACKBUTTON */}
                    <CardBody>
                        <Row>
                            <Col lg="3" md="3" sm="3" xs="3">{/* SORTER */}
                                <UncontrolledDropdown>
                                    <DropdownToggle caret data-toggle="dropdown">
                                        {this.state.sorter}
                                    </DropdownToggle>
                                    <DropdownMenu>
                                        <DropdownItem onClick={() => { this.handleSorter(this.labels.lblSorterName, 'NAME') }}>{this.labels.lblSorterName}</DropdownItem>
                                        <DropdownItem onClick={() => { this.handleSorter(this.labels.lblSorterLastName, 'PRIMERAPELLIDO') }}>{this.labels.lblSorterLastName}</DropdownItem>
                                        <DropdownItem onClick={() => { this.handleSorter(this.labels.lblSorterEmail, 'EMAIL') }}>{this.labels.lblSorterEmail}</DropdownItem>
                                        <DropdownItem onClick={() => { this.handleSorter(this.labels.lblSorterAmount, 'TXAMOUNTSUM') }}>{this.labels.lblSorterAmount}</DropdownItem>
                                        <DropdownItem onClick={() => { this.handleSorter(this.labels.lblSorterCount, 'TXCOUNT') }}>{this.labels.lblSorterCount}</DropdownItem>
                                        <DropdownItem onClick={() => { this.handleSorter(this.labels.lblSorterAvgTicket, 'TIQUETEPROMEDIO') }}>{this.labels.lblSorterAvgTicket}</DropdownItem>
                                    </DropdownMenu>
                                </UncontrolledDropdown>
                            </Col>
                            <Col lg="2" md="2" sm="2" xs="2">{/* ORDER */}
                                <UncontrolledDropdown>
                                    <DropdownToggle caret data-toggle="dropdown">
                                        {this.state.order}
                                    </DropdownToggle>
                                    <DropdownMenu>
                                        <DropdownItem onClick={() => { this.handleOrder('Desc.') }}>Desc.</DropdownItem>
                                        <DropdownItem onClick={() => { this.handleOrder('Asc.') }}>Asc.</DropdownItem>
                                    </DropdownMenu>
                                </UncontrolledDropdown>
                            </Col>
                            <Col>{/* GET BACK BUTTON */}
                                <Button className="float-right newSegmentButton" onClick={() => { this.props.navigate('exportList') }}> <span>&#10229;</span> {this.labels.lblBtnBack}</Button>
                            </Col>
                        </Row>

                    </CardBody>
                </Card>

                <Row>{/* TABLE */}
                    <Col>
                        <Card>
                            <CardBody>
                                {this.state.dataList !== null && this.state.dataList.length != undefined ?
                                    <>
                                        <Table responsive striped>
                                            <thead>
                                                <tr>
                                                    <th>{this.labels.lblId}</th>
                                                    <th>{this.labels.lblName}</th>
                                                    <th>{this.labels.lblLastName}</th>
                                                    <th>{this.labels.lblPurchases}</th>
                                                    <th>{this.labels.lblVisits}</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {this.state.dataList.map((element, key) => {
                                                    return (<tr key={key}>
                                                        <td>{element.CLIENTE}</td>
                                                        <td>{element.NOMBRE}</td>
                                                        <td>{element.PRIMERAPELLIDO}</td>
                                                        <td><NumberFormat value={element.TXAMOUNTSUM} prefix={this.props.currencySymbol} displayType={'text'} thousandSeparator={true} /></td>
                                                        <td>{element.TXCOUNT}</td>
                                                        <td><Button onClick={() => { this.props.selectClient(element.CLIENTE) }}>{this.labels.btnViewClients}</Button></td>
                                                    </tr>
                                                    )
                                                })
                                                }
                                            </tbody>
                                        </Table>

                                        <div className="tableRowButtons">
                                            <h4>{this.labels.lblPaginator}: {this.state.petitionBody.page + 1}</h4>
                                            <button className=" pager-button" onClick={() => { this.firstPage() }}>{this.labels.lblButtonFirst}</button>
                                            <button className=" pager-button" onClick={() => { this.prevPage() }}>
                                                <i className="ti-angle-left"></i></button>
                                            <button className=" pager-button" onClick={() => { this.nextPage() }}>
                                                <i className="ti-angle-right"></i>
                                            </button>
                                            <button className=" pager-button" onClick={() => { this.lastPage() }}>{this.labels.lblButtonLast}</button>
                                        </div>
                                    </>
                                    :
                                    <div className="text-center"><h2>{this.labels.lblLoading}</h2></div>
                                }
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </div>)
    }


}

class WrapedMyExports extends React.Component {
    constructor(props) {
        super(props)
        this._isMounted = false;
        this.labels = MyExportsLang(props.lang);
        this.state = { screen: 'exportList', exportId: '', clientId: null };
        //Function Binding
        this.navigate = this.navigate.bind(this);
        this.handleExportId = this.handleExportId.bind(this);
        this.handleClientId = this.handleClientId.bind(this);
        this.closeClientDetail = this.closeClientDetail.bind(this);
    }

    //0. REACT LIFECYCLE
    componentDidMount() {
        this._isMounted = true;
    }

    componentDidUpdate(prevProps) {
        if (prevProps.lang !== this.props.lang) {
            if (this._isMounted) {
                this.labels = MyExportsLang(this.props.lang);
                this.forceUpdate();
            }
        }
    }

    componentWillUnmount() {
        this._isMounted = false;
    }


    //1. NAVIGATION FUNCTIONS
    navigate(toWhere) {
        if (this._isMounted) {
            this.setState({ screen: toWhere })
        }
    }

    closeClientDetail() {
        this.setState({ clientId: null, screen: 'exportList' })
    }

    //2. HANDLERS
    handleExportId(id) {
        if (this._isMounted) {
            this.setState({ exportId: id })
        }
    }

    handleClientId(clientId) {
        if (this._isMounted) {
            this.setState({ clientId: clientId }, () => { this.navigate('clientDetail') })
        }
    }

    //RENDER -------------
    render() {
        var screen = null;
        switch (this.state.screen) {
            case "exportList": screen =
                <ExportsList
                    askFor={this.props.askFor}
                    labels={this.labels}
                    navigate={this.navigate}
                    selectExport={this.handleExportId}
                    getDownloadUrl={this.props.getUrl}
                    loadingSpinner={this.props.loadingSpinner}
                />
                ; break;
            case "exportClientList": screen =
                <ClientList
                    askFor={this.props.askFor}
                    lang={this.props.lang}
                    navigate={this.navigate}
                    exportId={this.state.exportId}
                    currencySymbol={this.props.currencySymbol}
                    selectClient={this.handleClientId}
                    loadingSpinner={this.props.loadingSpinner}
                />
                ; break;
            case "clientDetail": screen = <div className="content">
                <Container fluid>
                    <ClientDetail
                        askFor={this.props.askFor}
                        clientId={this.state.clientId}
                        backFunction={this.closeClientDetail}
                        currencySymbol={this.props.currencySymbol}
                        lang={this.props.lang}
                        theme={this.props.bucket.theme}
                        loadingSpinner={this.props.loadingSpinner}
                        hideRedeem={this.props.isSectionHidden('clientdetails_redeemed_points_permonth')}
                        isFieldHidden= {this.props.isFieldHidden}
                        getBucketLabels={this.props.getBucketLabels}
                    />
                </Container>
            </div>; break;
            default: ; break;
        }
        return (screen);
    }
}

var MyExports = props => {
    return (
        <FetchCmp render={props =>
            <WrapedMyExports
                askFor={props.askFor}
                lang={props.lang}
                currencySymbol={props.currencySymbol}
                getUrl={props.getUrl}
                loadingSpinner={props.loadingSpinner}
                isSectionHidden={props.isSectionHidden}
                isFieldHidden= {props.isFieldHidden}
                bucket={props.bucket}
                getBucketLabels={props.getLabels}
            />
        } />
    )
}

export default MyExports;