import React from "react";
import classnames from 'classnames';
import NumberFormat from "react-number-format";
import { RFMatrixLang, MySegmentsLang, MyExportsCreatorLang } from '../../lang';
import { CSSTransition } from "react-transition-group";
import ClientDetail from '../Client/ClientDetail'
import { Redirect } from "react-router-dom";
import $ from 'jquery';
import ReactTooltip from "react-tooltip";
// import FetchCmp from '../../components/FetchCmp/fetchCmp';
import InputRange from 'react-input-range';
import {
    Container, Row, Col,
    Card, CardBody,
    Button,
    Table,
    Input, FormGroup,
    Modal, ModalBody,
} from 'reactstrap';
import { Bar } from "react-chartjs-2";


//A. SUB-COMPONENT
class ClientList extends React.Component {
    constructor(props) {
        super(props)
        this._isMounted = false;
        this._pageSize = 10;
        this.state = { totalCount: 0, data: null, page: 0 };
        this.loadingSpinner = <div className="lds-container">
            <div className="lds-ring"><div></div><div></div><div></div><div></div></div>
        </div>;
        //Function Binding
        this.nextPage = this.nextPage.bind(this);
        this.prevPage = this.prevPage.bind(this);
        this.firstPage = this.firstPage.bind(this);
        this.lastPage = this.lastPage.bind(this);
        this.f = {
            selectedSegment: props.selectedSegment,
            settings: props.settingsData,
            matrixcellid: props.selectedMatrxCellId,
            decile: props.selectedDecile
        };
        this.body = {
            "parameters": {
                "requester": 'settings',
                "requestparameters": this.f,
                "filters": [],
                "textsearch": ''
            },
            "page": this.state.page,
            "sort": 'NOMBRE',
            "sortdirection": -1
        };

    }

    // 1. API CALLS ------------------------------------------------------------------------------------------------------------------------------
    async componentDidMount() {
        this._isMounted = true;
        try {
            let cacheId = 'SegmentsFromFRMMatriz-' + this.body.parameters.requester + this.props.selectedMatrxCellId.R + this.props.selectedMatrxCellId.F + this.props.selectedMatrxCellId.M + this.state.page
            let data = await this.props.askFor('crm', 'crm_bucket/searchclientswithparameters', [], false, this.body, cacheId);
            if (this._isMounted && data != null) {
                this.setState({ data: data.clients, totalCount: data.count })
            }
        } catch (err) {
            console.log(err);
        }
    }

    async componentDidUpdate(prevProps, prevState) {
        if (prevState.page != this.state.page) {
            try {
                if (this._isMounted) { this.setState({ data: null }) }
                let cacheId = 'SegmentsFromFRMMatriz' + this.body.parameters.requester + this.props.selectedMatrxCellId + this.state.page
                this.body.page = this.state.page
                let data = await this.props.askFor('crm', 'crm_bucket/searchclientswithparameters', [], false, this.body, cacheId);
                if (this._isMounted && data != null) {
                    this.setState({ data: data.clients, totalCount: data.count })
                }
            } catch (err) {
                console.log(err);
            }
        }

        if (prevProps.selectedMatrxCellId != this.props.selectedMatrxCellId) {
            try {
                let cacheId = 'SegmentsFromFRMMatriz-' + this.body.parameters.requester + this.props.selectedMatrxCellId.R + this.props.selectedMatrxCellId.F + this.props.selectedMatrxCellId.M + this.state.page
                let data = await this.props.askFor('crm', 'crm_bucket/searchclientswithparameters', [], false, this.body, cacheId);
                if (this._isMounted && data != null) {
                    this.setState({ data: data.clients, totalCount: data.count })
                }
            } catch (err) {
                console.log(err);
            }
        }

        ReactTooltip.rebuild();
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    //2. HANDLE PAGE FUNCTION ---------------------------------------------------------------------------------------------------------------------

    nextPage() {
        let clientsCount = this.state.totalCount;
        let pageCount = Math.ceil(clientsCount / this._pageSize);
        if (this.state.page + 1 < pageCount) {
            this.setState(state => ({ page: state.page + 1 }))
        }
        else {
            //reach last page
        }
    }

    prevPage() {
        if (this.state.page == 0) { return /*Reach firts page */ }
        this.setState(state => ({ page: state.page - 1 }))
    }

    firstPage() {
        let currentPage = this.state.page;
        if (currentPage > 0) {
            this.setState({ page: 0 })
        }
    }

    lastPage() {
        let currentPage = this.state.page;
        let clientsCount = this.state.totalCount;
        let pageCount = Math.ceil(clientsCount / this._pageSize);
        if (currentPage < pageCount) {
            this.setState({ page: pageCount - 1 })
        }
    }

    // 3. PRE-RENDER FUNCTIONS --------------------------------------------------------------------------------------------------------------


    //4 RENDER ------------------------------------------------------------------------------------------------------------------------------
    render() {
        if (this.state.data != null) {
            return (<div className="RfmMatrizClientList">
                <ReactTooltip id="settingsCrmClientsTooltip" place="left" textColor="#1c79cd" backgroundColor="#c7e1f9" />
                <h3>{this.props.labels.lblClientTableTitle}</h3>
                <Container fluid className="closeClientListRfmMatriz">
                    <Row>
                        <Col lg="10" />
                        <Col lg="2">
                            <button onClick={() => { this.props.closeList() }} className="btnOpenRfmMatriz">Volver a matriz</button>
                        </Col>
                    </Row>
                </Container>
                <Table responsive className="crmSegments" striped={true}>
                    <thead>
                        <tr>
                            <th>ID</th>
                            <th>{this.props.labels.lblSorterName}</th>
                            <th>{this.props.labels.lblSorterLastName}</th>
                            <th>{this.props.labels.lblSorterAge}</th>
                            <th>{this.props.labels.lblSorterCount}</th>
                            <th>{this.props.labels.lblVisitCounter}</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.state.data.map((element, key) => {
                            return (<tr key={key}>
                                <td>{element.CLIENTE}</td>
                                <td>{element.NOMBRE}</td>
                                <td>{element.PRIMERAPELLIDO}</td>
                                <td>{element.EDAD}</td>
                                <td><NumberFormat value={element.TXAMOUNTSUM} prefix={this.props.currencySymbol} displayType={'text'} thousandSeparator={true} /></td>
                                <td>{element.TXCOUNT}</td>
                                <td>
                                    <Button data-tip="Ver clientes" data-for="settingsCrmClientsTooltip" onClick={() => { this.props.openClientDetail(element.CLIENTE) }}> <i className="ti-eye"></i></Button>
                                    {/* <Button onClick={() => { this.props.openClientDetail(element.CLIENTE) }}>{this.props.labels.lblButtonViewClient}</Button> */}
                                </td>
                            </tr>
                            )
                        })
                        }
                    </tbody>
                </Table>

                <div className="tableRowButtons">
                    <h4>{this.props.labels.lblPaginator}: {this.state.page + 1}</h4>
                    <button className=" pager-button" onClick={() => { this.firstPage() }}>{this.props.labels.lblButtonFirst}</button>
                    <button className=" pager-button" onClick={() => { this.prevPage() }}>
                        <i className="ti-angle-left"></i></button>
                    <button className=" pager-button" onClick={() => { this.nextPage() }}>
                        <i className="ti-angle-right"></i>
                    </button>
                    <button className=" pager-button" onClick={() => { this.lastPage() }}>{this.props.labels.lblButtonLast}</button>
                </div>
            </div>)
        } else {
            return (
                <div className='text-center'>
                    {this.loadingSpinner}
                    <h3>{this.props.labels.lblLoading}</h3>
                </div>
            )
        }

    }
}

class FloatingCard extends React.Component {
    constructor(props) {
        super(props)
    }

    render() {
        return (
            <div className="floating-card container">
                <CardBody>
                    <Row>
                        <Col lg="9" xs="9" className="text-left">
                            <div className="stats">
                                <h2 className="text-primary" style={{ marginBottom: '0px' }}>{this.props.totalCount}</h2>
                                <h6 className="text-muted">Clientes</h6>
                            </div>
                        </Col>
                        <Col lg="3" xs="3" className="text-right">
                            <div className="stats">
                                <button onClick={() => { this.props.closeCellDetail() }} className="btnOpenRfmMatriz">
                                    <i className="ti-close"></i>
                                </button>
                            </div>
                        </Col>
                    </Row>
                    <div>
                        <hr />
                        <div className="stats">
                            <i className=" ti-user"></i> %Clientes: <b className="text-primary">{this.props.percCountFromTotal}%</b>
                        </div>
                        <hr />
                        <div className="stats">
                            <i className=" ti-bag"></i> % Compras: <b className="text-primary">{this.props.percMontoFromTotal}%</b>
                        </div>
                        <hr />
                        <div className="stats">
                            <i className=" ti-money"></i> Ticket Promedio: <b className="text-primary">{this.props.currencySymbol} {this.props.avgTicket}</b>
                        </div>

                        {/* <!-- buttons --> */}
                        <hr />
                        <div className="stats text-center" style={{ width: "100%" }}>
                            <Row style={{ width: "100%" }}>
                                <Col sm="6" className="text-left">
                                    <button className="btnOpenRfmMatriz" style={{ width: "100%" }} onClick={() => { this.props.openExport() }}><i className="ti-download"></i> Exportar </button>
                                </Col>
                                <Col sm="6" className="text-right">
                                    <button className="btnOpenRfmMatriz" onClick={() => { this.props.openClientList() }}>Ver Clientes</button>
                                </Col>
                            </Row>
                        </div>
                    </div>
                </CardBody>
            </div>
        )
    }
}

class ExportCreator extends React.Component {
    constructor(props) {
        super(props)
        this._isMounted = false;
        this._labels = MyExportsCreatorLang(props.lang);
        this.state = {
            petitionBody: {
                "parameters": {
                    "requester": "settings",
                    "requestparameters": {
                        "matrixcellid": props.cellId,
                    },
                    "filters": "",
                    "textsearch": "",
                    "collectionname": "",
                    "description": ""
                },
                "page": 0,
                "sort": -1
            },
            petitionSended: false,
        }
        //Function Binding
        this.handleDescription = this.handleDescription.bind(this);
        this.handleName = this.handleName.bind(this);
        this.saveExport = this.saveExport.bind(this);
    }

    //1. REACT LIFECYCLE --------------------------
    componentDidMount() {
        this._isMounted = true;
        this.setState(state => {
            let body = state.petitionBody;
            return ({ petitionBody: body })
        })
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.filters != this.props.filters) {
            if (this._isMounted) {
                this.setState(state => {
                    let body = state.petitionBody;
                    return ({ petitionBody: body })
                })
            }
        }

        if (prevProps.lang != this.props.lang) {
            if (this._isMounted) {
                this._labels = MyExportsCreatorLang(this.props.lang);
                this.forceUpdate();
            }
        }
    }

    componentWillMount() {
        this._isMounted = false;
    }


    //2.DATA HANDLERS
    handleName(name) {
        if (this._isMounted) {
            this.setState(state => {
                let body = state.petitionBody;
                body.parameters.collectionname = name;
                return ({ petitionBody: body })
            })
        }
    }

    handleDescription(desc) {
        if (this._isMounted) {
            this.setState(state => {
                let body = state.petitionBody;
                body.parameters.description = desc;
                return ({ petitionBody: body })
            })
        }
    }


    //3.SAVE EXPORT FUNCTION
    async saveExport() {
        try {
            let cacheId = 'SaveExport' + this.state.petitionBody.parameters.requester + this.state.petitionBody.parameters.page;
            let data = await this.props.askFor('crm', 'crm_bucket/clients/export/create', [], false, this.state.petitionBody, cacheId);
            if (this._isMounted && data != null) {
                this.setState({ petitionSended: true })
            }
        } catch (err) {
            console.log(err);
        }
    }


    //4. RENDER -------------------------- -------------------------- --------------------------
    render() {
        if (this.state.petitionSended) {
            //Redirects to My exports section
            return (<Redirect to="/myexports" />)
        }
        else {
            //Default
            return (<Container fluid className="exportCreator">
                <Card>
                    <CardBody>
                        <Row>{/* Title and explanation */}
                            <Col>
                                <h4>{this._labels.lblTitle}</h4>
                                <p>{this._labels.lblExplain}</p>
                            </Col>
                        </Row>
                        <Row>{/* Name and count */}
                            <Col>
                                <h4>{this.props.segmentName || 'Nombre'}</h4>
                                <p>{this._labels.lblClientsToExport}: <b>{this.props.count || 0}</b> </p>
                            </Col>
                        </Row>
                        <Row>{/* Inputs */}
                            <Col>
                                <FormGroup>
                                    <Input type="text" placeholder={this._labels.lblNamePlaceHolder} onChange={e => this.handleName(e.target.value)} />
                                    <Input type="textarea" placeholder={this._labels.lblDescPlaceholder} onChange={e => this.handleDescription(e.target.value)} />
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>{/* Buttons */}
                            <Col>
                                <Row>
                                    <Col>
                                        <button className="d-inline" onClick={() => { this.props.closeExport() }}>{this._labels.lblButtonCancel}</button>
                                        <button className="d-inline" onClick={() => { this.saveExport() }}><i className="ti-download" />  {this._labels.lblButtonExport}</button>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
            </Container>)
        }

    }
}

var Deciles = props => {
    //PROPS: type:string, values:int|range->{min:int, max:int} ,
    const values = props.values;
    const deciles = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
    const decilesFM = [10, 9, 8, 7, 6, 5, 4, 3, 2, 1];

    if (props.type == "r") {
        return (
            <div>
                <div className="decileContainer">
                    {deciles.map((d, key) => {
                        return (
                            <div key={key} className={classnames({ decile: true, low: d <= values, high: d > values })}>{d}</div>
                        )
                    })}
                </div>
                <div className="labels">
                    <span className="low">Reciente</span>
                    <span className="high">Anterior</span>
                </div>
            </div>

        )
    }
    else {
        return (
            <div>
                <div className="decileContainer">
                    {deciles.map((d, key) => {
                        return (
                            <div key={key} className={classnames({ decile: true, low: d < values.min, mid: d >= values.min && d <= values.max, high: d > values.max })}>{d}</div>
                        )
                    })}
                </div>
                {props.type == "f" ?
                    <div className="labels">
                        <span className="low">Alta</span>
                        <span className="medium">Media</span>
                        <span className="high">Baja</span>
                    </div>
                    :
                    <div className="labels">
                        <span className="low">Alto</span>
                        <span className="medium">Medio</span>
                        <span className="high">Bajo</span>
                    </div>
                }
            </div>

        )
    }
}

var BarChart = props => {
    //Props: type:string, data:array, value:range->{min:int, max:int};

    const chartOptions = {
        responsive: true,
        legend: {
            display: false,
            labels: {
                fontColor: '#9a9a9a'
            }
        },
        scales: {
            yAxes: [
                {
                    gridLines: {
                        drawBorder: false,
                        color: "rgba(29,140,248,0.0)",
                        zeroLineColor: "transparent"
                    },
                    ticks: {
                        display: false,
                        maxTicksLimit: 10,
                        fontColor: "#9a9a9a",
                        userCallback: function (value, index, values) {
                            var strVal = value.toString();

                            if (value >= 1000 && value < 10000) {
                                strVal = value.toString();
                                return strVal.substring(0, 1) + "," + strVal.substring(1);
                            }

                            if (value >= 10000 && value < 100000) {
                                strVal = value.toString();
                                return strVal.substring(0, 2) + "," + strVal.substring(2);
                            }

                            if (value >= 1000000) {
                                value = (value / 1000000);
                                strVal = value.toString();
                                return strVal + "m";
                            }

                            return strVal;
                        }
                    }
                }
            ],
            xAxes: [
                {
                    gridLines: {
                        drawBorder: false,
                        color: "rgba(29,140,248,0.0)",
                        zeroLineColor: "transparent"
                    },
                    ticks: {
                        // display:false,
                        fontColor: "#9a9a9a",
                        fontSize: 9.5
                    }
                }
            ]
        },
        tooltips: {
            callbacks: {
                title: function () {
                    return '';
                },
                label: function (tooltipItem, data) {
                    let v = tooltipItem.yLabel;
                    return v.toLocaleString();
                }
            }
        }
    }

    var sortByKey = (array, key) => {
        return array.sort(function (a, b) {
            var x = a[key]; var y = b[key];
            return ((x < y) ? -1 : ((x > y) ? 1 : 0));
        });
    }

    var readyChartData = (data, label) => {
        if (data === null || data === undefined) {
            return null;
        }

        return (canvas => {
            let ctx = canvas.getContext("2d");
            var concatArray = [];
            var value = props.value;
            var backgroundColors = []
            var gradientStroke = null;
            var pointColor = "#43b02a";
            let labels = [];
            let chartData = []

            switch (props.type) {
                case "r":
                    gradientStroke = '#1470c7ff';
                    pointColor = "#1470c7ff";
                    concatArray = concatArray.concat(data['rfmvalues_r_recent']);
                    concatArray = concatArray.concat(data['rfmvalues_r_prior']);
                    ; break;
                case "f":
                    gradientStroke = '#1470c7ff';
                    pointColor = "#1470c7ff";
                    concatArray = concatArray.concat(data['rfmvalues_f_high']);
                    concatArray = concatArray.concat(data['rfmvalues_f_medium']);
                    concatArray = concatArray.concat(data['rfmvalues_f_low']);
                    ; break;
                case "m":
                    gradientStroke = "#1470c7ff";
                    pointColor = "#1470c7ff";
                    concatArray = concatArray.concat(data['rfmvalues_m_high']);
                    concatArray = concatArray.concat(data['rfmvalues_m_medium']);
                    concatArray = concatArray.concat(data['rfmvalues_m_low']);
                    ; break;
            }

            sortByKey(concatArray, "INDICE")

            concatArray.forEach(element => {
                if (element === null || element === undefined) {
                    return null;
                }
                else {
                    switch (props.type) {
                        case "r":
                            labels.push(element['INDICE']); chartData.push(element['INICIAL']);
                            if (element['INDICE'] > value) { backgroundColors.push('#041525') }
                            else if (element['INDICE'] <= value) { backgroundColors.push('#1470c7ff') }
                            break;
                        case "f":
                            labels.push(element['INDICE']); chartData.push(element['FINAL']);
                            if (element['INDICE'] < value.min) { backgroundColors.push('#99c8f5') }
                            else if (element['INDICE'] >= value.min && element['INDICE'] <= value.max) { backgroundColors.push('#1470c7ff') }
                            else if (element['INDICE'] > value.max) { backgroundColors.push('#041525') }
                            break;
                        case "m":
                            labels.push(element['INDICE']); chartData.push(element['FINAL']);
                            if (element['INDICE'] < value.min) { backgroundColors.push('#99c8f5') }
                            else if (element['INDICE'] >= value.min && element['INDICE'] <= value.max) { backgroundColors.push('#1470c7ff') }
                            else if (element['INDICE'] > value.max) { backgroundColors.push('#041525') }
                            break;
                    }
                }
            });


            return {
                labels: labels,
                datasets: [
                    {
                        label: label,
                        fill: true,
                        backgroundColor: backgroundColors,
                        borderColor: backgroundColors,
                        borderWidth: 2,
                        borderDash: [],
                        borderDashOffset: 0.0,
                        pointBackgroundColor: pointColor,
                        pointBorderColor: "rgba(250,105,0,0)",
                        pointHoverBackgroundColor: "white",
                        pointBorderWidth: 20,
                        pointHoverRadius: 4,
                        pointHoverBorderWidth: 15,
                        pointRadius: 4,
                        data: chartData
                    }
                ]
            };
        })
    }

    return (
        <div className="barChartContainer">
            <Bar
                data={readyChartData(props.data, '')}
                options={chartOptions}
                height={250}
            />
            {props.type == "r" ?
                <div className="labels">
                    <span className="low">Reciente</span>
                    <span className="high">Anterior</span>
                </div>
                :
                props.type == "f" ?
                    <div className="labels">
                        <span className="low">Alta</span>
                        <span className="medium">Media</span>
                        <span className="high">Baja</span>
                    </div>
                    :
                    <div className="labels">
                        <span className="low">Alto</span>
                        <span className="medium">Medio</span>
                        <span className="high">Bajo</span>
                    </div>
            }

        </div>
    )
}

// B. MAIN COMPONENT
class Settings extends React.Component {
    constructor(props) {
        super(props)
        this._isMounted = false;
        this.labels = RFMatrixLang(props.lang)
        this.clientListLabels = MySegmentsLang(props.lang);
        this.rfmMatrixData = props.rfmMatrixData;
        var rfmcva = JSON.parse(JSON.stringify(props.settingsData.rfmvaluesarrays));;
        this.state = {
            rows: ["1", "2", "3", "4", "5", "6"],
            showConfiguration: false,
            showDeciles: false,
            showDecileGraphs: false,
            showDetail: false,
            showClientList: false,
            showExport: false,
            showclientdetail: false,
            showConfigChangeConfirm: false,
            showLoading: false,
            showConfigurationSaved: false,
            _generalTotal: 0,
            _percCountFromTotal: "",
            _totalCountDetail: "",
            _totalCountDetailNum: 0,
            _percMontoFromTotal: "",
            _avgTicket: "",
            _selectedIdObject: { "R": "", "F": "", "M": "" },
            _defaultFirstMatrixValueField: "count",
            cellsData: null,
            clientId: null,
            rfmConfig: {
                r: { min: 1, max: 10, value: 0 },
                f: { min: 1, max: 10, value: { min: 1, max: 2 } },
                m: { min: 1, max: 10, value: { min: 1, max: 2 } }
            },
            rfmvaluesarrays: rfmcva,
            rfmHasChanges: false,
        };
        this.colors = {
            _aRetenerColor: "#2DC13F",
            _incrementarMColor: "#258E98",
            _incrementarFColor: "#F9943A",
            _traerDeRegresoColor: "#ffb81c",
            _ocasionalesColor: "#F9443A",
        };
        this.classNames = {
            slider: 'input-range__slider',//El objeto que se desliza
            sliderContainer: 'input-range__slider-container',//Contenedor del objeto que se desliza
            track: 'input-range__track', //Barra de desplazamiento (blanca)
            activeTrack: 'input-range__track--active', //Barra de desplazamiento pintada
            inputRange: 'input-range',
            labelContainer: 'input-range__label-container', //Contenedor de todas las etiquetas
            maxLabel: 'input-range__label--max', // Etiquita de valor minimo
            minLabel: 'input-range__label--min', //Etiqueta de valor maximo
            valueLabel: 'input-range__label--value', // Etiqueta flotante sobre la barras deslizantes
            disabledInputRange: 'input-range--disabled'
        }
        //FUNCTION BINDING
        this.BuildSegmentObject = this.BuildSegmentObject.bind(this);
        this.PutSegmentInMatrix = this.PutSegmentInMatrix.bind(this);
        this.ProcessSettingsData = this.ProcessSettingsData.bind(this);
        this.PutCellValuesOnMatrix = this.PutCellValuesOnMatrix.bind(this);
        this.TruncDecimals = this.TruncDecimals.bind(this);
        this.CalculateTotals = this.CalculateTotals.bind(this);
        this.CellClick = this.CellClick.bind(this);
        this.CloseCellDetail = this.CloseCellDetail.bind(this);
        this.BuildCellId = this.BuildCellId.bind(this);
        this.BuildCellIdForDOM = this.BuildCellIdForDOM.bind(this);
        this.GetCell = this.GetCell.bind(this);
        this.GetCellVal = this.GetCellVal.bind(this);
        this.GetClassForMatrixContent = this.GetClassForMatrixContent.bind(this);
        this.changeDataVisualization = this.changeDataVisualization.bind(this);
        this.CloseClientList = this.CloseClientList.bind(this);
        this.openClientList = this.openClientList.bind(this);
        this.openExport = this.openExport.bind(this);
        this.closeExport = this.closeExport.bind(this);
        this.openClientDetail = this.openClientDetail.bind(this);
        this.closeClientDetail = this.closeClientDetail.bind(this);
        this.formatNumbers = this.formatNumbers.bind(this);
        this.formatRangeInputLabel = this.formatRangeInputLabel.bind(this);
        this.calcRangeInputDefaultValues = this.calcRangeInputDefaultValues.bind(this);
        this.handleRecencia = this.handleRecencia.bind(this);
        this.handleFrecuencia = this.handleFrecuencia.bind(this);
        this.handleMonto = this.handleMonto.bind(this);
        this.toggleModal = this.toggleModal.bind(this);
    }

    //0. API CALLS -----------------------------------------------------------------------------------------------------------------------------
    async componentDidMount() {
        this._isMounted = true;
        ReactTooltip.rebuild()
        if (this.rfmMatrixData != null) {
            this.ProcessSettingsData(this.props.settingsData);
            this.PutCellValuesOnMatrix();
        }
        if (this.props.settingsData != null) {
            this.calcRangeInputDefaultValues();
        }
    }

    async componentDidUpdate(prevProps, prevState) {
        if (prevState.showClientList != this.state.showClientList && !this.state.showClientList) {
            this.ProcessSettingsData(this.props.settingsData);
            this.PutCellValuesOnMatrix();
        }

        // if(prevState.showConfiguration != this.state.showConfiguration){
        // ReactTooltip.rebuild()
        // }

        if (prevProps.lang != this.props.lang) {
            if (this._isMounted) {
                this.labels(RFMatrixLang(this.props.lang))
                this.clientListLabels = MySegmentsLang(this.props.lang);
                this.forceUpdate();
            }
        }
    }

    //1. DATA HANDLERS FOR MATRIX -----------------------------------------------------------------------------------------------------------------------------
    BuildSegmentObject(segmentData, id) {
        // segmentData is an array of json objets like: {R: 1, F: 1, M: 1}
        let segment = {};
        segment.Cells = new Array(segmentData.length);
        segmentData.map(function (s) {
            let matrixCell = {};
            matrixCell.R = s["R"];
            matrixCell.F = s["F"];
            matrixCell.M = s["M"];
            segment.Cells.push(matrixCell);
        });
        segment.Id = id;
        return segment;
    }

    ProcessSettingsData(data) {
        // Extracts the Matrix
        let _matrix = data['rfmmatrix'];
        // console.log(data);

        // Buils local objects for the segments
        var segment1 = this.BuildSegmentObject(_matrix["Segment1"], "Segment1");
        var segment2 = this.BuildSegmentObject(_matrix["Segment2"], "Segment2");
        var segment3 = this.BuildSegmentObject(_matrix["Segment3"], "Segment3");
        var segment4 = this.BuildSegmentObject(_matrix["Segment4"], "Segment4");
        var segment5 = this.BuildSegmentObject(_matrix["Segment5"], "Segment5");

        this.PutSegmentInMatrix(segment1, this.colors._aRetenerColor);
        this.PutSegmentInMatrix(segment2, this.colors._incrementarMColor);
        this.PutSegmentInMatrix(segment3, this.colors._incrementarFColor);
        this.PutSegmentInMatrix(segment4, this.colors._traerDeRegresoColor);
        this.PutSegmentInMatrix(segment5, this.colors._ocasionalesColor);
    }

    PutCellValuesOnMatrix(field) {
        if (field == undefined) field = "count"; // Default.
        var symbol = "";
        switch (field) {
            case 'count': symbol = ""; break;
            case 'monto': symbol = this.props.currencySymbol; break;
            case 'perc': symbol = "%"; break;
        }
        let globalTotal = this.GetGlobalTotal(this.rfmMatrixData);
        let _cells = [];

        this.rfmMatrixData.map((c) => {
            // Calculates the % of total purchase
            let currentAmount = c["monto"];
            let perc = 0;
            if (currentAmount != undefined && currentAmount != null && currentAmount != "") {
                perc = (currentAmount * 100) / globalTotal;
                perc = Math.floor(perc * 100) / 100;
            }

            let id = c["_id"]["R"] + "-" + c["_id"]["F"] + "-" + c["_id"]["M"];
            let val;
            if (field == "perc") {
                val = perc.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 });
            }
            else {
                val = c[field];
            }

            let numval = 0;
            if (!isNaN(Number(val))) {
                val = Number(val);
                val = Math.floor(val * 100) / 100;
                numval = val;
            }
            else {
                val = 0; // Default in case the value comes in a wrong format.
            }

            val = val.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })

            _cells.push({ id: id, val: val, numval: numval, json: c });
            if (field === "monto") {
                $("#" + id + "-p").html(symbol + val);
            }
            else {
                $("#" + id + "-p").html(val + symbol);
            }

        });

        this.state.cellsData = _cells;
        this.CalculateTotals(this.state.cellsData, symbol, this.state._defaultFirstMatrixValueField);
    }

    TruncDecimals(val) {
        if (val == undefined || val == null) return 0;
        val = Math.floor(val * 100);
        val = val / 100;
        return val;
    }

    CalculateTotals(cells, symbol, field) {
        if (cells == undefined || cells == null) { cells = this.rfmMatrixData }

        let total1 = this.GetCellVal(this.GetCell(cells, 1, 1, 1)) + this.GetCellVal(this.GetCell(cells, 1, 1, 2)) + this.GetCellVal(this.GetCell(cells, 1, 1, 3));
        let total2 = this.GetCellVal(this.GetCell(cells, 1, 2, 1)) + this.GetCellVal(this.GetCell(cells, 1, 2, 2)) + this.GetCellVal(this.GetCell(cells, 1, 2, 3));
        let total3 = this.GetCellVal(this.GetCell(cells, 1, 3, 1)) + this.GetCellVal(this.GetCell(cells, 1, 3, 2)) + this.GetCellVal(this.GetCell(cells, 1, 3, 3));
        let total4 = this.GetCellVal(this.GetCell(cells, 2, 1, 1)) + this.GetCellVal(this.GetCell(cells, 2, 1, 2)) + this.GetCellVal(this.GetCell(cells, 2, 1, 3));
        let total5 = this.GetCellVal(this.GetCell(cells, 2, 2, 1)) + this.GetCellVal(this.GetCell(cells, 2, 2, 2)) + this.GetCellVal(this.GetCell(cells, 2, 2, 3));
        let total6 = this.GetCellVal(this.GetCell(cells, 2, 3, 1)) + this.GetCellVal(this.GetCell(cells, 2, 3, 2)) + this.GetCellVal(this.GetCell(cells, 2, 3, 3));

        total1 = this.TruncDecimals(total1);
        total2 = this.TruncDecimals(total2);
        total3 = this.TruncDecimals(total3);
        total4 = this.TruncDecimals(total4);
        total5 = this.TruncDecimals(total5);
        total6 = this.TruncDecimals(total6);

        let totalGeneral = total1 + total2 + total3 + total4 + total5 + total6;
        this._generalTotal = totalGeneral;

        total1 = total1.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 });
        total2 = total2.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 });
        total3 = total3.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 });
        total4 = total4.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 });
        total5 = total5.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 });
        total6 = total6.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 });


        if (field == "monto") {
            $("#total-row-0").html(symbol + total1);
            $("#total-row-1").html(symbol + total2);
            $("#total-row-2").html(symbol + total3);
            $("#total-row-3").html(symbol + total4);
            $("#total-row-4").html(symbol + total5);
            $("#total-row-5").html(symbol + total6);
        }
        else {
            $("#total-row-0").html(total1 + symbol);
            $("#total-row-1").html(total2 + symbol);
            $("#total-row-2").html(total3 + symbol);
            $("#total-row-3").html(total4 + symbol);
            $("#total-row-4").html(total5 + symbol);
            $("#total-row-5").html(total6 + symbol);
        }

    }

    GetGlobalTotal(cells) {
        let total = 0;
        cells.map(function (c) {
            total += c["monto"];
        });

        return total;
    }

    GetCell(cells, r, f, m) {
        let id = r + "-" + f + "-" + m;
        let result = null;
        cells.map(function (c) {
            if (c["id"] == id) result = c;
        });
        return result;
    }

    GetCellVal(cell) {
        if (cell == undefined || cell == null) {
            return 0;
        }
        return cell["numval"];
    }

    CellClick(id) {
        if ($("#" + id).hasClass("selected-matrix-cell")) {
            this.CloseCellDetail();
            return;
        }

        $(".matrixcard").removeClass("selected-matrix-cell");
        $("#" + id).addClass("selected-matrix-cell");
        let s = id.split("-");
        let r = s[0];
        let f = s[1];
        let m = s[2];
        let cell = this.GetCell(this.state.cellsData, r, f, m);

        this.state._selectedIdObject["R"] = r;
        this.state._selectedIdObject["F"] = f;
        this.state._selectedIdObject["M"] = m;

        // Get Totals
        let _totalCount = 0;
        let _totalMonto = 0;
        let _totalTxCount = 0;

        this.state.cellsData.forEach(function (c) {
            _totalCount += c["json"]["count"];
            _totalMonto += c["json"]["monto"];
            _totalTxCount += c["json"]["txcount"];
        });

        // Prepares the total for the detail
        if (cell == null) { cell = { "json": { count: 0, monto: 0, txcount: 0 } } }
        let count = cell["json"]["count"];
        let perc = (count * 100) / _totalCount;
        perc = this.TruncDecimals(perc);

        let monto = Number(cell["json"]["monto"]);
        let percM = 0;
        percM = (monto * 100) / _totalMonto;
        percM = this.TruncDecimals(percM);

        let avgTicket = 0;
        let txCount = cell["json"]["txcount"];
        avgTicket = monto / txCount;
        avgTicket = this.TruncDecimals(avgTicket);

        if (this._isMounted) {
            this.setState({
                _percCountFromTotal: perc.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
                _totalCountDetail: count.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
                _totalCountDetailNum: count,
                _percMontoFromTotal: percM.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
                _avgTicket: avgTicket.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
                showDetail: true,

            })
        }
    }

    CloseCellDetail() {
        $(".matrixcard").removeClass("selected-matrix-cell");
        if (this._isMounted) {
            this.setState({ showDetail: false })
        }
    }

    openClientList() {
        if (this._isMounted) {
            this.setState({ showClientList: true })
        }
    }

    CloseClientList() {
        if (this._isMounted) {
            this.setState({ showClientList: false })
        }
    }

    openExport() {
        if (this._isMounted) {
            this.setState({ showExport: true, showClientList: false, showDetail: false });
        }
    }

    closeExport() {
        if (this._isMounted) {
            this.setState({ showExport: false, showDetail: false, showClientList: false });
        }
    }

    openClientDetail(CLIENTE) {
        if (this._isMounted) {
            this.setState({ showclientdetail: true, clientId: CLIENTE });
        }
    }

    closeClientDetail() {
        if (this._isMounted) {
            this.setState({ showclientdetail: false, clientId: null });
        }
    }

    BuildCellId(currentRowIndex, position) {
        // currentRowIndex: the index from the ngFor repeater
        // position: each row has 3 columns, this references the column assign to the cell

        var _R = 0;
        var _F = 0;
        var _M = position;

        if (currentRowIndex < 3) {
            _R = 1;
        }
        else {
            _R = 2;
        }

        if (currentRowIndex == 0) _F = 1;
        if (currentRowIndex == 1) _F = 2;
        if (currentRowIndex == 2) _F = 3;
        if (currentRowIndex == 3) _F = 1;
        if (currentRowIndex == 4) _F = 2;
        if (currentRowIndex == 5) _F = 3;

        return _R + "," + _F + "," + _M;
    }

    BuildCellIdForDOM(currentRowIndex, position) {
        // currentRowIndex: the index from the ngFor repeater
        // position: each row has 3 columns, this references the column assign to the cell

        var _R = 0;
        var _F = 0;
        var _M = position;

        if (currentRowIndex < 3) {
            _R = 1;
        }
        else {
            _R = 2;
        }

        if (currentRowIndex == 0) _F = 1;
        if (currentRowIndex == 1) _F = 2;
        if (currentRowIndex == 2) _F = 3;
        if (currentRowIndex == 3) _F = 1;
        if (currentRowIndex == 4) _F = 2;
        if (currentRowIndex == 5) _F = 3;

        return _R + "-" + _F + "-" + _M;
    }

    //2. STYLE FUNCTIONS

    GetClassForMatrixContent() {
        if (this.state.showDetail) {
            return "effect-blur";
        }
        else {
            return "";
        }
    }

    changeDataVisualization(filter) {
        //count, monto, perc
        if (this.rfmMatrixData != null && this.rfmMatrixData != undefined) {
            this.setState({ _defaultFirstMatrixValueField: filter }, () => { this.PutCellValuesOnMatrix(filter); })
        }
    }

    PutSegmentInMatrix(segment, color) {
        let bgColor = color;
        let fontColor = "white";

        segment.Cells.map(function (c) {
            let id = c.R + "-" + c.F + "-" + c.M;
            $("#" + id).css("background-color", bgColor);
            $("#" + id).css("color", fontColor);
        });
    }

    formatNumbers(number, type) {
        var options = null;
        var strVal = "";
        if (type == undefined) { type = "r" }
        switch (type) {
            case "r": options = { minimumFractionDigits: 0, maximumFractionDigits: 0 }; break;
            case "f": options = { minimumFractionDigits: 2, maximumFractionDigits: 2 }; break;
            case "m": options = { minimumFractionDigits: 0, maximumFractionDigits: 0 }; break;
        }

        if (number >= 1000000) {
            number = (number / 1000000);
            strVal = number.toLocaleString(this.props.lang, options) + "m";
        }
        else {
            strVal = Number(number).toLocaleString(this.props.lang, options);
        }

        return strVal;
    }

    formatRangeInputValue(value, type) {
        switch (type) {
            case "r":
                if (value == 1) {
                    let val = this.getDecile(type, value);
                    return val.INICIAL + ' dias';
                }
                else {
                    let val = this.getDecile(type, value);
                    return val.FINAL + ' dias';
                }
                ; break;
            case "f":
                if (value == 10) {
                    let val = this.getDecile(type, value);
                    return this.formatNumbers(val.FINAL, "f") + ' compras';
                }
                else {
                    let val = this.getDecile(type, value);
                    return this.formatNumbers(val.INICIAL, "f") + ' compras';
                }
                ; break;
            case "m":
                if (value == 10) {
                    let val = this.getDecile(type, value);
                    return this.props.currencySymbol + this.formatNumbers(val.FINAL, "m")
                }
                else {
                    let val = this.getDecile(type, value);
                    return this.props.currencySymbol + this.formatNumbers(val.INICIAL, "m")
                }
                ; break;
        }
    }

    formatRangeInputLabel(value, type) {
        switch (type) {
            case "value": return this.formatRangeInputValue(value, 'r'); break;
            case "min": return this.formatRangeInputValue(value, 'r') + "\n(baja)"; break;
            case "max": return this.formatRangeInputValue(value, 'r') + "\n(alta)"; break;
        }
    }

    //3.CONFIG FUCNTIONS

    toggleModal() {
        this.setState(state => { return ({ showConfigChangeConfirm: !state.showConfigChangeConfirm }) })
    }

    sortByKey(array, key) {
        return array.sort(function (a, b) {
            var x = a[key]; var y = b[key];
            return ((x < y) ? -1 : ((x > y) ? 1 : 0));
        });
    }

    getDecile(type, indice) {
        //Prepare the data
        var sdata = JSON.parse(JSON.stringify(this.props.settingsData.rfmvaluesarrays));
        var rfmvalues_f_high = sdata["rfmvalues_f_high"];
        var rfmvalues_f_medium = sdata["rfmvalues_f_medium"];
        var rfmvalues_f_low = sdata["rfmvalues_f_low"];
        var rfmvalues_m_high = sdata["rfmvalues_m_high"];
        var rfmvalues_m_low = sdata["rfmvalues_m_low"];
        var rfmvalues_m_medium = sdata["rfmvalues_m_medium"];
        var rfmvalues_r_recent = sdata["rfmvalues_r_recent"];
        var rfmvalues_r_prior = sdata["rfmvalues_r_prior"];

        var currentDecile = null;
        var arraySample = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11];

        switch (type) {
            case "r":
                currentDecile = rfmvalues_r_recent.find(obj => { return indice == obj.INDICE });
                if (currentDecile == undefined) { currentDecile = rfmvalues_r_prior.find(obj => { return indice == obj.INDICE }); }
                ; break;
            case "f":
                // indice = arraySample.reverse()[indice];
                currentDecile = rfmvalues_f_high.find(obj => { return indice == obj.INDICE });
                if (currentDecile == undefined) { currentDecile = rfmvalues_f_medium.find(obj => { return indice == obj.INDICE }); }
                if (currentDecile == undefined) { currentDecile = rfmvalues_f_low.find(obj => { return indice == obj.INDICE }); }
                ; break;
            case "m":
                // indice = arraySample.reverse()[indice];
                currentDecile = rfmvalues_m_high.find(obj => { return indice == obj.INDICE });
                if (currentDecile == undefined) { currentDecile = rfmvalues_m_medium.find(obj => { return indice == obj.INDICE }); }
                if (currentDecile == undefined) { currentDecile = rfmvalues_m_low.find(obj => { return indice == obj.INDICE }); }
                ; break;
        }

        return currentDecile;
    }

    calcRangeInputDefaultValues() {
        var sdata = JSON.parse(JSON.stringify(this.props.settingsData.rfmvaluesarrays));
        var rfmvalues_f_high = sdata["rfmvalues_f_high"];
        var rfmvalues_f_medium = sdata["rfmvalues_f_medium"];
        var rfmvalues_f_low = sdata["rfmvalues_f_low"];
        var rfmvalues_m_high = sdata["rfmvalues_m_high"];
        var rfmvalues_m_low = sdata["rfmvalues_m_low"];
        var rfmvalues_m_medium = sdata["rfmvalues_m_medium"];
        var rfmvalues_r_recent = sdata["rfmvalues_r_recent"];
        var rfmvalues_r_prior = sdata["rfmvalues_r_prior"];

        rfmvalues_f_high.map(function (i) { i["INDICE"] = Number(i["INDICE"]); });
        rfmvalues_f_medium.map(function (i) { i["INDICE"] = Number(i["INDICE"]); });
        rfmvalues_f_low.map(function (i) { i["INDICE"] = Number(i["INDICE"]); });
        rfmvalues_m_high.map(function (i) { i["INDICE"] = Number(i["INDICE"]); });
        rfmvalues_m_low.map(function (i) { i["INDICE"] = Number(i["INDICE"]); });
        rfmvalues_m_medium.map(function (i) { i["INDICE"] = Number(i["INDICE"]); });
        rfmvalues_r_recent.map(function (i) { i["INDICE"] = Number(i["INDICE"]); });
        rfmvalues_r_prior.map(function (i) { i["INDICE"] = Number(i["INDICE"]); });

        rfmvalues_f_high = this.sortByKey(rfmvalues_f_high, "INDICE");
        rfmvalues_f_medium = this.sortByKey(rfmvalues_f_medium, "INDICE");
        rfmvalues_f_low = this.sortByKey(rfmvalues_f_low, "INDICE");
        rfmvalues_m_high = this.sortByKey(rfmvalues_m_high, "INDICE");
        rfmvalues_m_low = this.sortByKey(rfmvalues_m_low, "INDICE");
        rfmvalues_m_medium = this.sortByKey(rfmvalues_m_medium, "INDICE");
        rfmvalues_r_recent = this.sortByKey(rfmvalues_r_recent, "INDICE");
        rfmvalues_r_prior = this.sortByKey(rfmvalues_r_prior, "INDICE");

        let rmin = rfmvalues_r_recent[0].INDICE;
        let rvalue = rfmvalues_r_recent[rfmvalues_r_recent.length - 1].INDICE;
        // let rStep = rfmvalues_r_prior[0].FINAL - rvalue + 1;
        let rmax = sdata.rfmvalues_r_prior[rfmvalues_r_prior.length - 1].INDICE;
        //let fmin = sdata.rfmvalues_f_low[sdata.rfmvalues_f_low.length-1].FINAL; 
        let fvalue = { max: rfmvalues_f_medium[rfmvalues_f_medium.length - 1].INDICE, min: rfmvalues_f_medium[0].INDICE };
        //let fmax = sdata.rfmvalues_f_high[sdata.rfmvalues_f_high.length-2].FINAL;
        // let mmin = rfmvalues_m_low[rfmvalues_m_low.length - 1].FINAL;
        let mvalue = { max: rfmvalues_m_medium[rfmvalues_m_medium.length - 1].INDICE, min: rfmvalues_m_medium[0].INDICE };
        // let mmax = sdata.rfmvalues_m_high[0].INICIAL;
        this.setState(state => {
            // let nr = { max: rmax, min: rmin, value: rvalue }
            //let nf = {min:fmin * 100, max: fmax * 100, value: fvalue}
            //let nm = { min: mmin, max: mmax, value: mvalue }
            state.rfmConfig.r.value = rvalue;
            state.rfmConfig.f.value = fvalue;
            state.rfmConfig.m.value = mvalue;
            return ({ rfmConfig: state.rfmConfig, rfmHasChanges: false, rfmvaluesarrays: this.props.settingsData.rfmvaluesarrays, rfmvaluesarrays: sdata })
        })
    }

    handleRecencia(r) {
        if (r == undefined) return;
        var sdata = null;
        var indexToMove = null;
        var decileToMove = null;
        this.setState(state => {
            let rfmc = state.rfmConfig;
            sdata = JSON.parse(JSON.stringify(state.rfmvaluesarrays));
            if (r > rfmc.r.value) {

                sdata["rfmvalues_r_recent"].forEach((d, i) => { if (d.INDICE === r) { indexToMove = i; decileToMove = d } });
                sdata["rfmvalues_r_prior"].forEach((d, i) => { if (d.INDICE === r) { indexToMove = i; decileToMove = d } });

                if (indexToMove != null && decileToMove != null) {
                    sdata["rfmvalues_r_recent"].push(decileToMove);
                    sdata["rfmvalues_r_prior"].splice(indexToMove, 1);
                    this.sortByKey(sdata["rfmvalues_r_prior"], "INDICE");
                    this.sortByKey(sdata["rfmvalues_r_recent"], "INDICE");
                }

                rfmc.r.value = r;
                return ({ rfmConfig: rfmc, rfmHasChanges: true, rfmvaluesarrays: sdata })
            }
            else if (r < rfmc.r.value) {
                sdata["rfmvalues_r_recent"].forEach((d, i) => { if (d.INDICE === r + 1) { indexToMove = i; decileToMove = d } });
                sdata["rfmvalues_r_prior"].forEach((d, i) => { if (d.INDICE === r + 1) { indexToMove = i; decileToMove = d } });

                if (indexToMove != null) {
                    sdata["rfmvalues_r_prior"].push(decileToMove);
                    sdata["rfmvalues_r_recent"].splice(indexToMove, 1);
                    this.sortByKey(sdata["rfmvalues_r_prior"], "INDICE");
                    this.sortByKey(sdata["rfmvalues_r_recent"], "INDICE");
                }

                rfmc.r.value = r;
                return ({ rfmConfig: rfmc, rfmHasChanges: true, rfmvaluesarrays: sdata })
            }
        });
    }

    handleFrecuencia(f) {
        if (f == undefined) return;
        var sdata = null;
        var indexToMove = null; // []
        var decileToMove = null; // []
        var rfmc = null;
        this.setState(state => {
            rfmc = state.rfmConfig;
            sdata = JSON.parse(JSON.stringify(state.rfmvaluesarrays));

            if (f.min > rfmc.f.value.min) {
                sdata["rfmvalues_f_high"].forEach((d, i) => { if (d.INDICE === f.min - 1) { indexToMove = i; decileToMove = d } });
                sdata["rfmvalues_f_medium"].forEach((d, i) => { if (d.INDICE === f.min - 1) { indexToMove = i; decileToMove = d } });
                sdata["rfmvalues_f_low"].forEach((d, i) => { if (d.INDICE === f.min - 1) { indexToMove = i; decileToMove = d } });

                if (indexToMove != null && decileToMove != null) {
                    sdata["rfmvalues_f_high"].push(decileToMove);
                    sdata["rfmvalues_f_medium"].splice(indexToMove, 1);
                    this.sortByKey(sdata["rfmvalues_f_high"], "INDICE");
                    this.sortByKey(sdata["rfmvalues_f_medium"], "INDICE");
                    decileToMove = null;
                    indexToMove = null;
                }

            } else if (f.min < rfmc.f.value.min) {
                sdata["rfmvalues_f_high"].forEach((d, i) => { if (d.INDICE === f.min) { indexToMove = i; decileToMove = d } });
                sdata["rfmvalues_f_medium"].forEach((d, i) => { if (d.INDICE === f.min) { indexToMove = i; decileToMove = d } });
                sdata["rfmvalues_f_low"].forEach((d, i) => { if (d.INDICE === f.min) { indexToMove = i; decileToMove = d } });

                if (indexToMove != null && decileToMove != null) {
                    sdata["rfmvalues_f_medium"].push(decileToMove);
                    sdata["rfmvalues_f_high"].splice(indexToMove, 1);
                    this.sortByKey(sdata["rfmvalues_f_medium"], "INDICE");
                    this.sortByKey(sdata["rfmvalues_f_high"], "INDICE");
                }
            } else if (f.max > rfmc.f.value.max) {
                sdata["rfmvalues_f_high"].forEach((d, i) => { if (d.INDICE === f.max) { indexToMove = i; decileToMove = d } });
                sdata["rfmvalues_f_medium"].forEach((d, i) => { if (d.INDICE === f.max) { indexToMove = i; decileToMove = d } });
                sdata["rfmvalues_f_low"].forEach((d, i) => { if (d.INDICE === f.max) { indexToMove = i; decileToMove = d } });

                if (indexToMove != null && decileToMove != null) {
                    sdata["rfmvalues_f_medium"].push(decileToMove);
                    sdata["rfmvalues_f_low"].splice(indexToMove, 1);
                    this.sortByKey(sdata["rfmvalues_f_medium"], "INDICE");
                    this.sortByKey(sdata["rfmvalues_f_low"], "INDICE");
                }
            } else if (f.max < rfmc.f.value.max) {
                sdata["rfmvalues_f_high"].forEach((d, i) => { if (d.INDICE === f.max + 1) { indexToMove = i; decileToMove = d } });
                sdata["rfmvalues_f_medium"].forEach((d, i) => { if (d.INDICE === f.max + 1) { indexToMove = i; decileToMove = d } });
                sdata["rfmvalues_f_low"].forEach((d, i) => { if (d.INDICE === f.max + 1) { indexToMove = i; decileToMove = d } });

                if (indexToMove != null && decileToMove != null) {
                    sdata["rfmvalues_f_low"].push(decileToMove);
                    sdata["rfmvalues_f_medium"].splice(indexToMove, 1);
                    this.sortByKey(sdata["rfmvalues_f_medium"], "INDICE");
                    this.sortByKey(sdata["rfmvalues_f_low"], "INDICE");
                }
            }



            rfmc.f.value = f;
            return ({ rfmConfig: rfmc, rfmHasChanges: true, rfmvaluesarrays: sdata })
        });
    }

    handleMonto(m) {
        if (m == undefined) return;
        var sdata = null;
        var indexToMove = null; // []
        var decileToMove = null; // []
        var rfmc = null;
        this.setState(state => {
            rfmc = state.rfmConfig;
            sdata = JSON.parse(JSON.stringify(state.rfmvaluesarrays));

            if (m.min > rfmc.m.value.min) {
                sdata["rfmvalues_m_high"].forEach((d, i) => { if (d.INDICE === m.min - 1) { indexToMove = i; decileToMove = d } });
                sdata["rfmvalues_m_medium"].forEach((d, i) => { if (d.INDICE === m.min - 1) { indexToMove = i; decileToMove = d } });
                sdata["rfmvalues_m_low"].forEach((d, i) => { if (d.INDICE === m.min - 1) { indexToMove = i; decileToMove = d } });

                if (indexToMove != null && decileToMove != null) {
                    sdata["rfmvalues_m_high"].push(decileToMove);
                    sdata["rfmvalues_m_medium"].splice(indexToMove, 1);
                    this.sortByKey(sdata["rfmvalues_m_high"], "INDICE");
                    this.sortByKey(sdata["rfmvalues_m_medium"], "INDICE");
                }

            } else if (m.min < rfmc.m.value.min) {
                sdata["rfmvalues_m_high"].forEach((d, i) => { if (d.INDICE === m.min) { indexToMove = i; decileToMove = d } });
                sdata["rfmvalues_m_medium"].forEach((d, i) => { if (d.INDICE === m.min) { indexToMove = i; decileToMove = d } });
                sdata["rfmvalues_m_low"].forEach((d, i) => { if (d.INDICE === m.min) { indexToMove = i; decileToMove = d } });

                if (indexToMove != null && decileToMove != null) {
                    sdata["rfmvalues_m_medium"].push(decileToMove);
                    sdata["rfmvalues_m_high"].splice(indexToMove, 1);
                    this.sortByKey(sdata["rfmvalues_m_medium"], "INDICE");
                    this.sortByKey(sdata["rfmvalues_m_high"], "INDICE");
                }
            } else if (m.max > rfmc.m.value.max) {
                sdata["rfmvalues_m_high"].forEach((d, i) => { if (d.INDICE === m.max) { indexToMove = i; decileToMove = d } });
                sdata["rfmvalues_m_medium"].forEach((d, i) => { if (d.INDICE === m.max) { indexToMove = i; decileToMove = d } });
                sdata["rfmvalues_m_low"].forEach((d, i) => { if (d.INDICE === m.max) { indexToMove = i; decileToMove = d } });

                if (indexToMove != null && decileToMove != null) {
                    sdata["rfmvalues_m_medium"].push(decileToMove);
                    sdata["rfmvalues_m_low"].splice(indexToMove, 1);
                    this.sortByKey(sdata["rfmvalues_m_medium"], "INDICE");
                    this.sortByKey(sdata["rfmvalues_m_low"], "INDICE");
                }
            } else if (m.max < rfmc.m.value.max) {
                sdata["rfmvalues_m_high"].forEach((d, i) => { if (d.INDICE === m.max + 1) { indexToMove = i; decileToMove = d } });
                sdata["rfmvalues_m_medium"].forEach((d, i) => { if (d.INDICE === m.max + 1) { indexToMove = i; decileToMove = d } });
                sdata["rfmvalues_m_low"].forEach((d, i) => { if (d.INDICE === m.max + 1) { indexToMove = i; decileToMove = d } });

                if (indexToMove != null && decileToMove != null) {
                    sdata["rfmvalues_m_low"].push(decileToMove);
                    sdata["rfmvalues_m_medium"].splice(indexToMove, 1);
                    this.sortByKey(sdata["rfmvalues_m_medium"], "INDICE");
                    this.sortByKey(sdata["rfmvalues_m_low"], "INDICE");
                }
            }


            rfmc.m.value = m;
            return ({ rfmConfig: rfmc, rfmHasChanges: true, rfmvaluesarrays: sdata })

        });
    }

    setControlStep(type, val) {
        this.setState(state => {
            state.rfmConfig[type].step = this.calcStep(val);;
            return ({ rfmConfig: state.rfmConfig })
        })
    }

    //4. API CALLS

    async sendNewConfig() {
        this.setState({ showLoading: true }, async () => {
            let cacheId = "SetRmfConfig-" + Math.floor(Math.random() * 1000);
            var cd = {
                rfmmatrix: this.props.settingsData.rfmmatrix,
                rfmvaluesarrays: this.state.rfmvaluesarrays,
            }
            var settingsDataResponse = await this.props.askFor('segments', 'segments/setrfmconfig', [], false, { configurationData: cd }, cacheId);
            if (settingsDataResponse != null) {
                if (settingsDataResponse.result) {
                    this.props.resetCache();
                    this.setState({ showLoading: false, rfmHasChanges: false }, () => {
                        this.props.changeScreen({ screen: "segments" });
                    })
                }
            }
        })
    }




    //4.RENDER FUNCTION -----------------------------------------------------------------------------------------------------------------------------
    render() {
        if (this.state.showClientList) {
            if (this.state.showclientdetail) {
                //Render detalle de cliente
                return (
                    <ClientDetail
                        askFor={this.props.askFor}
                        clientId={this.state.clientId}
                        backFunction={this.closeClientDetail}
                        currencySymbol={this.props.currencySymbol}
                        lang={this.props.lang}
                        theme={this.props.bucket.theme}
                        hideRedeem={this.props.isSectionHidden('clientdetails_redeemed_points_permonth')}
                        isFieldHidden={this.props.isFieldHidden}
                    //scrollTop={this.props.scrollToTop}
                    />)
            }
            else {
                //Render lista de clientes
                return (
                    <ClientList
                        askFor={this.props.askFor}
                        selectedSegment={''}
                        settingsData={this.rfmMatrixData}
                        selectedMatrxCellId={this.state._selectedIdObject}
                        selectedDecile={""}
                        closeList={this.CloseClientList}
                        labels={this.clientListLabels}
                        openClientDetail={this.openClientDetail}
                        theme={this.props.bucket.theme}
                    />
                )
            }

        }
        else {
            //Render Matriz RFM
            return (
                <>
                    <div className="content settings">
                        <Container fluid>
                            <ReactTooltip id="settings" className="tooltip" />

                            <Row>{/* Open Config and Back Buttons */}
                                <Col lg="8" sm="8" />
                                <Col lg="4" sm="4" className="text-right buttonCol">
                                    <button className="btnConfig" onClick={() => { this.setState(state => { return { showConfiguration: !state.showConfiguration } }, () => { ReactTooltip.rebuild() }) }}>
                                        <i className="ti-settings"></i> {this.state.showConfiguration ? "Ocultar" : this.labels.lblConfig}
                                    </button>
                                    <button className="btnBack" onClick={() => { this.props.changeScreen({ screen: 'segments' }) }}>
                                        <i className="ti-close"></i> {this.labels.lblClose}
                                    </button>
                                </Col>
                            </Row>

                            {this.state.showConfiguration ?
                                <CSSTransition in={this.state.showConfiguration} appear={this.state.showConfiguration} classNames="animated" timeout={1000}>
                                    <div className="config">
                                        <hr />
                                        <Row className="configRow">
                                            <h4>Configuración RFM</h4>
                                            <button onClick={() => { this.setState(state => { return ({ showDecileGraphs: !state.showDecileGraphs }) }) }}><i className="ti-bar-chart" /> {this.state.showDecileGraphs ? "Ocultar Gráfica" : "Ver Gráfica"}</button>
                                            <button onClick={() => { this.setState(state => { return ({ showDeciles: !state.showDeciles }) }) }}><i className="ti-eye" /> {this.state.showDeciles ? "Ocultar deciles" : "Ver Deciles"}</button>
                                            {this.state.rfmHasChanges ?
                                                <>
                                                    <CSSTransition in={this.state.rfmHasChanges} appear={this.state.rfmHasChanges} classNames="animated" timeout={1000}>
                                                        <button onClick={() => { this.setState({ showConfigChangeConfirm: true }) }}><i className="ti-save" /> Aplicar cambios</button>
                                                    </CSSTransition>
                                                    <CSSTransition in={this.state.rfmHasChanges} appear={this.state.rfmHasChanges} classNames="animated" timeout={1000}>
                                                        <button onClick={() => { this.calcRangeInputDefaultValues() }}><i className="ti-close" /> Cancelar</button>
                                                    </CSSTransition>
                                                </>
                                                : ""}
                                        </Row>

                                        <Row className="controlsRow">
                                            <Col lg="3" className="r">
                                                <h6>{this.labels.lblRecencia} </h6>
                                                <p><i className="ti-help" data-tip={this.labels.lblRecenciaExplan} dat-for="settings" data-place="bottom" /></p>
                                                <InputRange
                                                    step={1}
                                                    classNames={this.classNames}
                                                    value={this.state.rfmConfig.r.value}
                                                    minValue={this.state.rfmConfig.r.min}
                                                    maxValue={this.state.rfmConfig.r.max}
                                                    onChange={value => { this.handleRecencia(value) }}
                                                    formatLabel={(value, type) => { return this.formatRangeInputValue(value, 'r') }}
                                                />
                                                {this.state.showDeciles ?
                                                    <CSSTransition in={this.state.showDeciles} appear={this.state.showDeciles} classNames="animated" timeout={1000}>
                                                        <Deciles type="r" values={this.state.rfmConfig.r.value} />
                                                    </CSSTransition>
                                                    : ""
                                                }

                                                {this.state.showDecileGraphs ?
                                                    <CSSTransition in={this.state.showDeciles} appear={this.state.showDeciles} classNames="animated" timeout={1000}>
                                                        <BarChart type="r" data={this.state.rfmvaluesarrays} value={this.state.rfmConfig.r.value} />
                                                    </CSSTransition>
                                                    : ""}
                                            </Col>
                                            <Col lg="3" className="f">
                                                <h6>{this.labels.lblFrecuencia}</h6>
                                                <p><i className="ti-help" data-tip={this.labels.lblFrecuenciaExplan} dat-for="settings" data-place="bottom" data-multiline={true} /></p>
                                                <InputRange
                                                    step={1}
                                                    classNames={this.classNames}
                                                    value={this.state.rfmConfig.f.value}
                                                    minValue={this.state.rfmConfig.f.min}
                                                    maxValue={this.state.rfmConfig.f.max}
                                                    onChange={value => { this.handleFrecuencia(value) }}
                                                    formatLabel={(value, type) => { return this.formatRangeInputValue(value, 'f') }}
                                                    allowSameValues={true}
                                                />
                                                {this.state.showDeciles ?
                                                    <CSSTransition in={this.state.showDeciles} appear={this.state.showDeciles} classNames="animated" timeout={1000}>
                                                        <Deciles type="f" values={this.state.rfmConfig.f.value} />
                                                    </CSSTransition>
                                                    : ""
                                                }

                                                {this.state.showDecileGraphs ?
                                                    <CSSTransition in={this.state.showDeciles} appear={this.state.showDeciles} classNames="animated" timeout={1000}>
                                                        <BarChart type="f" data={this.state.rfmvaluesarrays} value={this.state.rfmConfig.f.value} />
                                                    </CSSTransition>
                                                    : ""}
                                            </Col>
                                            <Col lg="3" className="m">
                                                <h6 >{this.labels.lblMonto}</h6>
                                                <p><i className="ti-help" data-tip={this.labels.lblMontoExplan} dat-for="settings" data-place="bottom" /></p>
                                                <InputRange
                                                    step={1}
                                                    classNames={this.classNames}
                                                    value={this.state.rfmConfig.m.value}
                                                    minValue={this.state.rfmConfig.m.min}
                                                    maxValue={this.state.rfmConfig.m.max}
                                                    onChange={(value) => { this.handleMonto(value) }}
                                                    formatLabel={(value, type) => { return this.formatRangeInputValue(value, 'm')  /* return this.props.currencySymbol + this.formatNumbers(value) */ }}
                                                    allowSameValues={true}
                                                />
                                                {this.state.showDeciles ?
                                                    <CSSTransition in={this.state.showDeciles} appear={this.state.showDeciles} classNames="animated" timeout={1000}>
                                                        <Deciles type="m" values={this.state.rfmConfig.m.value} />
                                                    </CSSTransition>
                                                    : ""
                                                }

                                                {this.state.showDecileGraphs ?
                                                    <CSSTransition in={this.state.showDeciles} appear={this.state.showDeciles} classNames="animated" timeout={1000}>
                                                        <BarChart type="m" data={this.state.rfmvaluesarrays} value={this.state.rfmConfig.m.value} />
                                                    </CSSTransition>
                                                    : ""}
                                            </Col>
                                        </Row>

                                        <hr />
                                    </div>
                                </CSSTransition>
                                : ""
                            }

                            <Row>{/* Data Visualization Selectors */}
                                <Col lg="12" md="12" sm="12" xs="12">
                                    <span className="viewSpan">Visualizar:</span>
                                    <button onClick={() => { this.changeDataVisualization('count') }} id="btnCount" className={classnames({ "btnView": true, active: this.state._defaultFirstMatrixValueField == "count" })} data-tip="Conteo de clientes" dat-for="settings" data-place="bottom"><i className="ti-user"></i></button>
                                    <button onClick={() => { this.changeDataVisualization('monto') }} id="btnAmount" className={classnames({ "btnView": true, active: this.state._defaultFirstMatrixValueField == "monto" })} data-tip="Monto de transacciones" dat-for="settings" data-place="bottom"><i className="ti-money"></i></button>
                                    <button onClick={() => { this.changeDataVisualization('perc') }} id="btnPerc" className={classnames({ "btnView": true, active: this.state._defaultFirstMatrixValueField == "perc" })} data-tip="Porcentaje de clientes" dat-for="settings" data-place="bottom">%</button>
                                </Col>
                            </Row>

                            <Row className={classnames({ 'effect-blur': this.state.showDetail, 'matrix-container': true })}>
                                <Col sm="12" lg="12">
                                    {/* <!-- Headers Top --> */}
                                    <Row>
                                        <Col lg="1" sm="1" />

                                        <Col lg="1" sm="1">
                                            <h6 className="r">{this.labels.lblRecencia}</h6>
                                        </Col>
                                        <Col lg="1" sm="1" className="text-center">
                                            <h6 className="f">{this.labels.lblFrecuencia}</h6>
                                        </Col>
                                        <Col lg="2" sm="2" className="text-center" />
                                        <Col lg="2" sm="2" className="text-center">
                                            <h6 className="m">{this.labels.lblMonto}</h6>
                                        </Col>
                                        <Col lg="2" sm="2" className="text-center" />
                                        <Col lg="2" sm="2" className="text-center" />
                                    </Row>

                                    {/* <!-- Headers Sub --> */}
                                    <Row>
                                        <Col lg="1" sm="1" />
                                        <Col lg="1" sm="1" />
                                        <Col lg="1" sm="1" />
                                        <Col lg="2" sm="2" className="text-center">
                                            <h6 className="mh">{this.labels.lblF_High}</h6>
                                        </Col>
                                        <Col lg="2" sm="2" className="text-center">
                                            <h6 className="mm">{this.labels.lblF_Medium}</h6>
                                        </Col>
                                        <Col lg="2" sm="2" className="text-center">
                                            <h6 className="ml">{this.labels.lblF_Low}</h6>
                                        </Col>
                                        <Col lg="2" sm="2" className="text-center">
                                            <h6>{this.labels.lblTotals}</h6>
                                        </Col>

                                        <Col lg="1" sm="1" />

                                    </Row>

                                    {/* <!-- Rows --> */}
                                    {/* For loop de objeto Rows apartir de  datos de matrix */}
                                    {this.state.rows.map((row, idx) => {
                                        return (
                                            <Row key={idx}>
                                                <Col lg="1" sm="1" />

                                                {/* <!-- Titles --> */}
                                                {idx == 1 ? <Col lg="1" sm="1"><h6 className="rr">{this.labels.lblR_recent}</h6></Col> : ""}
                                                {idx == 4 ? <Col lg="1" sm="1"><h6 className="rp">{this.labels.lblR_prior}</h6></Col> : ""}
                                                {(idx != 1 && idx != 4) ? <Col lg="1" sm="1"></Col> : ""}



                                                {/* <!-- Titles 2nd Column --> */}
                                                {idx == 0 ? <Col className="sndCol" lg="1" sm="1"><h6 className="fh">{this.labels.lblM_High}</h6></Col> : ""}
                                                {idx == 1 ? <Col className="sndCol" lg="1" sm="1"><h6 className="fm">{this.labels.lblM_Medium}</h6></Col> : ""}
                                                {idx == 2 ? <Col className="sndCol" lg="1" sm="1"><h6 className="fl">{this.labels.lblM_Low}</h6></Col> : ""}
                                                {idx == 3 ? <Col className="sndCol" lg="1" sm="1"><h6 className="fh">{this.labels.lblF_High}</h6></Col> : ""}
                                                {idx == 4 ? <Col className="sndCol" lg="1" sm="1"><h6 className="fm">{this.labels.lblF_Medium}</h6></Col> : ""}
                                                {idx == 5 ? <Col className="sndCol" lg="1" sm="1"><h6 className="fl">{this.labels.lblF_Low}</h6></Col> : ""}

                                                {/* <!-- Prevously Draggable Cells (Non-Dragable)--> */}
                                                <Col lg="2" sm="2">
                                                    <div className="card matrixcard text-right" onClick={() => { this.CellClick(this.BuildCellIdForDOM(idx, 1)) }} id={this.BuildCellIdForDOM(idx, 1)} >
                                                        <div className="content">
                                                            <div className="numbers">
                                                                <b id={this.BuildCellIdForDOM(idx, 1) + "-p"}>0</b>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Col>
                                                <Col lg="2" sm="2">
                                                    <div className="card matrixcard text-right" onClick={() => { this.CellClick(this.BuildCellIdForDOM(idx, 2)) }} id={this.BuildCellIdForDOM(idx, 2)}>
                                                        <div className="content">
                                                            <div className="numbers">
                                                                <b id={this.BuildCellIdForDOM(idx, 2) + "-p"}>0</b>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Col>
                                                <Col lg="2" sm="2">
                                                    <div className="card matrixcard text-right" onClick={() => { this.CellClick(this.BuildCellIdForDOM(idx, 3)) }} id={this.BuildCellIdForDOM(idx, 3)}>
                                                        <div className="content">
                                                            <div className="numbers">
                                                                <b id={this.BuildCellIdForDOM(idx, 3) + "-p"}>0</b>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Col>

                                                {/* <!-- Total --> */}
                                                <Col lg="2" sm="2" className="sndCol totalCol">
                                                    <h6 id={'total-row-' + idx} className="text-center">0</h6>
                                                </Col>


                                            </Row>
                                        )
                                    })}

                                </Col>
                            </Row>

                            <hr />

                            <Row>{/* Segment color Description */}
                                <Col className="segmentsColorDescription">
                                    <label>{this.labels.lblSegments}:</label>
                                    <span style={{ backgroundColor: this.colors._aRetenerColor }}>{this.labels.lblRetain}</span>
                                    <span style={{ backgroundColor: this.colors._incrementarMColor }}>{this.labels.lblIncrementM}</span>
                                    <span style={{ backgroundColor: this.colors._incrementarFColor }}>{this.labels.lblIncrementF}</span>
                                    <span style={{ backgroundColor: this.colors._ocasionalesColor }}>{this.labels.lblOcasionals}</span>
                                    <span style={{ backgroundColor: this.colors._traerDeRegresoColor }}>{this.labels.lblBringBack}</span>
                                </Col>
                            </Row>

                            <hr />
                        </Container>


                        {/* Floating Box and Export Component */}
                        {this.state.showDetail ?
                            <FloatingCard
                                totalCount={this.state._totalCountDetail}
                                percCountFromTotal={this.state._percCountFromTotal}
                                percMontoFromTotal={this.state._percMontoFromTotal}
                                avgTicket={this.state._avgTicket}
                                currencySymbol={this.props.currencySymbol}
                                closeCellDetail={this.CloseCellDetail}
                                openClientList={this.openClientList}
                                openExport={this.openExport}
                            />
                            : ""}

                        {this.state.showExport ?
                            <CSSTransition classNames="animated" timeout={500} in={true}>
                                <ExportCreator
                                    askFor={this.props.askFor}
                                    lang={this.props.lang}
                                    count={this.state._totalCountDetail}
                                    cellId={this.state._selectedIdObject}
                                    closeExport={this.closeExport}
                                />
                            </CSSTransition>
                            : ""}

                        {/* Config Change Confirmation Modal */}
                        <Modal
                            className="modalConfigRfm"
                            centered={true}
                            isOpen={this.state.showConfigChangeConfirm}
                            toggle={this.toggleModal}
                        >
                            < ModalBody >
                                <p>¿Seguro que desea actualizar la configuración?</p>

                                {this.state.showLoading ?
                                    <Row className="loadingRow">
                                        {this.props.loadingSpinner}
                                        <p>Guardando configuración</p>
                                    </Row>
                                    : <div className="modalContent">
                                        <Button onClick={() => { this.sendNewConfig() }}>Confirmar</Button>
                                        <Button onClick={() => { this.toggleModal() }}>Cancelar</Button>
                                    </div>}

                            </ModalBody>
                        </Modal>
                    </div>
                </>
            )
        }

    }
}

export default Settings;