import React from 'react';
import logo from '../../assets/img/corttLogoO.png';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { FetchLogin } from '../../components/FetchCmp/fetchCmp';
import { Container, Row, Col, Button } from 'reactstrap';
import { LoginLang } from '../../lang';




function Login(_props) {

  return (
    <Container fluid style={{ padding: "0px" }}>
      <header className="App-header">
        <img src={logo} className="logoCortteza" alt="logo" />
        <FetchLogin render={props => <FormikForm {...props} login={_props.login} />} />
      </header>
    </Container>
  );
}


//Resolucion del 2FA, el servicio devuleve el url de ingreso a cortteza que se lee desde el servidor Front end de la herramienta
//Verifica que la autenticacion sea correcta, de fallar la auth se le da al usuario
//2 oportunidades más de enviar las credenciasles correctas, de fallar los 3 intentos se le devulve la pantalla para solicitar el codigo


//Forms with Formik !!!!--------------------------------------------------------------------------------
class FormikForm extends React.Component {
  constructor(props) {
    super(props)
    this.state = { cs: false, authT: 0, srb: false }
    this.didMount = false;
    document.body.classList.remove("white-content");
    this.emailOnlyschema = Yup.object().shape({
      email: Yup.string().email('**Invalid Email').required('** This field is required'),
    });
    this.fullSchema = Yup.object().shape({
      email: Yup.string().email('**Invalid Email').required('** This field is required'),
      code: Yup.string().required('** The authentication code is required')
    })
  }

  componentDidMount() {
    this.didMount = true;
  }

  componentWillUnmount() {
    this.didMount = false;
  }

  render() {
    return (
      <Container fluid>
        <Formik initialValues={{ email: '', code: '' }} validationSchema={this.state.cs ? this.state.srb ? this.emailOnlyschema : this.fullSchema : this.emailOnlyschema} onSubmit={(values, { initialValues, setSubmitting, resetForm }) => {
          if (this.state.cs) {
            if (this.didMount) {
              this.props.auth(values.email, values.code)
                .then(async res => {
                  if (res.data.auth) {
                    document.body.classList.add("white-content");
                    // var isAdmin = await this.props.validateAdminToken(res.data.tokenReact);
                    this.props.login(res.data.tokenReact);
                  }
                  else {
                    if (this.state.authT < 2) {
                      if (this.didMount) { this.setState((state) => ({ authT: state.authT + 1, cs: true })) }
                      setSubmitting(false);
                      alert("Email or code are incorrect, please try again");
                    }
                    else {
                      alert("You have tried too many times, please ask for a new code");
                      resetForm(initialValues);
                      if (this.didMount) { this.setState(() => ({ authT: 0, cs: false })) }
                      setSubmitting(false);
                    }
                  }

                }).catch(err => { console.log(err) })
            }
          }
          else {
            if (this.didMount) {
              //Solicitud del 2FA code, de tener una sesion abierta se redirecciona
              this.props.requestCode(values.email)
                .then(res => {
                  if (res.data.auth) {
                    document.body.classList.add("white-content");
                    this.props.login(res.data.tokenReact);
                  } else {
                    if (res.data.codeSended) {
                      //console.log(res.data.tfaCode);
                      if (this.didMount) { this.setState(() => ({ cs: true, srb: false })) }//Sino habilita la pantalla de 2FA
                      setSubmitting(false)
                    }
                    else {
                      alert(res.msg);
                    }

                  }
                }).catch(err => {
                  alert(err);
                });
            }
          }
        }}>

          {({ values, isSubmitting, setSubmitting }) => (
            <Form>
              <Row className='addMarginB'>
                <Col>
                  <label className='col-8 loginLabel'>Correo Electrónico</label>
                  <Field name='email' type='email' placeholder='Email' className='cInput col-8' />
                  <ErrorMessage name="email" component="div" className="inputError col-8" />
                </Col>
              </Row>
              {this.state.cs ?
                <Row>
                  <Col>
                  <label className='col-8 loginLabel'>Código de verificación</label>
                    <Field name='code' type='text' placeholder='Code' className='cInput col-8'></Field>
                    <ErrorMessage name="code" component="div" className="inputError col-8" />
                  </Col>
                </Row> : ''}
              <Button type="submit" disabled={isSubmitting} className="btnIngresar">
                Ingresar
              </Button>
            </Form>
          )}
        </Formik>
        <footer className="loginFooter">
          <p>¿No recibiste código de verificación? <a onClick={() => { this.setState(() => ({ cs: false, srb: false })) }} href="#">Reenviar</a> o <a href="#">Enviar SMS</a></p>
          <p>Cortteza 2020. Todos los derechos reservados. Powered by <a href="http://loymark.com/">Loymark</a></p>
        </footer>
      </Container>
    )
  }



}




export default Login;
