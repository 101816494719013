import React from "react";
import GlobalCounts from "./globalCounts";
import GlobalPoints from "./globalPoints";
import CountsbyGender from './countsByGender';
import AgeBrackets from './ageBrackets';
import ProdByGender from './prodByGender';
import ProcessingPeriods from './processingPeriod';
import FetchCmp from '../../components/FetchCmp/fetchCmp';

// reactstrap components
import { Row, Container, Col } from "reactstrap";

class WrapedDashBoard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    var genderSummaryWidth = this.props.bucket.genderSeparation ? "5" : "12";
    return (
      <>
        <div className="content dashboard">
          <Container fluid>
            {/* Periodos de Proceso */}
            <Row className="mainRow">
              <Col><h1>Dashboard</h1></Col>
              <Col><ProcessingPeriods askFor={this.props.askFor} bucket={this.props.bucket} lang={this.props.lang} getBucketLabels={this.props.getBucketLabels}/></Col>
            </Row>

            {/* Resumen Global | Gráfica de Millas | Tabla de Edad */}
            <Row className="mainRow">
              {/* Resumen Global */}
              <Col lg="4" md="12" sm="12" xs="12" className="noPadding">
                <GlobalCounts
                  askFor={this.props.askFor}
                  bucket={this.props.bucket}
                  isSectionHidden={this.props.isSectionHidden}
                  lang={this.props.lang}
                  currencySymbol={this.props.bucket.currencySymbol}
                  useNoPeriods={this.props.bucket.additionalConfig.UseAvgPurchasePerUser_WithNoPeriod}
                  loadingSpinner={this.props.loadingSpinner}
                  getBucketLabels={this.props.getBucketLabels}
                />
              </Col>
              {/* Graficas y tabla de edad */}
              <Col lg="8" md="12" sm="12" xs="12" className="noPadding">
                <Row>
                  <GlobalPoints
                    askFor={this.props.askFor}
                    lang={this.props.lang}
                    loadingSpinner={this.props.loadingSpinner}
                    theme={this.props.bucket.theme}
                    hideRedeem={this.props.isSectionHidden('dashboard_redeemed_points')}
                    getBucketLabels={this.props.getBucketLabels}
                  />
                </Row>

                <hr />

                <Row>
                  {this.props.bucket.hiddenSections.indexOf("ageBracketTable") < 0 ?
                    <AgeBrackets bucket={this.props.bucket} lang={this.props.lang} getBucketLabels={this.props.getBucketLabels}/>
                  : /*No se pinta nada*/""}
                </Row>
              </Col>
            </Row>

            {/*  Resumen Por Genero | Productos Favoritos */}
            <Row className="mainRow">
              
              {this.props.bucket.hiddenSections.indexOf("countsByGender") < 0 ?
                <Col lg={genderSummaryWidth} sm="12" xs="12">
                  <CountsbyGender
                    askFor={this.props.askFor}
                    lang={this.props.lang}
                    currencySymbol={this.props.bucket.currencySymbol}
                    genderSeparation={this.props.bucket.genderSeparation}
                    theme={this.props.bucket.theme}
                    loadingSpinner={this.props.loadingSpinner}
                    getBucketLabels={this.props.getLabels}
                  />
                </Col>
              : /*No se pinta nada*/""}

              {this.props.bucket.genderSeparation ?
                <Col lg="7" md="12" sm="12" xs="12">
                  <ProdByGender
                    askFor={this.props.askFor}
                    isFieldHidden={this.props.isFieldHidden}
                    lang={this.props.lang}
                    genderSeparation={this.props.bucket.genderSeparation}
                    theme={this.props.bucket.theme}
                    loadingSpinner={this.props.loadingSpinner}
                    getBucketLabels={this.props.getBucketLabels}
                    showSku={this.props.bucket.additionalConfig.ShowProductSku}
                  />
                </Col>
                :
                ""}

            </Row>

          </Container>
        </div>
      </>
    )
  }
}

var Dashboard = props => {
  return (
    <FetchCmp
      render={props => <WrapedDashBoard
        bucket={props.bucket}
        askFor={props.askFor}
        isSectionHidden={props.isSectionHidden}
        isFieldHidden={props.isFieldHidden}
        currencySymbol={props.bucket.currencySymbol}
        lang={props.lang}
        loadingSpinner={props.loadingSpinner}
        size={props.size}
        getBucketLabels={props.getLabels}
      />
      }
    />
  )
}

export default Dashboard;
