import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import FetchCmp from '../../components/FetchCmp/fetchCmp';
import { Container, Row } from 'reactstrap';
import { Submenu } from './SubMenu';
import { AdminCampaign } from './SubCampaign';
import { AdminGeneral } from './SubGeneral';
import { AdminLabels } from './SubLabels';
import { AdminPermissions } from './SubPermisos';

var WrapedAdmin = props => {

    // 1. Var initialization
    const [perm, setPermissions] = useState(props.adminData.isAdmin.perm);
    //------------------------------------------------------------------------------
    const [screen, setScreen] = useState('general')
    // general || campaigns || labels || permissions || theme || 
    //------------------------------------------------------------------------------
    var searchInPerm = (search) => {
        var exists = false;
        perm.forEach(p => {
            if (p.id === 'owner') {
                exists = true;
            }
            else {
                if (p.id === search) {
                    p.buckets.forEach(b => {
                        if (b === props.bucket.bucketId) {
                            exists = true;
                        }
                    })

                }
            }

        });
        return exists
    }
    const owner_permission = searchInPerm("owner");
    const bucketAdmin_permission = searchInPerm('bucket_admin');
    const campaignAdmin_permission = searchInPerm('campaign_admin');
    //

    // 2. API CALLS
    useEffect(() => {
        // console.log(perm)
    }, [])

    var getScreen = () => {
        var s = <></>;
        switch (screen) {
            case "campaigns":
                if (campaignAdmin_permission) {
                    s = <AdminCampaign
                        campaignConfig={props.bucket.campaignsConfiguration}
                        askFor={props.askFor}
                        loadingSpinner={props.loadingSpinner}
                        changeBucketCampConf={props.changeBucketCampConf}
                        toggleCampaignRute={props.toggleCampaignRute}
                    />
                }
                ; break;
            case "general":
                if (bucketAdmin_permission) {
                    s = <AdminGeneral
                        askFor={props.askFor}
                        bucket={props.bucket}
                        loadingSpinner={props.loadingSpinner}
                        changeBucketGeneralConfigurations={props.changeBucketGeneralConfigurations}
                    />
                }
                ; break;
            case "labels":
                if (bucketAdmin_permission) {
                    s = <AdminLabels />
                }
                ; break;
            case "permissions":
                if (owner_permission) {
                    s = <AdminPermissions
                        askFor={props.askFor}
                        bucket={props.bucket}
                        loadingSpinner={props.loadingSpinner}
                    />
                }
                ; break;
            default: ; break;
        }

        return s;
    }

    return (
        <div className="content">
            <Container fluid className="adminContainer">
                <Row className="titleRow"><h1>Administrador</h1></Row>
                {/* <h3>Este es el administrador de buckets</h3> */}
                <Row className="contentRow">
                    <Submenu permissions={perm} bucketId={props.bucket.bucketId} active={screen} changeScreen={setScreen} />
                    {getScreen()}
                </Row>

            </Container>
        </div>

    )
}

WrapedAdmin.propTypes = {
    adminData: PropTypes.shape({
        email: PropTypes.string,
        isAdmin: PropTypes.shape({
            status: PropTypes.bool,
            perm: PropTypes.array
        }),
        status: PropTypes.string
    }),
}

var Admin = props => {
    return (
        <FetchCmp
            render={props => <WrapedAdmin
                bucket={props.bucket}
                askFor={props.askFor}
                isSectionHidden={props.isSectionHidden}
                isFieldHidden={props.isFieldHidden}
                currencySymbol={props.bucket.currencySymbol}
                lang={props.lang}
                loadingSpinner={props.loadingSpinner}
                adminData={props.adminData}
                changeBucketCampConf={props.changeBucketCampConf}
                changeBucketGeneralConfigurations={props.changeBucketGeneralConfigurations}
                toggleCampaignRute={props.toggleCampaignRute}
            />
            }
        />
    )
}

export default Admin;