import React from 'react';
import logo from '../../assets/img/corttLogoO.png';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { FetchLogin } from '../../components/FetchCmp/fetchCmp';
import { Container, Row, Col, Button } from 'reactstrap';
import { LoginLang } from '../../lang';




function UserLogin(_props) {

  return (
    <Container fluid style={{ padding: "0px" }}>
      <header className="App-header">
        <img src={logo} className="logoCortteza" alt="logo" />
        <FetchLogin render={props => <FormikForm {...props} login={_props.login} />} />
      </header>
    </Container>
  );
}


//Resolucion del 2FA, el servicio devuleve el url de ingreso a cortteza que se lee desde el servidor Front end de la herramienta
//Verifica que la autenticacion sea correcta, de fallar la auth se le da al usuario
//2 oportunidades más de enviar las credenciasles correctas, de fallar los 3 intentos se le devulve la pantalla para solicitar el codigo


//Forms with Formik !!!!--------------------------------------------------------------------------------
class FormikForm extends React.Component {
  constructor(props) {
    super(props)
    this.state = { cs: false, authT: 0, srb: false }
    this.didMount = false;
    document.body.classList.remove("white-content");
    this.emailOnlyschema = Yup.object().shape({
      user: Yup.string().required('** This field is required'),
    });
  }

  componentDidMount() {
    this.didMount = true;
  }

  componentWillUnmount() {
    this.didMount = false;
  }

  render() {
    return (
      <Container fluid>
        <Formik initialValues={{ user: '' }} validationSchema={this.emailOnlyschema} onSubmit={async (values, { initialValues, setSubmitting, resetForm }) => {
          if (this.didMount) {
            console.log('Enters')
            var loginResponse = await this.props.loginByUser(values.user);
            if(loginResponse !== null && loginResponse !== undefined){
              this.props.login(loginResponse)
            }
            else{
              console.log('Error')
            }
          }
        }}>

          {({ values, isSubmitting, setSubmitting }) => (
            <Form>
              <Row className='addMarginB'>
                <Col>
                  <label className='col-8 loginLabel'>USER ID</label>
                  <Field name='user' placeholder='Usuario' className='cInput col-8' />
                  <ErrorMessage name="user" component="div" className="inputError col-8" />
                </Col>
              </Row>
              <Button type="submit" disabled={isSubmitting} className="btnIngresar">
                Ingresar
              </Button>
            </Form>
          )}
          
        </Formik>
      </Container>
    )
  }



}




export default UserLogin;
