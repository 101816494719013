import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Container, Row, Col, Button, Table, Modal, ModalHeader, ModalBody } from 'reactstrap';
import ReactTooltip from "react-tooltip";
import classNames from "classnames";
import { CSSTransition } from "react-transition-group";
import classnames from "classnames";
// import { lib } from 'crypto-js';

var AdminEditor = props => {

    // 1. VARIABLES -----------------------------------------------------------------
    const [editAdmin, setEditAdmin] = useState(props.editAdmin);
    const [addBucketRowId, setAddBucketRowId] = useState('')
    //------------------------------------------------------------------------------
    const [newBucketId, setNewBucketId] = useState('');

    // 2. FUCNTIONS -----------------------------------------------------------------
    var isAdminHasPermission = (perm) => {
        let bool = false;

        editAdmin.permissions.forEach(p => {
            if (p.id === perm) {
                bool = true;
            }
        })
        return bool;
    }

    var getBucketsOfPermissionById = (id) => {
        let b = [];
        editAdmin.permissions.forEach(p => {
            if (p.id === id) {
                b = p.buckets;
            }
        })

        return b;
    }

    //-------------------------------------------------------------------------------

    var changeAdminId = id => {
        setEditAdmin(ea => {
            ea.id = id;
            return { ...ea }
        })
    }

    var addPermissionToAdmin = (id) => {
        if (isAdminHasPermission(id)) {
            setEditAdmin(ea => {
                ea.permissions = ea.permissions.filter(p => p.id !== id);
                return { ...ea }
            })

        } else {
            setEditAdmin(ea => {
                ea.permissions.push({ id: id, buckets: [] });
                return { ...ea }
            })
        }

    }

    //-------------------------------------------------------------------------------
    var addBucketToPermission = (bucket, id) => {
        setEditAdmin(ea => {
            ea.permissions.forEach(p => {
                if (p.id === id) {
                    p.buckets.push(bucket)
                }
            })
            return { ...ea }
        })
        setAddBucketRowId('')
        setNewBucketId('')
    }

    var removeBucketToPermission = (bucket, id) => {
        setEditAdmin(ea => {
            ea.permissions.forEach(p => {
                if (p.id === id) {
                    p.buckets = p.buckets.filter(b => b !== bucket)
                }
            })
            return { ...ea }
        })
    }

    // X PRE-RENDER -----------------------------------------------------------------

    var getButton = () => {
        let b = <></>;
        switch (props.type) {
            case 'update': b = <Button onClick={() => { props.setEditAdmin(editAdmin); props.setModalScreen('updateConfirm') }}>Aceptar</Button>; break;
            case 'create': b = <Button onClick={() => { props.setEditAdmin(editAdmin); props.setModalScreen('createConfirm') }}>Aceptar</Button>; break;
            default: ; break;
        }

        return b
    }

    // Z. Render
    return (
        <Container fluid className="content">
            <Row>
                <Col className="emailRow">
                    <label>Email: </label>
                    <input type="text" value={editAdmin.id} onChange={(e) => { changeAdminId(e.target.value) }} />
                </Col>
            </Row>
            <Row>
                <Container fluid>
                    <p>Permisos: </p>
                    <Row>
                        <Col lg="4" md="4" sm="12">
                            <div className="permissionCard">
                                <input type="checkbox" checked={isAdminHasPermission('owner')} onChange={() => { addPermissionToAdmin('owner') }} /> <label>Owner</label>
                            </div>
                        </Col>

                        <Col lg="4" md="4" sm="12" >
                            <div className="permissionCard">
                                <input type="checkbox" checked={isAdminHasPermission('bucket_admin')} onChange={() => { addPermissionToAdmin('bucket_admin') }} /> <label>Administrador de Bucket</label>
                                <CSSTransition mountOnEnter unmountOnExit classNames="ani" timeout={350} in={isAdminHasPermission('bucket_admin')} appear={isAdminHasPermission('bucket_admin')} >
                                    <div className="bucketCard">
                                        <p><b>Buckets</b></p>
                                        <ul>
                                            {getBucketsOfPermissionById('bucket_admin').map((b, key) => {
                                                return (<li key={key} className="item">{b} <span className="ti-close" onClick={() => { removeBucketToPermission(b, "bucket_admin") }} /></li>)
                                            })}
                                            {addBucketRowId === 'bucket_admin' ?
                                                <li><input type="text" value={newBucketId} onChange={e => { setNewBucketId(e.target.value) }} /></li>
                                                : ""}
                                            {newBucketId !== "" ?
                                                <li><button onClick={() => { addBucketToPermission(newBucketId, "bucket_admin") }}>Aceptar</button></li>
                                                :
                                                <li><button onClick={() => { setAddBucketRowId("bucket_admin") }}><span className="ti-plus" /> Agregar</button></li>
                                            }
                                        </ul>
                                    </div>
                                </CSSTransition>
                            </div>
                        </Col>

                        <Col lg="4" md="4" sm="12">
                            <div className="permissionCard">
                                <input type="checkbox" checked={isAdminHasPermission('campaign_admin')} onChange={() => { addPermissionToAdmin('campaign_admin') }} /> <label>Administrador de Campañas</label>
                                <CSSTransition mountOnEnter unmountOnExit classNames="ani" timeout={350} in={isAdminHasPermission('campaign_admin')} appear={isAdminHasPermission('campaign_admin')} >
                                    <div className="bucketCard">
                                        <p><b>Buckets</b></p>
                                        <ul>
                                            {getBucketsOfPermissionById('campaign_admin').map((b, key) => {
                                                return (<li key={key} className="item">{b} <span className="ti-close" onClick={() => { removeBucketToPermission(b, "bucket_admin") }} /></li>)
                                            })}
                                            {addBucketRowId === 'campaign_admin' ?
                                                <li><input type="text" value={newBucketId} onChange={e => { setNewBucketId(e.target.value) }} /></li>
                                                : ""}
                                            {newBucketId !== "" ?
                                                <li><button onClick={() => { addBucketToPermission(newBucketId, "campaign_admin") }}>Aceptar</button></li>
                                                :
                                                <li><button onClick={() => { setAddBucketRowId("campaign_admin") }}><span className="ti-plus" /> Agregar</button></li>
                                            }
                                        </ul>
                                    </div>
                                </CSSTransition>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </Row>
            <Row className="buttonRow">
                {getButton()}
            </Row>
        </Container>
    )
}


var AdminPermissions = props => {

    // 1. VAR INITIALIZATION ----------------------------------------------------------------------
    const [isLoading, setIsLoading] = useState(false);
    const [adminList, setAdminList] = useState(null);
    const [editId, setEditId] = useState("");
    const [deleteId, setDeleteId] = useState("");
    const [createNewUser, setCreateNewUser] = useState(false);
    // const [addNewPerm, setAddNewPerm] = useState(false);
    const [modalOpen, setModalOpen] = useState(false);
    const [modalScreen, setModalScreen] = useState('create') // create || update || delete
    const [newAdmin, setNewAdmin] = useState({
        id: "",
        permissions: []
    });
    const [editAdmin, setEditAdmin] = useState({
        id: "",
        permissions: []
    });


    // 2. EFFECTS ---------------------------------------------------------------------------------
    useEffect(() => {
        var getAdminList = async () => {
            let cacheId = "GetAdminList-" + Math.round(Math.random() * 10000);
            let adminList = await props.askFor('security', 'admin/get', [], false, {}, cacheId);
            if (adminList !== null) {
                setAdminList(adminList);
            }
        }

        if (adminList === null) {
            getAdminList();
        }
        return () => { }
    })

    // 3. ADMIN LIST EDIT FUCNTIONS ---------------------------------------------------------------

    var editAdminid = (id) => {
        setEditAdmin(ea => {
            ea.id = id;
            return { ...ea }
        })
    }

    var editAdminPermissions = (action, value) => {
        setEditAdmin(ea => {
            switch (action) {
                case 'add': ea.permissions.push({ id: value, buckets: [] }); break;
                case 'remove': ea.permissions = ea.permissions.filter(p => p.id !== value); break;
                case 'addBuckets': ea.permissions.map(p => { p['buckets'].push(value) })
                default: ; break
            }
        })
    }

    // 3. CREATE NEW ADMIN FUNCTIONS --------------------------------------------------------------

    var setAdminEmail = (email) => {
        setNewAdmin(na => {
            na.id = email;
            return { ...na }
        })
    }

    var setAdminPermissions = (perm) => {
        setNewAdmin(na => {
            na.permissions.id = perm;
            return { ...na }
        })
    }

    // 4. UTILITY FUNCTIONS -------------------------------------------------------------------

    var isAdminHasPermission = (id, perm) => {
        let bool = false;
        adminList.forEach(adm => {
            if (adm.id === id) {
                adm.permissions.forEach(p => {
                    if (p.id === perm) {
                        bool = true;
                    }
                })
            }
        })

        console.log(bool)
        return bool;
    }

    var getBucketsOfPermissionById = (adminPerm, id) => {
        let b = [];
        adminPerm.forEach(p => {
            if (p.id === id) {
                b = p.buckets;
            }
        })

        return b;
    }

    var toggleModal = () => {
        setModalOpen(!modalOpen);
    }

    // 5. API CALLS -------------------------------------------------------------------------------

    var createUser = async () => {
        setIsLoading(true);
        let cacheid = "CreateNewAdmin-" + props.bucket.bucketId + "-" + Math.round(Math.random() * 1000);
        var response = await props.askFor('security', 'admin/create', [], false, { adminObject: newAdmin }, cacheid)
        if (response !== null) {
            setIsLoading(false);
            toggleModal();
            setAdminList(null);
            setCreateNewUser(false);
            setNewAdmin({
                id: "",
                permissions: []
            })
        }
        else {
            alert('Error al crear el nuevo administrador')
        }
    }

    var deleteUser = async (id) => {
        setIsLoading(true);
        let cacheid = "DeleteNewAdmin-" + props.bucket.bucketId + "-" + Math.round(Math.random() * 1000);
        var response = await props.askFor('security', 'admin/delete', [], false, { adminId: id }, cacheid)
        if (response !== null) {
            setIsLoading(false);
            toggleModal();
            setAdminList(null);
        }
        else {
            alert('Error al eliminar el administrador: ' + id)
        }
    }

    var updateUser = async () => {
        setIsLoading(true);
        let cacheid = "UpdateAdmin-" + props.bucket.bucketId + "-" + Math.round(Math.random() * 1000);
        var response = await props.askFor('security', 'admin/update', [], false, { updateId: editId, adminObject: editAdmin }, cacheid)
        if (response !== null) {
            toggleModal();
            setIsLoading(false);
            setAdminList(null);
            setEditId('');
            setEditAdmin('');
        }
        else {
            alert('Error al actualizar el nuevo administrador')
        }
    }

    // Y.PRE-RENDER FUCNTIONS ---------------------------------------------------------------------

    var getModalContent = () => {
        var modal = <></>;
        switch (modalScreen) {
            case 'create':
                modal = <AdminEditor editAdmin={newAdmin} setModalScreen={setModalScreen} setEditAdmin={setNewAdmin} type="create" />
                    ; break;
            case 'createConfirm':
                modal = <div>
                    <p>¿Seguro desea crear el administrador usuario?</p>
                    <Button onClick={() => { createUser() }}>Aceptar</Button>
                </div>
                    ; break;
            case 'update':
                modal = <AdminEditor editAdmin={editAdmin} setModalScreen={setModalScreen} setEditAdmin={setEditAdmin} type="update" />
                    ; break;
            case 'updateConfirm':
                modal = <div>
                    <p>¿Seguro desea actualizar el administrador?</p>
                    <Button onClick={() => { updateUser() }}>Aceptar</Button>
                </div>
                    ; break;
            case 'delete':
                modal = <div>
                    <p>¿Seguro desea eliminar el administrador?</p>
                    <Button onClick={() => { deleteUser(deleteId) }}>Aceptar</Button>
                </div>
                break;
            default: ;
        }

        return modal;
    }

    // Z. RENDER ----------------------------------------------------------------------------------
    return (
        <div className="adminScreenContent">
            <Container fluid className="permisos">
                <Row className="titleRow"><h3>Permisos</h3></Row>
                <Row className="subtitle">
                    <div className="sub">
                        {adminList !== null ?
                            <>
                                <b>Lista de administradores</b>
                                <Button onClick={() => { setModalScreen('create'); toggleModal() }}>Nuevo administrador</Button>
                            </>

                            : ""}

                    </div>
                </Row>


                <Row className="contentRow">
                    {adminList !== null ?
                        <div className="formGroup">
                            <Table bordered responsive striped dark>
                                <thead>
                                    <tr>
                                        <th>Email</th>
                                        <th>Permisos</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {adminList.map((admin, key) => {
                                        return (
                                            <tr key={key}>
                                                <td>{admin.id}</td>
                                                <td>
                                                    {
                                                        admin.permissions.length !== undefined ?
                                                            <ul>
                                                                {admin.permissions.map((p, k) => {
                                                                    return (<li key={k}>{p.id}</li>)
                                                                })}
                                                            </ul>
                                                            :
                                                            admin.permissions.id
                                                    }
                                                </td>
                                                <td>
                                                    <Button onClick={() => { setEditId(admin.id); setEditAdmin(JSON.parse(JSON.stringify(admin))); setModalScreen('update'); toggleModal() }}><span className="ti-pencil" /> Editar</Button>
                                                    <Button className="cancelar" onClick={() => { setDeleteId(admin.id); setModalScreen('delete'); toggleModal() }}><span className="ti-minus" /> Eliminar</Button>
                                                </td>
                                            </tr>
                                        )
                                        // }
                                    })}
                                </tbody>
                            </Table>
                        </div>
                        :
                        props.loadingSpinner
                    }

                </Row>

                <Modal isOpen={modalOpen} toggle={() => { setModalOpen(!modalOpen) }} centered={modalScreen !== 'update' || modalScreen === "create"} className={classnames({ permisosConfirmationModal: modalScreen !== 'update' && modalScreen !== "craete", editAdminModal: modalScreen === "update" || modalScreen === "create" })}>
                    {modalScreen === 'update' ?
                        <ModalHeader toggle={() => { setModalOpen(!modalOpen) }}>Editor de administrador</ModalHeader>
                        : ""}
                        {modalScreen === 'create' ?
                        <ModalHeader toggle={() => { setModalOpen(!modalOpen) }}>Creador de administradores</ModalHeader>
                        : ""}
                    <ModalBody>
                        {isLoading ?
                            props.loadingSpinner
                            :
                            getModalContent()}
                    </ModalBody>
                </Modal>
            </Container>
        </div>
    )

}

export { AdminPermissions };