import React from "react";
import Environment from '../../env'
import BucketContext from '../../components/FetchCmp/BucketContext';
import Axios from "axios";
import Cache from "../../cache";

const _envieronment = new Environment('prod');

function mergeLabels(obj, obj2) {
  var merged = Object.assign({}, obj, obj2);

  return merged;
}

//Componente de abstraccion de datos del BackEnd. 
//Estos componentes se encargar de darle heredar la capacidad de hacer peticiones sus componentes hijos mediante un render prop.


//1. Componente principal de abstraccion de datos. En el se encapsulan las funcionalidades de hacer peticiones hacia los distintos servicios del back-end
//    ->Tiene funciones para verificar las secciones y campos ocultos según el bucket que se este mostrando.
//    ->Tiene acceso a la clase de variables de ambiente para acceder a las distintas urls necesarias para realizar peticiones.
//    ->Dentro se configura la variable de ambiente para trabajar en desarrollo(dev) con servicios locales, o en producción(prod) con servicios montados en el servidor web.
class FetchWrapedCmp extends React.Component {
  constructor(props) {
    super(props)
    this.env = _envieronment;
    this.prodEnv = new Environment('prod');
    this.cache = props.cache;
    this.loadingSpinner = <div className="lds-container">
      <div className="lds-ring"><div></div><div></div><div></div><div></div></div>
    </div>;
    this.state = {}
    this.postPetition = this.postPetition.bind(this);
    this.getPetition = this.getPetition.bind(this);
    this.formDataPetition = this.formDataPetition.bind(this)
    this.getAgeBrackets = this.getAgeBrackets.bind(this);
    this.getBucketLabels = this.getBucketLabels.bind(this);
    this.isSectionHidden = this.isSectionHidden.bind(this);
    this.isFieldHidden = this.isFieldHidden.bind(this);
    this.getCategorizationData = this.getCategorizationData.bind(this);
    this.getUrl = this.getUrl.bind(this);
    this.resetCache = this.resetCache.bind(this);
    this.validateAdminToken=this.validateAdminToken.bind(this);
    this.body = {
      "requestfilters": {
        "filtertype": "FAV",
        "selectedproducttype": "N",
        "notinflag": "false",
        "multiproductfilter": [
        ],
        "classification": "",
        "classificationarray": [
          {
            "class": "",
            "cellindex": "80"
          }
        ],
        "filters": [] // This is the section is where you put the filters
      }
    }
  }


  // Recibe diversos valores que se utilizan según la petición que desea enviarse.
  //toWhere: Identificador general del servicio que desea llamarse, Ej: 'globalsum'
  //endpoint: Identificador específico del servicio que desea llamarse, Ej: 'global/globalcountbygendersummary'
  //filters: Array que contiene los filtros a enviar en cada servicio. Puede ser null
  //useBody: Boolean que define si se debe usar el body por defecto que se envia hacia los servicios o el body modificado que puede recibir la funcion
  //customBody: Body personalizado que se debe enviar al servicio en casos especificos.
  //customCache: Id para almacenar lo que devuelve la petición dentro del cache, de no enviarse se guarda utilizando el endpoint enviado como identificador.
  // -----------------------------------------------------------------------------------------------------------------------------------------------------------------
  //Ej: ('segments', 'segments/getcustomsegmentpointsummary', [], false, {}, cacheId)
  async postPetition(toWhere, endpoint, filters, useBody, customBody, customCache) {
    var cacheId = '';
    if (customCache != undefined) { cacheId = customCache; } else { cacheId = endpoint }
    var cBody = "";

    // 1. Checks if the petition should be sended with a default body parameter
    if (useBody == null || useBody == undefined) useBody = true;
    if (customBody == null || customBody == undefined) { cBody = {} } else { cBody = customBody; if (customCache == undefined) { cacheId += cBody.segmentToFilter } };//modifies the cache id for each segment

    // 2. Creates default empty filter if theres no filter. And updates the cache id with the filters.
    var fil = ""
    if (filters == null || filters == undefined) { filters = [] } else { filters.map(filter => { let pol = null; filter.positive ? pol = 'S' : pol = 'N'; fil += "-" + filter.id + "-" + filter.filteroption + pol }); cacheId += fil }
    if (this.props.bucket.bucketId != undefined && cacheId != "GetBuckets-CacheId") { cacheId += "_" + this.props.bucket.bucketId; }

    // 3. Creates and runs the request and returns the result of the promise after checking its correct.
    if (this.props.token != null) {
      if (this.cache.existInCache(cacheId)) {
        // console.log('comes from cache: '+cacheId)
        return this.cache.getFromCache(cacheId).data;
      } else {
        this.body.requestfilters.filters = filters;
        var url = this.env.get(toWhere) + endpoint;
        
        // Logs the Service that is called
        // console.log("------------------------------")
        // console.log("URL: ", url)
        // console.log("Headers: ", { headers: { token: this.props.token, bucketid: this.props.bucket.bucketId } })
        // console.log("Body: ", JSON.stringify(this.body))
        // console.log("------------------------------")
        
        let res = await Axios.post(url, useBody ? this.body : cBody, { headers: { token: this.props.token, bucketid: this.props.bucket.bucketId } })
        let data = res.data.data;
        let status = res.data.status;
        if (status) {
          this.cache.storeInCache(cacheId, data);
          return data;
        }
        else {
          if (res.data.data.code == "VT03") {
            alert("Your session has expired");
            this.props.logout();
          }
          else {
            console.log("ERROR:", res);
            this.cache.removeFromCache(cacheId);
            // console.log(JSON.stringify(res.data.data));  
            return null;
          }
        }
      }
    }
    else {
      this.props.logout()
    }
  }

  //Ejecuta peticion de tipo GET.
  async getPetition(toWhere, endpoint) {
    if (this.cache.existInCache(endpoint)) {
      return this.cache.getFromCache(endpoint).data;
    } else {
      var url = this.env.get(toWhere) + endpoint;
      if (this.props.token != null) {
        let res = await Axios.get(url, { headers: { token: this.props.token } })
        let data = res.data.data;
        let status = res.data.status;
        if (status) {
          this.cache.storeInCache(endpoint, data);
          return data;
        }
        else {
          if (res.data.data.code == "VT03") {
            alert("Your session has expired: ");
            this.props.logout();
          }
          else {
            console.log("ERROR:");
            console.log(JSON.stringify(res.data.data));
            return null;
          }
        }

      }
      else {
        this.props.logout();
        return null;
      }
    }
  }

  //Recupera la información de categorias del bucket seleccionado
  async getCategorizationData(name, parentKeys, searchText) {
    if (name == undefined) name = "";
    if (parentKeys == undefined) parentKeys = [];
    if (searchText == undefined) searchText = "";

    let bod = {
      "name": name,
      "parentKeys": parentKeys,
      "searchText": searchText
    };

    let cacheId = this.props.bucket.bucketId + name;
    parentKeys.map(pk => {
      cacheId += pk.id;
    })

    if (searchText !== "") { cacheId += searchText }

    var url = this.env.get('categorization') + "categorization/getdata";

    if (this.props.token != null) {
      if (this.cache.existInCache(cacheId)) {
        return this.cache.getFromCache(cacheId).data;
      }
      else {
        let res = await Axios.post(url, bod, { headers: { token: this.props.token, bucketid: this.props.bucket.bucketId } })
        let data = res.data.data;
        console.log('Data:', data)
        let status = res.data.status;
        if (status) {
          this.cache.storeInCache(cacheId, data)
          return data;
        }
        else {
          if (res.data.data.code == "VT03") {
            alert("Your session has expired: ");
            this.props.logout();
          }
          else {
            console.log("ERROR:");
            console.log(JSON.stringify(res.data.data));
            return null;
          }
        }
      }
    }
    else {
      this.props.logout();
    }

  }

  //Recupera las etiquetas del bucket seleecionado
  async getBucketLabels(id, nativeLabels) {
    try {
      var idExist = false;
      var returnLabels = {};
      var res = null;
      var cacheId = 'configuration/labels/' + this.props.bucket.bucketId;

      if (this.cache.existInCache(cacheId)) {
        res = this.cache.getFromCache(cacheId).data;
      } 
      else{
        console.log(`Getting Labels ${'configuration/labels/' + this.props.bucket.bucketId}`);
        res = await this.getPetition('config', 'configuration/labels/' + this.props.bucket.bucketId)
        this.cache.storeInCache(cacheId, res);
      }
      
      res.map((l, index) => {
        if (l.id == id) {
          idExist = true;
          var x = l.labels;
          for (const label in x) {
            switch (this.props.lang) {
              case 'es':
                returnLabels[label] = x[label].es;
                break;
              case 'en':
                returnLabels[label] = x[label].en;
                break;
              default:
                break;
            }
          }
        }
      })

      if(!idExist){
        return null;
      }

      if(nativeLabels != null && nativeLabels != undefined){
        returnLabels = mergeLabels(nativeLabels, returnLabels);
      }
      
      return returnLabels;
    } catch (err) {
      console.log(err);
      return null;
    }
  }

  //Envia datos tipo multipart/form-data a través de un post
  async formDataPetition(toWhere, endpoint, formData) {
    var url = this.env.get(toWhere) + endpoint;
    let res = await Axios.post(
      url,
      formData,
      { headers: { token: this.props.token, bucketid: this.props.bucket.bucketId, 'Content-Type': 'multipart/form-data' } }
    )
    let data = res.data.data;
    let status = res.data.status;
    if (status) {
      return data;
    }
    else {
      if (res.data.data.code == "VT03") {
        alert("Your session has expired: ");
        this.props.logout();
      }
      else {
        console.log("ERROR:");
        console.log(JSON.stringify(res.data.data));
        return null;
      }
    }
  }

  // Valida que el token almacenado en el sitio sea de tipo administrador
  async validateAdminToken(token, bucketId){
    var url = this.env.get('security') + 'admin/validateToken';
    try{
      var res = await Axios.post(url, {}, {headers:{token:token, bucketId:bucketId}});
      return res.data.data;
    }
    catch(err){
      console.log(err);
      return false;
    }
  }

  // Limpia el cache de JS del sitio.
  resetCache(){
    this.cache = new Cache();
  }

  //Recupera el url especifico de uno de los servicios del sitio, recibe id y el ambiente del url que se quiere leer.
  getUrl(id, env) {
    //Posible Id's: security | globalsum | tfa | segemtns | config | categorization | crm | goals | exports |
    switch (env) {
      case 'dev': return this.env.get(id); break;
      case 'prod': return this.prodEnv.get(id); break;
      default: return ""; break;
    }

  }

  //Lee la configuración del bucket y devuelve si una sección deberia estar oculta
  isSectionHidden(id) {
    let found = false;
    this.props.bucket.hiddenSections.map((h) => {
      if (h == id) {
        found = true;
      }
    });

    return found;
  }

  //Lee la configuración del bucket y devuelve si una campo deberia estar oculta
  isFieldHidden(id) {
    let isHidden = false;
    this.props.bucket.hiddenFields.map((h) => {
      if (h === id) isHidden = true;
    });
    return isHidden;
  }

  //Lee la configuración del bucket y devuelve los ageBrackets
  getAgeBrackets() {
    return this.props.bucket.ageBrackets;
  }

  // Función en donde se encapsulan las propiedades del componente, 
  // utiliza un "Render Prop", el cual le permite hacer render de cualquier componente que se le pase por medio de este prop,
  // y asi compartir las propiedades encapsuladas en este componentes.
  render() {
    return (this.props.render(
      {
        askFor: this.postPetition,
        isSectionHidden: this.isSectionHidden,
        isFieldHidden: this.isFieldHidden,
        bucket: this.props.bucket,
        logout: this.props.logout,
        lang: this.props.lang,
        getPetition: this.getPetition,
        getLabels: this.getBucketLabels,
        getCatDat: this.getCategorizationData,
        getUrl: this.getUrl,
        scrollToTop: this.props.scrollToTop,
        loadingSpinner: this.loadingSpinner,
        formDataPetition: this.formDataPetition,
        validateAdminToken:this.validateAdminToken,
        resetCache:this.resetCache,
        adminData:this.props.adminData,
        changeBucketGeneralConfigurations:this.props.changeBucketGeneralConfigurations,
        changeBucketCampConf:this.props.changeBucketCampConf,
        toggleCampaignRute: this.props.toggleCampaignRute
      }))
  }


  //Ejemplo de uso:
  // var Dashboard = props => {       -- Componente que desea tener las funcionalidades de FecthCmp
  //   return (
  //     <FetchCmp                    -- Se retorna el componente de Fetch
  //       render={                   -- En su propiedad render se pasa una función tipo [props => Component], dentro de los props están todas las propiedades que se estan pasando dentro del render de FetchWrapedCmp
  //        props => 
  //         <WrapedDashBoard         -- Componente real donde está el UI y se imprime en pantalla lo que el usuario ve, en este caso el Dashboard del sitio Web.
  //            bucket={props.bucket}
  //            askFor={props.askFor}
  //            isSectionHidden={props.isSectionHidden}
  //            isFieldHidden={props.isFieldHidden}
  //            currencySymbol={props.bucket.currencySymbol}
  //            lang={props.lang}
  //            loadingSpinner={props.loadingSpinner}
  //            size={props.size}
  //            getBucketLabels={props.getLabels}
  //        />
  //       }
  //     />
  //   )
  // }
}




//2. Componente que envuelve a FetchWraped dentro del contexto de React para darle acceso a distintas propiedades como:
//   ->Bucket
//   ->Lenguaje seleccionado
//   ->Session token
// Este componente es el que se exporta y se utiliza en las distintas plantillas del app para permitir a cada componente hacer peticiones web.
var FetchCmp = props => {

  return (<BucketContext.Consumer>
    {value =>
      <FetchWrapedCmp
        bucket={value.bucket}
        render={props.render}
        lang={value.lang}
        token={value.token}
        adminData={value.adminData}
        logout={value.logout}
        cache={value.cache}
        scrollToTop={value.scrollToTop}
        changeBucketGeneralConfigurations={value.changeBucketGeneralConfigurations}
        changeBucketCampConf={value.changeBucketCampConf}
        toggleCampaignRute={value.toggleCampaignRute}
      />
    }
  </BucketContext.Consumer>)
}





//3. Variación del componente de abstraccion de datos. Unico para dar acceso al servicio de doble autenticación de Corrteza.
class FetchLogin extends React.Component {
  constructor(props) {
    super(props)
    this.env = _envieronment;
    this.state = {}
    this.requestCode = this.requestCode.bind(this);
    this.auth = this.auth.bind(this);
    this.validateAdminToken=this.validateAdminToken.bind(this);
    this.loginByUser = this.loginByUser.bind(this)
    this.headers = {
      "Access-Control-Allow-Origin": "*",
    }
  }

  requestCode(email) {
    var url = this.env.get('tfa') + 'RTFAuth/getTFACode';
    return Axios.post(url, { email: email })
  }

  auth(email, code) {
    var url = this.env.get('tfa') + 'RTFAuth/validateTFACode';
    var data = { email: email, tfacode: code }
    return Axios.post(url, data)
  }

  async validateAdminToken(token){
    var url = this.env.get('security') + 'admin/validateToken';
    try{
      var res = await Axios.post(url, {}, {headers:{token:token}});
      return res.data.data.result;
    }
    catch(err){
      console.log(err);
      return false;
    }
  }

  async loginByUser(user){
    var getTokenUrl = this.env.get('tfa') + 'token/generate';
    var validateTokenUrl = this.env.get('tfa') + 'token/validate';
    try{
      var res = await Axios.post(getTokenUrl, {"userid":user});
      console.log('GET TOKEN RESPONSE:', res);
      var oriToken =  res.data.data.token;
      var validateres = await Axios.post(validateTokenUrl, {"tokenstring":oriToken,"code":""});
      console.log('VALIDATE TOKEN RESPONSE:', validateres)
      var token = validateres.data.data.token;
      return token;
    }
    catch(err){
      console.log(err);
      return false;
    }
  }


  render() {
    return (this.props.render(
      {
        requestCode: this.requestCode,
        auth: this.auth,
        validateAdminToken: this.validateAdminToken,
        loginByUser:this.loginByUser
      }))
  }
}




export default FetchCmp;
export { FetchLogin, _envieronment };