import React, { useState, useEffect, useRef, useReducer } from "react";
import classnames from 'classnames';
// import FetchCmp from "../../../components/FetchCmp/fetchCmp"
import DatePicker, { registerLocale } from "react-datepicker";
import es from 'date-fns/locale/es';
import ReactTooltip from "react-tooltip";
import FacebookLogin from 'react-facebook-login';
import EmailEditor from 'react-email-editor';
// reactstrap components
import {
    Card, CardBody,
    Container, Row, Col,
    Button,
    TabContent, TabPane,
    /* FormGroup, */ Input,
    Dropdown, DropdownToggle, DropdownMenu, DropdownItem,
    ButtonDropdown,
    Nav, NavItem, NavLink, /* Progress, */ Table,
    Modal, ModalBody, /* ModalBodyProps, */ ModalHeader
} from "reactstrap";
import { CSSTransition } from "react-transition-group"
import NumberFormat from "react-number-format";
// import { Line, Bar, Pie, Polar } from "react-chartjs-2";
import { ReactComponent as EmailIcon } from '../../../assets/icons/createCampaign/email-icon.svg';
import { ReactComponent as SmsIcon } from '../../../assets/icons/createCampaign/sms-icon.svg';
// import { ReactComponent as PushIcon } from '../../../assets/icons/createCampaign/push-icon.svg';
import { ReactComponent as FacebookIcon } from '../../../assets/icons/createCampaign/facebook-icon.svg';
import { ReactComponent as RetainIcon } from '../../../assets/icons/createCampaign/retain-icon.svg';
import { ReactComponent as IncreaseMIcon } from '../../../assets/icons/createCampaign/increaseM-icon.svg';
import { ReactComponent as IncreaseFIcon } from '../../../assets/icons/createCampaign/increaseF-icon.svg';
import { ReactComponent as BringBackIcon } from '../../../assets/icons/createCampaign/bringBack-icon.svg';
import { ReactComponent as OccasionalsIcon } from '../../../assets/icons/createCampaign/ocasionals-icon.svg';
import { ReactComponent as LoymarkLogo } from '../../../assets/img/loymark.svg';
import DragnDropIcon from '../../../assets/icons/createCampaign/dragndrop.png';
import CuponIcon from '../../../assets/icons/createCampaign/cupon.png';
import UploadIcon from '../../../assets/icons/createCampaign/upload.png';
import logoCortteza from '../../../assets/img/corttC.png';
import { SegmentSelectorLang, CampaignEmailCreatorLang, FinalSelectionFormLang, CampaignSmsCreatorLang, CampaignFacebookCreatorLang } from '../../../lang';
import sample from "../../../assets/emailDesigns/sample.json"; //'./sample.json';
import blank from "../../../assets/emailDesigns/blank.json"; //'./sample.json';
import { createRef } from "react";
registerLocale('es', es);
// import Axios from "axios";
// import { render } from "@testing-library/react";


// 1. Components
class SelectCard extends React.Component {
    //Props: className | isActive | lg | md | sm | icon | title | text | function | functionValues
    constructor(props) {
        super(props)
        this.state = { isActive: props.isActive }
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.isActive != this.props.isActive) {
            this.setState({ isActive: this.props.isActive })
        }
    }

    render() {
        return (
            this.props.segment == undefined ?
                <Col className={this.props.className || "selectCard"} lg={this.props.lg} md={this.props.md} sm={this.props.sm}>
                    <Card>
                        <CardBody className="container">
                            <div className="icon">{this.props.icon}</div>
                            {this.props.name ? <h5>{this.props.name}</h5> : ""}
                            <b>{this.props.title}</b>
                            <p>{this.props.text}</p>
                            <div className="selector">
                                <span className={classnames({ active: this.state.isActive })} onClick={() => { this.props.function(this.props.functionValues); }}>
                                    <div className="ball" />
                                </span>
                            </div>
                        </CardBody>
                    </Card>
                </Col>
                :
                <Col className={this.props.className || "segmentCard"} style={{ width: "20%" }}>
                    <Card>
                        <CardBody className="container">
                            <div className="icon">{this.props.icon}</div>
                            {this.props.name ? <h5>{this.props.name}</h5> : ""}
                            <b>{this.props.title} CLIENTES</b>
                            <p>{this.props.text}</p>
                            <div className="selector">
                                <span className={classnames({ active: this.state.isActive })} onClick={() => { this.props.function(this.props.functionValues); }}>
                                    <div className="ball" />
                                </span>
                            </div>
                        </CardBody>
                    </Card>
                </Col>
        )
    }


}

var Cuponera = props => {

    const pageSize = 2;
    const stepSize = 3;
    const [cuponList, setCuponList] = useState(null);
    const [page, setPage] = useState(1);
    const [isDropDownOpen, setIsDropDownOpen] = useState(false);
    var cId = "";
    if (props.selectedCupon != null) {
        cId = props.selectedCupon.ID
    }
    const [selectedCuponId, setCupon] = useState(cId);



    //API CALL
    useEffect(() => {
        var getCuponList = async () => {
            let cacheId = "GetCuponList" + Math.round(Math.random() * 1000);
            // let cacheCId = "GetCuponCategoryList" + Math.round(Math.random() * 1000);
            try {
                let cuponList = await props.askFor("campaigns", "getCuponList", [], false, {}, cacheId);
                cuponList.filter(c => {
                    let notExpired = new Date(c.ExpirationDate).getTime() > new Date().getTime();
                    let isByRegister = c.CouponType === "R";
                    if (notExpired && isByRegister && c.CouponStatus) {
                        return true;
                    }
                    else {
                        return false;
                    }
                })
                setCuponList(cuponList);
            }
            catch (err) {
                console.log(err)
            }
        }

        getCuponList()
    }, []);

    //PAGINATIOR FUNCTIONS
    var nextPage = () => {
        let pageCount = Math.ceil(cuponList.length / pageSize);
        console.log(pageCount)
        if (page + 1 <= pageCount) {
            setPage(page + 1);
        }
    }

    var prevPage = () => {
        if (page - 1 != 0) {
            setPage(page - 1);
        }
    }

    var firstPage = () => {
        setPage(1);
    }

    var lastPage = () => {
        let pageCount = Math.ceil(cuponList.length / pageSize);
        setPage(pageCount - 1);
    }

    //Cupon functions
    var getCupon = id => {
        if (cuponList !== null && selectedCuponId !== '') {
            var cup = null;
            cuponList.forEach(c => {
                if (c.ID === id) {
                    cup = c;
                }
            })
            return cup.Name;
        }
    }

    //Pre-render
    var getCupuneraByType = () => {
        var cuponera = <></>;
        switch (props.type) {
            case 'email':
                cuponera = <Table responsive className="cuponListTable">
                    <thead>
                        <tr>
                            <th>Imagen</th>
                            <th>Cantidad</th>
                            <th>Nombre</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {cuponList.map((cupon, key) => {
                            var minKey = (page - 1) * pageSize;
                            var maxKey = page * pageSize;

                            if (page == 1) {
                                if (key <= maxKey) {
                                    return (
                                        <tr key={key} className={classnames({ active: cupon.ID == selectedCuponId })}>
                                            {cupon.ImageList.length > 0 ? <td><img className="cuponImg" src={cupon.ImageList[0].ImageUrl} alt="" /></td> : <td></td>}
                                            <td>{cupon.QuantityAvailable - cupon.QuantityClaimed}</td>
                                            <td>{cupon.Name}</td>
                                            <td><Button onClick={() => { props.setCampaignCupon(cupon); setCupon(cupon.ID) }}>Seleccionar</Button></td>
                                        </tr>
                                    )
                                }
                            }
                            else {
                                if (page > 1) {
                                    if ((key > minKey) && (key <= maxKey)) {
                                        return (
                                            <tr key={key} className={classnames({ active: cupon.ID == selectedCuponId })}>
                                                <td><img className="cuponImg" src={cupon.ImageList[0].ImageUrl} alt="" /></td>
                                                <td>{cupon.QuantityAvailable - cupon.QuantityClaimed}</td>
                                                <td>{cupon.Name}</td>
                                                <td><Button onClick={() => { props.setCampaignCupon(cupon); setCupon(cupon.ID) }}>Seleccionar</Button></td>
                                            </tr>
                                        )
                                    }
                                }
                            }
                        })}
                    </tbody>
                </Table>
                    ; break;
            case "sms":
                cuponera = <div className="cuponDropdown">
                    <h4>Selecciona el cupón correspondiente</h4>
                    <select defaultValue={''} onChange={(e) => {
                        if (e.target.value === '') {
                            setCupon('');
                            props.setCampaignCupon(null);
                        }
                        else {
                            var c = JSON.parse(e.target.value);
                            props.setCampaignCupon(c);
                            setCupon(c.ID);
                        }

                    }}>
                        <option value={''}> Ninguno </option>
                        {cuponList.map((cupon, key) => {
                            let notExpired = new Date(cupon.ExpirationDate).getTime() > new Date().getTime();
                            let isByRegister = cupon.CouponType === "R";
                            if (notExpired && isByRegister && cupon.CouponStatus) {
                                return (
                                    <option key={key} value={JSON.stringify(cupon)} selected={cupon.ID === selectedCuponId}>
                                        {cupon.Name}
                                    </option>
                                )
                            }

                        })}
                    </select>
                </div>
                    ; break;
            default: ; break;
        }

        return cuponera;
    }


    //Render
    if (cuponList != null) {
        return (
            <Container fluid>
                <Row>
                    <Col>
                        {cuponList.length > 0 ?
                            getCupuneraByType()
                            :
                            <h4>Este bucket no cuenta con cupones disponibles</h4>
                        }
                    </Col>
                </Row>

                {cuponList.length > 0 && props.type === 'email' ?
                    <Row className="text-center">
                        <Col>
                            <button className="pager-button" onClick={() => { firstPage() }}>Primera</button>
                            <button className="pager-button" onClick={() => { prevPage() }}>
                                <i className="ti-angle-left"></i></button>
                            <button className="pager-button" onClick={() => { nextPage() }}>
                                <i className="ti-angle-right"></i>
                            </button>
                            <button className="pager-button" onClick={() => { lastPage() }}>Ultima</button>
                        </Col>
                    </Row>
                    :
                    ""}

            </Container>
        )
    }
    else {
        return (
            <>
                {props.loadingSpinner}
                <h4 className="text-center">Cargando datos de cupones</h4>
            </>
        )
    }



}

var CampaignDatePicker = props => {
    return (
        <div className="campaignDatePickerC">
            <Card>
                <CardBody>
                    <span className="xButton" onClick={() => { props.toggleDatePicker() }} aria-label="Close">&times;</span>
                    <p>La campaña será enviada:</p>
                    <DatePicker
                        selected={props.selectedDate}
                        onChange={props.handleChange}
                    />
                    <Button onClick={() => { props.sendCampaign() }} className="btnCreate">Crear campaña</Button>
                </CardBody>
            </Card>
        </div>
    )
}

class SegmentSelectionForm extends React.Component {
    constructor(props) {
        super(props)
        this._isMounted = false;
        this.labels = SegmentSelectorLang(props.lang);
        this.state = {
            tab: "1",
            segmentData: null,
            segments: [],
            selectionCount: 0,
            customSegmentList: null,
            customSegment: null,
        }
        this.isSegmentActive = this.isSegmentActive.bind(this);
        this.setSegments = this.setSegments.bind(this);
    }

    //0. REACT LIFECYCLE FUNCTIONS
    async componentDidMount() {
        this._isMounted = true;
        try {
            // this.props.setLoadingStep(true);
            var segmentData = await this.props.askFor('segments', 'segments/getbasedata');
            let cacheId = "CustomSegmentListNoCache" + Math.round(Math.random() * 1000);
            let customSegmentList = await this.props.askFor('segments', 'segments/getcustomsegmentlist', [], false, {}, cacheId)
            if (customSegmentList != null && segmentData != null) {
                this.setState({ segmentData: segmentData.segments, customSegmentList: customSegmentList }, () => { /* this.props.setLoadingStep(false) */ })
            }

        }
        catch (err) {
            console.log(err);
        }
    }

    //1. SEGMENTS && CUSTOM SEGMENTS FUNCTIONS
    selectCustomSegment(segment) {
        if (this._isMounted) {
            this.setState({ customSegment: segment });
        }
    }

    searchSegment(segmentName) {
        this.setState(state => {
            let indexMove = null
            state.customSegmentList.forEach((segment, index) => {
                if (segment.segmentName.toUpperCase().indexOf(segmentName.toUpperCase()) != -1) {
                    state.customSegmentList.splice(index, 1);
                    state.customSegmentList.unshift(segment);
                }
            });

            return ({ customSegmentList: state.customSegmentList })
        })
    }

    isSegmentActive = id => {
        var isActive = false;
        this.state.segments.forEach(s => {
            if (s.id === id) {
                isActive = true;
            }
        })

        return isActive;
    }

    setSegments = (val) => {
        if (this._isMounted) {
            this.setState(state => {
                var c = state.segments;
                var sCount = state.selectionCount;
                if (this.isSegmentActive(val.id)) {
                    let ind = null;
                    c.forEach((s, index) => { if (s.id == val.id) { ind = index } })
                    c.splice(ind, 1);
                    sCount -= val.count;
                }
                else {
                    if (this.state.tab === "1") {
                        c.push(val);
                        sCount += val.count;
                    }
                    else if (this.state.tab === "2") {
                        c.push(val);
                        sCount = val.count;
                    }
                }

                if (sCount < 0) { sCount = 0 }
                return ({ segments: c, selectionCount: sCount });
            })
        }
    }

    resetSegments = () => {
        this.setState(state => {
            var s = state.segments;
            s = []
            return ({ segments: s, selectionCount: 0 })
        })
    }

    //2.TABS FUCNTIONS
    changeTabs(tab) {
        this.setState({ tab: tab, customSegment: null }, () => { this.resetSegments() })
    }

    //Y. PRE-RENDER FUNCTIONS
    getFinalRow() {
        var row = null
        switch (this.state.tab) {
            case "1": row =
                <Row className="peopleCounter">
                    <span>{this.labels.lblSelectedPeople}:</span>
                    {" "}
                    <NumberFormat className="count" displayType="text" thousandSeparator={true} value={this.state.selectionCount} />
                    {" "}
                    <span className="count">{this.labels.lblClients}</span>
                </Row>;
                break;
            case "2": row =
                <Row className="peopleCounter">
                    <span>{this.labels.lblCreateFromCustomSegment}:</span>
                    {" "}
                    {this.state.customSegment != null ? <span className="customSegmentName">{this.state.customSegment.segmentName}</span> : ""}
                    {" "}
                    {this.state.customSegment != null ? <span>{this.labels.lblSelectedPeople}:</span> : ""}
                    {" "}
                    <NumberFormat className="count" displayType="text" thousandSeparator={true} value={this.state.selectionCount} />
                    {" "}
                    <span className="count">{this.labels.lblClients}</span>
                </Row>;
                break;
        }

        return row;
    }

    render() {
        if (this.state.segmentData != null && this.state.customSegmentList != null) {
            return (
                <Container fluid className="segmentSelectorForm">
                    <Row>
                        <Nav className="segmentTabs" tabs>
                            <NavItem>
                                <NavLink onClick={() => this.changeTabs("1")} className={classnames({ active: this.state.tab == "1" })}>{this.labels.lblSegmentTab}</NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink onClick={() => this.changeTabs("2")} className={classnames({ active: this.state.tab == "2" })}>{this.labels.lblCustomSegmentsTab}</NavLink>
                            </NavItem>
                        </Nav>
                    </Row>

                    <Row>
                        <TabContent activeTab={this.state.tab} className="container-fluid">
                            <TabPane tabId="1" >
                                <div className="segmentRow">
                                    <SelectCard
                                        segment
                                        icon={<RetainIcon />}
                                        name={this.labels.lblRetain}
                                        title={this.state.segmentData.segment1.count}
                                        function={this.setSegments}
                                        functionValues={{ id: 1, name: "A retener", count: this.state.segmentData.segment1.count }}
                                        isActive={this.isSegmentActive(1)}
                                    />
                                    <SelectCard
                                        segment
                                        icon={<IncreaseMIcon />}
                                        name={this.labels.lblIncrementM}
                                        title={this.state.segmentData.segment2.count}
                                        function={this.setSegments}
                                        functionValues={{ id: 2, name: "Incrementar M", count: this.state.segmentData.segment2.count }}
                                        isActive={this.isSegmentActive(2)}
                                    />
                                    <SelectCard
                                        segment
                                        icon={<IncreaseFIcon />}
                                        name={this.labels.lblIncrementF}
                                        title={this.state.segmentData.segment3.count}
                                        function={this.setSegments}
                                        functionValues={{ id: 3, name: "Incrementar F", count: this.state.segmentData.segment3.count }}
                                        isActive={this.isSegmentActive(3)}
                                    />
                                    <SelectCard
                                        segment
                                        icon={<BringBackIcon />}
                                        name={this.labels.lblBringBack}
                                        title={this.state.segmentData.segment4.count}
                                        function={this.setSegments}
                                        functionValues={{ id: 4, name: "Traer de Regreso", count: this.state.segmentData.segment4.count }}
                                        isActive={this.isSegmentActive(4)}
                                    />
                                    <SelectCard
                                        segment
                                        icon={<OccasionalsIcon />}
                                        name={this.labels.lblOccasionals}
                                        title={this.state.segmentData.segment5.count}
                                        function={this.setSegments}
                                        functionValues={{ id: 5, name: "Ocasionales", count: this.state.segmentData.segment5.count }}
                                        isActive={this.isSegmentActive(5)}
                                    />
                                </div>
                            </TabPane>

                            <TabPane tabId="2">
                                <Card>
                                    <CardBody>
                                        <Input type="text" placeholder={this.labels.lblSegmentListSearchBar} onChange={e => { this.searchSegment(e.target.value) }} />
                                        <Table className="customSegmentTable">
                                            <thead>
                                                <tr>
                                                    <th>Nombre</th>
                                                    <th>Conteo</th>
                                                    <th></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {this.state.customSegmentList.map((segment, key) => {
                                                    return (
                                                        <tr className={classnames({ active: (this.state.customSegment != null && this.state.customSegment.segmentID == segment.segmentID) })} key={key}>
                                                            <td>{segment.segmentName}</td>
                                                            <td>{segment.baseData[segment.baseData.length - 1].count}</td>
                                                            <td>
                                                                <Button className="selectButton" onClick={() => {
                                                                    this.resetSegments();
                                                                    this.setSegments({
                                                                        id: segment.segmentID,
                                                                        name: segment.segmentName,
                                                                        count: segment.baseData[segment.baseData.length - 1].count
                                                                    })
                                                                    this.selectCustomSegment(segment);
                                                                }}
                                                                >{this.labels.lblSelect}
                                                                </Button>
                                                            </td>
                                                        </tr>
                                                    )
                                                })}
                                            </tbody>
                                        </Table>
                                    </CardBody>
                                </Card>
                            </TabPane>
                        </TabContent>
                    </Row>

                    {this.getFinalRow()}

                    {this.state.segments != null && this.state.selectionCount !== 0 ?
                        <CSSTransition classNames="animated" timeout={1000} in={true} appear={true}>
                            <Row className="buttonsRow">
                                <Button onClick={() => { this.props.exportFunction(this.state.segments, this.state.selectionCount) }}>Siguiente</Button>
                            </Row>
                        </CSSTransition>
                        :
                        ""
                    }

                </Container>
            )
        }
        else {
            return (
                <div style={{ width: "100%", textAlign: "center" }}>
                    {this.props.loadingSpinner}
                    <h3>Cargando Datos de Segmentos</h3>
                </div>
            )
        }

    }
}

class Upload extends React.Component {
    constructor(props) {
        super(props)
        // if(props.file != undefined)
        this.state = {
            file: props.image != null ? props.image : null,
        }
        this.handleChange = this.handleChange.bind(this)
    }

    handleChange(event) {
        if (event.target.files[0] == null || event.target.files[0] == undefined) {
            var filePreview = null;
            var file = null;
        }
        else {
            var filePreview = URL.createObjectURL(event.target.files[0]);
            var file = event.target.files[0];
        }

        this.setState({ file: filePreview }, () => { this.props.setCampaignImg(file, filePreview) })
    }

    render() {
        return (
            <div className="upload">
                <input type="file" name="imgFile" id="imgFile" onChange={this.handleChange} />
                <label htmlFor="imgFile">
                    {this.state.file == null ?
                        <>
                            <span className="ti-upload" />
                            <h5>{this.props.labels.lblCampaigImg}</h5>
                            <p>{this.props.labels.lblCampaigImgExplan}</p>
                        </>

                        :
                        <img src={this.state.file} />
                    }
                </label>

            </div>
        );
    }
}

var Breadcrumbs = props => {

    //Props:
    //breadcrumps = [{text:string, function: f(), functionValue: x, isEnable: function f(),}]
    //active

    return (
        <Container fluid className="breadcrumps">
            <Row>
                <Col>
                    {props.breadcrumps.map((b, index) => {
                        return (
                            <div key={index}>
                                {index != 0 ? <span className="middle"> {">"} </span> : ""}
                                <span className={classnames({ active: index + 1 == props.active })}
                                    onClick={() => {
                                        // if (b.condition) {
                                        b.function(b.functionValue)
                                        // }
                                    }}>
                                    {b.text}
                                </span>
                            </div>
                        )
                    })}
                </Col>
            </Row>
        </Container>
    )
}

var PhonePreview = props => {

    return (
        <div className="phone">
            {props.message.length > 0 ?
                <div className="textMessage">
                    {props.message || ""}
                </div>
                : ""
            }
        </div>

    )
}

// 2. Dynamic Components (changes depending on the type of campaign selected)
class FinalSelectionForm extends React.Component {
    constructor(props) {
        super(props)
        this._isMounted = false;
        this.labels = FinalSelectionFormLang(props.lang)
        this.state = {
            data: null,
            body: { segments: props.segments, type: props.type }
        }
        this.subjectOrtextLabel = null;
        this.availablePoblationExplain = null;
        this.titleLabel = null;
        this.campaignIcon = null;
        this.imageRow = <tr></tr>;
        this.htmlPreviewRef = createRef();
        switch (props.type) {
            case "email":
                this.titleLabel = this.labels.lblSummaryTitle;
                this.availablePoblationExplain = <i className="ti-info" data-tip={this.labels.lblSummaryAvailablePoblationExplanEmail} data-for="CS" />;
                break;
            case "sms":
                this.titleLabel = this.labels.lblSummaryTitle;
                this.availablePoblationExplain = <i className="ti-info" data-tip={this.labels.lblSummaryAvailablePoblationExplanSms} data-for="CS" />;
                break;
            case "facebook":
                this.titleLabel = this.labels.lblSummaryTitleFacebook;
                this.availablePoblationExplain = <i className="ti-info" data-tip={this.labels.lblSummaryAvailablePoblationExplanSms} data-for="CS" />;
                this.state.body.type = "email"; // checks for emails to get the avaliable poblation count
                break;
        }
    }

    //1. ReactLife Cycle
    async componentDidMount() {
        this._isMounted = true;
        ReactTooltip.rebuild()
        // this.props.setLoadingStep(true)
        //Asks for the available poblation count
        let cacheId = 'GetAvailablePoblation-' + Math.round(Math.random() * 1000);
        let data = await this.props.askFor('campaigns', "getAvailablePoblation", [], false, this.state.body, cacheId);
        if (data != null) {
            this.setState({ data: data.count }, () => { this.props.isLoaded(true) /* this.props.setLoadingStep(false); */ })
        }
    }

    componentDidUpdate(prevProps, prevState) {

    }

    //2. PRE-RENDER FUNCTIONS

    getTable() {
        let table = <tr></tr>;
        var segmentsRow = <tr>
            <td>{this.labels.lblSummarySegments}</td>
            <td>
                <ul className="segments">
                    {this.props.segments.map((s, key) => {
                        return (<li key={key}>{s.name}</li>)
                    })}
                </ul>
            </td>
        </tr>;
        var imageRow = <tr></tr>;
        var poblationRows = <>
            <tr>
                <td className="iTooltip">
                    {this.labels.lblSummaryTotalPoblation}
                    <i className="ti-info" data-tip={this.labels.lblSummaryTotalPoblationExplan} data-for="CS" />
                </td>
                <td className="data">{this.props.selectionCount}</td>
            </tr>

            <tr>
                <td className="iTooltip">
                    {this.labels.lblSummaryAvailablePoblation}
                    {this.availablePoblationExplain}
                </td>
                <td className="data">{this.state.data || 0}</td>
            </tr>
        </>;
        var cuponRow = <tr></tr>

        switch (this.props.type) {
            case "email":
                switch (this.props.emailType) {
                    case "html":
                        // imageRow = <tr>
                        //     <td>Diseño</td>
                        //     <td><div className="htmlPreview" dangerouslySetInnerHTML={{__html:this.props.campaignHtml}}></div></td>
                        // </tr>;
                        break;
                    case "cupon":
                        imageRow = <tr>
                            <td>Cupon</td>
                            <td><img style={{ width: "100%", height: "auto" }} src={this.props.campaignCupon.ImageUrl} alt="" /></td>
                        </tr>;
                        cuponRow = <tr>
                            <td>
                                {this.labels.lblSummaryAvailableCoupons}
                            </td>
                            <td className="data">{this.props.cuponCount || 0}</td>
                        </tr>
                        break;
                    case "image":
                        imageRow = <tr>
                            <td>Imagen</td>
                            <td><img style={{ width: "100%", height: "auto" }} src={this.props.campaignImg} alt="" /></td>
                        </tr>;
                        break;

                }
                table = <Table responsive>
                    <tbody>
                        <tr>
                            <td>{this.labels.lblSummaryType}</td>
                            <td className="data"> <EmailIcon className="campaignIcon" />  {this.props.type.toUpperCase()}</td>
                        </tr>
                        <tr>
                            <td>{this.labels.lblSummaryName}</td>
                            <td className="data">{this.props.campaignName}</td>
                        </tr>
                        <tr>
                            <td>{this.labels.lblSummaryTextMessage}</td>
                            <td className="">{this.props.campaignSubject}</td>
                        </tr>
                        {segmentsRow}
                        {imageRow}
                        {poblationRows}
                        {cuponRow}
                    </tbody>
                </Table>
                    ; break;
            case "sms":
                table = <Table responsive>
                    <tbody>
                        <tr>
                            <td>{this.labels.lblSummaryType}</td>
                            <td className="data"> <SmsIcon className="campaignIcon" />  {this.props.type.toUpperCase()}</td>
                        </tr>
                        <tr>
                            <td>{this.labels.lblSummaryName}</td>
                            <td className="data">{this.props.campaignName}</td>
                        </tr>
                        <tr>
                            <td>{this.labels.lblSummaryTextMessage}</td>
                            <td className="">{this.props.campaignSubject}</td>
                        </tr>
                        {segmentsRow}
                        {poblationRows}
                    </tbody>
                </Table>;
                break;
            case "facebook":
                table = <Table responsive>
                    <tbody>
                        <tr>
                            <td>{this.labels.lblSummaryType}</td>
                            <td className="data"> <FacebookIcon className="campaignIcon" />  {this.props.type.toUpperCase()}</td>
                        </tr>
                        <tr>
                            <td>{this.labels.lblSummaryNameAudience}</td>
                            <td className="data">{this.props.audienceName}</td>
                        </tr>
                        <tr>
                            <td>{this.labels.lblSummaryAudienceDescription}</td>
                            <td className="">{this.props.audienceDescription}</td>
                        </tr>
                        {segmentsRow}
                        {poblationRows}
                    </tbody>
                </Table>;
                break;
            default: table = <tr></tr>; break;
        }

        return table;
    }

    //Z. RENDER
    render() {
        if (this.state.data != null) {
            return (
                <Container fluid className="finalSelectionForm">
                    <Row >
                        <div className="flexCenter">
                            <Col lg="8" md="8" sm="12">
                                <Card>
                                    <div className="table-header">
                                        <h3>{this.titleLabel}</h3>
                                    </div>
                                    {this.getTable()}
                                    <ReactTooltip id="CS" place="bottom" />
                                </Card>
                            </Col>
                        </div>
                    </Row>
                </Container>
            )
        }
        else {
            return (
                <Container fluid className="finalSelectionForm">
                    {this.props.loadingSpinner}
                    <h3 className="loadingText">Cargando datos...</h3>
                </Container>
            )
        }

    }
}

var EmailCampaignForm = props => {

    // 1. Set initial properties ---------------
    const [step, setInternalStep] = useState(1); // 1. Basic Campaign Info | 2. Email construction or cupon selection | 3. Desing or Cuponera or Upload | 4. Segment Selection
    const [emailType, setEmailType] = useState(''); // html | cupon | image
    const [editorLoaded, setEditorLoaded] = useState(false);
    const [editorNextOption, setEditorNextOption] = useState({ label: "Guardar y salir", function: props.setStep })
    const [isDropDownOpen, setIsDropDownOpen] = useState(false);
    const [selectionCount, setSelectionCount] = useState(0);
    const [cuponCount, setCuponCount] = useState(0);
    const [finalSelectionLoaded, setFinalSelectionLoaded] = useState(false);
    const [modalScreen, setModalScreen] = useState('');
    const [image, setImage] = useState({
        file: null,
        filePreview: null
    })
    const [testMail, setTestMail] = useState({
        testEmail: null,
        testEmailSending: false,
        testEmailSent: false,
        testEmailError: false
    })
    const [testModal, setTestModal] = useState(false);
    const [Campaign, setCampaign] = useState({
        name: '',
        subject: '',
        segments: [],
        cupon: null,
        redirectUrl: '',
        html: null,
    })
    var emailEditorRef = useRef(null);
    const [emailDesign, setEmailDesing] = useState(sample);
    var labels = CampaignEmailCreatorLang(props.lang);
    // var buttons = [
    //     {text: labels.lblCreateCampaign, function: summitCampaign}
    // ]



    // 2. Utility Functions ------------------

    useEffect(() => {
        ReactTooltip.rebuild();
        return () => { }
    })

    var createFormTextInput = (label, explan, controller, required, value, placeholder) => {
        if (required == undefined) { required = false }
        if (value == undefined || value == null) { value = "" }
        if (placeholder == undefined || placeholder == null) { placeholder = "" }
        return (
            <div className="formGroup">
                <label> {label} <span className="requiredLabel" data-for="emailCampaignCreatorFormT" data-tip="Campo requerido" data-place="right">{required ? "?" : ""}</span> </label>
                <Input type="text" placeholder={placeholder} onChange={e => { controller(e.target.value) }} value={value}></Input>
                <p>{explan}</p>
            </div>
        )
    }

    var createSelectCard = (title, icon, text, emailType) => {
        if (title == undefined) { title = "" }
        if (icon == undefined) { icon = "" }
        if (text == undefined) { text = "" }
        return (
            <Col className="emailTypeSelectCard">
                <Card onClick={() => { setEmailType(emailType); setInternalStep(3) }}>
                    <CardBody className="container">
                        <h3 className="icon">{title}</h3>
                        {icon}
                        <p>{text}</p>
                    </CardBody>
                </Card>
            </Col>
        )
    }

    var toggleDropDown = () => {
        setIsDropDownOpen(!isDropDownOpen);
    }

    var editorOnLoad = () => {
        setEditorLoaded(true);
        if (emailEditorRef.current !== undefined && emailEditorRef.current !== null) {
            if (Campaign.cupon != null) {
                let cuponRUrl = "https://cuponeraclientapidev.loymark.com/ssr/{{contact.LAST_COUPON}}";
                sample.body.rows[2].columns[0].contents[0].values.src.url = Campaign.cupon.ImageUrl;
                sample.body.rows[2].columns[0].contents[0].values.action.values.href = cuponRUrl;
                sample.body.rows[2].columns[0].contents[0].values.action.url = cuponRUrl;
                emailEditorRef.current.editor.loadDesign(sample);
            }
            else {
                emailEditorRef.current.editor.loadDesign(emailDesign);
                emailEditorRef.current.editor.registerCallback('image', uploadImgFile)
            }

        } else {
            if (Campaign.cupon != null) {
                let cuponRUrl = "https://cuponeraclientapidev.loymark.com/ssr/{{contact.LAST_COUPON}}";
                sample.body.rows[2].columns[0].contents[0].values.src.url = Campaign.cupon.ImageUrl;
                sample.body.rows[2].columns[0].contents[0].values.action.values.href = cuponRUrl;
                sample.body.rows[2].columns[0].contents[0].values.action.url = cuponRUrl;
                setTimeout(() => emailEditorRef.current.editor.loadDesign(sample), 3000);
            }
            else {
                setTimeout(() => emailEditorRef.current.editor.loadDesign(emailDesign), 3000);
            }

        }

    }

    var setStepCheckingCondition = (nstep) => {
        var bool = false;
        switch (step) {
            case 1: if (Campaign.name !== "" && Campaign.subject !== "" && nstep > step) { bool = true; setInternalStep(nstep) }; break;
            case 2:
                if (nstep < step) {
                    setInternalStep(nstep);
                }
                ; break;
            case 3:
                if (nstep < step) {
                    setInternalStep(nstep)
                } else {
                    if (Campaign.html != null) { bool = true; setInternalStep(nstep); };
                }
                break;
            case 4:
                if (nstep < step) {
                    setInternalStep(nstep);
                    setFinalSelectionLoaded(false)
                } else {
                    if (Campaign.segments.length > 0) { bool = true; setInternalStep(nstep); };
                }
                break;
            case 5:
                if (nstep < step) {
                    setInternalStep(nstep);
                    setFinalSelectionLoaded(false)
                } else {
                    if (Campaign.name !== "" && Campaign.subject !== "") { bool = true; };
                }
                break;
            default: ; break;
        }

        return bool;
    }

    var breadcrumbs = [
        { text: labels.lblStepConf, function: setStepCheckingCondition, functionValue: 1 },
        { text: labels.lblStepContentType, function: setStepCheckingCondition, functionValue: 2 },
        { text: labels.lblStepDesing, function: setStepCheckingCondition, functionValue: 3 },
        { text: labels.lblStepSegments, function: setStepCheckingCondition, functionValue: 4 },
        { text: labels.lblStepConfirmation, function: setStepCheckingCondition, functionValue: 5 },
    ];

    var resetEmailDesing = () => {
        setEmailDesing(blank);
        emailEditorRef.current.editor.loadDesign(blank);
    }

    var exportHtml = () => {
        emailEditorRef.current.editor.exportHtml(data => {
            const { design, html } = data;
            props.setCampaignHtml(data.html);
            props.setStep(3)
        })

    }

    // 3. Test Email Functions ------------------

    var setEmailforTest = (email) => {
        setTestMail(t => {
            t.testEmail = email;
            return ({ ...t });
        })
    }

    var setTestEmailError = (bool) => {
        if (bool == undefined) { bool = false }
        setTestMail(t => {
            t.testEmailError = bool;
            return ({ ...t });
        })
    }

    var toggleTestEmailSent = (bool) => {
        if (bool == undefined) { bool = false }
        setTestMail(t => {
            t.testEmail = "";
            t.testEmailSent = bool;
            t.testEmailSending = false;
            return ({ ...t });
        })
    }

    // var toggleTestModal = () => { setTestModal(!testModal); setEmailforTest("") }
    var toggleTestModal = (screen) => { if (screen === undefined) { screen = '' }; setModalScreen(screen); setEmailforTest(""); setTestModal(!testModal); }

    // 4. Email Campaign Object Functions ------------------

    var setCampaignName = (name) => {
        setCampaign(c => {
            c.name = name;
            return ({ ...c });
        })
    }

    var setCampaignSubject = (sub) => {
        setCampaign(c => {
            c.subject = sub;
            return ({ ...c });
        })
    }

    var setCampaignSegments = (seg, count) => {
        setCampaign(c => {
            c.segments = seg;
            return ({ ...c });
        })
        setSelectionCount(count)
        setStepCheckingCondition(5);
    }

    var setCampaignRedirectUrl = red => {
        setCampaign(c => {
            c.redirectUrl = red;
            return ({ ...c });
        })
    }

    var setCampaignImg = (file, filePreview) => {
        let f = { file: file, filePreview: filePreview }
        setImage({ ...f });
    }

    var setCampaignHtml = () => {
        emailEditorRef.current.editor.exportHtml(data => {
            const { design, html } = data;
            setEmailDesing(design);
            setCampaign(c => {
                c.html = html;
                return ({ ...c });
            });
            setStepCheckingCondition(4);
        })

    }

    var setCampaignCupon = cupon => {
        var cup = null;
        var cc = 0;
        if (cupon != undefined) {
            if (cupon["ImageList"].length > 0) {
                cup = {
                    "ID": cupon['ID'],
                    "ImageUrl": cupon["ImageList"][0]["ImageUrl"],
                };
            }
            else {
                cup = {
                    "ID": cupon['ID'],
                    "ImageUrl": "https://storage.googleapis.com/corttezamailinglists/logoCortteza.png",
                };
            }

            cc = cupon["QuantityAvailable"] - cupon["QuantityClaimed"];
        }

        setCuponCount(cc);
        setCampaign(c => {
            c.cupon = cup;
            return ({ ...c })
        })

    }

    var resetCampaignSegments = () => {
        setCampaign(c => {
            c.segments = [];
            return ({ ...c })
        })
    }

    var changeEmailType = (type) => {
        switch (type) {
            case "html":
                setEmailType(type);
                setCampaign(c => { c.cupon = null; return ({ ...c }) });
                setImage({ file: null, filePreview: null });
                // setStepCheckingCondition(3);
                break;
            case "cupon":
                setEmailType(type);
                setCampaign(c => { c.html = null; return ({ ...c }) });
                setImage({ file: null, filePreview: null });
                // setStepCheckingCondition(3)
                break;
            case "image":
                setEmailType(type);
                setCampaign(c => { c.html = null; return ({ ...c }) });
                setCampaign(c => { c.cupon = null; return ({ ...c }) });
                // setStepCheckingCondition(3)
                break;
        }
    }

    //5. Async Functions - API CALLS
    var summitCampaign = async () => {
        if (Campaign.name != "" && Campaign.subject != "" && Campaign.segments.length > 0 && (image.file != null || Campaign.cupon != null || Campaign.html != null)) {
            props.setLoadingStep(true);
            var formData = new FormData();
            formData.append("campaign", JSON.stringify(Campaign));
            formData.append('imgFile', image.file);
            var res = await props.formDataPetition('campaigns', "createCampaign", formData);
            //var res = null;
            if (res == null) {
                alert("Error while creating the campaign")
            }
            props.changeScreen({ screen: 'list', creationCancel: false });
        }
    }

    var sendEmailTest = async () => {
        if (Campaign.name != "" && Campaign.subject != "") {
            setTestMail(tm => {
                tm.testEmailSending = true;
                return ({ ...tm });
            })
            if (Campaign.cupon != null) {
                let cacheId = 'ClaimCupon' + Math.round(Math.random() * 1000);
                let code = await props.askFor('campaigns', 'claimCupon', [], false, { cuponId: Campaign.cupon.ID }, cacheId)
                if (code != null) {
                    let cuponRUrl = "https://cuponeraclientapidev.loymark.com/ssr/" + code;
                    sample.body.rows[2].columns[0].contents[0].values.action.values.href = cuponRUrl;
                    sample.body.rows[2].columns[0].contents[0].values.action.url = cuponRUrl;
                    emailEditorRef.current.editor.loadDesign(sample);
                    emailEditorRef.current.editor.exportHtml(async data => {
                        const { design, html } = data;
                        var formData = new FormData();
                        var email = {
                            destination: testMail.testEmail,
                            subject: Campaign.subject,
                            content: "",
                            cupon: Campaign.cupon,
                            redirectUrl: '',
                            html: html,
                        }
                        formData.append("email", JSON.stringify(email));
                        formData.append('imgFile', image.file);
                        // var cacheId = "SendTestEmail-" + this.props.bucket.bucketId + "-" + this.state.campaign.name
                        var res = await props.formDataPetition('campaigns', "sendTestEmail", formData);
                        if (res != null) {
                            setTestMail(tm => {
                                tm.testEmailSent = true;
                                tm.testEmailSending = false;
                                tm.testEmailError = false;
                                return ({ ...tm });
                            })
                        }
                        else {
                            setTestMail(tm => {
                                tm.testEmailSent = true;
                                tm.testEmailSending = false;
                                tm.testEmailError = true;
                                return ({ ...tm });
                            })
                        }
                    })
                } else {
                    setTestMail(tm => {
                        tm.testEmailSent = true;
                        tm.testEmailSending = false;
                        tm.testEmailError = true;
                        return ({ ...tm });
                    })
                }
            }
            else {
                emailEditorRef.current.editor.exportHtml(async data => {
                    const { design, html } = data;
                    var formData = new FormData();
                    var email = {
                        destination: testMail.testEmail,
                        subject: Campaign.subject,
                        content: "",
                        cupon: Campaign.cupon,
                        redirectUrl: '',
                        html: html,
                    }
                    formData.append("email", JSON.stringify(email));
                    formData.append('imgFile', image.file);
                    // var cacheId = "SendTestEmail-" + this.props.bucket.bucketId + "-" + this.state.campaign.name
                    var res = await props.formDataPetition('campaigns', "sendTestEmail", formData);
                    if (res != null) {
                        setTestMail(tm => {
                            tm.testEmailSent = true;
                            tm.testEmailSending = false;
                            tm.testEmailError = false;
                            return ({ ...tm });
                        })
                    }
                    else {
                        setTestMail(tm => {
                            tm.testEmailSent = true;
                            tm.testEmailSending = false;
                            tm.testEmailError = true;
                            return ({ ...tm });
                        })
                    }
                })
            }
        }
        else {
            setTestMail(tm => {
                tm.testEmailSent = true;
                tm.testEmailSending = false;
                tm.testEmailError = true;
                return ({ ...tm });
            })
        }
    }

    var uploadImgFile = async (file, done) => {
        // Handle file upload here
        done({ progress: 50 })
        var data = new FormData()
        data.append('imgFileName', "Campaign-" + Campaign.name);
        data.append('imgFile', file.attachments[0]);
        var res = await props.formDataPetition('campaigns', "uploadImage", data);
        if (res != null) {
            done({ progress: 100, url: res })
        }
    };


    // 6. Pre-render functions -------------------
    var getScreenStep = () => {
        var screen = <></>;
        switch (step) {
            case 1: //Configuration
                screen =
                    <Col lg="8" md="10" sm="12">
                        {createFormTextInput(labels.lblCampaignName, labels.lblCampaignNameExplan, setCampaignName, true, Campaign.name, "Promoción de Pilsen")}
                        {createFormTextInput(labels.lblCampaignSubject, labels.lblCampaignSubjectExplan, setCampaignSubject, true, Campaign.subject, "Disfrutá de este fin de semana con...")}

                        <div className="buttonsRow">
                            {Campaign.name != "" && Campaign.subject != "" ?
                                <CSSTransition classNames="animated" timeout={1000} in={true} appear={true}>
                                    <Button onClick={() => { setStepCheckingCondition(2) }}>Siguiente</Button>
                                </CSSTransition>
                                :
                                ""
                            }
                        </div>
                    </Col>
                    ; break;
            case 2: //Type selection
                screen =
                    <Col lg="8" md="10" sm="12">
                        <Row>
                            <Col lg="4" md="4" sm="4" className="emailTypeSelectCard">
                                <Card onClick={() => { changeEmailType("html"); setInternalStep(3) }}>
                                    <CardBody>
                                        <h5 className="icon">Editar Email</h5>
                                        <img src={DragnDropIcon} alt="" className="img-responsive" />
                                        <p>{labels.lblDragDropExplan}</p>
                                    </CardBody>
                                </Card>
                            </Col>
                            {props.cuponeraEnabled ?
                                <Col lg="4" md="4" sm="4" className="emailTypeSelectCard">
                                    <Card onClick={() => { changeEmailType("cupon"); setInternalStep(3) }}>
                                        <CardBody>
                                            <h5 className="icon">Seleccionar Cupón</h5>
                                            <img src={CuponIcon} alt="" className="img-responsive" />
                                            <p>{labels.lblCuponExplan}</p>
                                        </CardBody>
                                    </Card>
                                </Col>
                                : ""
                            }
                            <Col lg="4" md="4" sm="4" className="emailTypeSelectCard">
                                <Card onClick={() => { changeEmailType("image"); setInternalStep(3) }}>
                                    <CardBody>
                                        <h5 className="icon">Imagen</h5>
                                        <img src={UploadIcon} className="img-responsive" />
                                        <p>Sube una imagen para utilizar dentro de la plantilla</p>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Col>
                    ; break;
            case 3: //Content creation
                switch (emailType) {
                    case "html": screen =
                        <CSSTransition in={editorLoaded} appear={true} classNames="fadeIn" timeout={1000}>
                            <div className="emailEditor">
                                {editorLoaded == true ?
                                    <div className="buttonContainer">
                                        <img src={logoCortteza} alt="" className="logo" />
                                        <h5>{Campaign.name}</h5>
                                        <ReactTooltip id="eCreator" />

                                        <Button onClick={() => { toggleTestModal('sendTest') }} data-tip={labels.lblSentTestEmail} data-for="eCreator" data-place="bottom"><span className="ti-email" /></Button>
                                        <Button onClick={resetEmailDesing} data-tip={labels.lblBlankDesing} data-for="eCreator" data-place="bottom"><span className="ti-trash" /></Button>
                                        <Button onClick={() => { if (Campaign.html != null) { setStepCheckingCondition(4) } else { setStepCheckingCondition(2) } }} data-tip="Salir sin guardar" data-for="eCreator" data-place="bottom"><span className="ti-back-left" /></Button>
                                        <Button className="mainButton" onClick={() => { setCampaignHtml() }}>{labels.lblSaveAndContinue}</Button>
                                        {/* <ButtonDropdown className="mainButton" isOpen={isDropDownOpen} toggle={toggleDropDown}>
                                            <Button onClick={() => { setCampaignHtml() }}>{editorNextOption.label}</Button>
                                            <DropdownToggle caret />
                                            <DropdownMenu>
                                                <DropdownItem onClick={() => { setCampaignHtml() }}>{labels.lblSaveAndContinue}</DropdownItem>
                                                <DropdownItem onClick={resetEmailDesing}>{labels.lblBlankDesing}</DropdownItem>
                                                <DropdownItem onClick={() => { if (Campaign.html != null) { setStepCheckingCondition(4) } else { setStepCheckingCondition(2) } }}>Salir sin guardar</DropdownItem>
                                            </DropdownMenu>
                                        </ButtonDropdown> */}
                                    </div>
                                    : ""}

                                <EmailEditor
                                    ref={emailEditorRef}
                                    onLoad={editorOnLoad}
                                    minHeight="93%"
                                    options={{
                                        locale: "es-ES"
                                    }}
                                />
                            </div>
                        </CSSTransition>
                        ; break;
                    case "cupon":
                        if (Campaign.cupon == null) {
                            screen =
                                <div className="cuponSelector">
                                    <Row>
                                        <Col lg="8" md="10" sm="12">
                                            <Card>
                                                <CardBody>
                                                    <Cuponera
                                                        askFor={props.askFor}
                                                        loadingSpinner={props.loadingSpinner}
                                                        setCampaignCupon={setCampaignCupon}
                                                        selectedCupon={Campaign.cupon}
                                                        type="email"
                                                    />
                                                </CardBody>
                                            </Card>
                                        </Col>
                                    </Row>

                                    <Row>
                                        <div className="buttonContainer">
                                            {Campaign.cupon != null ?
                                                <>
                                                    <CSSTransition classNames="animated" timeout={1000} in={true} appear={true}>
                                                        <Button onClick={toggleTestModal}><span className="ti-email" /> Enviar correo de prueba</Button>
                                                    </CSSTransition>

                                                    <CSSTransition classNames="animated" timeout={1000} in={true} appear={true}>
                                                        <Button onClick={() => { /* setInternalStep(4) */ }}>Abrir Editor de correo <span className="ti-arrow-right" /></Button>
                                                    </CSSTransition>
                                                </>
                                                :
                                                ""}
                                        </div>
                                    </Row>
                                </div>;
                        }
                        else {
                            screen =
                                <CSSTransition in={editorLoaded} appear={true} classNames="fadeIn" timeout={1000}>
                                    <div className="emailEditor">
                                        {editorLoaded == true ?
                                            <div className="buttonContainer">
                                                <img src={logoCortteza} alt="" className="logo" />
                                                <h5>{Campaign.name}</h5>
                                                <ReactTooltip id="ecCreator" />

                                                <Button onClick={() => { toggleTestModal('sendTest') }} data-tip={labels.lblSentTestEmail} data-for="ecCreator" data-place="bottom"><span className="ti-email" /></Button>
                                                <Button onClick={() => { setCampaignCupon() }} data-tip={"Cambiar Cupón"} data-for="ecCreator" data-place="bottom"><span className="ti-ticket" /></Button>
                                                <Button onClick={() => { if (Campaign.html != null) { setStepCheckingCondition(4) } else { setStepCheckingCondition(2) } }} data-tip="Salir sin guardar" data-for="ecCreator" data-place="bottom"><span className="ti-back-left" /></Button>

                                                <Button className="mainButton" onClick={() => { setCampaignHtml() }}>{labels.lblSaveAndContinue}</Button>
                                                {/* <ButtonDropdown className="mainButton" isOpen={isDropDownOpen} toggle={toggleDropDown}>
                                                    <Button onClick={() => { setCampaignHtml() }}>{editorNextOption.label}</Button>
                                                    <DropdownToggle caret />
                                                    <DropdownMenu>
                                                        <DropdownItem onClick={() => { setCampaignHtml() }}>{labels.lblSaveAndContinue}</DropdownItem>
                                                        <DropdownItem onClick={() => { setCampaignCupon() }}>Cambiar Cupón</DropdownItem>
                                                        <DropdownItem onClick={() => { if (Campaign.html != null) { setInternalStep(4) } else { setInternalStep(2) } }}>Salir sin guardar</DropdownItem>
                                                    </DropdownMenu>
                                                </ButtonDropdown> */}
                                            </div>
                                            : ""}

                                        <EmailEditor
                                            ref={emailEditorRef}
                                            onLoad={editorOnLoad}
                                            minHeight="93%"
                                        />
                                    </div>
                                </CSSTransition>
                        }

                        break;
                    case "image": screen =
                        <Col lg="8" md="10" sm="12">
                            <div className="imageUploader">
                                <div className="formGroup">
                                    <Upload labels={labels} setCampaignImg={setCampaignImg} image={image.filePreview} />
                                </div>

                                <div className="buttonContainer">
                                    {image.file != null && image.filePreview != null ?
                                        <>
                                            <CSSTransition classNames="animated" timeout={1000} in={true} appear={true}>
                                                <Button onClick={() => { toggleTestModal('sendTest') }}><span className="ti-email" /> {labels.lblSentTestEmail}</Button>
                                            </CSSTransition>

                                            <CSSTransition classNames="animated" timeout={1000} in={true} appear={true}>
                                                <Button onClick={() => { setInternalStep(4) }}>{labels.lblNext} <span className="ti-arrow-right" /></Button>
                                            </CSSTransition>
                                        </>
                                        :
                                        ""}
                                </div>
                            </div>
                        </Col>
                        ; break;
                    default: ; break;
                }
                ; break;
            case 4: // SegmentSelection
                screen =
                    <Col lg="12" md="12" sm="12">
                        <Card>
                            <CardBody>
                                <SegmentSelectionForm
                                    askFor={props.askFor}
                                    exportFunction={setCampaignSegments}
                                    loadingSpinner={props.loadingSpinner}
                                    selectionCount={selectionCount}
                                    lang={props.lang}
                                />
                            </CardBody>
                        </Card>
                    </Col>
                    ; break
            case 5: // Campaign Confirmation
                screen =
                    <Col lg="12" md="12" sm="12">
                        <FinalSelectionForm
                            askFor={props.askFor}
                            lang={props.lang}
                            type="email"
                            emailType={emailType}
                            loadingSpinner={props.loadingSpinner}
                            selectionCount={selectionCount}
                            segments={Campaign.segments}
                            campaignName={Campaign.name}
                            campaignSubject={Campaign.subject}
                            campaignImg={image.filePreview}
                            campaignCupon={Campaign.cupon}
                            campaignHtml={Campaign.html}
                            cuponCount={cuponCount}
                            isLoaded={setFinalSelectionLoaded}
                        />

                        {finalSelectionLoaded ?
                            <Row className="buttonsRow">
                                <Button onClick={() => { toggleTestModal('confirmation') }}>{labels.lblCreateCampaign}</Button>
                            </Row>
                            :
                            ""
                        }

                    </Col>
                    ;
                ; break
        }

        return screen;
    }

    var getModalContent = () => {
        var screen = <></>;

        switch (modalScreen) {
            case 'confirmation':
                screen = <div className="confirmation">
                    <h6>La campaña se creará a continuación</h6>
                    <div className="buttonsRow">
                        <Button onClick={() => { summitCampaign() }}>{labels.lblAccept}</Button>
                        <Button onClick={toggleTestModal}>{labels.lblCancel}</Button>
                    </div>
                </div>
                    ; break;
            case "sendTest":
                screen = <div className="sendTestEmail">
                    <div className="inputGroup">
                        {testMail.testEmailSent ?
                            testMail.testEmailError ?
                                <>
                                    <span>Error al enviar el correo</span>
                                    <button onClick={() => { toggleTestEmailSent(false); }}>Enviar otro coreo</button>
                                </>
                                :
                                <>
                                    <span>Enviado a: {testMail.testEmail}</span>
                                    <button onClick={() => { toggleTestEmailSent(false); }}>Enviar otro correo</button>
                                </>
                            :
                            testMail.testEmailSending ?
                                <>
                                    {props.loadingSpinner}
                                </>
                                :
                                <>
                                    <Input type="email" placeholder="email@mail.com" onChange={e => { setEmailforTest(e.target.value) }}></Input>
                                    <button onClick={() => { sendEmailTest(); }}>Enviar Email de prueba</button>
                                </>
                        }
                    </div>
                </div>
                break;
            default: ; break;
        }

        return screen;
    }

    //Render
    return (
        <Container fluid className="emailCampaignCreatorForm">
            <Row className="stepRow">
                <Breadcrumbs breadcrumps={breadcrumbs} active={step} />
            </Row>
            <Row>
                <ReactTooltip id="emailCampaignCreatorFormT" />

                {getScreenStep()}

                <Modal isOpen={testModal} toggle={toggleTestModal} centered={true} className="emailModal">
                    <ModalHeader toggle={toggleTestModal}><label> {modalScreen == "sendTest" ? labels.lblTestEmail : ""}  </label></ModalHeader>
                    <ModalBody>
                        {getModalContent()}
                    </ModalBody>
                </Modal>
            </Row>
        </Container>
    )

}

var SmsCampaignForm = props => {

    // 1. CONST DEFINITIONS    
    const [step, setInternalStep] = useState(1); // 1. Campaign Name | 2. Sms Content | 3. Segment Selection | 4. Confirmation
    const [modal, setModal] = useState(false);
    const [modalScreen, setModalScreen] = useState('');
    // const [datePickerModal, setDatePickerModal] = useState(false);
    const labels = CampaignSmsCreatorLang(props.lang);
    const [maxCharacters, setMaxCharacters] = useState(160);
    const [selectionCount, setSelectionCount] = useState(0);
    const [finalSelectionLoaded, setFinalSelectionLoaded] = useState(false);
    const [summitChoice, setSummitChoice] = useState('summit') // summit | save | schedule
    const [campaign, setCampaign] = useState({
        name: '',
        text: '',
        cuponId: null,
        segments: [],
        config: { sentNow: true, sentAt: new Date() }

    });
    const [messageType, setMessagetype] = useState('');
    const [coupon, setCoupon] = useState(null);
    const [testSms, setTestSms] = useState({
        testSms: null,
        testSmsSending: false,
        testSmsSent: false,
        testSmsError: false
    })

    // 2. Utility Functions ------------------
    var createFormTextInput = (label, explan, controller, required, value, placeholder) => {
        if (required == undefined) { required = false }
        if (value == undefined || value == null) { value = "" }
        if (placeholder == undefined || placeholder == null) { placeholder = "" }
        return (
            <div className="formGroup">
                <label> {label} <span className="requiredLabel" data-for="smsCampaignCreatorForm" data-tip="Campo requerido" data-place="right">{required ? "?" : ""}</span></label>
                <Input type="text" placeholder={placeholder} onChange={e => { controller(e.target.value) }} value={value}></Input>
                <p>{explan}</p>
            </div>
        )
    }

    var setStepCheckingCondition = (nstep) => {
        var bool = false;
        switch (step) {
            case 1: if (campaign.name !== "" && nstep > step) { bool = true; setInternalStep(nstep) }; break;
            case 2:
                if (nstep < step) {
                    setInternalStep(nstep);
                }
                else if (campaign.text != '') {
                    bool = true;
                    setInternalStep(nstep);
                }
                ; break;
            case 3:
                if (nstep < step) {
                    setInternalStep(nstep)
                } else if (campaign.segments.length > 0) {
                    bool = true;
                    setInternalStep(nstep)
                };
                break;
            case 4:
                if (nstep < step) {
                    setFinalSelectionLoaded(false);
                    setInternalStep(nstep)
                }
                break;
            default: ; break;
        }

        return bool;
    }

    const breadcrumbs = [
        { text: labels.lblStepConf, function: setStepCheckingCondition, functionValue: 1 },
        { text: labels.lblStepContent, function: setStepCheckingCondition, functionValue: 2 },
        { text: labels.lblStepSegments, function: setStepCheckingCondition, functionValue: 3 },
        { text: labels.lblStepConfirmation, function: setStepCheckingCondition, functionValue: 4 },
    ];

    var setSmsforTest = (smsNumber) => {
        setTestSms(t => {
            t.testSms = smsNumber;
            return ({ ...t });
        })
    }

    var toggleTestSmsSent = (bool) => {
        if (bool == undefined) { bool = false }
        setTestSms(t => {
            t.testSms = "";
            t.testSmsSent = bool;
            t.testSmsSending = false;
            return ({ ...t });
        })
    }

    var toggleModal = (screen) => { if (screen === undefined) { screen = '' }; setModalScreen(screen); setModal(!modal); }

    // 3. CAMPAIGN OBJECT UPDATE FUNCTIONS
    var setCampaignName = (name) => {
        setCampaign(c => {
            c.name = name;
            return ({ ...c });
        })
    }

    var setTextMessage = (message) => {
        setCampaign(c => {
            // var maxC = state.maxCharacters;
            let valLength = message.length;
            let textLength = c.text.length;

            if (valLength > textLength && (valLength - textLength) === 1) {
                setMaxCharacters(maxCharacters - 1);
            }

            if (valLength < textLength && (textLength - valLength) === 1) {
                setMaxCharacters(maxCharacters + 1);
            }

            if (valLength > textLength && (valLength - textLength) !== 1) {
                setMaxCharacters(160 - valLength);
            }

            if (valLength < textLength && (textLength - valLength) !== 1) {
                setMaxCharacters(160 - valLength);
            }

            if (maxCharacters < 0) {
                setMaxCharacters(0);
            }

            if (valLength === 0) {
                setMaxCharacters(160);
            }

            c.text = message;
            return ({ ...c });
        })
    }

    var setCampaignDate = (date) => {
        setCampaign(c => {
            c.config.sentAt = date;
            return ({ ...c })
        })
    }

    var setCampaignSegments = (seg, count) => {
        setCampaign(c => {
            c.segments = seg;
            return ({ ...c });
        })
        setSelectionCount(count)
        setStepCheckingCondition(4);
    }

    var setCampaignCupon = (cupon) => {
        if (cupon === null) {
            setMaxCharacters(160);
            setCoupon(null);
            setCampaign(c => {
                c.text = '';
                c.cuponId = null;
                return { ...c }
            })
        }
        else {
            if (cupon.ShortMessage.length <= 160) {
                setMaxCharacters(160);
                setCoupon(cupon);
                setCampaign(c => {
                    c.text = cupon.ShortMessage;
                    c.cuponId = cupon.ID;
                    return { ...c }
                })
            } else {
                setMaxCharacters(maxCharacters - cupon.ShortMessage.length);
            }
        }


    }

    //5. Async Functions - API CALLS
    var sendSmsTest = async () => {
        // Checks if theres a text to be sended
        if (campaign.name != "" && campaign.text != "") {
            //Activates the loading Spinner
            setTestSms(tm => {
                tm.testSmsSending = true;
                return ({ ...tm });
            })

            //Checks if its trying to send a cupon
            if (campaign.cuponId !== null) {
                //Cupon Text Message ---------------------------------------------------------------------------------------------
                let claimCacheId = 'ClaimCuponSms' + Math.round(Math.random() * 1000);
                let code = await props.askFor('campaigns', 'claimCupon', [], false, { cuponId: campaign.cuponId }, claimCacheId)
                if (code != null) {
                    var cacheId = "SendTestSms-" + props.bucketId + "-" + campaign.name
                    var message = { message: campaign.text + " " + code, recipients: [testSms.testSms] };
                    var res = await props.askFor('campaigns', "sendTestSms", [], false, message, cacheId);
                    if (res != null) {
                        setTestSms(tm => {
                            tm.testSmsSent = true;
                            tm.testSmsSending = false;
                            tm.testSmsError = false;
                            return ({ ...tm });
                        })
                    }
                    else {
                        setTestSms(tm => {
                            tm.testSmsSent = true;
                            tm.testSmsSending = false;
                            tm.testSmsError = true;
                            return ({ ...tm });
                        })
                    }
                } else {
                    setTestSms(tm => {
                        tm.testSmsSent = true;
                        tm.testSmsSending = false;
                        tm.testSmsError = true;
                        return ({ ...tm });
                    })
                }
            }
            else {
                //Regular Text message --------------------------
                var cacheId = "SendTestSms-" + props.bucketId + "-" + campaign.name
                var message = { message: campaign.text, recipients: [testSms.testSms] };
                var res = await props.askFor('campaigns', "sendTestSms", [], false, message, cacheId);
                if (res != null) {
                    setTestSms(tm => {
                        tm.testSmsSent = true;
                        tm.testSmsSending = false;
                        tm.testSmsError = false;
                        return ({ ...tm });
                    })
                }
                else {
                    setTestSms(tm => {
                        tm.testSmsSent = true;
                        tm.testSmsSending = false;
                        tm.testSmsError = true;
                        return ({ ...tm });
                    })
                }
            }
        }
        else {
            setTestSms(s => {
                s.testSmsSent = true;
                s.testSmsSending = false;
                s.testSmsError = true;
                return ({ ...s });
            })
        }

    }

    var summitCampaign = async () => {
        if (campaign.name !== "" && campaign.subject !== "" && campaign.segments.length > 0) {
            props.setLoadingStep(true);
            var smsCampaign = {
                name: campaign.name,
                text: campaign.text,
                segments: campaign.segments,
                cuponId: campaign.cuponId,
                config: { sendNow: true }
            }
            var cacheId = "CreateSMSCampaign-" + props.bucketid + "-" + campaign.name;
            var ressms = await props.askFor('campaigns', "createsmscampaign", [], false, smsCampaign, cacheId);
            if (ressms === null) {
                alert("Error while creating the campaign")
                props.setLoadingStep(false);
            } else {
                props.setLoadingStep(false);
                props.changeScreen({ screen: 'list', creationCancel: false });
            }
        }
        // console.log('create campaña')
    }

    var summitAndSaveCampaign = async () => {
        var smsCampaign = {
            name: campaign.name,
            text: campaign.text,
            segments: campaign.segments,
            cuponId: campaign.cuponId,
            config: { sendNow: false }
        }
        var cacheId = "CreateSMSCampaign-" + props.bucketid + "-" + smsCampaign.name
        props.setLoadingStep(true);
        var res = await props.askFor('campaigns', "createsmscampaign", [], false, smsCampaign, cacheId);
        if (res !== null) {
            props.changeScreen({ screen: 'list', creationCancel: false });
        }
        // console.log('save campaña')
    }

    var summitAndScheduleCampaign = async () => {
        var smsCampaign = {
            name: campaign.name,
            text: campaign.text,
            segments: campaign.segments,
            cuponId: campaign.cuponId,
            config: { sendNow: false, sendAt: campaign.config.sentAt.toISOString() }
        }
        var cacheId = "CreateSMSCampaign-" + props.bucketid + "-" + smsCampaign.name
        props.setLoadingStep(true);
        var res = await props.askFor('campaigns', "createsmscampaign", [], false, smsCampaign, cacheId);
        if (res !== null) {
            props.changeScreen({ screen: 'list', creationCancel: false });
        }
        // console.log('schedule campaña')
    }

    //4. PRE-RENDER FUNCTIONS
    var getScreenStep = () => {
        var screen = <></>;

        switch (step) {
            case 1:/* Campaign Name */
                screen = <div>
                    {createFormTextInput(labels.lblCampaignName, labels.lblCampaignNameExplan, setCampaignName, true, campaign.name, "Promoción...")}

                    <div className="stepMessage">
                        <p>Selecciona el contenido de tu campaña</p>
                    </div>

                    <Container>
                        <Row>
                            <Col lg="4" md="4" sm="4" className="smsTypeSelectCard">
                                <Card onClick={() => { setMessagetype("message"); }} className={classnames({ active: messageType === "message" })}>
                                    <CardBody>
                                        <h5 className="icon">{labels.lblEditMessage}</h5>
                                        <img src={DragnDropIcon} alt="" className="img-responsive" />
                                        <p>{labels.lblEditMessage}</p>
                                    </CardBody>
                                </Card>
                            </Col>

                            <Col lg="4" md="4" sm="4" className="smsTypeSelectCard">
                                <Card onClick={() => { setMessagetype("cupon"); }} className={classnames({ active: messageType === "cupon" })}>
                                    <CardBody>
                                        <h5 className="icon">{labels.lblCuponExplan}</h5>
                                        <img src={CuponIcon} alt="" className="img-responsive" />
                                        <p>{labels.lblCuponExplan}</p>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Container>

                    <div className="buttonsRow">
                        {campaign.name !== "" && messageType !== '' ?
                            <CSSTransition classNames="animated" timeout={1000} in={true} appear={true}>
                                <Button onClick={() => { setStepCheckingCondition(2) }}>Siguiente</Button>
                            </CSSTransition>
                            :
                            ""
                        }
                    </div>
                </div>
                    ; break;
            case 2:/* Campaign Text Message */
                switch (messageType) {
                    case 'message':
                        screen = <div>
                            <div className="formGroup">
                                <label> {labels.lblCampaignTextMessage} </label>
                                <Input type="textarea" value={campaign.text} placeholder="Mensaje de texto" onChange={e => { setTextMessage(e.target.value) }}></Input>
                                {maxCharacters <= 0 ?
                                    <CSSTransition classNames="animated" timeout={1000} in={true} appear={true}>
                                        <p className="alert">{labels.lblAvailableCharactersAlert}</p>
                                    </CSSTransition>
                                    :
                                    <CSSTransition classNames="animated" timeout={1000} in={true} appear={true}>
                                        <p className="availableCharacters">{labels.lblAvailableCharacter + ": " + maxCharacters}</p>
                                    </CSSTransition>
                                }
                            </div>

                            {campaign.text != "" ?
                                maxCharacters > 0 ?
                                    <div className="buttonsRow">
                                        <CSSTransition classNames="animated" timeout={1000} in={true} appear={true}>
                                            <Button onClick={() => { toggleModal('sendTest') }}>Mensaje de prueba</Button>
                                        </CSSTransition>
                                        <CSSTransition classNames="animated" timeout={1000} in={true} appear={true}>
                                            <Button onClick={() => { setStepCheckingCondition(3) }}>Siguiente</Button>
                                        </CSSTransition>
                                    </div>
                                    :
                                    <div className="stepMessage">
                                        <p>El mensaje del cupón seleccionado es muy extenso</p>
                                    </div>
                                :
                                <div className="stepMessage">
                                    <p>Selecciona el cupón que quieres enviar</p>
                                </div>
                            }


                            <PhonePreview message={campaign.text} />
                        </div>
                            ; break;
                    case 'cupon':
                        screen = <div>
                            <div className="formGroup">
                                <Cuponera
                                    askFor={props.askFor}
                                    loadingSpinner={props.loadingSpinner}
                                    setCampaignCupon={setCampaignCupon}
                                    selectedCupon={coupon}
                                    type="sms"
                                />
                            </div>

                            {campaign.text != "" ?
                                maxCharacters > 0 ?
                                    <div className="buttonsRow">
                                        <CSSTransition classNames="animated" timeout={1000} in={true} appear={true}>
                                            <Button onClick={() => { toggleModal('sendTest') }}>Mensaje de prueba</Button>
                                        </CSSTransition>
                                        <CSSTransition classNames="animated" timeout={1000} in={true} appear={true}>
                                            <Button onClick={() => { setStepCheckingCondition(3) }}>Siguiente</Button>
                                        </CSSTransition>
                                    </div> : ""
                                :
                                <div className="stepMessage">
                                    {/* <p>Digita el contenido del mensaje de texto</p> */}
                                </div>
                            }

                            <CSSTransition mountOnEnter unmountOnExit classNames="ani" timeout={1000} in={coupon !== null} appear={coupon !== null}>
                                {coupon !== null ?
                                    <PhonePreview message={coupon.ShortMessage + ": XASFGDVA"} />
                                    :
                                    <></>
                                }
                            </CSSTransition>



                        </div>;
                        ; break;
                    default: ; break;
                }

                ; break;
            case 3:/* Segment selection */
                screen = <Col lg="12" md="12" sm="12">
                    <Card>
                        <CardBody>
                            <SegmentSelectionForm
                                askFor={props.askFor}
                                exportFunction={setCampaignSegments}
                                loadingSpinner={props.loadingSpinner}
                                selectionCount={selectionCount}
                                lang={props.lang}
                            />
                        </CardBody>
                    </Card>
                </Col>
                    ; break;
            case 4:/* Confirmation */
                screen = <div>
                    <FinalSelectionForm
                        askFor={props.askFor}
                        lang={props.lang}
                        type="sms"
                        loadingSpinner={props.loadingSpinner}
                        selectionCount={selectionCount}
                        segments={campaign.segments}
                        campaignName={campaign.name}
                        campaignSubject={campaign.text}
                        isLoaded={setFinalSelectionLoaded}
                    />

                    {finalSelectionLoaded ?
                        <Row className="buttonsRow">
                            <Button onClick={() => { setSummitChoice('summit'); toggleModal('confirmation') }}>{labels.lblCreateAndSendCampaign}</Button>
                            <Button onClick={() => { setSummitChoice('save'); toggleModal('confirmation') }}>{labels.lblCreateAndSaveCampaign}</Button>
                            <Button onClick={() => { setSummitChoice('schedule'); toggleModal('datePicker'); }}>{labels.lblCreateAndScheduleCampaign}</Button>
                        </Row>
                        :
                        ""
                    }
                </div>
                    ; break;
            default: ; break;
        }

        return screen;
    }

    var getModalContent = () => {
        var screen = <></>;

        switch (modalScreen) {
            case 'confirmation':
                var content = <></>;
                switch (summitChoice) {
                    case 'summit': content = <>
                        <h6>La campaña se creará a continuación</h6>
                        <div className="buttonsRow">
                            <Button onClick={() => { summitCampaign() }}>{labels.lblAccept}</Button>
                            <Button onClick={toggleModal}>{labels.lblCancel}</Button>
                        </div>

                    </>; break;
                    case 'save': content = <>
                        <h6>¿Guardar campaña como borrador?</h6>
                        <div className="buttonsRow">
                            <Button onClick={() => { summitAndSaveCampaign() }}>{labels.lblAccept}</Button>
                            <Button onClick={toggleModal}>{labels.lblCancel}</Button>
                        </div>
                    </>; break;
                    case 'schedule': content = <>
                        <h6>La campaña se enviará:</h6>
                        <p>{campaign.config.sentAt.toLocaleDateString()}</p>
                        <div className="buttonsRow">
                            <Button onClick={() => { summitAndScheduleCampaign() }}>{labels.lblAccept}</Button>
                            <Button onClick={toggleModal}>{labels.lblCancel}</Button>
                        </div>
                    </>; break;
                    default: break;
                }

                screen = <div className="confirmation">
                    {content}
                </div>
                    ; break;
            case "datePicker":
                screen = <div className="datePicker">
                    <p>Seleccionar fecha de envío</p>
                    <DatePicker
                        selected={campaign.config.sentAt}
                        onChange={setCampaignDate}
                        locale={props.lang}
                    />
                    <Button onClick={() => { setModalScreen('confirmation') }}>{labels.lblAccept}</Button>
                </div>
                    ; break;
            case "sendTest":
                screen = <div className="sendTestEmail">
                    <div className="inputGroup">
                        {testSms.testSmsSent ?
                            testSms.testSmsError ?
                                <>
                                    <span>Error al enviar el mensaje de texto</span>
                                    <button onClick={() => { toggleTestSmsSent(false); }}>Enviar mensaje</button>
                                </>
                                :
                                <>
                                    <span>Enviado a: {testSms.testSms}</span>
                                    <button onClick={() => { toggleTestSmsSent(false); }}>Enviar otro mensaje</button>
                                </>
                            :
                            testSms.testSmsSending ?
                                <>
                                    {props.loadingSpinner}
                                </>
                                :
                                <>
                                    <label>Número de telefono</label>
                                    <Input type="text" placeholder="50688776655" onChange={e => { setSmsforTest(e.target.value) }}></Input>
                                    <button onClick={() => { sendSmsTest(); }}>Enviar</button>
                                </>
                        }
                    </div>
                </div>
                break;
            default: ; break;
        }

        return screen;
    }

    //Render
    return (
        <Container fluid className="smsCampaignCreatorForm">
            <Row className="stepRow">
                <Breadcrumbs breadcrumps={breadcrumbs} active={step} />
            </Row>
            <Row>
                <Col lg={step === 3 || step === 4 ? "12" : "8"} md="8" sm="12">
                    <ReactTooltip id="smsCampaignCreatorForm" />

                    {getScreenStep()}

                    <Modal isOpen={modal} toggle={toggleModal} centered={true} className="smsModal">
                        <ModalHeader toggle={toggleModal}><label> {modalScreen == "sendTest" ? labels.lblTestSms : ""} </label></ModalHeader>
                        <ModalBody>
                            {getModalContent()}
                        </ModalBody>
                    </Modal>
                </Col>
            </Row>
        </Container>
    )

}

var FacebookCampaignForm = props => {
    // 1. CONST DEFINITIONS    
    const [step, setInternalStep] = useState(1); // 1. Login & Audience Name | 2. Segment Selection | 2. Confirmation
    const [isLogin, setIsLogin] = useState(false);
    const [facebookName, setFacebookName] = useState("");
    const [token, setToken] = useState(null);
    const [modal, setModal] = useState(false);
    const labels = CampaignFacebookCreatorLang(props.lang);
    // const [maxCharacters, setMaxCharacters] = useState(160);
    const [selectionCount, setSelectionCount] = useState(0);
    const [finalSelectionLoaded, setFinalSelectionLoaded] = useState(false);
    const AudienceReducer = (state, action) => {
        switch (action.type) {
            case 'name':
                state.name = action.value;
                return { ...state };
            case 'description':
                state.description = action.value;
                return { ...state };
            case 'segments':
                state.segments = action.value;
                return { ...state };
            default:
                throw new Error();
        }
    }
    const [audience, dispatchAudience] = useReducer(AudienceReducer, {
        name: '',
        description: '',
        segments: [],
    });
    const breadcrumbs = [
        { text: labels.lblStepLogin, function: setInternalStep, functionValue: 1 },
        { text: labels.lblStepSegments, function: setInternalStep, functionValue: 2 },
        { text: labels.lblStepConfirmation, function: setInternalStep, functionValue: 3 },
    ];

    useEffect(() => { ReactTooltip.rebuild() }, [isLogin])



    // 2. Utility Functions ------------------
    var createFormTextInput = (label, explan, controller, required, value, placeholder) => {
        if (required == undefined) { required = false }
        if (value == undefined || value == null) { value = "" }
        if (placeholder == undefined || placeholder == null) { placeholder = "" }
        return (
            <div className="formGroup">
                <label> {label} <span className="requiredLabel" data-for="fbCampaignCreatorForm" data-tip="Campo requerido" data-place="right">{required ? "?" : ""}</span></label>
                <Input type="text" placeholder={placeholder} onChange={e => { controller(e.target.value) }} value={value}></Input>
                <p>{explan}</p>
            </div>
        )
    }

    var setStepCheckingCondition = (nstep) => {
        var bool = false;
        switch (step) {
            case 1: if (audience.name != "" && audience.description != '') { bool = true; setInternalStep(nstep) }; break;
            case 2:
                if (nstep < step) {
                    setInternalStep(nstep);
                }
                else if (true) {
                    bool = true;
                    setInternalStep(nstep);
                }
                ; break;
            case 3:
                if (nstep < step) {
                    setInternalStep(nstep)
                } else if (true) {
                    bool = true;
                    setInternalStep(nstep)
                };
                break;
            case 4:
                if (nstep < step) {
                    setFinalSelectionLoaded(false);
                    setInternalStep(nstep)
                }
                break;
            default: ; break;
        }

        return bool;
    }

    var responseFacebook = (response) => {
        if (response.accessToken != undefined) {
            console.log(response)
            setToken(response.accessToken)
            setFacebookName(response.name)
            setIsLogin(true);
        }
    }

    var toggleModal = () => { setModal(!modal); }


    // 3. CAMPAIGN OBJECT UPDATE FUNCTIONS
    var setAudienceName = (name) => {
        console.log(name)
        dispatchAudience({ type: 'name', value: name });
    }

    var setAudienceDecription = (description) => {
        dispatchAudience({ type: 'description', value: description });
    }

    var setCampaignSegments = (seg, count) => {
        dispatchAudience({ type: 'segments', value: seg });
        setSelectionCount(count)
        setStepCheckingCondition(3);
    }

    //5. Async Functions - API CALLS

    var createFacebookAudience = async () => {
        if (isLogin && token !== null) {
            props.setLoadingStep(true);
            var body = {
                "token": token,
                "segments": audience.segments,
                "name": audience.name,
                "description": audience.description,
            }
            var cacheId = "CreateFacebookAudience-" + props.bucketId + "-" + audience.name
            var res = await props.askFor('campaigns', "createfacebookaudience", [], false, body, cacheId);
            console.log(res)
            if (res !== null) {
                props.changeScreen({ screen: 'list', creationCancel: false });
            }
        }
    }


    //4. PRE-RENDER FUNCTIONS
    var getScreenStep = () => {
        var screen = <></>;

        switch (step) {
            case 1:/* Login & Audience Name */
                screen = <div>
                    {isLogin ?
                        <>
                            <div className="facebookLoginInfo">
                                <h5>Your login as: <b>{facebookName}</b></h5>
                            </div>
                            {createFormTextInput(labels.lblAudienceName, labels.lblAudienceNameExplan, setAudienceName, true, audience.name)}
                            {createFormTextInput(labels.lblAudienceDescription, labels.lblAudienceDescriptionExplan, setAudienceDecription, true, audience.description)}

                            <div className="buttonsRow">
                                {audience.name != "" && audience.description != '' ?
                                    <CSSTransition classNames="animated" timeout={1000} in={true} appear={true}>
                                        <Button onClick={() => { setStepCheckingCondition(2) }}>Siguiente</Button>
                                    </CSSTransition>
                                    :
                                    ""
                                }
                            </div>
                        </>
                        :
                        <div className="facebookContainer">
                            <div className="facebookExplanation">
                                {/* <h4 >Audiencias de Facebook</h4> */}
                                <p>{labels.lblFacebookExplan}</p>

                                <div>
                                    <FacebookLogin
                                        appId="309779753520300"
                                        fields="name,email"
                                        //onClick={componentClicked}
                                        callback={responseFacebook}
                                        icon="fa-facebook"
                                        autoLoad={false}
                                    />
                                </div>

                            </div>
                        </div>
                    }
                </div>
                    ; break;
            case 2:/* Segment Selection */
                screen = <Col lg="12" md="12" sm="12">
                    <Card>
                        <CardBody>
                            <SegmentSelectionForm
                                askFor={props.askFor}
                                exportFunction={setCampaignSegments}
                                loadingSpinner={props.loadingSpinner}
                                selectionCount={selectionCount}
                                lang={props.lang}
                            />
                        </CardBody>
                    </Card>
                </Col>
                    ; break;
            case 3:/* Confirmation */
                screen = <div>
                    <FinalSelectionForm
                        askFor={props.askFor}
                        lang={props.lang}
                        type="facebook"
                        loadingSpinner={props.loadingSpinner}
                        selectionCount={selectionCount}
                        segments={audience.segments}
                        audienceName={audience.name}
                        audienceDescription={audience.description}
                        isLoaded={setFinalSelectionLoaded}
                    />

                    {finalSelectionLoaded ? <Row className="buttonsRow">
                        <Button onClick={toggleModal}>{labels.lblCreateCampaign}</Button>
                    </Row> : ""}
                </div>
                    ; break;
            default: ; break;
        }

        return screen;
    }

    //Render
    return (
        <Container fluid className="facebookCampaignCreatorForm">
            <Row className="stepRow">
                <Breadcrumbs breadcrumps={breadcrumbs} active={step} />
            </Row>
            <Row>
                <Col lg={step === 2 || step === 3 ? "12" : '8'} md="10" sm="12">
                    <ReactTooltip id="fbCampaignCreatorForm" />
                    {getScreenStep()}
                </Col>
            </Row>

            <Modal isOpen={modal} toggle={toggleModal} centered={true} className="emailModal">
                <ModalHeader toggle={toggleModal}></ModalHeader>
                <ModalBody>
                    <div className="confirmation">
                        <h6>La campaña se creará a continuación</h6>
                        <div className="buttonsRow">
                            <Button onClick={() => { createFacebookAudience() }}>{labels.lblAccept}</Button>
                            <Button onClick={toggleModal}>{labels.lblCancel}</Button>
                        </div>
                    </div>
                </ModalBody>
            </Modal>
        </Container>
    )
}

var CampaignForm = props => {

    // 1. Set initial properties
    var labels = props.labels;
    var campaignType = props.campaignType || "email";
    var form = <></>;
    var emailEditorRef = useRef(null);
    const [tab, setTab] = useState("1");
    const [showLoading, setShowLoading] = useState(false);
    const [isFacebookLogIn, setIsFacebookLogIn] = useState(props.isFacebookLogIn);
    const [facebookName, setFacebookName] = useState("");

    //1. Email Functions
    const exportHtml = () => {
        emailEditorRef.current.editor.exportHtml((data) => {
            const { design, html } = data;
            console.log('exportHtml', html);
        });
    };

    // 2. Pre-render functions

    var createFormTextInput = (label, explan, controller, required) => {
        if (required == undefined) { required = false }
        return (
            <div className="formGroup">
                <label> {label} <b>{required ? "*" : ""}</b> </label>
                <Input type="text" onChange={e => { controller(e.target.value) }}></Input>
                <p>{explan}</p>
            </div>
        )
    }

    var createFormTextAreaInput = (label, explan, controller) => {
        return (
            <div className="formGroup">
                <label> {label} </label>
                <Input type="textarea" onChange={e => { controller(e.target.value) }}></Input>
                <p>{explan}</p>
            </div>
        )
    }

    var changeEmailImgSelectionPanel = async (tab) => {
        switch (tab) {
            case "1":
                setTab(tab)
                    ; break;
            case "2":
                setTab(tab)
                    ; break;
        }
    }

    //3. Facebook functions
    var responseFacebook = (response) => {
        if (response.accessToken != undefined) {
            console.log(response)
            props.setIsFacebookLogIn(true, response.accessToken)
            setFacebookName(response.name)
            setIsFacebookLogIn(true);
        }
    }

    // 4. Render Step
    switch (campaignType) {
        case "email": form = <div className="campaignCreatorForm">
            <Col lg="8" md="10" sm="12">
                {createFormTextInput(labels.lblCampaignName, labels.lblCampaignNameExplan, props.setCampaignName, true)}
                {createFormTextInput(labels.lblCampaignSubject, labels.lblCampaignSubjectExplan, props.setCampaignSubject, true)}
                {/* {tab != "2" ?
                        createFormTextInput(labels.lblCallToAction, labels.lblCallToActionExplan, props.setCampaignRedirectUrl)
                        :
                        <div className="formGroup">
                            <label> {labels.lblCallToAction} </label>
                            <p>{labels.lblCallToActionCuponExplan}</p>
                        </div>
                    } */}
                <Container fluid>
                    <Row>
                        <Col>
                            <Nav className="segmentTabs" tabs>
                                <NavItem>
                                    <NavLink onClick={() => changeEmailImgSelectionPanel("1")} className={classnames({ active: tab == "1" })}>{labels.lblCampaigImg}</NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink onClick={() => changeEmailImgSelectionPanel("2")} className={classnames({ active: tab == "2" })}>Seleccionar Cupón</NavLink>
                                </NavItem>
                            </Nav>
                        </Col>
                    </Row>

                    <Row>
                        <Col>
                            <TabContent activeTab={tab}>
                                <TabPane tabId="1" >
                                    <div className="emailEditor">
                                        <EmailEditor
                                            ref={emailEditorRef}
                                        // onLoad={onLoad}
                                        />
                                    </div>
                                    {/* <div className="formGroup">
                                            <Upload labels={labels} setCampaignImg={props.setCampaignImg} />
                                        </div> */}
                                </TabPane>

                                <TabPane tabId="2">
                                    <Card>
                                        <CardBody>
                                            <Cuponera
                                                askFor={props.askFor}
                                                loadingSpinner={props.loadingSpinner}
                                                setCampaignCupon={props.setCampaignCupon}
                                            />
                                        </CardBody>
                                    </Card>
                                </TabPane>
                            </TabContent>
                        </Col>
                    </Row>
                </Container>

                {/* <div className="formGroup sendTestEmail">
                        <label> {labels.lblTestEmail} </label>
                        <div className="inputGroup">
                            {props.testEmailSent ?
                                props.testEmailError ?
                                    <>
                                        <span>Error al enviar el correo. Asegurese de seleccionar un archivo antes de enviar</span>
                                        <button onClick={() => { setShowLoading(false); props.toggleTestEmailSent(false); }}>Enviar otro email</button>
                                    </>
                                    :
                                    <>
                                        <span>Enviado a: {props.testEmail}</span>
                                        <button onClick={() => { setShowLoading(false); props.toggleTestEmailSent(false); }}>Enviar otro email</button>
                                    </>
                                :
                                showLoading ?
                                    <>
                                        {props.loadingSpinner}
                                    </>
                                    :
                                    <>
                                        <Input type="text" onChange={e => { props.setTestEmail(e.target.value) }}></Input>
                                        <button onClick={() => { setShowLoading(true); props.sendTestEmail(); }}>Enviar Email de prueba</button>
                                    </>
                            }

                        </div>
                        {showLoading ? "" : <p>{labels.lblTestEmailExplan}</p>}
                    </div> */}
            </Col>
        </div>; break;
        case "sms": form = <div className="campaignCreatorForm">
            <Col lg="8" md="10" sm="12">
                {createFormTextInput(labels.lblCampaignName, labels.lblCampaignNameExplan, props.setCampaignName)}
                <div className="formGroup">
                    <label> {labels.lblCampaignTextMessage} </label>
                    <Input type="textarea" onChange={e => { props.setCampaignSubject(e.target.value) }}></Input>
                    {props.maxCharacters <= 0 ?
                        <p className="alert">{labels.lblAvailableCharacterAlerts}</p>
                        :
                        <p className="availableCharacters">{labels.lblAvailableCharacter + " " + props.maxCharacters}</p>
                    }
                </div>
                <div className="formGroup sendTestSms">
                    <label> {labels.lblTestPhoneNumber} </label>
                    <div className="inputGroup">
                        {props.testSmsSent ?
                            <>
                                <span>Enviado a: {props.testPhoneNumber}</span>
                                <button onClick={() => { props.toggleTestSmsSent(false) }}>Enviar otro mensaje</button>
                            </>
                            :
                            <>
                                <Input type="text" onChange={e => { props.setTestPhoneNumber(e.target.value) }}></Input>
                                <button onClick={() => { props.sendTestSms() }}>Enviar Sms de prueba</button>
                            </>
                        }

                    </div>

                    <p>{labels.lblTestPhoneNumberExplan}</p>
                </div>
            </Col>
        </div>; break;
        case "facebook": form = <div className="campaignCreatorForm">
            <Col lg="8" md="10" sm="12">
                {isFacebookLogIn ?
                    <>
                        <div className="facebookLoginInfo">
                            <h5>Your login as: <b>{facebookName}</b></h5>
                        </div>
                        {createFormTextInput(labels.lblAudienceName, labels.lblAudienceNameExplan, props.setAudienceName)}
                        {createFormTextInput(labels.lblAudienceDescription, labels.lblAudienceDescriptionExplan, props.setAudienceDecription)}
                    </>
                    :
                    <div className="facebookContainer">
                        <div className="facebookExplanation">
                            {/* <h4 >Audiencias de Facebook</h4> */}
                            <p>{labels.lblFacebookExplan}</p>

                            <div>
                                <FacebookLogin
                                    appId="309779753520300"
                                    fields="name,email"
                                    //onClick={componentClicked}
                                    callback={responseFacebook}
                                    icon="fa-facebook"
                                    autoLoad={true}
                                />
                            </div>

                        </div>
                    </div>
                }


            </Col>
        </div>; break;
    }

    return (form)
}

export {
    SelectCard,
    Cuponera,
    CampaignDatePicker,
    SegmentSelectionForm,
    FinalSelectionForm,
    CampaignForm,
    EmailCampaignForm,
    SmsCampaignForm,
    FacebookCampaignForm,
    Breadcrumbs
}