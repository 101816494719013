import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Container, Row, Button } from 'reactstrap';
import ReactTooltip from "react-tooltip";
import classNames from "classnames";
import { CSSTransition } from "react-transition-group"


var AdminLabels = props => {

    return(
        <div className="adminScreenContent">
            <Container fluid className="campaigns">
                <Row className="titleRow"><h3>Etiquetas</h3></Row>
            </Container>
        </div>
    )

}

export { AdminLabels };