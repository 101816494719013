import React from "react";
import classnames from 'classnames';
import GlobalCounts from "../Dashboard/globalCounts"
import { Route, Switch, Redirect } from "react-router-dom";
import { Transition, CSSTransition, TransitionGroup } from "react-transition-group"
import FetchCmp from "../../components/FetchCmp/fetchCmp"
import Filtros from "./Filtros"
import SegmentDetail from "./SegmentDetail"
import ClientDetail from '../Client/ClientDetail'
import Settings from '../Settings/Settings'
import { SegmentsLang, MyExportsCreatorLang, GlobalCountLang } from '../../lang'
// reactstrap components
import {
  Card,
  CardBody,
  Container,
  Row,
  Col,
  Button,
  FormGroup, Input
} from "reactstrap";
import NumberFormat from "react-number-format";
import { Bar } from "react-chartjs-2";




var SegmentCard = props => {
  var labels = props.labels;
  var data = null;
  var getIcon = (num) => {
    switch (num) {
      case 1:
        return <i className="ti-heart icon" />

      case 2:
        return <i className="ti-money icon" />

      case 3:
        return <i className="ti-location-pin icon" />

      case 4:
        return <i className="ti-announcement icon" />

      case 5:
        return <i className="ti-user icon" />

      default:
        break;
    }
  }

  var getColor = (num) => {
    switch (num) {
      case 1:
        return "segment1"

      case 2:
        return "segment2"

      case 3:
        return "segment3"

      case 4:
        return "segment4"

      case 5:
        return "segment5"

      default:
        break;
    }
  }

  switch (props.segNum) {
    case 1:
      data = props.data.segment1;
      break;
    case 2:
      data = props.data.segment2;
      break;
    case 3:
      data = props.data.segment3;
      break;
    case 4:
      data = props.data.segment4;
      break;
    case 5:
      data = props.data.segment5;
      break;
    default:
      data = props.data.segment1;
      break;
  }

  let styles = { display: 'inline', }

  return (<>
    <Col style={{ marginBottom: "25px" }}>
      <div>
        <Card>
          <CardBody className={"text-center segmentCardHeader " + getColor(props.segNum)}>
            <Row>
              <Col lg="12">
                <div className="text-center">
                  {getIcon(props.segNum)}
                </div>
              </Col>
              <Col lg="12">
                <p>{props.name}</p>
              </Col>
              <Col lg="12">
                <NumberFormat displayType="text" value={data.count} thousandSeparator={true} />
                <p>{labels.lblClients}</p>
              </Col>
            </Row>
          </CardBody>
          <CardBody className="segmentCardBody">
            <div>
              <p>{data.perc_count_fromtotal}%</p>
              <p className="explain" style={styles}>{labels.lblOfClients}</p>
            </div>
            <hr />
            <div >
              <p>{data.perc_purchase_fromtotal}%</p>
              <p className="explain" style={styles}>{labels.lblOfPurchases}</p>
            </div>
            <hr />
            <div>
              {data.tiquete_promedio > 0 ?
                <>
                  <p>
                    <NumberFormat value={data.tiquete_promedio} prefix={props.currencySymbol} displayType={'text'} thousandSeparator={true} />
                  </p>
                </>
                : <b>{labels.lblNoDataAvailable}</b>
              }
              <p className="explain">{labels.lblAvgTicket}</p>
              {/* <p className="explain"><small>{labels.lblPeriod}</small></p> */}
            </div>
            <hr />
            <div className={getColor(props.segNum)}>
              <button className="btnDetail" onClick={() => { props.changeScreen({ screen: 'segmentDetail', value: props.segNum }); props.getSegmentIcon(getIcon(props.segNum), props.name) }}>{labels.btnDetail}</button>
              <button className="btnExport" onClick={() => { props.openExport(props.name, data.count, props.segNum) }}><i className="ti-download"></i> {labels.btnExport}</button>
            </div>
          </CardBody>
        </Card>
      </div>
    </Col>

  </>)
}

var GlobalSummary = props => {
  var labels = GlobalCountLang(props.lang);
  var labelPeriods = props.noPeriods ? labels.lblAvgVisitsYearExplanationWNP : labels.lblAvgVisitsYearExplanation;

  return (
    <Container fluid className="globalSummary">
      <Card>
        <CardBody>
          {!props.loading ?
            <Row>
              <Col lg="4" md="4" sm="12" xs="12">
                {props.showTicketMonthly ?
                  <>
                    <h3 className="value"><NumberFormat value={props.avgTicketPerMonth} thousandSeparator={true} displayType='text' prefix={props.currencySymbol}></NumberFormat></h3>
                    <span className="title">{labels.lblAvgTicket}</span>
                    <p className="explain">{labels.lblAvgTicketPerMonthExplanation}</p>
                  </>

                  :
                  <>
                    <h3 className="value"><NumberFormat value={props.avgTicket} thousandSeparator={true} displayType='text' prefix={props.currencySymbol}></NumberFormat></h3>
                    <span className="title">{labels.lblAvgTicketYear}</span>
                    <p className="explain">{labels.lblAvgTicketPerYearExplanation}</p>
                  </>
                }

              </Col>

              <Col lg="4" md="4" sm="12" xs="12">
                <h3 className="value"><NumberFormat value={props.count} thousandSeparator={true} displayType='text' /></h3>
                <span className="title">{labels.lblClientCount}</span>
                <p className="explain">{labels.lblClientCountExplanation}</p>
              </Col>

              <Col lg="4" md="4" sm="12" xs="12">
                <h3 className="value"><NumberFormat value={props.avgVisit} thousandSeparator={true} displayType='text' /></h3>
                <span className="title">{labels.lblAvgVisitsYear}</span>
                <p className="explain">{labelPeriods}</p>
              </Col>
            </Row>
            :
            <Col>
              {props.loadingSpinner}
              <p className="text-center">{labels.lblLoading}</p>
            </Col>

          }
        </CardBody>
      </Card>
    </Container>
  )
}

class ExportCreator extends React.Component {
  constructor(props) {
    super(props)
    this._isMounted = false;
    this._labels = MyExportsCreatorLang(props.lang);
    this.state = {
      petitionBody: {
        "parameters": {
          "requester": "segment",
          "requestparameters": {
            "matrixcellid": null,
            "selectedSegment": props.segmentId,
            "settings": null,
          },
          "filters": "",
          "textsearch": "",
          "collectionname": "",       // Client Export Only
          "description": ""    // Client Export Only
        },
        "page": 0,
        "sort": ""
      },
      petitionSended: false,
    }
    //Function Binding
    this.handleDescription = this.handleDescription.bind(this);
    this.handleName = this.handleName.bind(this);
    this.saveExport = this.saveExport.bind(this);
  }

  //1. REACT LIFECYCLE --------------------------
  componentDidMount() {
    this._isMounted = true;
    this.setState(state => {
      let body = state.petitionBody;
      if (this.props.filters.length > 0) {
        body.parameters.filters = this.props.filters;
      }
      else {
        body.parameters.filters = "";
      }
      return ({ petitionBody: body })
    })
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.filters != this.props.filters) {
      if (this._isMounted) {
        this.setState(state => {
          let body = state.petitionBody;
          if (this.props.filters.length > 0) {
            body.parameters.filters = this.props.filters;
          }
          else {
            body.parameters.filters = "";
          }
          return ({ petitionBody: body })
        })
      }
    }

    if (prevProps.lang != this.props.lang) {
      if (this._isMounted) {
        this._labels = MyExportsCreatorLang(this.props.lang);
        this.forceUpdate();
      }
    }
  }

  componentWillMount() {
    this._isMounted = false;
  }


  //2.DATA HANDLERS
  handleName(name) {
    if (this._isMounted) {
      this.setState(state => {
        let body = state.petitionBody;
        body.parameters.collectionname = name;
        return ({ petitionBody: body })
      })
    }
  }

  handleDescription(desc) {
    if (this._isMounted) {
      this.setState(state => {
        let body = state.petitionBody;
        body.parameters.description = desc;
        return ({ petitionBody: body })
      })
    }
  }


  //3.SAVE EXPORT FUNCTION
  async saveExport() {
    try {
      let cacheId = 'SaveExport' + this.state.petitionBody.parameters.collectionname + this.state.petitionBody.parameters.page;
      let data = await this.props.askFor('crm', 'crm_bucket/clients/export/create', [], false, this.state.petitionBody, cacheId);
      if (this._isMounted && data != null) {
        this.setState({ petitionSended: true })
      }
    } catch (err) {
      console.log(err);
    }
  }


  //4. RENDER -------------------------- -------------------------- --------------------------
  render() {
    if (this.state.petitionSended) {
      //Redirects to My exports section
      return (<Redirect to="/myexports" />)
    }
    else {
      //Default
      return (<Container fluid className="exportCreator">
        <Card>
          <CardBody>
            <Row>{/* Title and explanation */}
              <Col>
                <h4>{this._labels.lblTitle}</h4>
                <p>{this._labels.lblExplain}</p>
              </Col>
            </Row>
            <Row>{/* Name and count */}
              <Col>
                <h4>{this.props.segmentName || 'Nombre'}</h4>
                <p>{this._labels.lblClientsToExport}: <b>{this.props.count || 0}</b> </p>
              </Col>
            </Row>
            <Row>{/* Inputs */}
              <Col>
                <FormGroup>
                  <Input type="text" placeholder={this._labels.lblNamePlaceHolder} onChange={e => this.handleName(e.target.value)} />
                  <Input type="textarea" placeholder={this._labels.lblDescPlaceholder} onChange={e => this.handleDescription(e.target.value)} />
                </FormGroup>
              </Col>
            </Row>
            <Row>{/* Buttons */}
              <Col>
                <Row>
                  <Col>
                    <button className="d-inline" onClick={() => { this.props.closeExport() }}>{this._labels.lblButtonCancel}</button>
                    <button className="d-inline" onClick={() => { this.saveExport() }}><i className="ti-download" />  {this._labels.lblButtonExport}</button>
                  </Col>
                </Row>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Container>)
    }

  }
}


class SegmentBase extends React.Component {
  constructor(props) {
    super(props);
    this._isMounted = false;
    this.segmentsD = null;
    this.state = {
      screen: 'segments',
      segmentsAvailable: false,
      segmentData: null,
      segmentDetail: null,
      segmentIcon: null,
      segmentId: null,
      filters: [],
      openClientDetail: false,
      openSegmentExport: false,
      openRFMatrix: false,
      openFilterbar: false,
      showDarkPanel: false,
      isBusy: true,
      clientId: null,
      matrixData: null,
      settingsData: null,
      globalSumData: null,
      exportSegment: { name: null, count: null, id: null },

    };
    this.labels = SegmentsLang(props.lang);
    this.filterLabels = this.props.getLabels('Filters');
    this.selectSegmentDetail = this.selectSegmentDetail.bind(this);
    this.toggleSegmentExport = this.toggleSegmentExport.bind(this)
    this.changeSegment = this.changeSegment.bind(this);
    this.getSegmentIcon = this.getSegmentIcon.bind(this);
    this.openRFMatriz = this.openRFMatriz.bind(this);
    this.getFilterBar = this.getFilterBar.bind(this);
    this.getMainScreen = this.getMainScreen.bind(this);
    this.changeScreen = this.changeScreen.bind(this);
    this.toggleFilterBar = this.toggleFilterBar.bind(this);
    this.toggleFilterSelectorFocus = this.toggleFilterSelectorFocus.bind(this);
    this.applyFilters = this.applyFilters.bind(this);
  }

  //0. REACT LIFECYCLE FUNCTIIONS (API CALLS)---------------------------------------------------------------------------------------------------------
  async componentDidMount() {
    this._isMounted = true;
    //this.props.scrollToTop();
    try {
      this.setState({ isBusy: true });
      var settingsData = await this.props.askFor('segments', 'segments/getfrmconfig');
      var summaryData = await this.props.askFor('globalsum', 'global/globalcountsummary');
      var data = await this.props.askFor('segments', 'segments/getbasedata');
      if (this._isMounted && settingsData != null && summaryData != null && data != null) {
        this.setState({
          segmentsAvailable: true,
          segmentData: data.segments,
          isBusy: false,
          matrixData: data.matrixcells,
          settingsData: settingsData,
          globalSumData: summaryData
        });
      }
    }
    catch (err) {
      console.log(err);
    }
  }

  async componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevState.filters != this.state.filters) {
      try {
        this.setState({ isBusy: true, segmentData: null });
        var data = await this.props.askFor('segments', 'segments/getbasedata', this.state.filters);
        if (data != null) {
          if (this.state.segmentDetail != null) {
            let dat = null;
            switch (this.state.segmentDetail) {
              case 1: dat = data.segments.segment1; break;
              case 2: dat = data.segments.segment2; break;
              case 3: dat = data.segments.segment3; break;
              case 4: dat = data.segments.segment4; break;
              case 5: dat = data.segments.segment5; break;
              default:
                break;
            }
            if (this._isMounted) {
              this.setState({ segmentData: dat, isBusy: false });
            }
          }
          else {
            if (this._isMounted) {
              this.setState(() => ({ segmentData: data.segments, isBusy: false }));
            }
          }
        }


      }
      catch (err) {
        console.log(err);
      }
    }

    if (prevState.segmentData != this.state.segmentData) {
      if (this.state.segmentData === null) {
        try {
          this.setState({ isBusy: true });
          var settingsData = await this.props.askFor('segments', 'segments/getfrmconfig');
          var data = await this.props.askFor('segments', 'segments/getbasedata', this.state.filters);
          if (settingsData != null && data != null) {
            if (this.state.segmentDetail != null) {
              let dat = null;
              switch (this.state.segmentDetail) {
                case 1: dat = data.segments.segment1; break;
                case 2: dat = data.segments.segment2; break;
                case 3: dat = data.segments.segment3; break;
                case 4: dat = data.segments.segment4; break;
                case 5: dat = data.segments.segment5; break;
                default: dat = data.segments.segment1
                  break;
              }
              this.setState({ segmentData: dat, isBusy: false, matrixData: data.matrixcells, settingsData: settingsData });
            }
            else {
              this.setState(() => ({ segmentData: data.segments, isBusy: false, matrixData: data.matrixcells, settingsData: settingsData }));
            }
          }
        }
        catch (err) {
          console.log(err);
        }
      }

    }

    if (prevProps.lang != this.props.lang) {
      this.labels = SegmentsLang(this.props.lang);
      if (this._isMounted) {
        this.forceUpdate();
      }
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }


  //1. UTILITY FUNCTIONS ------------------------------------------------------------------------------------------------------------------------------

  applyFilters = (filter) => {
    this.setState({ filters: filter })
  }

  openClientDetail(clientId) {
    this.setState({ openClientDetail: true, clientId: clientId })
  }

  closeClientDetail() {
    this.setState({ openClientDetail: false, clientId: null })
  }

  //2. NAVIGATION FUNCTIONS ---------------------------------------------------------------------------------------------------------
  toggleFilterSelectorFocus = (bool) => {
    if (this._isMounted) {
      this.setState({ showDarkPanel: bool });
    }
  }

  toggleFilterBar = bool => {
    if (this._isMounted) {
      this.setState({ openFilterbar: bool });
    }
  }

  selectSegmentDetail(num) {
    var dat = null
    switch (num) {
      case 1:
        dat = this.state.segmentData.segment1;
        break;
      case 2:
        dat = this.state.segmentData.segment2;
        break;
      case 3:
        dat = this.state.segmentData.segment3;
        break;
      case 4:
        dat = this.state.segmentData.segment4;
        break;
      case 5:
        dat = this.state.segmentData.segment5;
        break;
      default: dat = null;
        break;
    }
    return dat;
  }

  closeSegmenDetail() {
    this.setState({ segmentDetail: null, segmentData: null });
  }

  changeSegment(num) {
    this.setState({ segmentData: null, segmentDetail: num });
    this.changeSegmentIcon(num);
  }

  toggleSegmentExport(name, count, id) {
    if (name == undefined) { name = "" }
    if (count == undefined) { count = 0 }
    if (id == undefined) { id = 1 }
    if (this._isMounted) {
      this.setState(state => {
        return ({ openSegmentExport: !state.openSegmentExport, exportSegment: { name: name, count: count, id: id } })
      })
    }
  }

  openRFMatriz(bool) {
    if (this._isMounted && this.state.segmentData != null) {
      this.setState({ openRFMatrix: bool });
    }
    else {
      console.log('No existen datos para constriuir la matriz o el componente no se ha montado')
    }
  }

  //3. PRE-RENDER FUNCTIONS ---------------------------------------------------------------------------------------------------------
  getSegmentIcon(obj, name) {
    this.setState({ segmentIcon: obj, segmentName: name });
  }

  changeSegmentIcon(num) {
    var icon = null;
    var name = "";
    switch (num) {
      case 1: icon = <i className="tim-icons icon-heart-2 iconBig" />; name = "A Retener"; break;
      case 2: icon = <i className="tim-icons ti-money" style={{ fontSize: "3em" }} />; name = "Incrementar Monto"; break;
      case 3: icon = <i className="tim-icons icon-square-pin iconBig" />; name = "Incrementar Frecuencia"; break;
      case 4: icon = <i className="tim-icons icon-volume-98 iconBig" />; name = "Traer de Regreso"; break;
      case 5: icon = <i className="tim-icons icon-user-run iconBig" />; name = "Ocasionales"; break;

      default: break;
    }

    this.setState({ segmentIcon: icon, segmentName: name })
  }

  changeScreen(values) {
    //In case of the ClientDetail and SegmentDetail screens, the app needs a value to retrieve the correct data.
    //The value property refers said value, and in the case you select this screens, this porperty is required.
    var screen = values.screen;
    var value = values.value;
    if (screen == null || screen == undefined || typeof screen != "string") { return }

    if (this._isMounted && this.state.segmentData != null) {
      switch (screen) {
        case 'segments':
          this.setState({ screen: screen, segmentDetail: null, segmentData: null, detailTab: null });;
          break;
        case 'segmentDetail':
          var selectedSegment = this.selectSegmentDetail(value);
          if (this.state.screen == "clientDetail") {
            this.setState({ screen: screen, segmentDetail: value, detailTab: "2" });
          }
          else {
            this.setState({ screen: screen, segmentDetail: value, segmentData: selectedSegment, detailTab: null });
          }
          break;
        case 'clientDetail':
          this.setState({ screen: screen, clientId: value, detailTab: null })
          break;
        case 'rfmMatriz':
          this.setState({ screen: screen, detailTab: null });
          break;
      }
    }
  }

  getFilterBar() {
    var bar = <></>;

    if (this.state.screen == 'segments' || this.state.screen == "segmentDetail") {
      bar = <>
        <CSSTransition classNames="animated" timeout={1000} in={this.state.openFilterbar}>
          <Row className="topFiltros" hidden={!this.state.openFilterbar}>
            <Filtros
              filters={this.state.filters}
              ageBrackets={this.props.ageBrackets}
              clasificationlist={this.props.clasificationlist}
              genderSeparation={this.props.genderSeparation}
              lang={this.props.lang}
              getCatDat={this.props.getCatDat}
              toggleFilterSelectorFocus={this.toggleFilterSelectorFocus}
              toogleFilterBar={this.toggleFilterBar}
              applyFilters={this.applyFilters}
              isSectionHidden={this.props.isSectionHidden}
            />
          </Row>
        </CSSTransition>
      </>
    }

    return bar;
  }

  getMainScreen() {
    var screen = null

    switch (this.state.screen) {
      case 'segments': screen =
        <>
          <Row className="segmentTitleRow">
            <Col className="title" lg="4" md="4" sm="4">
              <h1>Segmentos</h1>
              <button className="btnOpenRfmMatriz" onClick={() => { this.changeScreen({ screen: 'rfmMatriz' }) }}>{<i className="ti-eye"></i>} Matriz RFM</button>
            </Col>
            <Col className="addFilterButtonRow" lg="8" md="8" sm="8">
              <Button hidden={this.state.openFilterbar} onClick={() => { this.toggleFilterBar(true) }}><i className="ti-filter" /> Agregar Filtro</Button>
            </Col>
            <Col lg="12" md="12" sm="12">
              {this.getFilterBar()}
            </Col>
          </Row>
          <Row>
            {this.state.globalSumData == null ?
              <GlobalSummary
                loading
                loadingSpinner={this.props.loadingSpinner}
                lang={this.props.lang}
                theme={this.props.bucket.theme}
              />
              :
              this.props.bucket.additionalConfig.UseAvgPurchasePerUser_WithNoPeriod ?
                <GlobalSummary
                  count={this.state.globalSumData.count}
                  avgVisit={this.state.globalSumData.avg_visitsperyear_withoutperiods}
                  avgTicket={this.state.globalSumData.avg_ticketyear}
                  avgTicketPerMonth={this.state.globalSumData.avg_ticketpermonth}
                  lang={this.props.lang}
                  currencySymbol={this.props.bucket.currencySymbol}
                  showTicketMonthly={!this.props.isSectionHidden("monthlyAvgTicket")}
                  noPeriods
                />
                :
                <GlobalSummary
                  count={this.state.globalSumData.count}
                  avgVisit={this.state.globalSumData.avg_visitsperyear}
                  avgTicket={this.state.globalSumData.avg_ticketyear}
                  avgTicketPerMonth={this.state.globalSumData.avg_ticketpermonth}
                  lang={this.props.lang}
                  currencySymbol={this.props.bucket.currencySymbol}
                  showTicketMonthly={!this.props.isSectionHidden("monthlyAvgTicket")}
                />
            }
          </Row>
          <Row>
            {this.state.segmentData != null ?
              <>
                <SegmentCard name={this.labels.lblSegment1} data={this.state.segmentData} segNum={1} labels={this.labels} currencySymbol={this.props.currencySymbol} openExport={this.toggleSegmentExport} changeScreen={this.changeScreen} getSegmentIcon={this.getSegmentIcon} />
                <SegmentCard name={this.labels.lblSegment2} data={this.state.segmentData} segNum={2} labels={this.labels} currencySymbol={this.props.currencySymbol} openExport={this.toggleSegmentExport} changeScreen={this.changeScreen} getSegmentIcon={this.getSegmentIcon} />
                <SegmentCard name={this.labels.lblSegment3} data={this.state.segmentData} segNum={3} labels={this.labels} currencySymbol={this.props.currencySymbol} openExport={this.toggleSegmentExport} changeScreen={this.changeScreen} getSegmentIcon={this.getSegmentIcon} />
                <SegmentCard name={this.labels.lblSegment4} data={this.state.segmentData} segNum={4} labels={this.labels} currencySymbol={this.props.currencySymbol} openExport={this.toggleSegmentExport} changeScreen={this.changeScreen} getSegmentIcon={this.getSegmentIcon} />
                <SegmentCard name={this.labels.lblSegment5} data={this.state.segmentData} segNum={5} labels={this.labels} currencySymbol={this.props.currencySymbol} openExport={this.toggleSegmentExport} changeScreen={this.changeScreen} getSegmentIcon={this.getSegmentIcon} />
              </>
              :
              <Col className="text-center">
                {this.props.loadingSpinner}
                <h3>Espere por favor...</h3>
              </Col>
            }
          </Row>
        </>
        ; break;
      case 'segmentDetail': screen =
        <SegmentDetail
          askFor={this.props.askFor}
          getCatDat={this.props.getCatDat}
          isFieldHidden={this.props.isFieldHidden}
          isSectionHidden={this.props.isSectionHidden}
          bucket={this.props.bucket}
          currencySymbol={this.props.currencySymbol}
          lang={this.props.lang}
          getBucketLabels={this.props.getLabels}
          segment={this.state.segmentDetail}
          name={this.state.segmentName}
          icon={this.state.segmentIcon}
          data={this.state.segmentData}
          filters={this.state.filters}
          changeSegment={this.changeSegment}
          changeScreen={this.changeScreen}
          openClientDetail={this.openClientDetail}
          toggleFilterSelectorFocus={this.toggleFilterSelectorFocus}
          toggleFilterBar={this.toggleFilterBar}
          applyFilters={this.applyFilters}
          openFilterBar={this.state.openFilterbar}
          loadingSpinner={this.props.loadingSpinner}
          tab={this.state.detailTab}
        />; break;
      case 'clientDetail': screen =
        <ClientDetail
          askFor={this.props.askFor}
          clientId={this.state.clientId}
          currencySymbol={this.props.currencySymbol}
          lang={this.props.lang}
          loadingSpinner={this.props.loadingSpinner}
          backFunction={this.changeScreen}
          backFunctionValues={{ screen: 'segmentDetail', value: this.state.segmentDetail }}
          theme={this.props.bucket.theme}
          hideRedeem={this.props.isSectionHidden('clientdetails_redeemed_points_permonth')}
          isFieldHidden= {this.props.isFieldHidden}
          isSectionHidden= {this.props.isSectionHidden}
          getBucketLabels={this.props.getLabels}
          
        />; break;
      case 'rfmMatriz': screen =
        <Settings
          askFor={this.props.askFor}
          resetCache={this.props.resetCache}
          rfmMatrixData={this.state.matrixData}
          settingsData={this.state.settingsData}
          currencySymbol={this.props.currencySymbol}
          lang={this.props.lang}
          changeScreen={this.changeScreen}
          loadingSpinner={this.props.loadingSpinner}
          isSectionHidden={this.props.isSectionHidden}
          isFieldHidden= {this.props.isFieldHidden}
          bucket={this.props.bucket}
        />
        ; break;
      default: ; break;
    }

    return screen;
  }

  //4. RENDER ------------------------------------------------------------------------------------------------------------------------
  render() {
    return (
      <div className="content">
        {/* {Animacion de pantalla negra para hacer focus en la barra de filtros} */}
        <CSSTransition in={this.state.showDarkPanel} classNames="darkPanelT" timeout={500}>
          <div className="darkPanel" hidden={!this.state.showDarkPanel} onClick={() => { this.toggleFilterSelectorFocus(false) }}></div>
        </CSSTransition>

        {/* Main Content */}
        <Container fluid className={classnames({ "segmentDashboard": true, "blur": this.state.openSegmentExport })}>
          {this.getMainScreen()}
        </Container>

        {/* Export Component */}
        {this.state.openSegmentExport ?
          <CSSTransition classNames="animated" timeout={500} in={true}>
            <ExportCreator
              askFor={this.props.askFor}
              lang={this.props.lang}
              closeExport={this.toggleSegmentExport}
              segmentName={this.state.exportSegment.name}
              count={this.state.exportSegment.count}
              segmentId={this.state.exportSegment.id}
              filters={this.state.filters}
            />
          </CSSTransition>
          : ""}
      </div>
    )


  }
}

var Segments = p => {
  return (<>
    <FetchCmp
      render={props =>
        <SegmentBase
          getBucketLabels={props.getLabels}
          getCatDat={props.getCatDat}
          askFor={props.askFor}
          resetCache={props.resetCache}
          getLabels={props.getLabels}
          lang={props.lang}
          isSectionHidden={props.isSectionHidden}
          clasificationlist={props.bucket.clasificationlist}
          ageBrackets={props.bucket.ageBrackets}
          genderSeparation={props.bucket.genderSeparation}
          currencySymbol={props.bucket.currencySymbol}
          bucket={props.bucket}
          isFieldHidden={props.isFieldHidden}
          scrollToTop={props.scrollToTop}
          loadingSpinner={props.loadingSpinner}
        />}
    />
  </>)
}

export default Segments;
