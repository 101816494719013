//ESTA CLASE CONSTRUYE LAS ETIQUETAS EN AMBOS IDIOMAS PARA LA APLICACION
//CADA METODO DEVUELVE LAS ETIQUETAS NECESARIAS PARA CIERTO COMPONENTE, ESTA CLASE ESTA DIVIDIDA POR PANTALLAS LAS CUALES SON
// 0. MENU
// 1. DASHBOARD
// 2. VENTAS
// 3. SEGMENTOS
// 4. CRM
// 5. FILTROS
// 6. EXPORTS
// 7. OPCIONES
// 8. SEGMENTOS BAJO ESTUDIO / KPIS
// 9. ESTADISTICAS
// ---------------------------------------------------------------------------------------------------------------------------------
//----------------------------------------------------------------------------------------------------------------------------------



// 0. MENU / BUCKET SELECTOR / LOGIN -------------------------------------------------------------------------------------------------------------------------

var MenuLang = (lang) => {
    var r = {};
    let resources = {
        'menuDashboard': { 'en': 'Dashboard', 'es': 'Dashboard' },
        'menuSalesForce': { 'en': 'Sales Force', 'es': 'Ventas' },
        'menuSegments': { 'en': 'Segments', 'es': 'Segmentos' },
        'menuCrm': { 'en': 'CRM', 'es': 'CRM' },
        'menuFilters': { 'en': 'My Segmets', 'es': 'Mis segmentos' },
        //'menuFilters': { 'en': 'Filters', 'es': 'Filtros' },
        'menuOptions': { 'en': 'Options', 'es': 'Opciones' },
        'menuMyExports': { 'en': 'My "Exports"', 'es': 'Mis "Exports"' },
        'menuEditExport': { 'en': 'Edit Export', 'es': 'Editar Export' },
        'menuRedemptions': { 'en': 'Redemptions', 'es': 'Canjes' },
        'menuCampaigns': { 'en': 'Campaigns', 'es': 'Campañas' },
        'lblTimeOut': { 'en': 'Your session expired.', 'es': 'Su sesión expiró.' },
        'lblTimeOutTitle': { 'en': 'Expired Session', 'es': 'Sesión Expirada' },
        'lblTimingOut': { 'en': "Session will expire in ", 'es': 'La sesión cerrará en ' },
        'lblSeconds': { 'en': 'seconds', 'es': 'segundos' },
        'menuRedemptionList': { 'en': 'Redemptions', 'es': 'Canjes' },
        'menuKpis': { 'en': 'Segments Under Research', 'es': 'Segmentos Bajo Estudio' },
        'menuStats': { 'en': 'Stas', 'es': 'Estadísticas' }
    };

    for (const l in resources) {
        switch (lang) {
            case "es":
                r[l] = resources[l].es;
                break;

            case "en":
                r[l] = resources[l].en;
                break;

            default:

                break;
        }
    }

    return r;

}

var BucketSelectorLang = lang => {
    var r = [];
    let resources = {
        lblSearch: { es: 'Buscar', en: 'Search' },
        lblSearchPlaceHolder: { es: 'Ingrese el nombre o ID del bucket', en: 'Type the BucketId or name' },
        btnUseBucket: { es: 'Seleccionar Bucket', en: 'Select Bucket' },
        lblAvailableBuckets: { es: 'Buckets Disponibles', en: 'Available Buckets' },
        lblBucketRegion: { es: 'Región', en: 'Region' },
        lblBucketName: { es: 'Nombre del Bucket', en: 'Bucket Name' },
        lblLoading: { es: 'Cargando Buckets...', en: 'loading Buckets...' }
    };

    for (const l in resources) {
        switch (lang) {
            case "es":
                r[l] = resources[l].es;
                break;

            case "en":
                r[l] = resources[l].en;
                break;

            default:

                break;
        }
    }

    return r;
}

var NoBucketLang = lang => {
    var r = [];
    let resources = {
        lblTitle: { es: 'No tiene nungun bucket seleccionado', en: 'Theres no bucket selected' },
        lblSubtitle: { es: 'Por favor elija un bucket de la lista de la esquina superior derecha', en: 'Please select a bucket from the button in the rigth corner' },
    };

    for (const l in resources) {
        switch (lang) {
            case "es":
                r[l] = resources[l].es;
                break;

            case "en":
                r[l] = resources[l].en;
                break;

            default:

                break;
        }
    }

    return r;
}



// 1. DASHBOARD --------------------------------------------------------------------------------------------------------------------

var GlobalCountLang = (lang) => {
    var resources = {
        'lblTitle': 'Global Summary',
        'lblSubTitle': 'Summary that reflects the total population of the database.',
        'lblClientCount': 'Client Count',
        'lblAvgTicket': 'Average Ticket (monthly)',
        'lblAvgTicketYear': 'Average Ticket',
        'lblAvgVisitsYear': 'Avg. Transactions per Year',
        'lblPopUpTitle': 'Ready!',
        'lblPopUpMessage': 'The information was loaded successfully.',
        'lblAvgVisitsYearExplanation': '(Client count / Transaction count) * Analized periods',
        'lblAvgVisitsYearExplanationWNP': 'Client count / Transaction count',
        'lblClientCountExplanation': 'Total client count',
        'lblAvgTicketPerMonthExplanation': 'Total purchase amount / Transaction count',
        'lblAvgTicketPerYearExplanation': 'Total purchase amount / Transaction count',
        'lblSales': 'Sales',
        'lblSalesExplanation': 'Total purchase amount',
        'lblVisits': 'Transactions',
        'lblVisitsExplanation': 'Transaction count',
        'lblLoading': 'Loading...'
    };

    var recursos = {
        'lblTitle': 'Resumen Global',
        'lblSubTitle': 'Este resumen refleja la totalidad de la población de la base de datos.',
        'lblClientCount': 'Conteo Clientes',
        'lblAvgTicket': 'Ticket Promedio (por mes)',
        'lblAvgTicketYear': 'Ticket Promedio',
        'lblAvgVisitsYear': 'Prom. Transacciones por cliente',
        'lblPopUpTitle': 'Listo!',
        'lblPopUpMessage': 'Se cargó la información correctamente.',
        'lblAvgVisitsYearExplanation': '(Conteo de Transacciones / Conteo de los Clientes) * Periodos analizados',
        'lblAvgVisitsYearExplanationWNP': 'Conteo de Transacciones / Conteo de los Clientes',
        'lblClientCountExplanation': 'Conteo total de clientes',
        'lblAvgTicketPerMonthExplanation': '(Total monto compras / Conteo de transacciones ) / (Meses en el periodo)',
        'lblAvgTicketPerYearExplanation': 'Total monto compras / Conteo de transacciones ',
        'lblSales': 'Compras',
        'lblSalesExplanation': 'Monto total de compras',
        'lblVisits': 'Transacciones',
        'lblVisitsExplanation': 'Conteo de transacciones',
        'lblLoading': 'Cargando...'
    };

    if (lang === "es") {
        return recursos;
    } else {
        return resources;
    }
}

var GlobalPointsSummaryLang = (lang) => {

    var resources = {
        'lblTitle': 'Global Point Summary',
        'lblSubTitle': '',
        'lblBonusPoints': 'Bonus Points',
        'lblRedemeedPoints': 'Redeemed Points',
        'lblAvailablePoints': 'Available Points',
        'lblExpiredPoints': 'Expired Points',
        'lblSalesPoints': 'Sales Points',
        'lblLoading': 'Loading...',
    };

    var recursos = {
        'lblTitle': 'Resumen Global de Millas',
        'lblSubTitle': '',
        'lblBonusPoints': 'Millas Bonos',
        'lblRedemeedPoints': 'Millas Redimidas',
        'lblAvailablePoints': 'Millas Acumuladas',
        'lblExpiredPoints': 'Millas Expiradas',
        'lblSalesPoints': 'Millas por Ventas',
        'lblLoading': 'Cargando...',
    };

    if (lang === 'es') {
        return recursos;
    }
    else {
        return resources;
    }
}

var CountsByGenderLang = (lang) => {
    var resources = {
        'lblUnifiedTitle': 'Clients',
        'lblUnifiedTopTitle': 'Global Summary (Demographics)',
        'lblUnifiedTopSubTitle': 'Summary reflects the total population of the database.',
        'lblTitle': 'Global Summary by Gender',
        'lblSubTitle': 'Summary of the population segregated by gender.',
        'lblMenTitle': 'Men',
        'lblAvgAge': 'Avg. Age',
        'lblAvgTicket': 'Average Ticket',
        'btnExport': 'Export',
        'lblWomenTitle': 'Women',
        'lblPopUpTitle': 'Ready!',
        'lblPopUpMessage': 'The information was loaded successfully.',
        'lblPeriod': '(Annual)',
        'lblFChartTitle': 'Age Brackets',
        'lblFChartSubTitle': '(Count)',
        'lblMChartTitle': 'Age Brackets',
        'lblMChartSubTitle': '(Count)',

    };

    var recursos = {
        'lblUnifiedTitle': 'Clientes',
        'lblUnifiedTopTitle': 'Resumen Global (Demográficos)',
        'lblUnifiedTopSubTitle': 'Este resumen refleja la totalidad de la población de la base de datos.',
        'lblTitle': 'Resumen Global por Género',
        'lblSubTitle': 'Resumen de la población segmentada por género.',
        'lblMenTitle': 'Hombres',
        'lblAvgAge': 'Edad Promedio',
        'lblAvgTicket': 'Ticket Promedio',
        'btnExport': 'Exportar',
        'lblWomenTitle': 'Mujeres',
        'lblPopUpTitle': 'Listo!',
        'lblPopUpMessage': 'Se cargó la información correctamente.',
        'lblPeriod': '(Anual)',
        'lblFChartTitle': 'Rango de Edad',
        'lblFChartSubTitle': '(Conteo)',
        'lblMChartTitle': 'Rango de Edad',
        'lblMChartSubTitle': '(Conteo)',

    };

    if (lang === 'es') {
        return recursos;
    } else {
        return resources;
    }

}

var AgeBracketsLang = (lang) => {

    var resources = {
        'cardHeader': 'Age Brackets',
        'cardSubHeader': 'Birthdate years included within the age brackets.',
        'headerName': 'Age Bracket',
        'headerStartYear': 'From',
        'headerEndYear': 'Until'
    };

    var recursos = {
        'cardHeader': 'Rangos de Edad',
        'cardSubHeader': 'Años de nacimiento incluídos por rango de edad',
        'headerName': 'Rango de Edad',
        'headerStartYear': 'Desde',
        'headerEndYear': 'Hasta'
    };

    if (lang === "es") {
        return recursos;
    }
    else {
        return resources;
    }

}

var ProductsByGenderLang = (lang) => {

    let resources = {
        'lblTitleF': 'Favorite Stores (Women)',
        'lblTitleM': 'Favorite Stores (Men)',
        'lblCount': 'Count',
        'lblBestSeller': 'Favorite Store',
        'lblVisitCounter': 'Visit Count',
        'lblLoading': 'Loading...',
    };

    let recursos = {
        'lblTitleF': 'Productos Favoritos (Mujeres)',
        'lblTitleM': 'Productos Favoritos (Hombres)',
        'lblCount': 'Conteo',
        'lblBestSeller': 'Producto Favorito',
        'lblVisitCounter': 'Conteo de transacciones',
        'lblLoading': 'Cargando...',
    };

    if (lang === 'es') {
        return recursos;
    }
    else {
        return resources;
    }

}

var ProcessingPeriodsLang = (lang) => {
    var resources = {
        'lblTitle': 'Analysis Period'
    };

    var recursos = {
        'lblTitle': 'Período del Análisis'
    };

    if (lang === 'en') {
        return resources;
    }
    else {
        return recursos;
    }

}


// 2. SEGMENTS ----------------------------------------------------------------------------------------------------------------------
var SegmentsLang = (lang) => {
    let resources = {
        'lblTitle': 'Segments',
        'lblConfig': 'Config RFM',
        'lblClients': 'Clients',
        'lblSegment1': 'Retain',
        'lblSegment2': 'Increment Amount',
        'lblSegment3': 'Increment Freq.',
        'lblSegment4': 'Bring Back',
        'lblSegment5': 'Occasionals',
        'lblOfClients': 'Percentage of Clients',
        'lblOfPurchases': 'Percentage of Purchases',
        'lblAvgTicket': 'Average Ticket',
        'btnExport': 'Export',
        'btnDetail': 'View Detail',
        'lblPleaseWait': 'Please wait...',
        'lblNoDataAvailable': 'No Data.',
        'lblErrorFound': 'Error found.',
        'lblPeriod': '(Annual)',
        'lblVisitCounter': 'Visit Count',
    };

    let recursos = {
        'lblTitle': 'Segmentos',
        'lblConfig': 'Configuración del RFM',
        'lblClients': 'Clientes',
        'lblSegment1': 'A Retener',
        'lblSegment2': 'Incrementar M.',
        'lblSegment3': 'Incrementar F.',
        'lblSegment4': 'Traer de Regreso',
        'lblSegment5': 'Ocasionales',
        'lblOfClients': 'Porcentaje de Clientes',
        'lblOfPurchases': 'Porcentaje de Compras',
        'lblAvgTicket': 'Ticket Promedio',
        'btnExport': 'Exportar',
        'btnDetail': 'Ver Detalle',
        'lblPleaseWait': 'Por favor espere...',
        'lblNoDataAvailable': 'No hay datos.',
        'lblErrorFound': 'Ocurrió un problema.',
        'lblPeriod': '(Anual)',
        'lblVisitCounter': 'Conteo de transacciones',
    };

    if (lang === 'es') {
        return recursos;
    } else {
        return resources;
    }

}

var FiltersLang = (lang) => {
    var r = {};
    let resources = {
        'lblApply': { 'en': 'Apply', 'es': 'Aplicar' },
        'lblDemographicTitle': { 'en': 'Demographics', 'es': 'Filtros Demográficos' },
        'lblGenderTitle': { 'en': 'Gender', 'es': 'Género' },
        'lblMenGender': { 'en': 'Men', 'es': 'Hombres' },
        'lblWomenGender': { 'en': 'Women', 'es': 'Mujeres' },
        'lblAgeRangeTitle': { 'en': 'Age Range', 'es': 'Rango de Edad' },
        'lblMonthsTitle': { 'en': 'Months', 'es': 'Meses' },
        'lblCatTitle': { 'en': 'Category', 'es': 'Categoría' },
        'lblAntiquity': { 'en': 'Program Lifetime', 'es': 'Antigüedad' },
        'lblCategorizationTitle': { 'en': 'Categorization', 'es': 'Categorización' },
        'btnAddFilter': { 'en': 'Add categorization filter', 'es': 'Agregar filtro por categorización' },
        'lblYes': { 'en': 'Yes', 'es': 'Si' },
        'lblNo': { 'en': 'No', 'es': 'No' },
        'lblAll': { 'en': 'ALL', 'es': 'TODOS' },
        'lblSim': { 'en': 'SIMILARITY', 'es': 'SIMILARES' },
        'lblPred': { 'en': 'PREDICTION', 'es': 'PREDICCION' },
        'lblSto': { 'en': 'STORES', 'es': 'TIENDAS' },
        'lblNofilters': { 'en': 'No selected filters.', 'es': 'No hay filtros seleccionados.' },
        'lblStoresListTitle': { 'en': 'Stores', 'es': 'Tiendas' },
        'btnStoresList': { 'en': 'Add filter by Store', 'es': 'Agregar filtro por Tiendas' },
        'lblStores': { 'en': 'STORES', 'es': 'TIENDAS' },
        'lblAddFilter': { 'en': 'Add Filter', 'es': 'Agregar Filtro' },
        'lblFIlterBy': { 'en': 'Filter by ', 'es': 'Filtros por ' },
        'lblFIltersAvailable': { 'en': 'Available Filters in ', 'es': 'Filtros disponibles en ' },
        'lblCleanFilters': { en: 'Clean Filters', es: 'Limpiar Filtros' },
        'lblEditFilters': { en: 'Edit Filters', es: 'Editar Filtros' },
        'lblFiltersToApply': { en: 'Filters to apply: ', es: "Filtros a aplicar:" },
        'lblAppliedFilters': { en: 'Applied Filters:', es: "Filtros aplicados:" },
        'lblSearchInCategories': { en: "Search", es: "Buscar" },
        'lblWait': { en: 'Please wait...', es: "Por favor espere..." }
    };


    for (const l in resources) {
        switch (lang) {
            case "es":
                r[l] = resources[l].es;
                break;

            case "en":
                r[l] = resources[l].en;
                break;

            default:

                break;
        }
    }

    return r;
}

var SegmentDetailLang = (lang) => {
    var r = [];
    let resources = {
        'btnBackToSegments': { 'en': 'Back to Segments', 'es': 'Volver a Segmentos' },
        'lblClientCount': { 'en': 'Client Count', 'es': 'Conteo de Clientes' },
        'lblPurchases': { 'en': 'Purchases', 'es': 'Compras' },
        'lblAvgTicket': { 'en': 'Average Ticket ', 'es': 'Ticket Promedio ' },
        'lblAvg': { 'en': 'Average', 'es': 'Promedio' },
        'lblVisitsYear': { 'en': 'Transactions of the segment', 'es': 'Transacciones del segmento' },
        'lblOfPurchases': { 'en': 'Percentage of Purchases', 'es': 'Porcentaje de Compras' },
        'lblPercFromCount': { 'en': 'Percentage', 'es': 'Porcentaje' },
        'lblMenTitle': { 'en': 'Men', 'es': 'Hombres' },
        'lblWomenTitle': { 'en': 'Women', 'es': 'Mujeres' },
        'btnViewClients': { 'en': 'View Clients', 'es': 'Ver Clientes' },
        'btnPurchaseCharts': { 'en': 'Charts', 'es': 'Gráficas' },
        'btnTreeMapCharts': { 'en': 'Tree Map', 'es': 'Mapa Jerárquico' },
        'btnFavoriteProducts': { 'en': 'Top Products', 'es': 'Productos Favoritos' },
        'btnFavoriteStores': { 'en': 'Favorite Stores', 'es': 'Tiendas Favoritas' },
        'btnShowSummary': { 'en': 'Summary', 'es': 'Resumen' },
        'lblPleaseWait': { 'en': 'Please wait', 'es': 'Por favor espere' },
        'lblClientTableTitle': { 'en': 'Client List', 'es': 'Lista de Clientes' },
        'btnExportClients': { 'en': 'Export', 'es': 'Exportar' },
        'btnCancelExportClients': { 'en': 'Cancel', 'es': 'Cancelar' },
        'lblProductCount': { 'en': 'Product Count', 'es': 'Conteo de Productos' },
        'lblStoresCount': { 'en': 'Store Count', 'es': 'Tiendas Encontradas' },
        'lblBestSeller': { 'en': 'Favorite Product', 'es': 'Producto Favorito' },
        'lblBestSellerStore': { 'en': 'Favorite Store', 'es': 'Tienda Favorito' },
        'lblTop10': { 'en': 'Top 10', 'es': 'Top 10' },
        'lblTop10Subtitle': { 'en': 'Showing the first top 10 items of the list.', 'es': 'Se muestran los primeros 10 items de la lista.' },
        'btnNext': { 'en': 'Next', 'es': 'Siguientes' },
        'btnPrev': { 'en': 'Previous', 'es': 'Anteriores' },
        'btnFirst': { 'en': 'First', 'es': 'Primera' },
        'btnLast': { 'en': 'Last', 'es': 'Ultima' },
        'lblShowingPage': { 'en': 'Showing', 'es': 'Mostrando' },
        'lblSorterName': { 'en': 'Name', 'es': 'Nombre' },
        'lblSorterLastName': { 'en': 'Last Name', 'es': 'Apellido' },
        'lblSorterEmail': { 'en': 'Email', 'es': 'Email' },
        'lblSorterAge': { 'en': 'Age', 'es': 'Edad' },
        'lblSorterAmount': { 'en': 'Purchase Amount', 'es': 'Monto Compras' },
        'lblSorterCount': { 'en': 'Purchase Count', 'es': 'Cant. Compras' },
        'lblSorterAvgTkt': { 'en': 'Avg. Ticket', 'es': 'Ticket Prom.' },
        'lblNoProducts': { 'en': 'No products for this segment.', 'es': 'No hay productos para este segmento.' },
        'lblNoStores': { 'en': 'No stores for this segment.', 'es': 'No hay tiendas para este segmento.' },
        'lblPeriod': { 'en': '(Annual)', 'es': '(Anual)' },
        'lblLoading': { 'es': 'Cargando datos...', 'en': 'Loading...' },
        'lblSearchPlaceHolder': { 'es': 'Buscar por nombre', 'en': 'Search by name' },
        // Chart Titles
        'lblChartPurchaseSumTitle': { 'en': 'Purchases per Month', 'es': 'Compras por Mes' },
        'lblChartPurchaseSumTitleDayOfWeek': { 'en': 'Day of the Week', 'es': 'Día de la Semana' },
        'lblChartPurchaseSumTitlePortionOfWeek': { 'en': 'Portion of the Week', 'es': 'Parte de la Semana' },
        'lblChartPurchaseSumTitlePayment': { 'en': 'Pay / No Pay', 'es': 'Pago / No Pago' },
        'lblChartPurchaseSumTitleTimeOfDay': { 'en': 'Time of Day', 'es': 'Hora del Día' },
        'lblChartPointsSummary': { 'en': 'Point Accrued per Month', 'es': 'Millas Acumuladas por Mes' },
        'treeMapTitle': { 'en': 'Hierarchical Map', 'es': 'Mapa Jerárquico' },
        'treeMapSubTitle': { 'en': '', 'es': '' },
        'treeMapValAmount': { 'en': 'Amount', 'es': 'Monto' },
        'treeMapValTimes': { 'en': 'Times', 'es': 'Veces' },
        'lblVisitCounter': { 'en': 'Transactions Count', 'es': 'Conteo de Transacciones' },
        'lblActions': { 'en': 'Actions', 'es': 'Acciones' },
        'lblChartReversals': { 'en': 'Reversals per Month', 'es': 'Reversiones por Mes' },
        'lblChartRedemptions': { 'en': 'Redemptions per Month', 'es': 'Redenciones por Mes' },
        'lblChartPointsSummarySubTitle': { 'en': 'Total Points Accrued', 'es': 'Total Millas Acumuladas' },
        'lblChartRedemptionsSubTitle': { 'en': 'Total Points Redeemed', 'es': 'Total Millas Redimidas' },
        //Segements Names
        'lblSegment1': { en: 'Retain', es: 'A Retener' },
        'lblSegment2': { en: 'Increment Amount', es: 'Incrementar M.' },
        'lblSegment3': { en: 'Increment Freq.', es: 'Incrementar F.' },
        'lblSegment4': { en: 'Bring Back', es: 'Traer de Regreso' },
        'lblSegment5': { en: 'Occasionals', es: 'Ocasionales' },
    };

    for (const l in resources) {
        switch (lang) {
            case "es":
                r[l] = resources[l].es;
                break;

            case "en":
                r[l] = resources[l].en;
                break;

            default:

                break;
        }
    }
    return r;
}

var ClientDetailLang = (lang) => {
    var r = [];
    let resources = {
        'btnBack': { 'en': 'Back', 'es': 'Volver' },
        'btnClientInsight': { 'en': 'Client Insight', 'es': 'Client Insight' },
        'lblHistogramSeriesTitle': { 'en': 'Sales Progress', 'es': 'Progreso de Metas' },
        'lblRecentPeriodTitle': { 'en': 'Periods', 'es': 'Períodos' },
        'lblRecentPeriodSubTitle': { 'en': '', 'es': '' },
        'lblHistogramTitle': { 'en': 'Goals History', 'es': 'Histórico de Metas' },
        'lblHistogramSubTitle': { 'en': '', 'es': '' },
        'lblBirthDate': { 'en': 'Birth Date', 'es': 'Fecha Nacimiento' },
        'lblAge': { 'en': 'Age', 'es': 'Edad' },
        'lblEnrollmentDate': { 'en': 'Enrollment Date', 'es': 'Fecha Afiliación' },
        'lblAvailablePoints': { 'en': 'Accrued Miles', 'es': 'Millas Acumuladas' },
        'lblRedeemedPoints': { 'en': 'Redeemed', 'es': 'Millas Redimidas' },
        'lblExpiredPoints': { 'en': 'Expired', 'es': 'Millas Expiradas' },
        'btnBack': { 'en': 'Back', 'es': 'Volver' },
        'lblAvgTicket': { 'en': 'Avg. Ticket', 'es': 'Ticket Promedio' },
        'lblTotalReversals': { 'en': 'Total Reversals', 'es': 'Total Reversiones' },
        'lblId': { 'en': 'Id.', 'es': 'Id.' },
        'lblPointsPerMonths': { 'en': 'Miles Per Month', 'es': 'Millas Por Mes' },
        'pointChartTitle': { 'en': 'Miles accrued per month.', 'es': 'Millas acumuladas por mes.' },
        'pointChartsubTitle': { 'en': '', 'es': '' },
        'lblRedemsPerMonths': { 'en': 'Redemptions Per Month', 'es': 'Redenciones Por Mes' },
        'redemChartTitle': { 'en': 'Redemptions per month.', 'es': 'Redenciones por mes.' },
        'redemChartSubTitle': { 'en': '', 'es': '' },
        'salesVSgoals': { en: 'SALES PER MONTH VS GOALS PER MONTH (AMOUNT)', es: 'VENTAS POR MES VS METAS POR MES (MONTO)' },
        'salesVSgoalsSubtitle': { en: 'Sales vs Goals per month', es: "Ventas vs metas por mes" },
        'hourPurchases': { en: 'HOUR OF THE EDAY', es: "HORA DEL DÍA" },
        'hourPurchasesSubtitle': { en: "Relative hour of purchases", es: "Hora del día donde se realizan las ventas." },
        'favProducts': { en: 'Favorite Products', es: 'Producto Favoritos' },
        'favStores': { en: 'Favorite Stores', es: 'Tiendas Favoritas' },
        'lblAmount': { en: 'Amount', es: 'Monto' },
        'lblTimes': { en: 'Transactions', es: 'Transacciones' },
        'lblCount': { en: 'Count', es: 'Conteo' },
        'lblLoading': { en: 'Loading...', es: 'Cargando...' }
    };

    for (const l in resources) {
        switch (lang) {
            case "es":
                r[l] = resources[l].es;
                break;

            case "en":
                r[l] = resources[l].en;
                break;

            default:

                break;
        }
    }

    return r;
}

var RFMatrixLang = (lang) => {
    var r = [];
    let resources = {
        'lblRecencia': { es: 'Recencia', en: 'Recency' },
        'lblFrecuencia': { es: 'Frecuencia', en: 'Frecuency' },
        'lblMonto': { es: 'Monto', en: 'amount' },
        'lblRecenciaExplan': { es: 'Dias transcurridos desde la última compra', en: 'Days past since last purchase' },
        'lblFrecuenciaExplan': { es: '<< Cantidad de Visitas / Compras >>', en: '<< Visits / Purchases >>' },
        'lblMontoExplan': { es: 'Total del monto comprado', en: 'Total amount purchased' },
        'lblR_recent': { es: 'Reciente', en: 'Recent' },
        'lblR_prior': { es: 'Anterior', en: 'Prior' },
        'lblF_High': { es: 'Alta', en: 'High' },
        'lblF_Medium': { es: 'Media', en: 'Medium' },
        'lblF_Low': { es: 'Baja', en: 'Low' },
        'lblM_High': { es: 'Alto', en: 'High' },
        'lblM_Medium': { es: 'Medio', en: 'Medium' },
        'lblM_Low': { es: 'Bajo', en: 'Low' },
        'lblTotals': { es: 'Totales', en: 'Totals' },
        'lblClose': { es: 'Cerrar', en: 'Close' },
        "lblSegments": { es: 'Segmentos', en: 'Segments' },
        lblRetain: { es: 'A retener', en: 'Retain' },
        lblIncrementM: { es: 'Incrementar M.', en: 'Increment A.' },
        lblIncrementF: { es: 'Incrementar F.', en: 'Increment F.' },
        lblBringBack: { es: 'Traer de Regreso.', en: 'Bring Back' },
        lblOcasionals: { es: 'Ocasionales', en: 'Occasionals' },
        lblConfig:{ es: 'Configuración', en: 'Configuration' },
    };

    for (const l in resources) {
        switch (lang) {
            case "es":
                r[l] = resources[l].es;
                break;

            case "en":
                r[l] = resources[l].en;
                break;

            default:

                break;
        }
    }

    return r;
}

var crmDropdownLang = (lang) => {
    var r = [];
    let resources = {
        'name': { es: 'Nombre', en: 'Name' },
        'amount': { es: 'Monto', en: 'Amount' },
        'visits': { es: 'Transacciones', en: 'Transacciones' },
        'export': { es: 'Exportar', en: 'Export' },
    }


    for (const l in resources) {
        switch (lang) {
            case "es":
                r[l] = resources[l].es;
                break;

            case "en":
                r[l] = resources[l].en;
                break;

            default:

                break;
        }
    }

    return r;
}

//3. SALESFORCE ----------------------------------------------------------------------------------------------------------------------

var SalesForceSummaryLang = lang => {
    var r = [];
    let resources = {
        lblTitle: { es: 'VENTAS TOTALES (RESUMEN)', en: 'TOTAL SALES (SUMMARY)' },
        lblSubTitle: { es: 'Resumen de ventas durante el periodo', en: 'Summary of all sales throughout the period.' },
        lblSalesTitle: { es: 'VENTAS (TOTALES):', en: 'SALES (TOTAL):' },
        lblAvgTicket: { es: 'TICKET PROMEDIO :', en: 'AVERAGE TICKET:' },
        lblLoading: { es: 'Cargando...', en: 'Loading...' },
    };

    for (const l in resources) {
        switch (lang) {
            case "es":
                r[l] = resources[l].es;
                break;

            case "en":
                r[l] = resources[l].en;
                break;

            default:

                break;
        }
    }

    return r;
}

var SalesForceTabsLang = lang => {
    var r = [];
    let resources = {
        lblGoalsPerStore: { es: 'Metas por Tienda', en: 'Goals Per Store' },
        lblTransPerStore: { es: 'Transacciones por Tienda', en: 'Transactions Per Store' },
        lblSearch: { es: 'Buscar', en: 'Search' },
        lblStoreName: { es: 'Tienda', en: 'Store Name' },
        lblTransactions: { es: 'Transacciones', en: 'Transactions' },
        lblGoals: { es: 'Meta', en: 'Goal' },
    };

    for (const l in resources) {
        switch (lang) {
            case "es":
                r[l] = resources[l].es;
                break;

            case "en":
                r[l] = resources[l].en;
                break;

            default:

                break;
        }
    }

    return r;
}


//4. MY SEGMENTS | SEGMENT CREATOR | CUSTOM SEGMENT DETAIL ----------------------------------------------------------------------------------------------------------------------

var MySegmentsLang = lang => {
    var r = [];
    let resources = {
        //My Segments Labels
        lblTitle: { es: "Mis Segmentos", en: "My Segments" },
        lblButtonNewSegment: { es: 'Crear Nuevo Segmento', en: 'Create New Segment' },
        lblSegmentListSearchBar: { es: 'Busca un segmento por nombre', en: 'Search by name' },
        lblLoading: { es: 'Cargando Datos...', en: 'Loading...' },
        lblButtonViewDetail: { es: 'Ver Detalle', en: 'View Detail' },
        lblButtonDeleteSegment: { es: 'Eliminar', en: 'Delete' },
        lblButtonModify: { es: 'Editar', en: 'Edit' },
        lblButtonExport: { es: 'Exportar', en: 'Export' },
        lblButtonRefresh: { es: 'Refrescar datos', en: 'Refresh data' },
        lblMessageSuccess: { es: 'Segmento eliminado correctamente', en: 'Segment successfully deleted' },
        lblMessageFailure: { es: 'Se prensentó un problema al intentar eliminar el segmento', en: "There were troubles deleting the segment, please try again" },
        //SegmentList Item Labels
        lblClients: { es: 'Clientes', en: 'Clients' },
        lblAvgTicket: { es: 'Ticket Promedio', en: 'Avarage Ticket' },
        lblAvgTIcketExplain: { es: '# total de facturas / gasto total de la población', en: ' # of bills / total expenses' },
        lblAvgTicketExplain2: { es: '(de toda la población)', en: '(of the entire population)' },
        lblSales: { es: 'Compras', en: 'Purchases' },
        lblAvgVisit: { es: 'Visita Promedio', en: 'Avarage Visit per Year' },
        lblDescription: { es: 'Descripción', en: 'Description' },
        lblBaseSegments: { es: 'Segmentos Base', en: 'Based Segments' },
        lblAllPeople: { es: 'Toda la población', en: 'All the clients' },
        lblClientProfile: { es: 'Perfil de cliente', en: 'Client Profile' },
        lblAppliedFilters: { es: 'Filtros Aplicados', en: 'Applied Filters' },
        lblNoFilter: { es: 'Sin Filtros', en: 'No Filters' },
        lblButtonBack: { es: 'Regresar', en: 'Go Back' },
        lblCardSummary: { es: 'Resumen del dia', en: 'Symmary of' },
        //Client Profile -------------------
        lblFrom: { es: 'De ', en: 'From ' },
        lblTo: { es: ' a ', en: ' to ' },
        lblSinceLastPruchase: { es: ' dias desde la última compra', en: ' days since the last purchase' },
        lblVisitsBetween: { es: 'Visita entre ', en: 'Visits between ' },
        lblBuysBetween: { es: 'Compra entre ', en: 'Buys between ' },
        lblAnd: { es: ' y ', en: ' and ' },
        lblExpendsBetween: { es: 'Gasta entre ', en: 'Expends between ' },
        lblTimesPerYear: { es: ' veces al año', en: ' times per year' },
        lblPerYear: { es: ' al año', en: ' per year' },
        //Custom Segment Detail ------------
        //Tabs
        btnShowSummary: { es: 'Resumen', en: 'Summary' },
        btnViewClients: { es: 'Ver clientes', en: 'View Clients' },
        btnPurchaseCharts: { es: 'Gráficos', en: 'Charts' },
        btnFavoriteProducts: { es: 'Productos Favoritos', en: 'Favorite Products' },
        btnFavoriteStores: { es: 'Tiendas Favortitas', en: 'FAvorite Stores' },
        //Charts
        lblPointChartTitle: { es: 'Millas Acumuladas por Mes', en: 'Point Accrued per Month' },
        lblPointChartSubTitle: { es: 'Total de Millas Acumulados', en: 'Total point accrued:' },
        lblRedemChartTitle: { es: 'Millas Redimidas por Mes', en: 'Redemptions per Month' },
        lblRedemChartSubTitle: { es: 'Total de Millas Redimidas', en: 'Total points redeemed:' },
        lblPurchasesChartTitle: { es: 'Compras por Mes', en: 'Purchases per Month' },
        lblPurchasesChartSubTitle: { es: 'Total de Compras durante el Periodo', en: 'Total Purchases:' },
        lblDoughnutTitle: { es: 'Porcentaje de Compras', en: 'Percentage of purchases' },
        //Products
        'lblProductCount': { 'en': 'Product Count', 'es': 'Conteo de Productos' },
        'lblStoresCount': { 'en': 'Store Count', 'es': 'Tiendas Encontradas' },
        'lblBestSeller': { 'en': 'Favorite Product', 'es': 'Producto Favorito' },
        'lblBestSellerStore': { 'en': 'Favorite Store', 'es': 'Tienda Favorito' },
        'lblTop10': { 'es': 'Top 35 Productos', 'en': 'Top 35 Products' },
        'lblTop10Subtitle': { 'en': 'Showing the first top 10 items of the list.', 'es': 'Se muestran los primeros 10 items de la lista.' },
        //Genders
        lblMenTitle: { es: 'Solo Hombres', en: 'Just Men' },
        lblWomenTitle: { es: 'Solo Mujeres', en: 'Just Women' },
        lblMen: { es: 'Hombres', en: 'Men' },
        lblWomen: { es: 'Mujeres', en: 'Women' },
        //Client Table
        lblSorterName: { en: 'Name', es: 'Nombre' },
        lblSorterLastName: { en: 'Last Name', es: 'Apellido' },
        lblSorterEmail: { en: 'Email', es: 'Email' },
        lblSorterAge: { en: 'Age', es: 'Edad' },
        lblSorterAmount: { en: 'Purchase Amount', es: 'Monto Compras' },
        lblSorterCount: { en: 'Purchase Count', es: 'Cant. Compras' },
        lblSorterAvgTkt: { en: 'Avg. Ticket', es: 'Ticket Prom.' },
        lblProductName: { es: 'Nombre', en: 'Name' },
        lblVisitCounter: { es: 'Transacciones', en: 'Transactions Count' },
        lblPurchases: { es: 'Compras', en: 'Purchases' },
        lblButtonViewClient: { es: 'Ver Cliente', en: 'View Client' },
        lblSearchPlaceHolder: { es: 'Buscar por nombre', en: 'Search by name' },
        lblPaginator: { es: 'Mostrando página', en: 'Current Page' },
        lblButtonFirst: { es: 'Primera', en: 'First' },
        lblButtonLast: { es: 'Última', en: 'Last' },
        lblNameDrop: { es: 'Nombre', en: 'Name' },
        lblAmountDrop: { es: 'Monto', en: 'Amount' },
        lblVisitsDrop: { es: 'Transacciones', en: 'Transactions' },
        lblExportDrop: { es: 'Exportar', en: 'Export' },
        lblActions: { es: 'Actions', en: 'Acciones' },
        //SEGMENT CREATOR ------------------------
        //NameForm
        lblSegmentName: { es: 'Nombre del Segmento', en: 'Segment Name' },
        lblSegmentNameDefault: { es: 'Mi Segmento', en: 'My Segment' },
        lblSegmentDescription: { es: 'Descripción del Segmento', en: 'Segment Description' },
        lblColorTitle: { es: 'Elige un Color', en: 'Pick a Color' },
        lblIconTitle: { es: 'Elige un Ícono', en: 'Pick an Icon' },
        lblIcon: { es: 'Ícono', en: 'Icon' },
        lblDays: { es: 'Días', en: 'Days' },
        lblFilters: { es: 'Filtros', en: 'Filters' },
        lblSelectedFilters: { es: 'Filtros a aplicar', en: 'Selected filters' },
        lblSelectedSegments: { es: 'Segmentos Seleccionados', en: 'Selected Segments' },
        //BaseSegmentSelector
        lblBsTitle: { es: '¿Qué población deseas incluir?', en: "Which people do you want to include?" },
        lblTabFromBaseSegments: { es: 'Formar de segmentos', en: 'Select from segments' },
        lblTabFromRfmValues: { es: 'Definir valores propios', en: 'Define cryteria' },
        lblSelectAll: { es: 'Seleccionar toda la población', en: 'Select all' },
        lblRetain: { es: 'A retener', en: 'Retain' },
        lblIncrementA: { es: 'Incrementar M.', en: 'Increment A.' },
        lblIncrementF: { es: 'Incrementar F.', en: 'Increment F.' },
        lblBringBack: { es: 'Traer de Regreso.', en: 'Bring Back' },
        lblOccasionals: { es: 'Ocasionales', en: 'Occasionals' },
        lblTimeSinceLastPurchase: { es: 'Tiempo desde la última compra', en: 'Time since last purchase' },
        lblPurchasesPerYear: { es: 'Compras al año', en: 'Purchases per year' },
        lblExpensesPerYear: { es: 'Gasto al año', en: 'Expends per year' },
        lblVisitsPerYear: { es: 'Transacciones al año', en: 'Transactions per year' },
        lblPerfectFToggler: { es: 'Compra cada visita', en: 'Buys in every visit' },
        //FILTER SELECTOR
        lblFilterSelectorTitle: { es: '¿Cuáles filtros quieres aplicar?' },
        lblTabDemAndSpe: { es: 'Demográficos y Especiales', en: 'Demographic and Specials' },
        lblTabByCat: { es: 'Por Categorias', en: 'By Categories' },
        lblTabByStore: { es: 'Por Tienda', en: 'By Store' },
        lblDemoTitle: { es: 'Demográficos', en: 'Demographics' },
        lblGenderTitle: { es: 'Género', en: 'Gender' },
        lblAgeTitle: { es: 'Rango de Edad', en: 'Age Range' },
        lblSpecialTitle: { es: 'Especiales', en: 'Special Filters' },
        lblBirthday: { es: 'Dia de cumpleaños', en: 'Birthday' },
        lblToday: { es: 'Hoy', en: 'Today' },
        lblAddDate: { es: 'Agregar Fecha', en: 'Add Date' },
        lblShoppingTime: { es: "Tiempo favorito para comprar", en: "Prefered Shopping Time" },
        lblWeek: { es: 'Entre semana', en: "During the week" },
        lblWeekEnd: { es: 'Fin de semana', en: "Weekend" },
        lblByProductTitle: { es: 'Productos', en: 'Products' },
        lblByStoreTitle: { es: 'Tiendas', en: 'Stores' },
        lblSelectedStores: { es: 'Tiendas Elegidas', en: 'Selected Stores' },
        lblStoresSearchBar: { es: 'Busca una tienda', en: 'Search a store' },
        lblButtonNext: { es: 'Siguiente', en: 'Next' },
        lblButtonPrev: { es: 'Anterior', en: 'Prev' },
        lblPlaceholder: { es: 'Buscar...', en: 'Search...' },
        lblCategories: { es: 'Categorias', en: 'Categories' },
        lblSelectedFilters: { es: 'Filtros Seleccionados', en: 'Selected Filters' },
        lblAddFilter: { es: 'Agregar', en: 'Add' },
        lblNegateFilter: { es: 'Negar', en: 'Negate' },
        lblRemove: { es: 'Borrar', en: 'Remove' },
        //GLOBAL
        lblCreateSegment: { es: 'Guardar', en: 'Save' },
        lblCancel: { es: 'Cancelar', en: 'Cancel' },
        lblNext: { es: 'Siguiente', en: 'Next' },
        lblBack: { es: 'Volver', en: 'Back' },
        lblGeneralTitle: { es: '1 - Nombre y población', en: "1 - Name and Poblation" },
        lblFiltersTitle: { es: '2 - Selección de filtros', en: "2 - Filter Selection" },
        lblPreviewTitle: { es: '3 - Revisa tu segmento', en: "3- Preview your Segment" },
        lblDeleteQuestion: { es: 'Eliminar el segmento?', en: "Delete segment?" },

    };

    for (const l in resources) {
        switch (lang) {
            case "es":
                r[l] = resources[l].es;
                break;

            case "en":
                r[l] = resources[l].en;
                break;

            default:

                break;
        }
    }

    return r;
}

//5. MY EXPORTS ----------------------------------------------------------------------------------------------------------------------

var MyExportsLang = lang => {
    var r = [];
    let resources = {
        lblButtonEdit: { es: 'Editar', en: 'Edit' },
        lblButtonSave: { es: 'Guardar', en: 'Save' },
        lblButtonCancel: { es: 'Cancelar', en: 'Cancel' },
        lblButtonDownload: { es: 'Decargar Archivo', en: 'Download File' },
        lblButtonViewClients: { es: 'Ver Clientes', en: 'View Clients' },
        lblLoading: { es: 'Cargando datos...', en: 'Loading...' },
        lblPlaceHolder: { es: 'Busca tus exports', en: 'Search your exports' }
    }

    for (const l in resources) {
        switch (lang) {
            case "es":
                r[l] = resources[l].es;
                break;

            case "en":
                r[l] = resources[l].en;
                break;

            default:

                break;
        }
    }

    return r;
}

var MyExportsClientListLang = lang => {
    var r = [];
    let resources = {
        //Sorters
        lblSorterName: { es: 'Nombre', en: 'Name' },
        lblSorterLastName: { es: 'Apellido', en: 'Last Name' },
        lblSorterEmail: { es: 'Correo Electrónico', en: 'Email' },
        lblSorterAmount: { es: 'Monto Compras', en: 'Purchase Amount' },
        lblSorterCount: { es: 'Cant. Compras', en: 'Purchase Count' },
        lblSorterAvgTicket: { es: 'Ticket Prom.', en: 'Avarage Ticket' },
        lblBtnBack: { es: 'Volver', en: 'Back to My Exports' },
        //Table
        lblId: { es: 'ID', en: 'Member Number' },
        lblName: { es: 'Nombre', en: 'Name' },
        lblLastName: { es: 'Apellido', en: 'Last Name' },
        lblSegment: { es: 'Segmento', en: 'Segment' },
        lblPurchases: { es: 'Compras', en: 'Purchases' },
        lblVisits: { es: 'Transacciones', en: 'Transactions' },
        lblLoading: { es: 'Cargando datos...', en: 'Loading...' },
        btnViewClients: { es: 'Ver cliente', en: 'View Client' },
        //PAGINATOR
        lblPaginator: { es: 'Mostranto página', en: 'Current Page' },
        lblButtonFirst: { es: 'Primera', en: 'First' },
        lblButtonLast: { es: 'Última', en: 'Last' },
    }

    for (const l in resources) {
        switch (lang) {
            case "es":
                r[l] = resources[l].es;
                break;

            case "en":
                r[l] = resources[l].en;
                break;

            default:

                break;
        }
    }

    return r;
}

var MyExportsCreatorLang = lang => {
    var r = [];
    let resources = {
        lblTitle: { es: 'Exportar Lista de Clientes', en: 'Export Client List' },
        lblExplain: { es: 'Se va a crear un "export" con los clientes que cumplan con los parámetros de búsqueda.', en: 'An "export" will be created with the list of clients that match the search criteria' },
        //lblRetain:{es:'', en:'Retain'},
        lblClientsToExport: { es: 'Clientes a exportar', en: 'Clients to export' },
        lblNamePlaceHolder: { es: 'Nombre', en: 'Name' },
        lblDescPlaceholder: { es: 'Descripción', en: 'Decription' },
        lblButtonExport: { es: 'Exportar', en: 'Export' },
        lblButtonCancel: { es: 'Cancelar', en: 'Cancel' },
        lblMessageSuccess: { es: 'Export creado exitosamente, diríjase a la pestaña "Mis Exports para visualizarlos"', en: 'Export created succesfully, please navigate to "My Exports" section to see it' },
    }

    for (const l in resources) {
        switch (lang) {
            case "es":
                r[l] = resources[l].es;
                break;

            case "en":
                r[l] = resources[l].en;
                break;

            default:

                break;
        }
    }

    return r;
}


//6. CAMPAIGNS ----------------------------------------------------------------------------------------------------------------------

var CampaignsLang = lang => {
    var r = [];
    let resources = {
        //Global
        lblTitle: { es: 'Campañas', en: 'Campaigns' },
        lblCreateCampaign: { es: 'Crear Campaña', en: 'Create Campaigns' },
        lblLoading: { es: 'Cargando', en: 'Loading' },
        //Dropdowns
        lblView: { es: 'Visualizar', en: 'View' },
        lblCampaignStatus: { es: "Estatus de campaña", en: "Campaign status" },
        lblCampaignStatusAll: { es: "Todas", en: "All" },
        lblCampaignStatusSent: { es: "Enviada", en: "Sent" },
        lblCampaignStatusDraft: { es: "Procesando", en: "Drafts" },
        lblSort: { es: 'Ordenar por', en: 'Sort by' },
        lblSortByDate: { es: 'Fecha', en: 'Date' },
        lblSortByName: { es: 'Nombre', en: 'Name' },
    }

    for (const l in resources) {
        switch (lang) {
            case "es":
                r[l] = resources[l].es;
                break;

            case "en":
                r[l] = resources[l].en;
                break;

            default:

                break;
        }
    }

    return r;
}

var CampaignCardLang = lang => {
    var r = [];
    let resources = {
        //Global
        lblSent: { es: 'Enviados', en: 'Sent' },
        lblDelivered: { es: 'Entregados', en: 'Delivered' },
        lblOpened: { es: 'Abiertos', en: 'Opened' },
        lblBounced: { es: 'Rebotados', en: 'Bounced' },
        lblEmails: { es: 'Correos', en: 'Emails' },
        lblBtnDetailCampaign: { es: 'Ver Detalle', en: 'View Detail' },
        lblOpenedEmails: { es: 'Correos Abiertos', en: 'Opened Emails' },
        lblSentEmails: { es: 'Correos enviados', en: 'Sent emails' },
        lblDate: { es: "Fecha de envío", en: "Sent Date" },
    }

    for (const l in resources) {
        switch (lang) {
            case "es":
                r[l] = resources[l].es;
                break;

            case "en":
                r[l] = resources[l].en;
                break;

            default:

                break;
        }
    }

    return r;
}

var CampaignDetailLang = lang => {
    var r = [];
    let resources = {
        //Title And Buttons
        btnGetBack: { es: 'Volver a Campañas', en: 'Back to Campaigns' },
        'btnFirst': { 'en': 'First', 'es': 'Primera' },
        'btnLast': { 'en': 'Last', 'es': 'Ultima' },
        //Tabs
        lblTabSummary: { es: 'Resumen', en: 'Summary' },
        lblTabContacts: { es: 'Contactos', en: 'Contacts' },
        //Detail
        'lblTabSummary': { 'en': 'Summary', 'es': 'Resumen' },
        'lblTabContacts': { 'en': 'Contacts', 'es': 'Lista de Contactos' },
        'lblParticipants': { 'en': 'Recipients:', 'es': 'Participantes:' },
        'lblSubject': { 'en': 'Participants', 'es': 'Participantes' },
        'lblSentDate': { 'en': 'Subject:', 'es': 'Asunto:' },
        'lblSegments': { 'en': 'Segments:', 'es': 'Segmentos:' },
        'lblCroTitle': { 'en': 'Opening history', 'es': 'Cronología de aperturas de correo' },
        'lblOpenedEmails': { 'en': 'Opened emails', 'es': 'Correos Abiertos' },
        'lblDelivered': { 'en': 'Delivered', 'es': 'Entregados' },
        'lblOpened': { 'en': 'Opened', 'es': 'Abiertos' },
        'lblBounced': { 'en': 'Bounced', 'es': 'Rebotados' },
        'lblEmails': { 'en': 'emails', 'es': 'correos' },
        'lblOpenedRate': { 'en': 'Open Rate', 'es': 'Tase de apertura' },
        'lblSoftBounces': { 'en': 'Soft Bounces', 'es': 'Rebotes Suaves' },
        'lblHardBounces': { 'en': 'Hard Bounces', 'es': 'Rebotes Duros' },
        'lblUniqueViews': { 'en': 'Unique Views', 'es': 'Tasa de apertura única' },
        'lblMultipleViews': { 'en': 'Multiple Views', 'es': 'Tasa de apertura múltiple' },
        'lblButtonExport': { 'en': 'Export', 'es': 'Exportar' },
        'lblMemberNumber': { 'en': 'Member Num', 'es': 'No. Miembro' },
        'lblName': { 'en': 'Name', 'es': 'Nombre' },
        'lblButtonViewClient': { 'en': 'View Client', 'es': 'Ver cliente' },
        'lblSoftBounceTooltip': { 'en': 'The term "soft-bounce" refers to an email that could not be delivered to its recipient due to a temporary problem and a story will be sent back in future campaigns (for example: The server is not available or your mailbox entrance is full).', 'es': 'El término « soft-bounce » se refiere a un e-mail que no pudo ser entregado a su destinatario debido a un problema temporal y se volverá a enviar a tales destinatarios en futuras campañas (por ejemplo: El servidor no está disponible o su buzón de entrada está lleno).' },
        'lblHardBounceTooltip': { 'en': 'The term "hard-bounce" refers to an e-mail that could not be delivered to its recipient due to a permanent problem (for example: non-existent addresses or blocked addresses).', 'es': 'El término « hard-bounce » se refiere a un e-mail que no pudo ser entregado a su destinatario debido a un problema permanente (por ejemplo: Direcciones inexistentes o direcciones bloqueadas).' },
        'lblUniqueViewsTooltip': { 'en': 'Number of people who opened the email only once', 'es': 'Cantidad de personas que abrieron el email solo una vez' },
        'lblMultipleViewsTooltip': { 'en': 'Number of people who opened the email multiple times', 'es': 'Cantidad de personas que abrieron el email en multiples ocasiones' },
        "lblClientSearchBar": { "es": "Busca un cliente por nombre", "en": "Search client by name" },
        //SMS
        "lblContent": { "es": "Contenido", "en": "Content" },
        "lblCreationDate": { "es": "Fecha de creación", "en": "Creation date" },
        "lblSentDate": { "es": "Fecha de envio", "en": "Sent Date" },
        "lblNotSentYet": { "es": "La campaña aún no ha sido enviada", "en": "The campaign has yet to be sent" },
        "lblSchedule": { "es": "La campaña se enviará en la fecha programada", "en": "The campaign will be sent on the schedule date" },
        "lblSendCampaign": { "es": "Enviar campaña", "en": "Send campaign" },
        "lblScheduleCampaign": { "es": "Programar campaña", "en": "Schedule campaign" },

    }

    for (const l in resources) {
        switch (lang) {
            case "es":
                r[l] = resources[l].es;
                break;

            case "en":
                r[l] = resources[l].en;
                break;

            default:

                break;
        }
    }

    return r;
}

var CampaignCreatorLang = lang => {
    var r = [];
    let resources = {
        //Global
        lblTitle: { es: 'Crear campaña', en: 'Create campaign' },
        lblStep: { es: 'Paso', en: 'Step' },
        lblOf: { es: 'de', en: 'of' },
        lblStep0: { es: 'Seleccione el tipo de campaña que desea crear', en: 'Choose the type of campaign to create' },
        lblStep1: { es: 'Configuración', en: 'Configuration' },
        lblStep2: { es: 'Diseño', en: 'Desing' },
        lblStep3: { es: 'Destinatarios', en: 'Recipients' },
        lblStep4: { es: 'Confimación', en: 'Confirmation' },
        lblNext: { es: 'Siguiente', en: 'Next' },
        lblCreateCampaign: { es: 'Crear Campaña', en: 'Create Campaign' },
        lblClients: { es: 'Clientes', en: 'Clients' },
        lblSelectedPeople: { es: "Total de la población seleccionada", en: "Total of the selected poblation" },
        lblCreateFromCustomSegment: { es: 'Enviar campaña al segmento', en: 'Send campaign tos' },
        lblSelect: { es: 'Seleccionar', en: 'Select' },
        //Summary
        lblSummaryTitle: { es: "Información de la campaña", en: "Campaign Summary" },
        lblSummaryType: { es: "Tipo de campaña", en: "CampaignType" },
        lblSummaryName: { es: "Nombre", en: "Name" },
        lblSummarySubject: { es: "Sujeto", en: "Subject" },
        lblSummaryAudienceDescription: { es: "Description", en: "Description" },
        lblSummaryTextMessage: { es: "Contenido del mensaje", en: "Message content" },
        lblSummarySegments: { es: "Segmentos", en: "Segments" },
        lblSummaryTotalPoblation: { es: "Total de poblacion seleccionada", en: "Total poblation" },
        lblSummaryTotalPoblationExplan: { es: "Conteo total de clientes de los segmentos seleccionados", en: "Total count of customers of the selected segments" },
        lblSummaryAvailablePoblation: { es: "Total de poblacion disponible", en: "Available poblation" },
        lblSummaryAvailablePoblationExplanEmail: { es: "Conteo total de clientes de la población seleccionada que cuentan con un correo electrónico disponible para enviar la campaña", en: "Total count of customers who have an email available to send the campaign" },
        lblSummaryAvailablePoblationExplanSms: { es: "Conteo total de clientes de la población seleccionada  que cuentan con un número de teléfono disponible para enviar la campaña de mensajes de texto", en: "Total count of customers who have an phone number available to send the campaign" },
        lblSummaryAvailableCoupons: { es: "Total de cupones disponibles", en: "Available cupon count" },
        //Campaign Types
        lblEmail: { es: 'Correos', en: 'Email Marketing' },
        lblSms: { es: 'Mensaje de texto', en: 'SMS' },
        lblPush: { es: 'Notificaciones Push', en: 'Push Notifications' },
        lblFacebook: { es: 'Facebook', en: 'Facebook' },
        lblEmailExplan: { es: 'Campaña de email marketing a través de los distintos clientes de correo electrónico', en: 'Email marketing campaign through the different email clients' },
        lblSmsExplan: { es: 'Envie un mensaje de texto personalisado para cierta población', en: 'Send a personalized text message for a certain population' },
        lblFacebookExplan: { es: 'Exporta una población hacia el API de Facebook', en: 'Export a poblation to Facebook' },
        //EMAIL CREATION FORM
        lblCampaignName: { es: 'Nombre de la campaña', en: 'Campaign Name' },
        lblCampaignNameExplan: { es: 'Elija un nombre para su campaña, así podrá encontrarla fácilmente. Por ejemplo: "Promoción Octubre"', en: 'Choose a name for your campaign so you can easily find it. For example: "October sales"' },
        lblCampaignSubject: { es: 'Asunto', en: 'Subject' },
        lblCampaignSubjectExplan: { es: 'Seleccione un asunto que describa claramente el contenido de su e-mail. Es lo primero que verá su destinatario. Por ejemplo: «Venta privada: 25 % de descuento en nuestra nueva colección»', en: 'Select a subject that clearly describes the content of your email. It is the first thing your recipient will see. For example: «Private sale: 25% discount on our new collection»' },
        lblCampaigImg: { es: 'Importar diseño', en: 'Import Image' },
        lblCampaigImgExplan: { es: 'Crea una campaña cargando una imagen o archivo ZIP que contenga el HTML', en: 'Create a campaign by loading an image or ZIP file that contains the HTML' },
        lblTestEmail: { es: "Email de prueba", en: "Test Email" },
        lblTestEmailExplan: { es: "Escriba el email para enviar una prueba de la campaña", en: "Write the email direction to sent a test of the campaign" },
        lblCallToAction: { es: "Redireccionar hacia", en: "Redirect Url" },
        lblCallToActionExplan: { es: "Dirección web a la cual redireccionar al usuario cuando interactue con la imagen", en: "Web url to redirect the user after clicking on the image" },
        lblCallToActionCuponExplan: { es: "Al enviar un cupón el email redireccionará al usuario a la página con la información del cupón canjeado", en: "When a coupon is sent the email will redirect the client to a page with the claimed coupon information" },
        lblCuponExplan: { es: "Selecciona un cupón para enviar en tu campaña", en: "Select a cupon to send in your campaign" },
        lblDragDropExplan: { es: "Crea un email compatible con distintos dispositivos", en: "Create an email compatible with different devices" },
        //SEGMENTS
        lblRetain: { es: 'A retener', en: 'Retain' },
        lblIncrementM: { es: 'Incrementar M.', en: 'Increment A.' },
        lblIncrementF: { es: 'Incrementar F.', en: 'Increment F.' },
        lblBringBack: { es: 'Traer de Regreso.', en: 'Bring Back' },
        lblOccasionals: { es: 'Ocasionales', en: 'Occasionals' },
        lblSegmentTab: { es: 'Segmentos', en: 'Segmentos' },
        lblCustomSegmentsTab: { es: 'Segmentos Personalizados', en: 'Custom Segments' },
        lblSegmentListSearchBar: { es: 'Busca un segmento por nombre', en: 'Search by name' },
        //SMS
        lblCampaignTextMessage: { es: 'Texto', en: 'Text' },
        lblCampaignTextMessageExplan: { es: 'Contenido del mensaje', en: 'Contents of the message' },
        lblAvailableCharacter: { es: "Caracteres disponibles", en: "Available Characters" },
        lblAvailableCharactersAlert: { es: "Se supero la cantidad de caracteres disponibles!", en: "Available characters were exceed!" },
        lblTestPhoneNumber: { es: "Mensaje de prueba", en: "Test Message" },
        lblTestPhoneNumberExplan: { es: "Escribir el número en formato internacional. Ej: (506878586882)", en: "Write the number in international format,. Ex: (506878586882)" },
        lblCreateAndSendCampaign: { es: "Crear y enviar campaña", en: "Create and send campaign" },
        lblCreateAndSaveCampaign: { es: "Crear y guardar campaña", en: "Create and save campaign" },
        lblCreateAndScheduleCampaign: { es: "Programar fecha de envío", en: "Schedule campaign" },
        //FACEBOOK
        lblTitleFacebook: { es: 'Crear audiencia de facebook', en: 'Create Facebook Audience' },
        lblSummaryTitleFacebook: { es: "Información de la audienca", en: "Audience Summary" },
        lblFacebookExplan: { es: "Selecciona una población y exporta una audiencia de Facebook Ads", en: "Select and export an audience to Facebook Ads" },
        lblCreateAudience: { es: "Crear audiencia", en: "Create audience" },
        lblAudienceName: { es: 'Nombre de la audiencia', en: 'Audience Name' },
        lblAudienceNameExplan: { es: 'Nombre de la audiencia a crear. Este identificador será útil para identificar la población exportada dentro de las herramientas de mercadeo de Facebook', en: 'The name of the audience. This name will be usefull to identify the audience in Facebook`s marketing tools' },
        lblAudienceDescription: { es: 'Descripción', en: 'Description' },
        lblAudienceDescriptionExplan: { es: 'Breve descripción de la audiencia a exportar', en: 'Small description of the audience to export' },
        //lblStep1Facebook:{es:'01. Selecciona el tipo de campaña', en:'01. Select the type of campaign'},
        lblStep2Facebook: { es: '02. Asocia tu cuenta de facebook y crea tu audiencia', en: '02. Log in with your facebook account and create an audience' },
        lblStep3Facebook: { es: '03. Selecciona los segmentos de población a incluir en la audiencia', en: '03. Select the segments to include in the audience' },
    }

    for (const l in resources) {
        switch (lang) {
            case "es":
                r[l] = resources[l].es;
                break;

            case "en":
                r[l] = resources[l].en;
                break;

            default:

                break;
        }
    }

    return r;
}

var CampaignEmailCreatorLang = lang => {
    var r = [];
    let resources = {
        lblStepConf: { es: 'Configuración', en: 'Configuration' },
        lblStepContentType: { es: 'Tipo de contenido', en: 'Content Type' },
        lblStepDesing: { es: 'Diseño', en: 'Desing' },
        lblStepSegments: { es: 'Destinatarios', en: 'Recipients' },
        lblStepConfirmation: { es: 'Confirmación', en: 'Confirmation' },
        lblNext: { es: 'Siguiente', en: 'Next' },
        lblCreateCampaign: { es: 'Crear Campaña', en: 'Create Campaign' },
        lblClients: { es: 'Clientes', en: 'Clients' },
        lblSelectedPeople: { es: "Total de la población seleccionada", en: "Total of the selected poblation" },
        lblCreateFromCustomSegment: { es: 'Enviar campaña al segmento', en: 'Send campaign tos' },
        lblSelect: { es: 'Seleccionar', en: 'Select' },
        lblAccept: { es: 'Aceptar', en: 'Accept' },
        lblCancel: { es: 'Cancelar', en: 'Cancel' },
        //EMAIL CREATION FORM
        lblCampaignName: { es: 'Nombre de la campaña', en: 'Campaign Name' },
        lblCampaignNameExplan: { es: 'Elija un nombre para su campaña, así podrá encontrarla fácilmente. Por ejemplo: "Promoción Octubre"', en: 'Choose a name for your campaign so you can easily find it. For example: "October sales"' },
        lblCampaignSubject: { es: 'Asunto', en: 'Subject' },
        lblCampaignSubjectExplan: { es: 'Seleccione un asunto que describa claramente el contenido de su e-mail. Es lo primero que verá su destinatario. Por ejemplo: «Venta privada: 25 % de descuento en nuestra nueva colección»', en: 'Select a subject that clearly describes the content of your email. It is the first thing your recipient will see. For example: «Private sale: 25% discount on our new collection»' },
        lblCampaigImg: { es: 'Importar diseño', en: 'Import Image' },
        lblCampaigImgExplan: { es: 'Crea una campaña cargando una imagen o archivo ZIP que contenga el HTML', en: 'Create a campaign by loading an image or ZIP file that contains the HTML' },
        lblTestEmail: { es: "Dirección de correo electrónico", en: "Email Adress" },
        lblTestEmailExplan: { es: "Escriba el email para enviar una prueba de la campaña", en: "Write the email direction to sent a test of the campaign" },
        lblCallToAction: { es: "Redireccionar hacia", en: "Redirect Url" },
        lblCallToActionExplan: { es: "Dirección web a la cual redireccionar al usuario cuando interactue con la imagen", en: "Web url to redirect the user after clicking on the image" },
        lblCallToActionCuponExplan: { es: "Al enviar un cupón el email redireccionará al usuario a la página con la información del cupón canjeado", en: "When a coupon is sent the email will redirect the client to a page with the claimed coupon information" },
        lblCuponExplan: { es: "Selecciona un cupón para enviar en tu campaña", en: "Select a cupon to send in your campaign" },
        lblDragDropExplan: { es: "Crea un email compatible con distintos dispositivos", en: "Create an email compatible with different devices" },
        lblSentTestEmail:{es:"Enviar correo de prueba", en:"Send Test Email"},
        lblSaveAndContinue:{es:"Guardar y continuar", en:"Save and continue"},
        lblBlankDesing:{es:"Empezar con diseño en blanco", en:"Restart e-mail desing"}
    }

    for (const l in resources) {
        switch (lang) {
            case "es":
                r[l] = resources[l].es;
                break;

            case "en":
                r[l] = resources[l].en;
                break;

            default:

                break;
        }
    }

    return r;
}

var CampaignSmsCreatorLang = lang => {
    var r = [];
    let resources = {
        lblStepConf: { es: 'Configuración', en: 'Configuration' },
        lblStepContent: { es: 'Contenido', en: 'Content' },
        lblStepSegments: { es: 'Destinatarios', en: 'Recipients' },
        lblStepConfirmation: { es: 'Confirmación', en: 'Confirmation' },
        lblNext: { es: 'Siguiente', en: 'Next' },
        lblCreateCampaign: { es: 'Crear Campaña', en: 'Create Campaign' },
        lblClients: { es: 'Clientes', en: 'Clients' },
        lblSelectedPeople: { es: "Total de la población seleccionada", en: "Total of the selected poblation" },
        lblCreateFromCustomSegment: { es: 'Enviar campaña al segmento', en: 'Send campaign tos' },
        lblSelect: { es: 'Seleccionar', en: 'Select' },
        lblAccept: { es: 'Aceptar', en: 'Accept' },
        lblCancel: { es: 'Cancelar', en: 'Cancel' },
        //SMS CAMPAIGN FORM
        lblCampaignName: { es: 'Nombre de la campaña', en: 'Campaign Name' },
        lblCampaignTextMessage: { es: 'Texto', en: 'Text' },
        lblCampaignTextMessageExplan: { es: 'Contenido del mensaje', en: 'Contents of the message' },
        lblAvailableCharacter: { es: "Caracteres disponibles", en: "Available Characters" },
        lblAvailableCharactersAlert: { es: "¡Se supero la cantidad de caracteres disponibles para un solo mensaje!", en: "Available characters for one text were exceed!" },
        lblTestPhoneNumber: { es: "Mensaje de prueba", en: "Test Message" },
        lblTestPhoneNumberExplan: { es: "Escribir el número en formato internacional. Ej: (506878586882)", en: "Write the number in international format,. Ex: (506878586882)" },
        lblCreateAndSendCampaign: { es: "Enviar campaña", en: "Send campaign" },
        lblCreateAndSaveCampaign: { es: "Guardar borrador", en: "Save campaign" },
        lblCreateAndScheduleCampaign: { es: "Programar fecha de envío", en: "Schedule campaign" },
        lblRetain: { es: 'A retener', en: 'Retain' },
        lblIncrementM: { es: 'Incrementar M.', en: 'Increment A.' },
        lblIncrementF: { es: 'Incrementar F.', en: 'Increment F.' },
        lblBringBack: { es: 'Traer de Regreso.', en: 'Bring Back' },
        lblOccasionals: { es: 'Ocasionales', en: 'Occasionals' },
        lblSegmentTab: { es: 'Segmentos', en: 'Segmentos' },
        lblCustomSegmentsTab: { es: 'Segmentos Personalizados', en: 'Custom Segments' },
        lblSegmentListSearchBar: { es: 'Busca un segmento por nombre', en: 'Search by name' },
        lblEditMessage: { es: "Escribir mensaje de texto", en: "Write text message" },
        lblCuponExplan: { es: "Selecciona un cupón ", en: "Select a cupon " },
    }

    for (const l in resources) {
        switch (lang) {
            case "es":
                r[l] = resources[l].es;
                break;

            case "en":
                r[l] = resources[l].en;
                break;

            default:

                break;
        }
    }

    return r;
}

var CampaignFacebookCreatorLang = lang => {
    var r = [];
    let resources = {
        lblStepLogin: { es: 'Login', en: 'Login' },
        lblStepSegments: { es: 'Seleccionar Audiencia', en: 'Select Audience' },
        lblStepConfirmation: { es: 'Confirmación', en: 'Confirmation' },
        lblNext: { es: 'Siguiente', en: 'Next' },
        lblCreateCampaign: { es: 'Crear Campaña', en: 'Create Campaign' },
        lblClients: { es: 'Clientes', en: 'Clients' },
        lblSelectedPeople: { es: "Total de la población seleccionada", en: "Total of the selected poblation" },
        lblCreateFromCustomSegment: { es: 'Enviar campaña al segmento', en: 'Send campaign tos' },
        lblSelect: { es: 'Seleccionar', en: 'Select' },
        lblAccept: { es: 'Aceptar', en: 'Accept' },
        lblCancel: { es: 'Cancelar', en: 'Cancel' },
        //FACEBOOK
        lblTitleFacebook: { es: 'Crear audiencia de facebook', en: 'Create Facebook Audience' },
        lblSummaryTitleFacebook: { es: "Información de la audienca", en: "Audience Summary" },
        lblFacebookExplan: { es: "Selecciona una población y exporta una audiencia de Facebook Ads", en: "Select and export an audience to Facebook Ads" },
        lblCreateAudience: { es: "Crear audiencia", en: "Create audience" },
        lblAudienceName: { es: 'Nombre de la audiencia', en: 'Audience Name' },
        lblAudienceNameExplan: { es: 'Nombre de la audiencia a crear. Este identificador será útil para identificar la población exportada dentro de las herramientas de mercadeo de Facebook', en: 'The name of the audience. This name will be usefull to identify the audience in Facebook`s marketing tools' },
        lblAudienceDescription: { es: 'Descripción', en: 'Description' },
        lblAudienceDescriptionExplan: { es: 'Breve descripción de la audiencia a exportar', en: 'Small description of the audience to export' },
    }

    for (const l in resources) {
        switch (lang) {
            case "es":
                r[l] = resources[l].es;
                break;

            case "en":
                r[l] = resources[l].en;
                break;

            default:

                break;
        }
    }

    return r;
}

var SegmentSelectorLang = lang => {
    var r = [];
    let resources = {
        lblRetain: { es: 'A retener', en: 'Retain' },
        lblIncrementM: { es: 'Incrementar M.', en: 'Increment A.' },
        lblIncrementF: { es: 'Incrementar F.', en: 'Increment F.' },
        lblBringBack: { es: 'Traer de Regreso.', en: 'Bring Back' },
        lblOccasionals: { es: 'Ocasionales', en: 'Occasionals' },
        lblSegmentTab: { es: 'Segmentos', en: 'Segmentos' },
        lblCustomSegmentsTab: { es: 'Segmentos Personalizados', en: 'Custom Segments' },
        lblSegmentListSearchBar: { es: 'Busca un segmento por nombre', en: 'Search by name' },
        lblClients: { es: 'Clientes', en: 'Clients' },
        lblSelectedPeople: { es: "Total de la población seleccionada", en: "Total of the selected poblation" },
        lblCreateFromCustomSegment: { es: 'Enviar campaña al segmento', en: 'Send campaign tos' },
        lblSelect: { es: 'Seleccionar', en: 'Select' },
    }

    for (const l in resources) {
        switch (lang) {
            case "es":
                r[l] = resources[l].es;
                break;

            case "en":
                r[l] = resources[l].en;
                break;

            default:

                break;
        }
    }

    return r;
}

var FinalSelectionFormLang = lang => {
    var r = [];
    let resources = {
        //Summary
        lblSummaryTitle: { es: "Información de la campaña", en: "Campaign Summary" },
        lblSummaryType: { es: "Tipo de campaña", en: "Campaign Type" },
        lblSummaryName: { es: "Nombre", en: "Name" },
        lblSummarySubject: { es: "Sujeto", en: "Subject" },
        lblSummaryNameAudience: { es: "Nombre de Audiencia", en: "Audience Name" },
        lblSummaryAudienceDescription: { es: "Descripción de audiencia", en: "Audience Description" },
        lblSummaryTextMessage: { es: "Contenido del mensaje", en: "Message content" },
        lblSummarySegments: { es: "Segmentos", en: "Segments" },
        lblSummaryTotalPoblation: { es: "Total de poblacion seleccionada", en: "Total poblation" },
        lblSummaryTotalPoblationExplan: { es: "Conteo total de clientes de los segmentos seleccionados", en: "Total count of customers of the selected segments" },
        lblSummaryAvailablePoblation: { es: "Total de poblacion disponible", en: "Available poblation" },
        lblSummaryAvailablePoblationExplanEmail: { es: "Conteo total de clientes de la población seleccionada que cuentan con un correo electrónico disponible para enviar la campaña", en: "Total count of customers who have an email available to send the campaign" },
        lblSummaryAvailablePoblationExplanSms: { es: "Conteo total de clientes de la población seleccionada  que cuentan con un número de teléfono disponible para enviar la campaña de mensajes de texto", en: "Total count of customers who have an phone number available to send the campaign" },
        lblSummaryAvailableCoupons: { es: "Total de cupones disponibles", en: "Available cupon count" },
    }

    for (const l in resources) {
        switch (lang) {
            case "es":
                r[l] = resources[l].es;
                break;

            case "en":
                r[l] = resources[l].en;
                break;

            default:

                break;
        }
    }

    return r;
}

var SmsCampaignCardLang = lang => {
    var r = [];
    let resources = {
        //Global
        lblSent: { es: 'Enviados', en: 'Sent' },
        lblDelivered: { es: 'Entregados', en: 'Delivered' },
        lblOpened: { es: 'Abiertos', en: 'Opened' },
        lblBounced: { es: 'Rebotados', en: 'Bounced' },
        lblEmails: { es: 'Correos', en: 'Emails' },
        lblBtnDetailCampaign: { es: 'Ver Detalle', en: 'View Detail' },
        lblOpenedEmails: { es: 'Correos Abiertos', en: 'Opened Emails' },
        lblSentSMS: { es: 'SMS enviados', en: 'Sent SMS' },
        lblDateSent: { es: "Fecha de envío", en: "Sent Date" },
        lblDateNotSentYet: { es: "Fecha programada de envío", en: "Schedule date" },
        lblBtnRefreshCampaign: { es: "Refrescar datos", en: "Refresh Data" },
        lblNotSentYet: { es: "La campaña aún no ha sido programada", en: "The campaign has not yet been sent" },
        lblPending: { es: "La campaña fue programada para su envío", en: "The campaign has been schedule" },
        lblSendCampaign: { es: "Enviar Campaña", en: "Send Campaign" },
    }

    for (const l in resources) {
        switch (lang) {
            case "es":
                r[l] = resources[l].es;
                break;

            case "en":
                r[l] = resources[l].en;
                break;

            default:

                break;
        }
    }

    return r;
}

//EXPORTS DE LOS DIFERENTES METODOS -------------------------------------------------------------------------------------------------
export {
    GlobalCountLang, GlobalPointsSummaryLang,
    BucketSelectorLang, NoBucketLang,
    CountsByGenderLang,
    AgeBracketsLang,
    ProductsByGenderLang,
    ProcessingPeriodsLang,
    SegmentsLang,
    MenuLang,
    FiltersLang,
    SegmentDetailLang,
    ClientDetailLang,
    SalesForceSummaryLang,
    SalesForceTabsLang,
    MySegmentsLang,
    MyExportsLang, MyExportsClientListLang, MyExportsCreatorLang,
    RFMatrixLang, crmDropdownLang,
    CampaignsLang, CampaignCardLang, CampaignDetailLang, CampaignCreatorLang, CampaignEmailCreatorLang, CampaignSmsCreatorLang, CampaignFacebookCreatorLang, SegmentSelectorLang, SmsCampaignCardLang, FinalSelectionFormLang
}
