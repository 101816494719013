import React from "react";
import classnames from 'classnames';
import FetchCmp from "../../components/FetchCmp/fetchCmp"
import { CampaignDetailLang } from '../../lang'
import ReactTooltip from "react-tooltip";
// reactstrap components
import {
    Card, CardBody,
    Container, Row, Col,
    Button,
    TabContent, TabPane,
    FormGroup, Input,
    Dropdown, DropdownToggle, DropdownMenu, DropdownItem,
    Nav, NavItem, NavLink, Progress, Table
} from "reactstrap";
import NumberFormat from "react-number-format";
import { Line, Bar, Pie, Polar } from "react-chartjs-2";
import { ReactComponent as Icon4 } from '../../assets/icons/icon4.svg';



class CampaignDetail extends React.Component {
    constructor(props) {
        super(props)
        this._isMounted = false;
        this.labels = CampaignDetailLang(props.lang)
        this.state = {
            tab: "1",
            body: { campaignId: props.campaign["ID-cortteza"], page: 1, textSearch: null, type: "email" },
            contactList: null,
            count: null,
            textSearch: null,
        }
        this.lineChartOptions = {
            responsive: true,
            aspectRatio: false,
            legend: {
                display: true,
                labels: {
                    fontColor: '#9a9a9a'
                }
            },
            scales: {
                yAxes: [
                    {
                        gridLines: {
                            drawBorder: true,
                            color: "rgba(29,140,248,0.1)",
                            zeroLineColor: "#bac4ce"
                        },
                        ticks: {
                            fontColor: "#9a9a9a",
                            maxTicksLimit: 10,
                            userCallback: function (value, index, values) {
                                var strVal = value.toString();

                                if (value >= 1000 && value < 10000) {
                                    //strVal = value.toString();
                                    return strVal.substring(0, 1) + "," + strVal.substring(1);
                                }

                                if (value >= 10000 && value < 100000) {
                                    //strVal = value.toString();
                                    return strVal.substring(0, 2) + "," + strVal.substring(2);
                                }

                                if (value >= 10000 && value < 1000000) {
                                    //strVal = value.toString();
                                    return strVal.substring(0, 3) + "," + strVal.substring(3);
                                }

                                if (value >= 1000000) {
                                    value = (value / 1000000);
                                    strVal = value.toString();
                                    return strVal + "m";
                                }

                                return strVal;
                            }
                        }
                    }
                ],
                xAxes: [
                    {
                        gridLines: {
                            drawBorder: true,
                            color: "rgba(29,140,248,0.1)",
                            zeroLineColor: "#bac4ce"
                        },
                        ticks: {
                            fontColor: "#9a9a9a",
                            fontSize: 12
                        }
                    }
                ],
            },
            tooltips: {
                callbacks: {
                    label: function (tooltipItem, data) {
                        let v = tooltipItem.yLabel;
                        return v.toLocaleString();
                    }
                }
            }
        }
    }

    //0. REACT LIFECYCLE
    async componentDidMount() {
        this._isMounted = true;
        let cacheId = 'GetCampaignPoblation-' + this.props.bucket.bucketId + "-" + this.props.campaign["ID-cortteza"];
        let data = await this.props.askFor('campaigns', "getCampaignPoblation", [], false, this.state.body, cacheId);
        if (data != null) {
            this.setState({ contactList: data.list, count: data.count })
        }
    }

    async componentDidUpdate(prevProps, prevState) {
        if (prevState.body.page != this.state.body.page) {
            if (this._isMounted) {
                let cacheId = 'GetCampaignPoblation-' + this.props.bucket.bucketId + "-" + this.props.campaign["ID-cortteza"] + "-" + this.state.body.page;
                let data = await this.props.askFor('campaigns', "getCampaignPoblation", [], false, this.state.body, cacheId);
                if (data != null) {
                    this.setState({ contactList: data.list, count: data.count })
                }
            }
        }

        if (prevProps.lang != this.props.lang) {
            if (this._isMounted) {
                this.labels = CampaignDetailLang(this.props.lang)
                this.forceUpdate()
            }
        }
    }


    //1. TABS 
    changeTabs(tab) {
        this.setState({ tab: tab });
    }

    nextPage() {
        let pageCount = Math.ceil(this.state.count / 10);
        if (this.state.body.page + 1 < pageCount) {
            this.setState(state => {
                let bod = state.body;
                bod.page++;
                return ({ body: bod })
            })
        }
        else {
            //reach last page
        }

    }

    prevPage() {
        if (this.state.page == 0) { return /*Reach firts page */ }
        this.setState(state => {
            let bod = state.body;
            bod.page--;
            return ({ body: bod })
        })
    }

    firstPage() {
        let currentPage = this.state.body.page;
        if (currentPage > 0) {
            this.setState(state => {
                let bod = state.body;
                bod.page = 0;
                return ({ body: bod })
            })
        }
    }

    lastPage() {
        let currentPage = this.state.page;
        let clientsCount = this.props.data.count;
        let pageCount = Math.ceil(clientsCount / this._pageSize);
        if (currentPage < pageCount) {
            this.setState(state => {
                let bod = state.body;
                bod.page = pageCount - 1;
                return ({ body: bod })
            })
        }


    }

    //2. CLIENT SEARCH FUNCTION
    handleTextSearch(textSearch) {
        if (this._isMounted) {
            this.setState(state => {
                var b = state.body;
                if (textSearch == "") {
                    b.textSearch = null;
                }
                else {
                    b.textSearch = textSearch;
                }

                return ({ body: b });
            })
        }
    }

    async searchClient() {
        if (this._isMounted) {
            this.setState({ contactList: null, count: null })
            let cacheId = 'GetCampaignPoblation-' + this.props.bucket.bucketId + "-" + this.props.campaign["ID-cortteza"] + "-" + this.state.body.page + "-search-" + this.state.body.textSearch;
            let data = await this.props.askFor('campaigns', "getCampaignPoblation", [], false, this.state.body, cacheId);
            if(data != null){
                this.setState({ contactList: data.list, count: data.count })
            }
        }
    }

    // PRE-RENDER FUNCTIOSNS

    //Z. Render
    render() {
        return (
            <div className="content">
                <Container fluid>
                    <Row className="titleRow">
                        <h1>{this.props.campaign.name}</h1>
                        <Button onClick={() => { this.props.changeScreen({ screen: 'list' }) }}><span>&#10229;</span> {this.labels.btnGetBack}</Button>
                    </Row>
                    <Row className="segmentTabs">
                        <Col lg="12" md="12" sm="12" xs="12">
                            <Nav tabs>
                                <NavItem>
                                    <NavLink onClick={() => this.changeTabs("1")} className={classnames({ active: this.state.tab == "1" })}><i className="ti-align-justify" />{this.labels.lblTabSummary}</NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink onClick={() => this.changeTabs("2")} className={classnames({ active: this.state.tab == "2" })}><i className="ti-user" />{this.labels.lblTabContacts}</NavLink>
                                </NavItem>
                            </Nav>
                        </Col>
                    </Row>
                    <Row>
                        <TabContent activeTab={this.state.tab} className="container-fluid">
                            <TabPane tabId="1">
                                <div className="innerSection">
                                    <div className="firstRow">
                                        <div className="card statsCard">
                                            <div className="mainHalf">
                                                <h2>{this.state.count != null ? this.state.count : 0}</h2>
                                                <h4>{this.labels['lblParticipants']}</h4>
                                            </div>
                                            <div className="secondHalf">
                                                <div>
                                                    <b>{this.labels['lblSubject']}</b>
                                                    <p>{this.props.campaign.subject}</p>
                                                </div>
                                                <div>
                                                    <b>{this.labels['lblSentDate']}</b>
                                                    <p>{this.props.campaign.formatDate}</p>
                                                </div>
                                                <div>
                                                    <b>{this.labels['lblSegments']}</b>
                                                    <div className="segments">
                                                        {this.props.campaign.segments.map((segment, key) => {
                                                            return (
                                                                <div key={key}>
                                                                    <span>{segment.name}</span>
                                                                </div>
                                                            )
                                                        })}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="card">
                                            <div className="croChart">
                                                <div className="chartTitle"><b>{this.labels['lblCroTitle']}</b></div>
                                                <Line
                                                    data={this.props.campaign.timelineDataset}
                                                    options={this.lineChartOptions}
                                                    height={100}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="innerSection">
                                    <div className="firstRow">
                                        <div className="card mainStats">
                                            <div className="statsTable">
                                                <div className="entregados">
                                                    <b>{this.labels['lblDelivered']}</b>
                                                    <b className="bPerc">{this.props.campaign.formatStats.delivered.perc}%</b>
                                                    <span><b>{this.props.campaign.formatStats.delivered.num} {this.labels['lblEmails']}</b></span>
                                                </div>
                                                <div className="abiertos">
                                                    <b>{this.labels['lblOpened']}</b>
                                                    <b className="bPerc">{this.props.campaign.formatStats.opened.singleOpened.perc}%</b>
                                                    <span><b>{this.props.campaign.formatStats.opened.singleOpened.num} {this.labels['lblEmails']}</b></span>
                                                </div>
                                                <div className="rebotados">
                                                    <b>{this.labels['lblBounced']}</b>
                                                    <b className="bPerc">{this.props.campaign.formatStats.bounced.total.perc}%</b>
                                                    <span><b>{this.props.campaign.formatStats.bounced.total.num} {this.labels['lblEmails']}</b></span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="card rateBar">
                                            <div className="barInfo">
                                                <b>{this.labels['lblOpenedRate']}</b>
                                                <b className="barPercentage">{this.props.campaign.formatStats.opened.singleOpened.perc}%</b>
                                            </div>
                                            <div>
                                                <Progress value={this.props.campaign.formatStats.opened.singleOpened.perc} />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="card detailStats">
                                        <ReactTooltip id="campaignDetailStats" place="right" className="tooltip" />
                                        <div className="r">
                                            <div>
                                                <p>{this.labels['lblSoftBounces']}</p>
                                                <i className="ti-help" data-tip={this.labels.lblSoftBounceTooltip} data-for="campaignDetailStats"></i> {/* tooltip */}
                                            </div>
                                            <p className="pink">
                                                <small>{this.props.campaign.formatStats.bounced.softBounced.num} {this.labels['lblEmails']}</small>
                                                {this.props.campaign.formatStats.bounced.softBounced.perc}%
                                        </p>
                                        </div>
                                        <div className="r">
                                            <div>
                                                <p>{this.labels['lblHardBounces']}</p>
                                                <i className="ti-help" data-tip={this.labels.lblHardBounceTooltip} data-for="campaignDetailStats"></i>
                                            </div>
                                            <p className="pink">
                                                <small>{this.props.campaign.formatStats.bounced.hardBounced.num} {this.labels['lblEmails']}</small>
                                                {this.props.campaign.formatStats.bounced.hardBounced.perc}%
                                        </p>
                                        </div>
                                        <div className="r">
                                            <div>
                                                <p>{this.labels['lblUniqueViews']}</p>
                                                <i className="ti-help" data-tip={this.labels.lblUniqueViewsTooltip} data-for="campaignDetailStats"></i>
                                            </div>
                                            <p className="pink">
                                                <small>{this.props.campaign.formatStats.opened.singleOpened.num} {this.labels['lblEmails']}</small>
                                                {this.props.campaign.formatStats.opened.singleOpened.perc} %
                                        </p>
                                        </div>
                                        <div className="r">
                                            <div>
                                                <p>{this.labels['lblMultipleViews']}</p>
                                                <i className="ti-help" data-tip={this.labels.lblMultipleViewsTooltip} data-for="campaignDetailStats"></i>
                                            </div>
                                            <p className="pink">
                                                <small>{this.props.campaign.formatStats.opened.multiOpened.num} {this.labels['lblEmails']}</small>
                                                {this.props.campaign.formatStats.opened.multiOpened.perc}%
                                        </p>
                                        </div>
                                    </div>
                                </div>
                            </TabPane>

                            <TabPane tabId="2">
                                <div className="content-section">
                                    <div className="innerSection">
                                        <div className="contactsTopBar">
                                            {/* <button className="btn"> <i className="ti-download"></i> {this.labels['lblButtonExport']}
                                                {this.props.campaign['contactList'].length} {this.labels['lblEmails']}
                                            </button> */}
                                        </div>
                                        <div>
                                            <Card>
                                                <Row className="searchTextRow">
                                                    <Input type="text" placeholder={this.labels.lblClientSearchBar} onChange={e => { this.handleTextSearch(e.target.value) }} />
                                                    <button onClick={() => { this.searchClient() }}><i className="ti-search"></i></button>
                                                </Row>

                                                <Table responsive className="tableClients">
                                                    <thead>
                                                        <tr>
                                                            <th>{this.labels['lblMemberNumber']}</th>
                                                            <th>{this.labels['lblName']}</th>
                                                            <th></th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {this.state.contactList != null ?
                                                            this.state.contactList.map((client, key) => {
                                                                return (
                                                                    <tr key={key}>
                                                                        <td>{client.CLIENTE[0]}</td>
                                                                        <td>{client.NOMBRE}</td>
                                                                        <td>
                                                                            <button className="btnClientDetail"
                                                                                onClick={() => {
                                                                                    this.props.changeScreen({ screen: "client", clientId: client.CLIENTE[0], campaignDetail: this.props.campaign })
                                                                                }}
                                                                            >
                                                                                {this.labels['lblButtonViewClient']}
                                                                            </button>
                                                                        </td>
                                                                    </tr>
                                                                )
                                                            })
                                                            :
                                                            <tr></tr>
                                                        }
                                                    </tbody>
                                                </Table>
                                                {this.state.count != null && this.state.count > 10 ?
                                                    <Row className="text-center">
                                                        <Col>
                                                            <button className="pager-button" onClick={() => { this.firstPage() }}>{this.labels.btnFirst}</button>
                                                            <button className="pager-button" onClick={() => { this.prevPage() }}>
                                                                <i className="ti-angle-left"></i></button>
                                                            <button className="pager-button" onClick={() => { this.nextPage() }}>
                                                                <i className="ti-angle-right"></i>
                                                            </button>
                                                            <button className="pager-button" onClick={() => { this.lastPage() }}>{this.labels.btnLast}</button>
                                                        </Col>
                                                    </Row>
                                                    : ""}
                                            </Card>
                                        </div>
                                    </div>
                                </div>
                            </TabPane >
                        </TabContent >
                    </Row >
                </Container >
            </div >
        )
    }

}




export default CampaignDetail;