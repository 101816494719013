import React from "react";
import classnames from 'classnames';
import {
    Card, CardBody,
    Container, Row, Col,
    Button,
    TabContent, TabPane,
    Input,
    Nav, NavItem, NavLink, Table,
} from "reactstrap";
import { CSSTransition } from "react-transition-group"
import NumberFormat from "react-number-format";
import { ReactComponent as RetainIcon } from '../../../../assets/icons/createCampaign/retain-icon.svg';
import { ReactComponent as IncreaseMIcon } from '../../../../assets/icons/createCampaign/increaseM-icon.svg';
import { ReactComponent as IncreaseFIcon } from '../../../../assets/icons/createCampaign/increaseF-icon.svg';
import { ReactComponent as BringBackIcon } from '../../../../assets/icons/createCampaign/bringBack-icon.svg';
import { ReactComponent as OccasionalsIcon } from '../../../../assets/icons/createCampaign/ocasionals-icon.svg';
import { SegmentSelectorLang } from '../../../../lang';

class SegmentSelectionForm extends React.Component {
    constructor(props) {
        super(props)
        this._isMounted = false;
        this.labels = SegmentSelectorLang(props.lang);
        this.state = {
            tab: "1",
            segmentData: null,
            segments: [],
            selectionCount: 0,
            customSegmentList: null,
            customSegment: null,
        }
        this.isSegmentActive = this.isSegmentActive.bind(this);
        this.setSegments = this.setSegments.bind(this);
    }

    //0. REACT LIFECYCLE FUNCTIONS
    async componentDidMount() {
        this._isMounted = true;
        try {
            // this.props.setLoadingStep(true);
            var segmentData = await this.props.askFor('segments', 'segments/getbasedata');
            let cacheId = "CustomSegmentListNoCache" + Math.round(Math.random() * 1000);
            let customSegmentList = await this.props.askFor('segments', 'segments/getcustomsegmentlist', [], false, {}, cacheId)
            if (customSegmentList != null && segmentData != null) {
                this.setState({ segmentData: segmentData.segments, customSegmentList: customSegmentList }, () => { /* this.props.setLoadingStep(false) */ })
            }

        }
        catch (err) {
            console.log(err);
        }
    }

    //1. SEGMENTS && CUSTOM SEGMENTS FUNCTIONS
    selectCustomSegment(segment) {
        if (this._isMounted) {
            this.setState({ customSegment: segment });
        }
    }

    searchSegment(segmentName) {
        this.setState(state => {
            let indexMove = null
            state.customSegmentList.forEach((segment, index) => {
                if (segment.segmentName.toUpperCase().indexOf(segmentName.toUpperCase()) != -1) {
                    state.customSegmentList.splice(index, 1);
                    state.customSegmentList.unshift(segment);
                }
            });

            return ({ customSegmentList: state.customSegmentList })
        })
    }

    isSegmentActive = id => {
        var isActive = false;
        this.state.segments.forEach(s => {
            if (s.id === id) {
                isActive = true;
            }
        })

        return isActive;
    }

    setSegments = (val) => {
        if (this._isMounted) {
            this.setState(state => {
                var c = state.segments;
                var sCount = state.selectionCount;
                if (this.isSegmentActive(val.id)) {
                    let ind = null;
                    c.forEach((s, index) => { if (s.id == val.id) { ind = index } })
                    c.splice(ind, 1);
                    sCount -= val.count;
                }
                else {
                    if (this.state.tab === "1") {
                        c.push(val);
                        sCount += val.count;
                    }
                    else if (this.state.tab === "2") {
                        c.push(val);
                        sCount = val.count;
                    }
                }

                if (sCount < 0) { sCount = 0 }
                return ({ segments: c, selectionCount: sCount });
            })
        }
    }

    resetSegments = () => {
        this.setState(state => {
            var s = state.segments;
            s = []
            return ({ segments: s, selectionCount: 0 })
        })
    }

    //2.TABS FUCNTIONS
    changeTabs(tab) {
        this.setState({ tab: tab, customSegment: null }, () => { this.resetSegments() })
    }

    //Y. PRE-RENDER FUNCTIONS
    getFinalRow() {
        var row = null
        switch (this.state.tab) {
            case "1": row =
                <Row className="peopleCounter">
                    <span>{this.labels.lblSelectedPeople}:</span>
                    {" "}
                    <NumberFormat className="count" displayType="text" thousandSeparator={true} value={this.state.selectionCount} />
                    {" "}
                    <span className="count">{this.labels.lblClients}</span>
                </Row>;
                break;
            case "2": row =
                <Row className="peopleCounter">
                    <span>{this.labels.lblCreateFromCustomSegment}:</span>
                    {" "}
                    {this.state.customSegment != null ? <span className="customSegmentName">{this.state.customSegment.segmentName}</span> : ""}
                    {" "}
                    {this.state.customSegment != null ? <span>{this.labels.lblSelectedPeople}:</span> : ""}
                    {" "}
                    <NumberFormat className="count" displayType="text" thousandSeparator={true} value={this.state.selectionCount} />
                    {" "}
                    <span className="count">{this.labels.lblClients}</span>
                </Row>;
                break;
        }

        return row;
    }

    render() {
        if (this.state.segmentData != null && this.state.customSegmentList != null) {
            return (
                <Container fluid className="segmentSelectorForm">
                    <Row>
                        <Nav className="segmentTabs" tabs>
                            <NavItem>
                                <NavLink onClick={() => this.changeTabs("1")} className={classnames({ active: this.state.tab == "1" })}>{this.labels.lblSegmentTab}</NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink onClick={() => this.changeTabs("2")} className={classnames({ active: this.state.tab == "2" })}>{this.labels.lblCustomSegmentsTab}</NavLink>
                            </NavItem>
                        </Nav>
                    </Row>

                    <Row>
                        <TabContent activeTab={this.state.tab} className="container-fluid">
                            <TabPane tabId="1" >
                                <div className="segmentRow">
                                    <SelectCard
                                        segment
                                        icon={<RetainIcon />}
                                        name={this.labels.lblRetain}
                                        title={this.state.segmentData.segment1.count}
                                        function={this.setSegments}
                                        functionValues={{ id: 1, name: "A retener", count: this.state.segmentData.segment1.count }}
                                        isActive={this.isSegmentActive(1)}
                                    />
                                    <SelectCard
                                        segment
                                        icon={<IncreaseMIcon />}
                                        name={this.labels.lblIncrementM}
                                        title={this.state.segmentData.segment2.count}
                                        function={this.setSegments}
                                        functionValues={{ id: 2, name: "Incrementar M", count: this.state.segmentData.segment2.count }}
                                        isActive={this.isSegmentActive(2)}
                                    />
                                    <SelectCard
                                        segment
                                        icon={<IncreaseFIcon />}
                                        name={this.labels.lblIncrementF}
                                        title={this.state.segmentData.segment3.count}
                                        function={this.setSegments}
                                        functionValues={{ id: 3, name: "Incrementar F", count: this.state.segmentData.segment3.count }}
                                        isActive={this.isSegmentActive(3)}
                                    />
                                    <SelectCard
                                        segment
                                        icon={<BringBackIcon />}
                                        name={this.labels.lblBringBack}
                                        title={this.state.segmentData.segment4.count}
                                        function={this.setSegments}
                                        functionValues={{ id: 4, name: "Traer de Regreso", count: this.state.segmentData.segment4.count }}
                                        isActive={this.isSegmentActive(4)}
                                    />
                                    <SelectCard
                                        segment
                                        icon={<OccasionalsIcon />}
                                        name={this.labels.lblOccasionals}
                                        title={this.state.segmentData.segment5.count}
                                        function={this.setSegments}
                                        functionValues={{ id: 5, name: "Ocasionales", count: this.state.segmentData.segment5.count }}
                                        isActive={this.isSegmentActive(5)}
                                    />
                                </div>
                            </TabPane>

                            <TabPane tabId="2">
                                <Card>
                                    <CardBody>
                                        <Input type="text" placeholder={this.labels.lblSegmentListSearchBar} onChange={e => { this.searchSegment(e.target.value) }} />
                                        <Table className="customSegmentTable">
                                            <thead>
                                                <tr>
                                                    <th>Nombre</th>
                                                    <th>Conteo</th>
                                                    <th></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {this.state.customSegmentList.map((segment, key) => {
                                                    return (
                                                        <tr className={classnames({ active: (this.state.customSegment != null && this.state.customSegment.segmentID == segment.segmentID) })} key={key}>
                                                            <td>{segment.segmentName}</td>
                                                            <td>{segment.baseData[segment.baseData.length - 1].count}</td>
                                                            <td>
                                                                <Button className="selectButton" onClick={() => {
                                                                    this.resetSegments();
                                                                    this.setSegments({
                                                                        id: segment.segmentID,
                                                                        name: segment.segmentName,
                                                                        count: segment.baseData[segment.baseData.length - 1].count
                                                                    })
                                                                    this.selectCustomSegment(segment);
                                                                }}
                                                                >{this.labels.lblSelect}
                                                                </Button>
                                                            </td>
                                                        </tr>
                                                    )
                                                })}
                                            </tbody>
                                        </Table>
                                    </CardBody>
                                </Card>
                            </TabPane>
                        </TabContent>
                    </Row>

                    {this.getFinalRow()}

                    {this.state.selectionCount + this.props.currentEmailCuota > this.props.limitEmailCuota ?
                        <CSSTransition classNames="animated" timeout={1000} in={true} appear={true}>
                            <Row className="peopleCounter">
                                <span className="count">La población seleccionada supera la cuota de correos mensual</span>
                            </Row>
                        </CSSTransition>
                        : ""
                    }

                    { this.props.type != undefined && this.props.type == 'sms' ?
                        <CSSTransition classNames="animated" timeout={1000} in={true} appear={true}>
                            <Row className="buttonsRow">
                                <Button onClick={() => { this.props.exportFunction(this.state.segments, this.state.selectionCount) }}>Siguiente</Button>
                            </Row>
                        </CSSTransition>
                        :
                        this.state.segments != null && this.state.selectionCount !== 0 && this.state.selectionCount + this.props.currentEmailCuota < this.props.limitEmailCuota ?
                            <CSSTransition classNames="animated" timeout={1000} in={true} appear={true}>
                                <Row className="buttonsRow">
                                    <Button onClick={() => { this.props.exportFunction(this.state.segments, this.state.selectionCount) }}>Siguiente</Button>
                                </Row>
                            </CSSTransition>
                            :
                            ""
                    }

                </Container>
            )
        }
        else {
            return (
                <div style={{ width: "100%", textAlign: "center" }}>
                    {this.props.loadingSpinner}
                    <h3>Cargando Datos de Segmentos</h3>
                </div>
            )
        }

    }
}

class SelectCard extends React.Component {
    //Props: className | isActive | lg | md | sm | icon | title | text | function | functionValues
    constructor(props) {
        super(props)
        this.state = { isActive: props.isActive }
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.isActive != this.props.isActive) {
            this.setState({ isActive: this.props.isActive })
        }
    }

    render() {
        return (
            this.props.segment == undefined ?
                <Col className={this.props.className || "selectCard"} lg={this.props.lg} md={this.props.md} sm={this.props.sm}>
                    <Card>
                        <CardBody className="container">
                            <div className="icon">{this.props.icon}</div>
                            {this.props.name ? <h5>{this.props.name}</h5> : ""}
                            <b>{this.props.title}</b>
                            <p>{this.props.text}</p>
                            <div className="selector">
                                <span className={classnames({ active: this.state.isActive })} onClick={() => { this.props.function(this.props.functionValues); }}>
                                    <div className="ball" />
                                </span>
                            </div>
                        </CardBody>
                    </Card>
                </Col>
                :
                <Col className={this.props.className || "segmentCard"} style={{ width: "20%" }}>
                    <Card>
                        <CardBody className="container">
                            <div className="icon">{this.props.icon}</div>
                            {this.props.name ? <h5>{this.props.name}</h5> : ""}
                            <b>{this.props.title} CLIENTES</b>
                            <p>{this.props.text}</p>
                            <div className="selector">
                                <span className={classnames({ active: this.state.isActive })} onClick={() => { this.props.function(this.props.functionValues); }}>
                                    <div className="ball" />
                                </span>
                            </div>
                        </CardBody>
                    </Card>
                </Col>
        )
    }


}

export default SegmentSelectionForm;