import React, { useState, useEffect, useRef, useReducer } from "react";
import classnames from 'classnames';
// reactstrap components
import {
    Container, Row, Col,
    Button,
    Table,
} from "reactstrap";


var Cuponera = props => {

    const pageSize = 2;
    const stepSize = 3;
    const [cuponList, setCuponList] = useState(null);
    const [page, setPage] = useState(1);
    const [isDropDownOpen, setIsDropDownOpen] = useState(false);
    var cId = "";
    if (props.selectedCupon != null) {
        cId = props.selectedCupon.ID
    }
    const [selectedCuponId, setCupon] = useState(cId);



    //API CALL
    useEffect(() => {
        var getCuponList = async () => {
            let cacheId = "GetCuponList" + Math.round(Math.random() * 1000);
            // let cacheCId = "GetCuponCategoryList" + Math.round(Math.random() * 1000);
            try {
                let cuponList = await props.askFor("campaigns", "getCuponList", [], false, {}, cacheId);
                cuponList.filter(c => {
                    let notExpired = new Date(c.ExpirationDate).getTime() > new Date().getTime();
                    let isByRegister = c.CouponType === "R";
                    if (notExpired && isByRegister && c.CouponStatus) {
                        return true;
                    }
                    else {
                        return false;
                    }
                })
                setCuponList(cuponList);
            }
            catch (err) {
                console.log(err)
            }
        }

        getCuponList()
    }, []);

    //PAGINATIOR FUNCTIONS
    var nextPage = () => {
        let pageCount = Math.ceil(cuponList.length / pageSize);
        console.log(pageCount)
        if (page + 1 <= pageCount) {
            setPage(page + 1);
        }
    }

    var prevPage = () => {
        if (page - 1 != 0) {
            setPage(page - 1);
        }
    }

    var firstPage = () => {
        setPage(1);
    }

    var lastPage = () => {
        let pageCount = Math.ceil(cuponList.length / pageSize);
        setPage(pageCount - 1);
    }

    //Cupon functions
    var getCupon = id => {
        if (cuponList !== null && selectedCuponId !== '') {
            var cup = null;
            cuponList.forEach(c => {
                if (c.ID === id) {
                    cup = c;
                }
            })
            return cup.Name;
        }
    }

    //Pre-render
    var getCupuneraByType = () => {
        var cuponera = <></>;
        switch (props.type) {
            case 'email':
                cuponera = <Table responsive className="cuponListTable">
                    <thead>
                        <tr>
                            <th>Imagen</th>
                            <th>Cantidad</th>
                            <th>Nombre</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {cuponList.map((cupon, key) => {
                            var minKey = (page - 1) * pageSize;
                            var maxKey = page * pageSize;

                            if (page == 1) {
                                if (key <= maxKey) {
                                    return (
                                        <tr key={key} className={classnames({ active: cupon.ID == selectedCuponId })}>
                                            {cupon.ImageList.length > 0 ? <td><img className="cuponImg" src={cupon.ImageList[0].ImageUrl} alt="" /></td> : <td></td>}
                                            <td>{cupon.QuantityAvailable - cupon.QuantityClaimed}</td>
                                            <td>{cupon.Name}</td>
                                            <td><Button onClick={() => { props.setCampaignCupon(cupon); setCupon(cupon.ID) }}>Seleccionar</Button></td>
                                        </tr>
                                    )
                                }
                            }
                            else {
                                if (page > 1) {
                                    if ((key > minKey) && (key <= maxKey)) {
                                        return (
                                            <tr key={key} className={classnames({ active: cupon.ID == selectedCuponId })}>
                                                <td><img className="cuponImg" src={cupon.ImageList[0].ImageUrl} alt="" /></td>
                                                <td>{cupon.QuantityAvailable - cupon.QuantityClaimed}</td>
                                                <td>{cupon.Name}</td>
                                                <td><Button onClick={() => { props.setCampaignCupon(cupon); setCupon(cupon.ID) }}>Seleccionar</Button></td>
                                            </tr>
                                        )
                                    }
                                }
                            }
                        })}
                    </tbody>
                </Table>
                    ; break;
            case "sms":
                cuponera = <div className="cuponDropdown">
                    <h4>Selecciona el cupón correspondiente</h4>
                    <select defaultValue={''} onChange={(e) => {
                        if (e.target.value === '') {
                            setCupon('');
                            props.setCampaignCupon(null);
                        }
                        else {
                            var c = JSON.parse(e.target.value);
                            props.setCampaignCupon(c);
                            setCupon(c.ID);
                        }

                    }}>
                        <option value={''}> Ninguno </option>
                        {cuponList.map((cupon, key) => {
                            let notExpired = new Date(cupon.ExpirationDate).getTime() > new Date().getTime();
                            let isByRegister = cupon.CouponType === "R";
                            if (notExpired && isByRegister && cupon.CouponStatus) {
                                return (
                                    <option key={key} value={JSON.stringify(cupon)} selected={cupon.ID === selectedCuponId}>
                                        {cupon.Name}
                                    </option>
                                )
                            }

                        })}
                    </select>
                </div>
                    ; break;
            default: ; break;
        }

        return cuponera;
    }


    //Render
    if (cuponList != null) {
        return (
            <Container fluid>
                <Row>
                    <Col>
                        {cuponList.length > 0 ?
                            getCupuneraByType()
                            :
                            <h4>Este bucket no cuenta con cupones disponibles</h4>
                        }
                    </Col>
                </Row>

                {cuponList.length > 0 && props.type === 'email' ?
                    <Row className="text-center">
                        <Col>
                            <button className="pager-button" onClick={() => { firstPage() }}>Primera</button>
                            <button className="pager-button" onClick={() => { prevPage() }}>
                                <i className="ti-angle-left"></i></button>
                            <button className="pager-button" onClick={() => { nextPage() }}>
                                <i className="ti-angle-right"></i>
                            </button>
                            <button className="pager-button" onClick={() => { lastPage() }}>Ultima</button>
                        </Col>
                    </Row>
                    :
                    ""}

            </Container>
        )
    }
    else {
        return (
            <>
                {props.loadingSpinner}
                <h4 className="text-center">Cargando datos de cupones</h4>
            </>
        )
    }



}

export default Cuponera;