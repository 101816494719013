import React from "react";
import ReactTooltip from "react-tooltip";
// import ReactDOM from 'react-dom'
import classnames from 'classnames';
// import Switch from "react-switch";
import InputRange from 'react-input-range';
import { /* Transition, */ CSSTransition/* , TransitionGroup */ } from "react-transition-group"
// import { CirclePicker } from "react-color"
// import 'react-input-range/lib/css/index.css';
import {
    Container, Row, Col,
    Card, CardBody, CardHeader,
    /* Navbar, Conatiner, NavbarBrand, NavbarToggler, Collapse, */ Nav, NavItem, NavLink,
    TabContent, TabPane,
    Button, Table,
    Form, FormGroup, Label, Input, /* CustomInput, */
    UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem
} from 'reactstrap'
import NumberFormat from "react-number-format";
// import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
// import { array } from "prop-types";


// 0. Functional Components
var ClientProfile = props => {
    //Props: 
    //recencia: int; montoMin:int, montoMax:int
    //periodType: "dias", "meses", "años";
    //visitas: {min:int , max:int} , compras: {min:int, max:int}
    //color: string
    //labels: array;
    return (<Container fluid className={classnames({ clientProfile: true, darken: props.darken })}>
        <Row>
            <Col>
                <Card>
                    <CardBody>
                        <i className={classnames({ "fa fa-male": true, "d-none": props.noIcon != undefined })}></i>
                        <div style={{ display: 'inline' }}>
                            <p>{props.labels.lblFrom} <b>{props.recencia.min}</b> {props.labels.lblTo} <b>{props.recencia.max}</b> {props.periodType} {props.labels.lblSinceLastPruchase}</p>
                        </div>
                        <div style={{ display: 'inline' }}>
                            <p>{props.labels.lblBuysBetween} <b>{props.frecuencia.min}</b> {props.labels.lblAnd} <b>{props.frecuencia.max}</b>  {props.labels.lblTimesPerYear}</p>
                        </div>
                        <div style={{ display: 'inline' }}>
                            <p className='clientProfileSum'>{props.labels.lblExpendsBetween} <b><NumberFormat displayType='text' value={props.montoMin} prefix={props.currencySymbol} thousandSeparator={true} /></b> {props.labels.lblAnd} <b><NumberFormat displayType='text' value={props.montoMax} prefix={props.currencySymbol} thousandSeparator={true} /></b> {props.labels.lblPerYear}</p>
                        </div>
                    </CardBody>
                </Card>
            </Col>
        </Row>
    </Container>)
}

var DropDown = props => {
    if (props.resolve == undefined) { return (<div>No revolve function passed</div>) }

    return (<>
        <UncontrolledDropdown>
            <DropdownToggle caret data-toggle="dropdown">
                {props.labels.lblCategories}
            </DropdownToggle>
            <DropdownMenu>
                {props.data != undefined ?
                    props.data.map((obj, key) => {
                        return (<DropdownItem key={key} onClick={() => { props.resolve(obj) }}>{obj.label}</DropdownItem>)
                    })
                    :
                    <DropdownItem>No data available</DropdownItem>
                }
            </DropdownMenu>
        </UncontrolledDropdown>
    </>)
}

class DropDownWithSearchBar extends React.Component {
    //PROPS
    //Important for BE ---------- ---------- ---------- ---------- ---------- ----------
    //fieldname:String (collection field used to compare on BE)  |  idField:String (fieldname of value (on dataList) save to compare on BE) | filteroption:String (ALL, SIM, PRE, STO)
    //Important for FE ---------- ---------- ---------- ---------- ---------- ----------
    //className:string | dataList:array | labels:array (Used for lenguage) | title:String (Name of the filters)
    //labelField:string (propname of value to show on FE) | filterType:String (use to sort filters in segment preview) | addFilter:function | removeFilter:function | editFilter:function | 

    constructor(props) {
        super(props)
        this._isMounted = false;
        this.labels = props.labels;
        this.state = { selectedProducts: [], page: 1, recharge: false, hasSearchtext: false }
        if (props.appliedFilters != undefined) {
            this.state.selectedProducts = props.appliedFilters;
        }

        this.tableRef = React.createRef();
        this.selectProduct = this.selectProduct.bind(this);
        //OPERATIONS TO SHOW THE CORRECT DATA
        this.dataIndex = 0;
        this.dataList = [];
        if (this.props.dataList.length > 50) {
            for (this.dataIndex; this.dataIndex < 50; this.dataIndex++) {
                let clone = Object.assign({}, this.props.dataList[this.dataIndex]);
                this.dataList.push(clone);
            }
        }
        else {
            this.dataList = this.props.dataList;
        }
        //console.log(props.dataList)
    }

    componentDidMount() {
        this._isMounted = true;
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.recharge != this.state.recharge && this.state.recharge == true) {
            this.setState({ recharge: false })
        }

        if (prevProps.dataList != this.props.dataList) {
            this.dataList = [];
            if (this.props.dataList.length > 50) {
                for (var i = 0; i < 50; i++) {
                    //let clone = Object.assign({}, this.props.dataList[i]);
                    let clone = this.props.dataList[i];
                    this.dataList.push(clone);
                }
                this.dataIndex = 0;
                this.setState({ recharge: true });
            }
            else {
                this.dataList = this.props.dataList;
                this.setState({ recharge: true });
            }
        }

        //Lenguage Change
        if (prevProps.labels != this.props.labels) {
            this.labels = this.props.labels;
            if (this._isMounted) {
                this.forceUpdate();
            }
        }
    }

    //1. PAGINATION FUNCTIONS ------------ ------------ ------------ ------------ ------------
    nextPage() {
        if (this.dataIndex < this.props.dataList.length) {
            this.dataList = [];
            let nextPage = this.dataIndex + 50;
            for (this.dataIndex; this.dataIndex < nextPage; this.dataIndex++) {
                this.dataList.push(this.props.dataList[this.dataIndex]);
            }
            console.log(this.dataIndex);
            this.setState(state => ({ page: state.page + 1 }))
        }

    }

    previousPage() {
        if (this.dataIndex > 50) {
            this.dataList = [];
            let currentPage = this.dataIndex - 50;
            this.dataIndex = this.dataIndex - 100;
            for (this.dataIndex; this.dataIndex < currentPage; this.dataIndex++) {
                this.dataList.push(this.props.dataList[this.dataIndex]);
            }
            console.log(this.dataIndex);
            this.setState(state => ({ page: state.page - 1 }))
        }

    }

    //2. PRODUCTS (FILTER) FUNCTIONS ------------ ------------ ------------ ------------ ------------
    checkSelectedProducts = (producto) => {
        let bool = false;
        this.state.selectedProducts.forEach(prod => {
            if (prod.id == producto.id) {
                bool = true;
            }
        })

        return bool;
    }

    selectProduct = (producto) => {
        if (!this.checkSelectedProducts(producto.label)) {
            this.setState(state => {
                state.selectedProducts.push(producto);
                return ({ selectedProducts: state.selectedProducts })
            }, () => { this.props.addFilter(producto) })
        }
    }

    removeProduct = producto => {
        if (this.checkSelectedProducts(producto)) {
            this.setState(state => {
                let sp = state.selectedProducts;
                let indexToRemove = null;
                sp.forEach((p, index) => {
                    if (producto.label == p.label) {
                        indexToRemove = index;
                    }
                })

                if (indexToRemove != null) {
                    sp.splice(indexToRemove, 1);
                }

                return ({ selectedProducts: sp })
            }, () => { this.props.removeFilter(producto) })
        }

    }

    toggleProductPolarity(producto) {
        if (this.checkSelectedProducts(producto)) {
            var filter = null;
            this.setState(state => {
                state.selectedProducts.forEach(prod => {
                    if (prod.id == producto.id) {
                        prod.positive = !prod.positive;
                        let index = prod.name.indexOf(':');
                        if (prod.positive) {
                            prod.name = prod.name.substring(0, index) + ': ' + prod.label;
                        }
                        else {
                            prod.name = prod.name.substring(0, index) + ': NO ' + prod.label;
                        }
                        filter = prod;
                    }
                });
                return ({ selectedProducts: state.selectedProducts })
            }, () => { this.props.editFilter(filter) })
        }
    }

    searchProducts(prod) {
        this.dataList = [];
        if (prod == "") {
            if (this.props.dataList.length > 50) {
                for (var i = 0; i < 50; i++) {
                    let clone = this.props.dataList[i];
                    this.dataList.push(clone);
                }
                this.dataIndex = 50;
                this.setState({ recharge: true, page: 1, hasSearchtext: false })
            }
            else {
                this.dataList = this.props.dataList;
                this.setState({ recharge: true, page: 1, hasSearchtext: false })
            }
        }
        else {
            this.props.dataList.forEach((data, index) => {
                if (data[this.props.labelField].toUpperCase().indexOf(prod.toUpperCase()) != -1) {
                    if (this.dataList.length < 50) {
                        this.dataList.push(data);
                    }
                }
            })
            this.setState({ recharge: true, hasSearchtext: true })
        }



    }


    //3. RENDER ------------ ------------ ------------ ------------ ------------
    render() {
        return (<Container fluid className="dropDownWithSearchBar">
            <Input type="text" placeholder={this.labels.lblPlaceholder} onChange={e => { this.searchProducts(e.target.value) }} />
            <Row className="tables">
                <Col>{/* TABLE WITH FILTERS TO SELECT*/}
                    <Table striped={true} id="tableProduct">
                        <tbody>
                            {this.dataList.map((obj, index) => {
                                if ((obj != undefined) && (obj != null))
                                    return (
                                        <tr key={index}>
                                            <td>{obj[this.props.labelField]}</td>
                                            <td>
                                                <button onClick={() => {
                                                    this.selectProduct({
                                                        id: obj[this.props.idField],
                                                        label: obj[this.props.labelField],
                                                        name: this.props.title + ": " + obj[this.props.labelField],
                                                        fieldname: this.props.fieldname,
                                                        filteroption: this.props.filteroption,
                                                        type: this.props.filterType,
                                                        positive: true,
                                                    })
                                                }}>{this.labels.lblAddFilter}</button>
                                            </td>
                                        </tr>
                                    )
                            })}
                        </tbody>
                    </Table>
                    {this.dataList.length == 50 ?
                        <div className={classnames({ "d-none": this.state.hasSearchtext })}>
                            <button className="btnPager" onClick={() => { this.previousPage() }}><i className="fa fa-angle-left" /> Prev</button>
                            <button className="btnPager" onClick={() => { this.nextPage() }}>Next <i className="fa fa-angle-right" /></button>
                        </div>
                        : ""}
                </Col>
                <Col className="tableSelectedProducts">{/* SELECTED FILTERS */}
                    <h4>{this.labels.lblSelectedFilters}</h4>
                    <Table>
                        <tbody>
                            {this.state.selectedProducts.map((prod, index) => {
                                return (
                                    <tr key={index}>
                                        <td>{prod.name}</td>
                                        <td>
                                            <button className={classnames({ active: !prod.positive })} onClick={() => { this.toggleProductPolarity(prod) }}>{this.labels.lblNegateFilter}</button>
                                            <button onClick={() => { this.removeProduct(prod) }}>{this.labels.lblRemove}</button>
                                        </td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </Table>
                </Col>
            </Row>
        </Container>)
    }
}

// 1. Single components ----------------------------------------------------------------------------------------------------------------

class BaseSegmentSelector extends React.Component {
    constructor(props) {
        super(props)
        this.labels = props.labels;
        let _amountRange = 100000;
        if (props.currencySymbol == '$') _amountRange = 50000;
        this.state = {
            tab: props.creationType == "sb" ? "1" : "2",
            activeBol: false,
            amountRange: _amountRange,
            frecuenciaPerfecta: props.frecuenciaPerfecta, // frecuencia perfecta en db = 1;
        }
        this.changeTabs = this.changeTabs.bind(this);
        this.handleAmountRange = this.handleAmountRange.bind(this);
        this.handleFrecuenciaPerfecta = this.handleFrecuenciaPerfecta.bind(this);
        this.createCard = this.createCard.bind(this);
    }


    componentDidUpdate(prevProps) {
        if (prevProps.frecuenciaPerfecta != this.props.frecuenciaPerfecta) {
            this.setState({ frecuenciaPerfecta: this.props.frecuenciaPerfecta })
        }

        //Lenguage Change
        if (prevProps.labels != this.props.labels) {
            this.labels = this.props.labels;
            this.forceUpdate();
        }
    }

    // 1. NAVIGATION FUNCTIONS -------------------------------------------------------------------------------------------
    changeTabs(tab) {
        this.setState({ tab: tab });
    }

    handleAmountRange(val) {
        this.setState({ amountRange: val })
    }

    handleFrecuenciaPerfecta() {
        this.props.handlers.fToggle();
        //this.setState(state => ({ frecuenciaPerfecta: !state.frecuenciaPerfecta }))
    }

    // 2. PRE-RENDER FUNCTIONS -------------------------------------------------------------------------------------------
    createCard = (name, id) => {
        return (<Col className="col-md-15 col-lg-15 col-sm-15">
            <Card className={classnames({ active: this.props.checkSegmentBase(id), baseSegment: true })} onClick={() => {
                if (this.props.checkSegmentBase(id)) {
                    this.props.deleteSegmentBase(id)
                } else {
                    this.props.addBaseSegment(id, name)
                }
            }}>
                <CardBody>
                    <h6 className="text-center">{name}</h6>
                </CardBody>
            </Card>
        </Col>)
    }

    //Construye y devuelve tarjetas con el control deslizante para definir rangos de datos.
    createMinAndMaxInputs(title, min, max, values, handler, lg, step) {
        return (<Col lg={lg == undefined ? "6" : lg} className='container-fluid formCols'>
            <Label style={{ marginRight: '10px' }}>{title}: </Label>
            <Row>
                <Col>
                    <FormGroup>
                        {this.createRangeInput(min, max, values, handler, step)}
                    </FormGroup>
                </Col>
            </Row>
        </Col>
        )
    }

    //Construye y devulve tarjetas con un input de rango. Con modificadores para varias el máximo y minimo rango permitido.
    createMixAndMaxInputsWithModifiers(id, title, minDef, maxDef, values, handler, modifiers) {
        return (<>
            <Col className='container-fluid formCols'>
                <Label style={{ marginRight: '10px' }}>{title}: </Label>
                {modifiers != undefined && modifiers.mods.length > 0 ?
                    <div className='timePeriodSelect'>
                        {modifiers.mods.map((mod, index) => {
                            return (
                                <FormGroup check inline className="form-check-radio" key={index}>
                                    <Label className="form-check-label">
                                        <Input type="radio" defaultChecked={index == 0 ? true : false} name={id} value={mod.val} onClick={e => { modifiers.handler(mod.val) }} />
                                        {mod.name}
                                        <span className="form-check-sign"></span>
                                    </Label>
                                </FormGroup>
                            )
                        })}
                    </div>
                    :
                    ""
                }
                <Row>
                    <Col>
                        <FormGroup>
                            {this.createRangeInput(minDef, maxDef, values, handler)}
                        </FormGroup>
                    </Col>
                </Row>
            </Col>
        </>)
    }

    //Crea el input range según los valores que se le ingresen. Además conecta el input con su controlador
    createRangeInput(min, max, value, handler, label) {
        var classNames = {
            slider: 'pruebaRangeSlider',//El objeto que se desliza
            sliderContainer: 'pruebaRangeSliderContainer',//Contenedor del objeto que se desliza
            track: 'pruebaTrack', //Barra de desplazamiento (blanca)
            activeTrack: 'pruebaRangeActiveTrack', //Barra de desplazamiento pintada
            inputRange: 'pruebaInputRange',
            labelContainer: 'pruebaLabelContainer', //Contenedor de todas las etiquetas
            maxLabel: 'pruebaMaxLabel', // Etiquita de valor minimo
            minLabel: 'pruebaMinLabel', //Etiqueta de valor maximo
            valueLabel: 'pruebaValueLabel', // Etiqueta flotante sobre la barras deslizantes
        }

        var step = max * 0.01;
        if (max != 365) {
            if (value.max > max) {
                value.min = 0;
            }
        }


        return (<div className='inputRangeContainer'>
            {max == 365 ?
                <InputRange
                    classNames={classNames}
                    value={value}
                    minValue={min}
                    maxValue={max}
                    onChange={value => { handler(value) }}
                />
                :
                <InputRange
                    classNames={classNames}
                    step={step}
                    value={value}
                    minValue={min}
                    maxValue={max}
                    onChange={value => { handler(value) }}
                />
            }
        </div>)
    }

    // 4. RENDER ---------------------------------------------------------------------------------------------------------

    render() {
        return (<Row className='rowBaseSegments'>
            <Container fluid>
                {/* <Row style={{ marginBottom: '35px' }}>
                    <Col className='text-center noPadNoMar'>
                        <h3>{this.props.labels.lblBsTitle}</h3>
                    </Col>
                </Row> */}
                <Row className="segmentTabs">{/* NAVIGATION TABS */}
                    <Nav tabs>
                        <NavItem>
                            <NavLink onClick={() => { this.changeTabs("1"); this.props.handlers.clean(); this.props.changeCreationType('sb') }} className={classnames({ active: this.state.tab == "1" })}>{this.props.labels.lblTabFromBaseSegments}</NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink onClick={() => { this.changeTabs("2"); this.props.cleanSegmentBase(); this.props.changeCreationType('rfm') }} className={classnames({ active: this.state.tab == "2" })}>{this.props.labels.lblTabFromRfmValues}</NavLink>
                        </NavItem>
                    </Nav>
                </Row>
                <Row>{/* NAVIGATION CONTENTS */}
                    <TabContent activeTab={this.state.tab} className="container-fluid">
                        <TabPane tabId="1">
                            <Row>
                                <Container fluid>
                                    <Row className="selectAllControl">
                                        <Col>
                                            <FormGroup check inline>
                                                <Label check>
                                                    <Input type="checkbox" checked={this.props.allSegmentsSelected} onChange={() => { this.props.addAllSegments() }} />
                                                    {this.props.labels.lblSelectAll}
                                                    <span className="form-check-sign">
                                                        <span className="check"></span>
                                                    </span>
                                                </Label>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        {this.createCard(this.props.labels.lblRetain, 1)}
                                        {this.createCard(this.props.labels.lblIncrementA, 2)}
                                        {this.createCard(this.props.labels.lblIncrementF, 3)}
                                        {this.createCard(this.props.labels.lblBringBack, 4)}
                                        {this.createCard(this.props.labels.lblOccasionals, 5)}
                                    </Row>
                                </Container>
                            </Row>
                        </TabPane>

                        <TabPane tabId="2">
                            <Row>
                                <Container fluid className='rfmForm'>
                                    <Row>
                                        <Col lg='8' md="12" sm="12">{/* FORMULARIO */}
                                            <Row>{/* RECENCIA */}
                                                {this.createMixAndMaxInputsWithModifiers('timeValue', this.props.labels.lblTimeSinceLastPurchase, 0, 365, this.props.clientProfile.recencia, this.props.handlers.recencia)}
                                            </Row>

                                            <Row>{/* FRECUENCIA */}
                                                {this.createMixAndMaxInputsWithModifiers('txCount', this.props.labels.lblPurchasesPerYear, 0, 365, this.props.clientProfile.frecuencia, this.props.handlers.frecuencia)}
                                            </Row>

                                            <Row>{/* MONTO */}
                                                {this.createMixAndMaxInputsWithModifiers('amountRange', this.props.labels.lblExpensesPerYear + '(' + this.props.currencySymbol + ')', 0, this.state.amountRange, this.props.clientProfile.monto, this.props.handlers.monto, { mods: [{ name: '100K', val: 100000 }, { name: '1 million', val: 1000000 }], handler: this.handleAmountRange })}
                                            </Row>
                                        </Col>

                                        <Col className="clientProfileCol" lg='4' md="12" sm="12">{/* PERFIL DEL CLIENTE */}
                                            <h5>Perfil de Cliente</h5>
                                            <ClientProfile
                                                recencia={this.props.clientProfile.recencia}
                                                frecuencia={this.props.clientProfile.frecuencia}
                                                montoMin={this.props.clientProfile.monto.min}
                                                montoMax={this.props.clientProfile.monto.max}
                                                currencySymbol={this.props.currencySymbol}
                                                periodType={this.props.clientProfile.periodType}
                                                color={this.props.segmentColor}
                                                labels={this.labels}
                                            />
                                        </Col>
                                    </Row>
                                </Container>
                            </Row>
                        </TabPane>
                    </TabContent>
                </Row>
            </Container>
        </Row>
        )
    }

}

class FilterSelector extends React.Component {
    constructor(props) {
        super(props)
        this._isMounted = false;
        this.labels = props.labels;
        var _initialCategory = { "label": props.clasificationList[props.clasificationList.length - 1].label, "name": props.clasificationList[props.clasificationList.length - 1].name }
        var categoryFieldName = props.clasificationList[props.clasificationList.length - 1].productsTableIdField;
        //Function Binding ----
        this.createSingleChoiceCard = this.createSingleChoiceCard.bind(this);
        this.checkSelectedFilters = this.checkSelectedFilters.bind(this);
        this.addFilter = this.addFilter.bind(this);
        this.editPolarityOfFilter = this.editPolarityOfFilter.bind(this);
        this.removeFilter = this.removeFilter.bind(this);
        this.handleDate = this.handleDate.bind(this);
        this.handleAvgTicketRange = this.handleAvgTicketRange.bind(this);
        this.handleCategoryLevel = this.handleCategoryLevel.bind(this);
        this.constructRangeText = this.constructRangeText.bind(this);
        //State Initialization ----
        this.state = {
            selectedFilters: props.selectedFilters,
            timeOfPurchase: 'ENTRESEMANA', // ENTRESEMANA | VIERNES | FINDESEMANA
            tab: "1",
            categoryItemList: null,
            storeList: null,
            date: new Date(),
            avgTicketRange: { min: 0, max: 0 },
            categoryLevel: _initialCategory,
            categoryFieldName: categoryFieldName,

        }
        this.catFiltersApplied = [];
        this.storeFiltersApplied = [];
        props.selectedFilters.forEach(filter => {
            switch (filter.type) {
                case "birthday": if (filter.date != undefined) { this.state.date = new Date(filter.date) }; break;
                case "avgTicket": this.state.avgTicketRange = filter.range; break;
                case "shoppingPeriod": this.state.timeOfPurchase = filter.value; break;
                case "category": this.catFiltersApplied.push(filter); break;
                case "store": this.storeFiltersApplied.push(filter); break;
            }
        })
        // PRE-management of the Age Brackets Filters
        this.filterForAgeBrackets = [];
        props.ageBrackets.map(age => {
            let filter = {
                id: age.name,
                name: age.name,
                flagvariable: "_filter_ageRange",
                type: 'generation'
            }
            this.filterForAgeBrackets.push(filter);
        })
    }

    async componentDidMount() {
        this._isMounted = true;
        try {
            let productCacheId = 'ProductListForSegmentCreator';
            let storeCacheId = 'StoreListForSegmentCreator';
            let data = await this.props.getCatData(this.state.categoryLevel.name, [], "");
            let storeData = await this.props.askFor('categorization', 'categorization/getStoresData', [], false, {}, storeCacheId);
            if (this._isMounted) {
                if (data != null && storeData == null) { this.setState({ categoryItemList: data }); }
                if (data == null && storeData != null) { this.setState({ storeList: storeData }); }
                if (data != null && storeData != null) { this.setState({ categoryItemList: data, storeList: storeData }); }
            }
        }
        catch (err) {
            console.log(err);
        }
    }

    async componentDidUpdate(prevProps, prevState) {
        //Lenguage Change
        if (prevProps.labels != this.props.labels) {
            this.labels = this.props.labels;
            if (this._isMounted) {
                this.forceUpdate();
            }
        }

        if (prevState.categoryLevel != this.state.categoryLevel) {
            let data = await this.props.getCatData(this.state.categoryLevel.name, [], "");
            if (this._isMounted) {
                if (data != null) {
                    this.setState({ categoryItemList: data })
                }
            }

        }
    }

    componentWillUnmount() {
        this._isMounted = false;
    }



    //0. CATEGORY TOGGLERS ------------------------------------------------------------------------------------------------------------

    handleCategoryLevel(level) {
        let category = { label: level.label, name: level.name }
        let fieldname = level.productsTableIdField;
        this.setState({ categoryLevel: category, categoryFieldName: fieldname })
    }

    changeTabs(tab) {
        this.setState({ tab: tab })
    }

    //1. FILTER FUNCTIONS ------------------ ------------------ ------------------ ------------------ ------------------------------
    checkSelectedFilters(filter) {
        let bool = false;
        this.state.selectedFilters.forEach(el => {
            if (el.id == filter.id) {
                bool = true;
            }
        })

        return bool;
    }

    addFilter(filter) {
        if (filter == null || filter == undefined) filter = { id: "", name: "", flagvariable: "", positive: true, filteroption: "" };
        if (filter.filteroption == null || filter.filteroption == undefined) filter.filteroption = "";
        if (filter.flagvariable == null || filter.flagvariable == undefined) filter.flagvariable = null;
        if (filter.positive == null || filter.positive == undefined) filter.positive = true;
        if (filter.type == null || filter.type == undefined) filter.type = "";
        var fil = {
            id: filter.id,
            name: filter.name,
            flagvariable: filter.flagvariable,
            positive: filter.positive,
            filteroption: filter.filteroption,
            type: filter.type,
        }

        //Special Filter properties. To add only if they exist in the upcoming filter.
        if (filter.label != undefined) { fil.id = filter.label; fil.label = filter.label }
        if (filter.fieldname != undefined) { fil.fieldname = filter.fieldname }
        if (filter.date != undefined) { fil.date = filter.date; fil.positive = null }
        if (filter.range != undefined) { fil.range = filter.range }
        if (filter.value != undefined) { fil.value = filter.value }

        //Adds the filter
        if (!this.checkSelectedFilters(fil.id)) {
            this.setState(state => {
                state.selectedFilters.push(fil);
                return ({ selectedFilters: state.selectedFilters })
            }, () => { this.props.applyFilters(this.state.selectedFilters) })
        }
    }

    removeFilter(filter) {
        let indexToRemove = null;
        this.state.selectedFilters.forEach((el, index) => {
            if (el.id == filter.id) {
                indexToRemove = index;
            }
        })


        if (indexToRemove != null) {
            this.setState(state => {
                state.selectedFilters.splice(indexToRemove, 1);
                return ({ selectedFilters: state.selectedFilters })
            }, () => { this.props.applyFilters(this.state.selectedFilters) })
        }
    }

    editPolarityOfFilter(filter) {
        if (this.checkSelectedFilters(filter)) {
            this.setState(state => {
                state.selectedFilters.forEach(fil => {
                    if (fil.id == filter.id) {
                        fil.positive = filter.positive;
                        fil.name = filter.name;
                    }
                })
                return ({ selectedFilters: state.selectedFilters })
            }, () => { this.props.applyFilters(this.state.selectedFilters) })
        }
    }

    checkBirthdayDate() {
        var bool = false;
        this.state.selectedFilters.forEach(filter => {
            if (filter.id == 'birthday') {
                if (filter.date != null) {
                    bool = true;
                }
            }
        })

        return bool;
    }

    handleDate = date => {
        this.setState(state => {
            state.selectedFilters.map(filter => {
                if (filter.id == 'birthday') {
                    filter.date = new Date(date).toISOString();
                    filter.name = 'Cumpleaños Cercano: ' + date.toLocaleDateString();
                }
            })
            return ({ date: date, selectedFilters: state.selectedFilters })
        }, () => { this.props.applyFilters(this.state.selectedFilters) })
    }

    handleTimeOfPurchase = value => {
        var nameText = '';
        switch (value) {
            case 'ENTRESEMANA': nameText = 'Entre Semana'; break;
            case 'VIERNES': nameText = 'Viernes'; break;
            case 'FINDESEMANA': nameText = 'Fin de Semana'; break;
        }


        this.setState(state => {
            state.selectedFilters.map(filter => {
                if (filter.id == 'timeOfPurchase') {
                    filter.value = value;
                    filter.name = 'Rango de Compra: ' + value;
                }
            })

            return ({ timeOfPurchase: value, selectedFilters: state.selectedFilters });
        }, () => { this.props.applyFilters(this.state.selectedFilters) })
    }

    handleAvgTicketRange = range => {
        this.setState(state => {
            state.selectedFilters.map(filter => {
                if (filter.id == 'avgTicket') {
                    filter.range = range;
                    filter.name = 'Tiquete Promedio: ' + this.constructRangeText(range);
                }
            })

            return ({ avgTicketRange: range, selectedFilters: state.selectedFilters });
        }, () => { this.props.applyFilters(this.state.selectedFilters) })
    }

    constructRangeText = (range) => {
        var text = 'de ' + this.props.currencySymbol + range.min + ' a ' + this.props.currencySymbol + range.max;
        return text;
    }


    // 2. IN-RENDER FUNCTIONS ------------------------------------------------------------------------------------------------------
    //This functions return JSX with the UI to select different filters. Here is where the filter OBJ are configure to be sent and save. | The dropdown card has its own component where the filter is configure.
    createSingleChoiceCard = (filter, choices) => {
        //Center peude ser cualquier, dato o variable pues solo se utiliza como booleano al preguntar si existe o no
        return (<Row>
            <Card>
                <CardBody>
                    <Row>
                        <Col>
                            <FormGroup check inline>
                                <Label check style={{ fontSize: '15px' }}>
                                    <Input type="checkbox"
                                        defaultChecked={this.checkSelectedFilters(filter)}
                                        onChange={() => {
                                            if (!this.checkSelectedFilters(filter)) { this.addFilter(filter) }
                                            else { this.removeFilter(filter) }
                                        }} />
                                    {filter.title}
                                    <span className="form-check-sign">
                                        <span className="check"></span>
                                    </span>
                                </Label>
                            </FormGroup>
                        </Col>
                    </Row>
                    {this.checkSelectedFilters(filter) ?
                        <>
                            <Row>
                                {choices.map((choice, key) => {
                                    return (<Col key={key}>
                                        <FormGroup check inline className="form-check-radio">
                                            <Label className="form-check-label">
                                                <Input type="radio" defaultChecked={key == 0 ? true : false} name={filter.id} value={choice.val} onClick={e => { this.handleTimeOfPurchase(choice.val) }} />
                                                {choice.name}
                                                <span className="form-check-sign"></span>
                                            </Label>
                                        </FormGroup>
                                    </Col>)

                                })}
                            </Row>
                        </>
                        : ""}
                </CardBody>
            </Card>
        </Row>)
    }

    createCardWithCheckBox = (title, filters) => {
        return (<Row>
            <Card>
                <CardBody>
                    <Row>
                        {filters.map((filter, key) => {
                            return (<Col className={classnames({ 'col-lg-12': filters.length > 2 })} key={key}>
                                <FormGroup check inline>
                                    <Label check style={{ fontSize: '15px' }}>
                                        <Input type="checkbox"
                                            defaultChecked={this.checkSelectedFilters(filter)}
                                            onChange={() => {
                                                if (!this.checkSelectedFilters(filter)) { this.addFilter(filter) }
                                                else { this.removeFilter(filter) }
                                            }} />
                                        {filter.name}
                                        <span className="form-check-sign">
                                            <span className="check"></span>
                                        </span>
                                    </Label>
                                </FormGroup>
                            </Col>)
                        })}
                    </Row>
                </CardBody>
            </Card>
        </Row>)
    }

    createCardWithRangeInput = (id, name, value) => {
        if (id == undefined) { return; }
        if (name == undefined || name == null) { name = id }
        if (value == undefined) { value = '' }

        var maxValue = this.props.currencySymbol == '$' ? 500 : 100000;
        var step = maxValue / 100;

        var rangeText = this.constructRangeText(this.state.avgTicketRange);

        var classNames = {
            slider: 'pruebaRangeSlider',//El objeto que se desliza
            sliderContainer: 'pruebaRangeSliderContainer',//Contenedor del objeto que se desliza
            track: 'pruebaTrack', //Barra de desplazamiento (blanca)
            activeTrack: 'pruebaRangeActiveTrack', //Barra de desplazamiento pintada
            inputRange: 'pruebaInputRange',
            labelContainer: 'pruebaLabelContainer', //Contenedor de todas las etiquetas
            maxLabel: 'pruebaMaxLabel', // Etiquita de valor minimo
            minLabel: 'pruebaMinLabel', //Etiqueta de valor maximo
            valueLabel: 'pruebaValueLabel', // Etiqueta flotante sobre la barras deslizantes
        }


        return (<Row>
            <Card>
                <CardBody>
                    <Row>{/* CheckBox Input */}
                        <Col>
                            <FormGroup check inline>
                                <Label check style={{ fontSize: '15px' }}>
                                    <Input type="checkbox"
                                        defaultChecked={this.checkSelectedFilters({ id: id })}
                                        onChange={() => {
                                            if (!this.checkSelectedFilters({ id: id })) {
                                                this.addFilter({ id: id, name: name + ': ' + rangeText, range: this.state.avgTicketRange, flagvariable: "flag_avgTicket", type: id })
                                            } else {
                                                this.removeFilter({ id: id })
                                            }
                                        }} />
                                    {name || id}
                                    <span className="form-check-sign">
                                        <span className="check"></span>
                                    </span>
                                </Label>
                            </FormGroup>
                        </Col>
                    </Row>
                    {this.checkSelectedFilters({ id: id }) ?
                        <>{/* Range Input */}
                            <Row>
                                <Col>
                                    <p>{rangeText}</p>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <div className='rfmForm'>
                                        <InputRange
                                            classNames={classNames}
                                            step={step}
                                            value={this.state.avgTicketRange}
                                            minValue={0}
                                            maxValue={maxValue}
                                            onChange={value => { this.handleAvgTicketRange(value) }}
                                        />
                                    </div>
                                </Col>
                            </Row>
                        </>
                        : ""}
                </CardBody>
            </Card>
        </Row>)
    }

    createBirthDayCard = () => {
        return (<Row>
            <Card>
                <CardBody>
                    <Row>
                        <Col>
                            <FormGroup check inline>
                                <Label check style={{ fontSize: '15px' }}>
                                    <Input type="checkbox"
                                        defaultChecked={this.checkSelectedFilters({ id: "birthday" })}
                                        onChange={() => {
                                            if (!this.checkSelectedFilters({ id: 'birthday' })) {
                                                this.addFilter({ id: "birthday", name: this.labels.lblBirthday + ": " + this.labels.lblToday, date: null, type: 'birthday', date: new Date() })
                                            }
                                            else {
                                                this.removeFilter({ id: "birthday" })
                                            }
                                        }} />
                                    {this.labels.lblBirthday}
                                    <span className="form-check-sign">
                                        <span className="check" />
                                    </span>
                                </Label>
                            </FormGroup>
                        </Col>
                    </Row>
                </CardBody>
            </Card>
        </Row>)
    }

    createCardWithDropdown = (title, filters, fieldName, idField, labelField, filteroption, filterType, appliedFilters) => {
        return (<Row>
            <Card>
                <CardBody>
                    <h3>{title}</h3>
                    <DropDownWithSearchBar
                        dataList={filters}
                        fieldname={fieldName}
                        idField={idField}
                        labelField={labelField}
                        title={title}
                        labels={this.labels}
                        addFilter={this.addFilter}
                        removeFilter={this.removeFilter}
                        editFilter={this.editPolarityOfFilter}
                        filterType={filterType}
                        filteroption={filteroption}
                        appliedFilters={appliedFilters}
                    />
                </CardBody>
            </Card>
        </Row>)
    }


    // 3. RENDER --------------------------------------------------------------------------------------------------------------------
    render() {
        return (<Row style={{ marginTop: '25px' }}>
            <Container fluid className="segmentCreatorFilterSelector">
                <Row>
                    <Col className='text-center noPadNoMar'><h5>{this.labels.lblFilterSelectorTitle}</h5></Col>
                </Row>
                <Row className="segmentTabs" style={{ marginBottom: '15px' }}>{/* NAVBAR */}
                    <Nav tabs>
                        <NavItem>
                            <NavLink onClick={() => { this.changeTabs("1") }} className={classnames({ active: this.state.tab == "1" })}>{this.labels.lblTabDemAndSpe}</NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink onClick={() => { this.changeTabs("2") }} className={classnames({ active: this.state.tab == "2" })}>{this.labels.lblTabByCat}</NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink onClick={() => { this.changeTabs("3") }} className={classnames({ active: this.state.tab == "3" })}>{this.labels.lblTabByStore}</NavLink>
                        </NavItem>
                    </Nav>
                </Row>
                <Row> {/* CONTENT */}
                    <TabContent activeTab={this.state.tab} className="container-fluid">
                        <TabPane tabId="1">{/* DEMOGRAPHIC AND SPECIALS */}
                            <Row>
                                <Col>
                                    {/* <h3 className="text-center">{this.labels.lblDemoTitle}</h3> */}
                                    {this.createCardWithCheckBox(this.labels.lblGenderTitle, [{ name: this.labels.lblMenTitle, id: 'male', flagvariable: "_filter_male", type: "gender" }, { name: this.labels.lblWomenTitle, id: 'female', flagvariable: "_filter_female", type: 'gender' }])}
                                    {this.createCardWithCheckBox(this.labels.lblAgeTitle, this.filterForAgeBrackets)}
                                </Col>
                                <Col>
                                    {/* <h3 className="text-center">{this.labels.lblSpecialTitle}</h3> */}
                                    {this.createBirthDayCard()}
                                    {this.props.type == "sb" ? this.createCardWithRangeInput('avgTicket', this.labels.lblAvgTicket) : ""}
                                    {/* {this.createSingleChoiceCard({ title: this.labels.lblShoppingTime, name: this.labels.lblShoppingTime + ": " + this.state.timeOfPurchase, id: 'timeOfPurchase', value: this.state.timeOfPurchase, type: 'shoppingPeriod' }, [{ name: this.labels.lblWeek, val: 'ENTRESEMANA' }, { name: this.labels.lblWeekEnd, val: 'FINDESEMANA' }])} */}
                                </Col>
                            </Row>
                        </TabPane>

                        <TabPane tabId="2">{/* BY CATEGORY */}
                            <Col>
                                {/* <h4 className="text-center">Categorias</h4> */}
                                <Row>
                                    <DropDown data={this.props.clasificationList} resolve={this.handleCategoryLevel} labels={this.labels} />
                                </Row>
                                {this.state.categoryItemList != null ?
                                    this.createCardWithDropdown(this.state.categoryLevel.label, this.state.categoryItemList, this.state.categoryFieldName, "id", "label", "ALL", "category", this.catFiltersApplied)
                                    :
                                    <h2>{this.labels.lblLoading}</h2>}

                            </Col>
                        </TabPane>

                        <TabPane tabId="3">{/* BY STORE */}
                            <Col>
                                {this.state.storeList != null ? this.createCardWithDropdown(this.labels.lblByStoreTitle, this.state.storeList, this.props.storeFieldName, "ID_SUCURSAL", 'DESCRIPCION', "STO", "store", this.storeFiltersApplied) : ""}
                            </Col>
                        </TabPane>

                    </TabContent>
                </Row>
            </Container>
        </Row>)
    }

}

class SegmentPreview extends React.Component {
    constructor(props) {
        super(props)
        this.state = { clientCount: 0 }
    }



    render() {
        return (<Row>
            <Container fluid className='segmentPreview'>
                <Row>
                    <Col>
                        <Card className='mainCard'>
                            <CardHeader>
                                <h4>{this.props.segmentName}</h4>
                            </CardHeader>
                            <CardBody>
                                <Row>
                                    <Col lg='8' md='8' sm='12'>
                                        <Card className='previewSubCards'>
                                            <CardBody >
                                                <h3>Perfil del cliente:</h3>
                                                <Container>
                                                    <Row>
                                                        <Col>
                                                            {this.props.showProfile ?
                                                                <ClientProfile
                                                                    recencia={this.props.clientProfile.recencia}
                                                                    visitas={this.props.clientProfile.frecuencia.visitas}
                                                                    compras={this.props.clientProfile.frecuencia.compras}
                                                                    periodType={this.props.clientProfile.periodType}
                                                                    montoMax={this.props.clientProfile.monto.max}
                                                                    montoMin={this.props.clientProfile.monto.min}
                                                                    currencySymbol={this.props.currencySymbol}
                                                                />
                                                                : <>
                                                                    {this.props.baseSegments.length > 0 ? <h6>Pertenecen a los segmentos:</h6> : ""}
                                                                    <ul className='list-unstyled'>
                                                                        {this.props.baseSegments.map((el, key) => {
                                                                            return (<li key={key} className='minSegmentName'><h5>{el.name}</h5></li>)
                                                                        })}
                                                                    </ul>
                                                                </>
                                                            }
                                                        </Col>
                                                        {this.props.filterList.length > 0 ?
                                                            <Col>
                                                                <h6>Filtros a aplicar:</h6>
                                                                <ul className='list-unstyled'>
                                                                    {this.props.filterList.map((el, key) => {
                                                                        return <li key={key} className='minFilterName'>{el.name}</li>
                                                                    })}
                                                                </ul>
                                                            </Col>
                                                            : " "}
                                                    </Row>
                                                </Container>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                    <Col lg='2' md='2' sm='12'>
                                        <Button className="btnCreateSegment" onClick={() => { this.props.crearSegmento() }} disabled={this.props.enableCreate}>Crear Segmento</Button>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </Row>)
    }
}

class FloatingPreview extends React.Component {
    constructor(props) {
        super(props)
        this.filters = [];
        this.state = { enableCreate: props.enableCreate }
        this.disableButton = this.disableButton.bind(this);
    }

    componentDidUpdate(prevProps) {
        if (prevProps.enableCreate != this.props.enableCreate) {
            this.setState({ enableCreate: this.props.enebaleCreate })
        }
    }

    sortFilters() {
        this.props.filterList.sort((a, b) => (a.type > b.type) ? 1 : -1)
    }

    disableButton() {
        this.setState({ enableCreate: true })
    }


    render() {
        this.sortFilters();
        return (<Row className={classnames({ floatingPreview: true, active: this.props.display })}>
            <Container fluid>
                <Card className='maincard'>
                    <CardBody>
                        <Row className="nameAndIcon"> {/* Title and Create Button */}
                            <Col lg='8' md="12"><i style={{ color: this.props.segmentColor }} className={this.props.segmentIcon} /><h4 style={{ color: this.props.segmentColor }}>{this.props.title}</h4></Col>
                            {/* <Col className="btnCreateSegmentCol" lg='4' md="12"><Button className="btnCreateSegment" onClick={() => { this.disableButton(); this.props.crearSegmento() }} disabled={this.state.enableCreate}>Aceptar</Button></Col> */}
                        </Row>
                        <Row>{/* Base Segments, Filter List, Client Profile */}
                            <Col>
                                {this.props.showProfile ?
                                    <Row>{/* Perfil de Cliente y Filtros */}
                                        <ClientProfile
                                            recencia={this.props.clientProfile.recencia}
                                            visitas={this.props.clientProfile.frecuencia.visitas}
                                            frecuencia={this.props.clientProfile.frecuencia}
                                            periodType={this.props.clientProfile.periodType}
                                            montoMax={this.props.clientProfile.monto.max}
                                            montoMin={this.props.clientProfile.monto.min}
                                            currencySymbol={this.props.currencySymbol}
                                            darken={true}
                                            color={this.props.segmentColor}
                                            labels={this.props.labels}
                                        />
                                        {this.props.filterList.length > 0 ?
                                            <>
                                                <Col lg="12" md="12" sm="12">
                                                    <p className="text-center">{this.props.labels.lblFilters}</p>
                                                </Col>
                                                <Col>
                                                    <ul className='list-unstyled'>
                                                        {this.props.filterList.map((el, key) => {
                                                            if (key % 2 == 0) return <li key={key} className='minFilterName'><h5>{el.name}</h5></li>
                                                        })}
                                                    </ul>
                                                </Col>
                                                <Col>
                                                    <ul className='list-unstyled'>
                                                        {this.props.filterList.map((el, key) => {
                                                            if (key % 2 != 0) return <li key={key} className='minFilterName'><h5>{el.name}</h5></li>
                                                        })}
                                                    </ul>
                                                </Col>
                                            </>
                                            : ""
                                        }
                                    </Row>
                                    :
                                    <Row>{/* Segmentos Base y Filtros */}
                                        {this.props.baseSegments.length > 0 ?
                                            <Col>
                                                <h6>{this.props.labels.lblSelectedSegments}:</h6>
                                                <ul className='list-unstyled'>
                                                    {this.props.baseSegments.length == 5 ?
                                                        <li className='minSegmentName'><h5>Toda la población</h5></li>
                                                        :
                                                        this.props.baseSegments.map((el, key) => {
                                                            return (<li key={key} className='minSegmentName'><h5>{el.name}</h5></li>)
                                                        })}
                                                </ul>
                                            </Col>
                                            : ""
                                        }
                                        {this.props.filterList.length > 0 ?
                                            <Col>
                                                <h6>{this.props.labels.lblSelectedFilters}:</h6>
                                                <ul className='list-unstyled'>
                                                    {this.props.filterList.map((fil, key) => {
                                                        return (<li key={key} className='minFilterName'><h5>{fil.name}</h5></li>)
                                                    })}
                                                </ul>
                                            </Col>
                                            : ""
                                        }
                                        {(this.props.baseSegments.length) < 1 && (this.props.filterList.length) < 1 ?
                                            <Col style={{ marginTop: '30px' }}><h5 className="text-center">Selecciona la población a segmentar y que filtrado aplicar</h5></Col>
                                            : ""
                                        }
                                    </Row>
                                }
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
            </Container>
        </Row>)
    }
}

class NameForm extends React.Component {
    constructor(props) {
        super(props)
        this.labels = this.props.labels;
        this.state = { openColor: false, openIcon: false, color: props.segmentColor, icon: props.segmentIcon }
        this.colors = ['#43b02a', '#00C1D5', '#ffb81c', '#ff6900', '#f9423a ']
        this.icons = ['tim-icons icon-heart-2', 'tim-icons ti-money', 'tim-icons icon-square-pin', 'tim-icons icon-volume-98', 'tim-icons icon-user-run']
    }

    componentDidUpdate(prevProps) {
        //Lenguage Change
        if (prevProps.labels != this.props.labels) {
            this.labels = this.props.labels;
            this.forceUpdate();
        }
    }

    toggleColorInput(bool, color) {
        if (color != undefined) {
            this.setState({ openColor: bool, color: color.hex },
                () => { this.props.handleSegmentColor(color.hex) })
        }
        else {
            this.setState({ openColor: bool })
        }
    }

    toggleIconInput(bool, icon) {
        if (icon != undefined) {
            this.setState({ openIcon: bool, icon: icon },
                () => { this.props.handleSegmentIcon(icon) });
        }
        else {
            this.setState({ openIcon: bool });
        }
    }



    render() {
        return (<Row className="nameForm">
            <Col className="noPadding">
                <Card>
                    <ReactTooltip id="segmentCreator"/>
                    <CardBody>
                        <Row>
                            <Col>
                                <FormGroup>
                                    <Label for="SegmentName">{this.labels.lblSegmentName}<span data-for="segmentCreator" data-tip="Campo requerido" data-place="right">?</span></Label>
                                    <Input required={true} type="text" name="segmentName" placeholder="Nombre de Segmento" value={this.props.segmentName} onChange={(e) => { this.props.handleSegmentName(e.target.value) }} />
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <FormGroup>
                                    <Label for="SegmentDescription">{this.labels.lblSegmentDescription} <span data-for="segmentCreator" data-tip="Campo requerido" data-place="right">?</span></Label>
                                    <Input required={true} type="textarea" name="segmentDescription" placeholder='Descripción del segmento' value={this.props.segmentDescription} onChange={(e) => { this.props.handleSegmentDescription(e.target.value) }} />
                                </FormGroup>
                            </Col>
                        </Row>
                        {/* <Row>
                            <Col>
                                <Row>
                                    <Col>
                                        {this.state.openColor ?
                                            <CSSTransition classNames="animated" timeout={2000} in={this.state.openColor}>
                                                <FormGroup className="colorSelector">
                                                    <h4>{this.labels.lblColorTitle}</h4>
                                                    <CirclePicker onChange={(color) => { this.toggleColorInput(false, color) }} colors={this.colors} />
                                                </FormGroup>
                                            </CSSTransition>
                                            :
                                            <div className="customSegmentButtonColor" onClick={() => { this.toggleColorInput(true) }}>
                                                <button>Color:</button>
                                                <span style={{
                                                    backgroundColor: this.state.color,
                                                    width: '28px',
                                                    height: '28px',
                                                    display: 'inline-block',
                                                    verticalAlign: 'middle',
                                                    marginLeft: '10px',
                                                    borderRadius: '15px'
                                                }} />
                                            </div>


                                        }
                                    </Col>
                                    <Col>
                                        {this.state.openIcon ?
                                            <CSSTransition classNames="animated" timeout={2000} in={this.state.openIcon}>
                                                <FormGroup>
                                                    <h4>{this.labels.lblIconTitle}</h4>
                                                    <div className='inlineInputSelector'>
                                                        {this.icons.map((icon, key) => {
                                                            return (<div key={key} onClick={e => { this.toggleIconInput(false, icon) }}><i className={icon} /></div>)
                                                        })}
                                                    </div>
                                                </FormGroup>
                                            </CSSTransition>
                                            :
                                            <div className="customSegmentButtonColor" onClick={() => { this.toggleIconInput(true) }}>
                                                <button>{this.labels.lblIcon}</button>
                                                <span className={this.state.icon} style={{ marginLeft: '10px', fontSize: '25px', color: this.state.color }} />
                                            </div>
                                        }
                                    </Col>
                                </Row>
                            </Col>
                        </Row> */}
                    </CardBody>
                </Card>
            </Col>
        </Row>)
    }
}


// 3. MAJOR COMPONENT
class SegmentCreator extends React.Component {
    constructor(props) {
        super(props)
        this.labels = props.labels
        //Estado: segmentName:string | segmentDescription:string | filters:objArray -> {id:string , name:string , flagvariable:string ,filteroption: string, positive: boolean} |
        //creationType: string -> "sb" o "rfm" | 
        //clientProfile: obj ->  { recencia:{ min:int,  max:int} | monto:{ min:int, max:int } | frecuencia:{ visitas:{ min:int, max:int }compras:{ min:int, max:int }}}} |
        //baseSegments: objArray -> { _id:int , name:string } |
        //STATE INITIALIZATION
        this.state = {
            screen: 'general', // [general || filters || preview]
            screenTitle: this.labels.lblGeneralTitle,
            showDarkPanel: false,
            segmentName:'',
            segmentDescription: '',
            segmentColor: '#43b02a',
            segmentIcon: "tim-icons icon-heart-2",
            creationType: props.segment == undefined ? "sb" : props.segment.type,
            baseSegments: [],
            filters: [],
            displayPreview: false,
            frecuenciaPerfecta: true,
            clientProfile: {
                recencia: { min: 0, max: 0 },
                monto: { min: 0, max: 0 },
                frecuencia: { min: 0, max: 0 },
            }
        }
        if (props.segment != undefined) {
            this.state.segmentName = props.segment.segmentName;
            this.state.segmentDescription = props.segment.segmentDescription;
            this.state.segmentColor = props.segment.segmentColor;
            this.state.segmentIcon = props.segment.segmentIcon;
            this.state.creationType = props.segment.type;
            this.state.baseSegments = props.segment.config.baseSegments;
            this.state.filters = props.segment.config.filters;
            this.state.frecuenciaPerfecta = props.segment.config.perfectF;
            this.state.clientProfile.recencia = props.segment.config.rfmValues.r;
            this.state.clientProfile.monto = props.segment.config.rfmValues.m;
            this.state.clientProfile.frecuencia = props.segment.config.rfmValues.f;
        }
        //FUNCTION BINDING
        //Base Segments Functions
        this.addSegmentsBase = this.addSegmentsBase.bind(this);
        this.checkSegmentBase = this.checkSegmentBase.bind(this);
        this.deleteSegmentBase = this.deleteSegmentBase.bind(this);
        this.handleSegmentName = this.handleSegmentName.bind(this);
        this.handleSegmentDescription = this.handleSegmentDescription.bind(this);
        this.handleSegmentColor = this.handleSegmentColor.bind(this);
        this.handleSegmentIcon = this.handleSegmentIcon.bind(this);
        this.checkAllSegments = this.checkAllSegments.bind(this);
        this.addAllSegments = this.addAllSegments.bind(this);
        this.cleanSegmentBase = this.cleanSegmentBase.bind(this);
        //Utility Functions
        this.crearSegmento = this.crearSegmento.bind(this);
        this.applyFilters = this.applyFilters.bind(this);
        this.checkCreationType = this.checkCreationType.bind(this);
        this.changeCreationType = this.changeCreationType.bind(this);
        this.getMainScreen = this.getMainScreen.bind(this);
        this.changeMainScreen = this.changeMainScreen.bind(this);
        //Client Profile Handlers
        this.handleFrecuencia = this.handleFrecuencia.bind(this);
        this.handleMonto = this.handleMonto.bind(this);
        this.handleRecencia = this.handleRecencia.bind(this);
        this.cleanClientProfile = this.cleanClientProfile.bind(this);
        this.toggleFrecuenciaPerfecta = this.toggleFrecuenciaPerfecta.bind(this);
        this.clientProfileHandlers = {
            recencia: this.handleRecencia,
            visitas: this.handleFrecuenciaVisitas,
            frecuencia: this.handleFrecuencia,
            monto: this.handleMonto,
            clean: this.cleanClientProfile,
            fToggle: this.toggleFrecuenciaPerfecta,
        };
        //Default body to be sent in the petition that creates the segment.
        this.body = {
            "segmentConfig": {
                "segmentName": "",
                "segmentDescription": "",
                "segmentColor": "#43b02a",
                "segmentIcon": 'tim-icons icon-heart-2',
                "bucketId": this.props.bucketId,
                "type": "sb",
                "config": {
                    "baseSegments": [],
                    "rfmValues": { "r": { "min": 0, "max": 0 }, "f": { "min": 0, "max": 0 }, "m": { "min": 0, "max": 0 } },
                    "filters": [],
                    "perfectF": true
                }
            }
        }
        if (props.segment != undefined) {
            this.body.segmentConfig = props.segment;
            delete this.body.segmentConfig._id;
        }
        this.loadingSpinner = <div className="lds-container">
            <div className="lds-ring-w"><div></div><div></div><div></div><div></div></div>
        </div>;
    }

    //0. REACT LIFECYCLE FUNCTIONS ------------------------------------------------------------------------------------------------
    componentDidUpdate(prevProps, prevState) {
        // Segments Changes

        if (prevState.baseSegments != this.state.baseSegments) {
            this.body.segmentConfig.config.baseSegments = this.state.baseSegments;
        }

        if (prevState.segmentName != this.state.segmentName) {
            this.body.segmentConfig.segmentName = this.state.segmentName;
        }

        if (prevState.segmentDescription != this.state.segmentDescription) {
            this.body.segmentConfig.segmentDescription = this.state.segmentDescription;
        }

        if (prevState.segmentColor != this.state.segmentColor) {
            this.body.segmentConfig.segmentColor = this.state.segmentColor;
        }

        if (prevState.segmentIcon != this.state.segmentIcon) {
            this.body.segmentConfig.segmentIcon = this.state.segmentIcon;
        }

        //Lenguage Change
        if (prevProps.labels != this.props.labels) {
            this.labels = this.props.labels;
            this.forceUpdate();
        }

    }

    //1. BASE SEGMENTS SELECTOR FUNCTIONS ------------------------------------------------------------------------------------------------
    addSegmentsBase(id, name) {
        if (id == undefined) return;
        let newBaseSegments = null
        var seg = [{ "_id": id, 'name': name }];
        this.setState(state => {
            newBaseSegments = seg.concat(state.baseSegments)
            return ({ baseSegments: newBaseSegments })
        })
    }

    checkSegmentBase(id) {
        if (id == undefined) return;
        let bool = false;
        if (this.state.baseSegments.length > 0) {
            this.state.baseSegments.forEach(element => {
                if (element._id == id) {
                    bool = true;
                }
            })
        }
        return bool;
    }

    deleteSegmentBase(id) {
        if (id == undefined) return;
        let indexToDelete = null;
        this.setState(state => {
            let clone = [...state.baseSegments];
            clone.forEach((element, index) => {
                if (element._id == id) {
                    indexToDelete = index;
                }
            })

            if (indexToDelete != null) {
                clone.splice(indexToDelete, 1);
            }

            return ({ baseSegments: clone })
        })
    }

    addAllSegments() {
        if (this.state.baseSegments.length == 5) {
            this.cleanSegmentBase()
        }
        else {
            var seg1 = { "_id": 1, 'name': 'A retener' }
            var seg2 = { "_id": 2, 'name': 'Incrementar M.' }
            var seg3 = { "_id": 3, 'name': 'Incrementar F.' }
            var seg4 = { "_id": 4, 'name': 'Traer de Regreso' }
            var seg5 = { "_id": 5, 'name': 'Ocasionales' }
            var base = [seg1, seg2, seg3, seg4, seg5];

            this.setState({ baseSegments: base })
        }

    }

    checkAllSegments() {
        let bool = false;
        if (this.state.baseSegments.length == 5) {
            bool = true
        }
        return bool;
    }

    cleanSegmentBase() {
        this.setState({ baseSegments: [] });
    }


    enableCreate() {
        let bool = true;
        if ((this.state.baseSegments.length > 0) && (this.state.segmentDescription != "")) {
            bool = false;
        }

        if ((this.state.clientProfile.recencia != { min: 0, max: 0 }) && (this.state.clientProfile.monto != { min: 0, max: 0 }) && (this.state.segmentDescription != "")) {
            if (this.state.frecuenciaPerfecta) {
                if (this.state.clientProfile.frecuencia.compras != { min: 0, max: 0 }) {
                    bool = false;
                }
            }
            else {
                if ((this.state.clientProfile.frecuencia.visitas != { min: 0, max: 0 }) && (this.state.clientProfile.frecuencia.compras != { min: 0, max: 0 })) {
                    bool = false;
                }
            }
        }

        return bool;
    }

    //2.FILTER, SEGMENT CONFIGURATION, NAMING FUNCITONS -----------------------------------------------------------------------------------------------------------------

    handleSegmentName(name) {
        if (name == undefined) return;
        this.setState({ segmentName: name })
    }

    handleSegmentDescription(des) {
        if (des == undefined) return;
        this.setState({ segmentDescription: des })
    }

    handleSegmentColor(color) {
        this.setState({ segmentColor: color })
    }

    handleSegmentIcon(icon) {
        this.setState({ segmentIcon: icon })
    }

    handleClientProfile(profile) {
        if (profile == undefined) return;
        this.setState({ clientProfile: profile })
    }

    applyFilters(filters) {
        this.setState({ filters: filters });
    }

    //3. CLIENT PROFILE -----------------------------------------------------------------------------------------------------------------

    handleRecencia(r) {
        if (r == undefined) return;
        this.setState(state => {
            let cp = state.clientProfile;
            cp.recencia = r
            return ({ clientProfile: cp })
        });
    }

    handleFrecuencia(f) {
        if (f == undefined) return;
        this.setState(state => {
            let cp = state.clientProfile;
            cp.frecuencia = f;
            return ({ clientProfile: cp })
        });
    }

    handleMonto(m) {
        if (m == undefined) return;
        this.setState(state => {
            let cp = state.clientProfile;
            cp.monto = m;
            return ({ clientProfile: cp });
        });
    }

    toggleFrecuenciaPerfecta() {
        this.setState(state => ({ frecuenciaPerfecta: !state.frecuenciaPerfecta }))
    }

    cleanClientProfile() {
        this.setState(state => {
            let cp = state.clientProfile;
            cp.frecuencia = { min: 0, max: 0 };
            cp.recencia = { min: 0, max: 0 };
            cp.periodType = 'dias';
            cp.monto = { min: 0, max: 0 }
            return ({ clientProfile: cp })
        })
    }

    //4. UTILITY FUNCTIONS ------------------------------------------------------------------------------------
    async crearSegmento() {
        if (this.props.segment != undefined) /* MOdifies an esisting Segment */ {
            try {
                let cacheId = 'CustomSegmentModify' + this.state.segmentName;
                this.body.segmentConfig.config.filters = this.state.filters;
                this.body.segmentConfig.config.rfmValues.m = this.state.clientProfile.monto;
                this.body.segmentConfig.config.rfmValues.r = this.state.clientProfile.recencia;
                this.body.segmentConfig.config.rfmValues.f = this.state.clientProfile.frecuencia;
                this.body.segmentConfig.type = this.state.creationType;
                this.state.baseSegments.forEach(obj => { cacheId += obj._id })
                this.state.filters.forEach(filter => { cacheId += filter.id })
                let res = await this.props.askFor('segments', 'segments/modifyCustomSegment', [], false, this.body, cacheId);
                if (res != null) {
                    this.props.openSegmentList();
                }
            } catch (err) {
                console.log(err)
            }

        }
        else /* Creates a New Segment */ {
            try {
                let cacheId = 'CustomSegmentCreation' + this.state.segmentName;
                this.body.segmentConfig.config.filters = this.state.filters;
                this.body.segmentConfig.config.rfmValues.m = this.state.clientProfile.monto;
                this.body.segmentConfig.config.rfmValues.r = this.state.clientProfile.recencia;
                if (this.state.frecuenciaPerfecta) {
                    this.body.segmentConfig.config.rfmValues.f = this.state.clientProfile.frecuencia;
                }
                else {
                    let compras = this.state.clientProfile.frecuencia.compras;
                    let visitas = this.state.clientProfile.frecuencia.visitas;
                    let frec = { min: compras.min / visitas.min, max: compras.max / visitas.max };
                    this.body.segmentConfig.config.rfmValues.f = frec;
                }
                this.body.segmentConfig.config.perfectF = this.state.frecuenciaPerfecta;
                this.body.segmentConfig.type = this.state.creationType;
                this.state.baseSegments.forEach(obj => { cacheId += obj._id })
                this.state.filters.forEach(filter => { cacheId += filter.id })
                let res = await this.props.askFor('segments', 'segments/createCustomSegment', [], false, this.body, cacheId);
                if (res != null) {
                    this.props.openSegmentList();
                }
            } catch (err) {
                console.log(err)
            }
        }

    }

    checkCreationType(type) {
        if (type == undefined) return;
        let bool = false
        if (type == this.state.creationType) {
            bool = true;
        }
        return bool;

    }

    changeCreationType(type) {
        if (type == undefined) return;
        //if(type!='baseSegments' || type!='clientProfile') return;
        this.setState({ creationType: type });
    }

    togglePreview() {
        this.setState(state => ({ displayPreview: !state.displayPreview }))
    }

    getNavigationButtons() {
        var buttons = null
        switch (this.state.screen) {
            case "general":
                if(this.state.segmentName !== "" && this.state.segmentDescription !== '')
                buttons = <CSSTransition classNames="animated" timeout={1000} in={true} appear={true}>
                    <Button className="navigationButton" onClick={() => { this.changeMainScreen('filters') }}>{this.labels.lblNext} <span>&#8594;</span></Button>
                </CSSTransition>
                    ; break;
            case "filters": buttons = <>
                <Button className="navigationButton" onClick={() => { this.changeMainScreen('general') }}><span>&#8592;</span> {this.labels.lblBack}</Button>
                <Button className="navigationButton" onClick={() => { this.changeMainScreen('preview') }}>{this.labels.lblNext} <span>&#8594;</span></Button>
            </>; break;
            case "preview": buttons = <>
                <Button className="navigationButton" onClick={() => { this.changeMainScreen('filters') }}><span>&#8592;</span>  {this.labels.lblBack}</Button>
                <Button disabled={this.enableCreate()} className="finButton" onClick={() => { this.toggleDarkPanel(); this.crearSegmento(); }}>{this.labels.lblCreateSegment}</Button>
            </>; break;
        }
        return buttons
    }

    toggleDarkPanel() {
        this.setState(state => { return ({ showDarkPanel: !state.showDarkPanel }) })
    }

    changeMainScreen(screen) {
        var title = "";
        if (screen != null) {
            switch (screen) {
                case "general": title = this.labels.lblGeneralTitle; break;
                case "filters": title = this.labels.lblFiltersTitle; break;
                case "preview": title = this.labels.lblPreviewTitle; break;
            }
            this.setState({ screen: screen, screenTitle: title })
        }
    }

    getMainScreen() {
        var mainScreen = null
        switch (this.state.screen) {
            case "general": mainScreen = <>
                <NameForm
                    segmentName={this.state.segmentName}
                    segmentDescription={this.state.segmentDescription}
                    segmentIcon={this.state.segmentIcon}
                    segmentColor={this.state.segmentColor}
                    handleSegmentName={this.handleSegmentName}
                    handleSegmentDescription={this.handleSegmentDescription}
                    handleSegmentColor={this.handleSegmentColor}
                    handleSegmentIcon={this.handleSegmentIcon}
                    clientProfile={this.state.clientProfile}
                    labels={this.labels}
                />
                <BaseSegmentSelector
                    clientProfile={this.state.clientProfile}
                    addBaseSegment={this.addSegmentsBase}
                    addAllSegments={this.addAllSegments}
                    checkSegmentBase={this.checkSegmentBase}
                    deleteSegmentBase={this.deleteSegmentBase}
                    cleanSegmentBase={this.cleanSegmentBase}
                    allSegmentsSelected={this.checkAllSegments()}
                    handlers={this.clientProfileHandlers}
                    creationType={this.state.creationType}
                    changeCreationType={this.changeCreationType}
                    frecuenciaPerfecta={this.state.frecuenciaPerfecta}
                    segmentColor={this.state.segmentColor}
                    currencySymbol={this.props.currencySymbol}
                    labels={this.labels}
                />
            </>; break;
            case "filters": mainScreen = <>
                <FilterSelector
                    askFor={this.props.askFor}
                    getCatData={this.props.getCatData}
                    selectedFilters={this.state.filters}
                    applyFilters={this.applyFilters}
                    showAvgTicketFilter={this.checkCreationType('sb')}
                    ageBrackets={this.props.ageBrackets}
                    clasificationList={this.props.clasificationList}
                    currencySymbol={this.props.currencySymbol}
                    labels={this.labels}
                    type={this.state.creationType}
                />
            </>; break;
            case "preview": mainScreen = <>
                <FloatingPreview
                    title={this.state.segmentName}
                    crearSegmento={this.crearSegmento}
                    clientProfile={this.state.clientProfile}
                    baseSegments={this.state.baseSegments}
                    segmentIcon={this.state.segmentIcon}
                    segmentColor={this.state.segmentColor}
                    filterList={this.state.filters}
                    display={this.state.displayPreview}
                    showProfile={this.checkCreationType('rfm')}
                    enableCreate={this.enableCreate()}
                    frecuenciaPerfecta={this.state.frecuenciaPerfecta}
                    currencySymbol={this.props.currencySymbol}
                    labels={this.labels}
                />
            </>; break;
        }

        return mainScreen
    }




    //5. RENDER ------------------------------------------------------------------------------------------------
    render() {
        return (<div>
            <Container fluid className="segmentCreator">
                <Row className="getBackRow">
                    <h2>{this.state.screenTitle}</h2>
                    <div>
                        {this.getNavigationButtons()}
                        <Button className="cancelButton" onClick={() => { this.props.openSegmentList() }}>
                            {this.labels.lblCancel}
                        </Button>
                    </div>
                </Row>
                {this.getMainScreen()}
                {/* {Animacion de pantalla negra para hacer focus en la barra de filtros} */}
                <CSSTransition in={this.state.showDarkPanel} classNames="darkPanelT" timeout={500}>
                    <>
                        <div className="darkPanel spinContainer" hidden={!this.state.showDarkPanel} onClick={() => { this.toggleDarkPanel(false) }}>
                            {this.loadingSpinner}
                            {this.labels.loading}
                        </div>

                    </>
                </CSSTransition>
            </Container>
        </div>)
    }
}

export default SegmentCreator;
export { ClientProfile };