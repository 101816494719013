import React from "react";
import classNames from "classnames";
import ReactTooltip from "react-tooltip";
import { NavLink, Link } from "react-router-dom";
// nodejs library to set properties for components
import { PropTypes } from "prop-types";
// reactstrap components
import { Nav } from "reactstrap";
import { MenuLang } from "../../lang";
import { ReactComponent as Icon1 } from '../../assets/icons/icon1.svg';
import { ReactComponent as Icon2 } from '../../assets/icons/icon2.svg';
import { ReactComponent as Icon3 } from '../../assets/icons/icon3.svg';
import { ReactComponent as Icon4 } from '../../assets/icons/icon4.svg';
import { ReactComponent as Icon5 } from '../../assets/icons/icon5.svg';
import { ReactComponent as Icon6 } from '../../assets/icons/icon6.svg';
import { ReactComponent as Icon7 } from '../../assets/icons/icon7.svg';
import { ReactComponent as IconAdmin } from '../../assets/icons/iconAdmin.svg';
// import  icon1 from '../../assets/icons/icon1.png';
// import  icon2 from '../../assets/icons/icon2.png';
// import  icon3 from '../../assets/icons/icon3.png';
// import  icon4 from '../../assets/icons/icon4.png';
// import  icon5 from '../../assets/icons/icon5.png';
// import  icon6 from '../../assets/icons/icon6.png';
// import  icon7 from '../../assets/icons/icon7.png';


class Sidebar extends React.Component {
  constructor(props) {
    super(props);
    this.activeRoute.bind(this);
    this.readyIcon = this.readyIcon.bind(this);
    this.labels = MenuLang(props.lang);
    this.activateMode = this.activateMode.bind(this);
    this.activateMode(props.bgColor);
  }
  // verifies if routeName is the one active (in browser input)
  activeRoute(routeName) {
    return this.props.location.pathname.replace(/[^a-zA-Z ]/g, "") == routeName.replace(/[^a-zA-Z ]/g, "") ? "active" : "";
  }
  componentDidMount() {

  }
  componentDidUpdate(prevProps, prevState) {
    ReactTooltip.rebuild()
    if (prevProps.bgColor != this.props.bgColor) {
      this.activateMode(this.props.bgColor)
    }
  }
  componentWillUnmount() {

  }
  linkOnClick = () => {
    document.documentElement.classList.remove("nav-open");
  };
  readyIcon = (iconClass) => {
    switch (iconClass) {
      case 'ti-dashboard': return <Icon1 />; break; //dashboard
      // case 'ti-medall-alt': return <Icon1/>; break; //salesforce
      case 'ti-layout-grid3': return <Icon2 />; break;  //segmentos
      case 'ti-comments': return <Icon6/>; break;//crm
      case 'ti-filter': return <Icon3 />; break; //filters / mySegments
      case 'ti-download': return <Icon5 />; break; //exports
      case 'ti-panel': return <Icon7 />; break;//options
      case 'ti-stats-up': return <Icon1 />; break;//kpis
      case 'ti-pie-chart': return <Icon1 />; break;//stats
      case 'ti-email': return <Icon4 />; break;//campaigns
      default: return <Icon1 />; break;
    }
  };
  activateMode = mode => {
    switch (mode) {
      case "default.css":
        // document.body.classList.add("white-content");
        document.body.classList.remove("lifeMiles-theme");
        break;
      case "lightTheme.css":
        document.body.classList.add("lifeMiles-theme");
        break;
      default: ; break;
    }
  };
  render() {
    const { bgColor, routes, logo } = this.props;
    let logoImg = null;
    let logoText = null;
    if (logo !== undefined || logo == " ") {
      logoImg = (
        <div className="logo-img">
          <img src={logo} alt="" />
        </div>
      );
    }
    return (
      <div className="sidebar" data={bgColor}>
        <div className="sidebar-wrapper" >
          {logoImg !== null || logoText !== null ? (
            <div className="logo">
              {logoImg}
              {logoText}
            </div>
          ) : null}
          <div className="nav-wrapper">
            <Nav>
              {routes.map((prop, key) => {
                if (prop == null) return null;
                var icon = this.readyIcon(prop.icon);
                return (
                  <li
                    className={
                      this.activeRoute(prop.path)
                    }
                    data-tip={this.labels[prop.name]}
                    key={key}
                  >
                    <NavLink
                      to={prop.path}
                      className="nav-link"
                      activeClassName="active"
                      onClick={this.props.toggleSidebar}
                    >
                      <i>
                        {icon}
                      </i>
                    </NavLink>
                  </li>
                );
              })}
              {this.props.isAdmin ?
                <li
                  className={
                    this.activeRoute('admin')
                  }
                  data-tip="Administrator"
                  key={routes.length + 1}
                >
                  <NavLink
                    to={'admin'}
                    className="nav-link"
                    activeClassName="active"
                    onClick={this.props.toggleSidebar}
                  >
                    <i>
                      <IconAdmin/>
                    </i>
                  </NavLink>
                </li>
                : ""}
            </Nav>
            <Nav className="footer">
              <li>
                <i data-tip="Theme">
                  <Icon7 />
                </i>
              </li>
              <li data-tip={this.props.lang == 'es' ? "Idioma: Español" : "Lenguage: English"} onClick={() => { this.props.handleLangChange() }} className={classNames({ active: this.props.lang == "en" })}>
                <i>
                  <Icon6 />
                </i>
              </li>
            </Nav>
          </div>
        </div>
        <ReactTooltip place="right" />
      </div>
    );
  }
}

Sidebar.defaultProps = {
  bgColor: "default",
  routes: [{}],
  lang: 'es'
};

Sidebar.propTypes = {
  // if true, then instead of the routes[i].name, routes[i].rtlName will be rendered
  // insde the links of this component
  rtlActive: PropTypes.bool,
  bgColor: PropTypes.oneOf(["default", "default.css", "lightTheme.css", "red"]),
  routes: PropTypes.arrayOf(PropTypes.object),
  logo: PropTypes.string,
  lang: PropTypes.string
};

export default Sidebar;
